import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { SimulationShape } from "@/models";
import { AdminContainer } from "@/Layouts/admin";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { ModelDashboardTopNav } from "@/Layouts/admin/ModelDashboardTopNav";
import { SubNavLink } from "@/Layouts/admin/SubNavLink";
import { isCurrent, sapienRoute } from "@/inertia-utils/hooks";
import { Icon } from "react-icons-kit";
import { pieChart } from "react-icons-kit/feather/pieChart";
import { compass } from "react-icons-kit/feather/compass";
import { ArrowUpRightIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { LaravelRoutes } from "@/ziggy-shims";

type Props = SapienPageProps & {
    simulations: (SimulationShape & { is_course: boolean })[];
};

const SimOrCourseDisplay = ({
    simOrCourse,
    isCourse,
    route,
}: {
    simOrCourse: SimulationShape & { is_course: boolean };
    isCourse?: boolean;
    route?: keyof LaravelRoutes;
}) => {
    return (
        <div
            key={simOrCourse.id}
            className="gap-4 divide-y rounded-md bg-white/75 px-4 py-2 hover:bg-white/100
                hover:shadow-sm"
        >
            <div className="flex items-center justify-between gap-2 py-2">
                <span className="text-base font-medium">
                    {simOrCourse.title}
                </span>
                <div className="flex flex-wrap gap-2 text-sm font-semibold">
                    <SubNavLink
                        href={sapienRoute("interactive.index", {
                            simulationSlug: simOrCourse.slug,
                        })}
                        active={isCurrent("interactive.index", route)}
                    >
                        <span className={"mr-2"}>
                            <Icon
                                icon={compass}
                                size={14}
                                style={{
                                    display: "flex",
                                }}
                            />
                        </span>
                        <span>Interactive</span>
                        <span className={"ml-2 opacity-75"}>
                            <ArrowUpRightIcon className="h-3 w-3" />
                        </span>
                    </SubNavLink>
                    <SubNavLink
                        href={sapienRoute("model-builder.index", {
                            simulationSlug: simOrCourse.slug,
                        })}
                        active={isCurrent("model-builder.index", route)}
                    >
                        <span className={"mr-2"}>
                            <Icon
                                icon={pieChart}
                                size={14}
                                style={{
                                    display: "flex",
                                }}
                            />
                        </span>
                        <span>Model Builder</span>
                        <span className={"ml-2 opacity-75"}>
                            <ArrowUpRightIcon className="h-3 w-3" />
                        </span>
                    </SubNavLink>
                    <div className="flex gap-2">
                        <SubNavLink
                            href={sapienRoute("data-sandbox.index", {
                                simulationSlug: simOrCourse.slug,
                            })}
                            active={isCurrent("data-sandbox.index", route)}
                        >
                            <span className={"mr-2"}>
                                <SparklesIcon className="h-5 w-5" />
                            </span>
                            <span>Data Sandbox</span>
                            <span className={"ml-2 opacity-75"}>
                                <ArrowUpRightIcon className="h-3 w-3" />
                            </span>
                        </SubNavLink>
                    </div>
                </div>
            </div>
            <div className="gap-2 pt-2 text-sm">
                <div className="font-medium">Interactive Models</div>
                {simOrCourse.interactiveBlocks?.length > 0 ? (
                    <div className="pl-4">
                        {simOrCourse?.interactiveBlocks?.map((block) => (
                            <div key={block.id} className="font-light">
                                <div>{block.label}</div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="pl-4 text-slate-400">
                        No interactive models
                    </div>
                )}
            </div>
        </div>
    );
};

export default function ModelDashboardIndex({ simulations }: Props) {
    return (
        <AdminContainer headTitle={"Models"}>
            <AdminPageHeader>
                <ModelDashboardTopNav title={"Models"} />
            </AdminPageHeader>
            <AdminPageSection>
                <div className="space-y-4 text-slate-900">
                    {simulations
                        .filter(
                            (sim) =>
                                !sim.is_course &&
                                sim?.interactiveBlocks?.length > 0,
                        )
                        .map((sim) => (
                            <SimOrCourseDisplay
                                key={sim.id}
                                simOrCourse={sim}
                                isCourse={false}
                            />
                        ))}
                    {simulations
                        .filter(
                            (sim) =>
                                sim.is_course &&
                                sim?.interactiveBlocks?.length > 0,
                        )
                        .map((sim) => (
                            <SimOrCourseDisplay
                                key={sim.id}
                                simOrCourse={sim}
                                isCourse={true}
                            />
                        ))}
                </div>
            </AdminPageSection>
        </AdminContainer>
    );
}
