import React, { useCallback, useMemo, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { SimulationShape, UserShape } from "@/models";
import { RoleSection } from "./RoleSection";
import { RoleName } from "./utils";
import { UserEnrollment } from "./UserEnrollment";

type Props = {
    roles: { id: number; name: string }[];
    users: UserShape[];
    simulations: (SimulationShape & { is_course: boolean })[];
};

type TabKey = "Collaboration" | "Enrollment";

const allRolesByTab: Record<TabKey, RoleName[]> = {
    Collaboration: [
        "Sapien Super Admin",
        "Super Admin",
        "Admin",
        "Facilitator",
        "Creator",
    ],
    Enrollment: ["Participant"],
};

const useFilteredRolesByTab = (
    roles: {
        id: number;
        name: string;
    }[],
) => {
    const rolesByTab = useMemo(() => {
        const rolesUserCanManage = roles.map((role) => role.name);
        return Object.entries(allRolesByTab).reduce<{
            [key: string]: RoleName[];
        }>((acc, [tab, tabRoles]) => {
            const filteredRoles = tabRoles.filter((role) =>
                rolesUserCanManage.includes(role),
            );
            if (filteredRoles.length > 0) {
                acc[tab] = filteredRoles;
            }
            return acc;
        }, {});
    }, [roles]);

    return rolesByTab;
};

export const UserList = ({ roles, users, simulations }: Props) => {
    const rolesByTab = useFilteredRolesByTab(roles);

    const [activeTab, setActiveTab] = useState(Object.keys(rolesByTab)[0]);

    const [userEmails, setUserEmails] = useState<string[]>([]);

    const handleSetUserEmails = useCallback(
        (userEmail: string) => {
            setUserEmails([...userEmails, userEmail]);
        },
        [userEmails],
    );

    const usersByRole = useMemo((): { [index: string]: UserShape[] } => {
        return roles?.reduce((carry, role) => {
            const usersWithRole = users?.filter(
                (user) =>
                    user.roles?.some((userRole) => userRole.id === role.id) ||
                    (role.name === "Participant" && !user.roles?.length),
            );

            return {
                ...carry,
                [role.name]: usersWithRole || [],
            };
        }, {});
    }, [roles, users]);

    return (
        <div className="flex w-full justify-center">
            <Tabs.Root
                onValueChange={setActiveTab}
                value={activeTab}
                className="w-full"
            >
                <Tabs.List className="flex w-full rounded-sm border-b border-slate-300/75 dark:border-slate-800">
                    {Object.keys(rolesByTab).map((tabKey) => (
                        <Tabs.Trigger
                            key={tabKey}
                            value={tabKey}
                            className={`text-slate-900 data-[state="active"]:border-b
                            data-[state="active"]:border-indigo-600 data-[state="active"]:text-indigo-700
                            dark:text-slate-300 dark:data-[state="active"]:border-indigo-600
                            dark:data-[state="active"]:text-indigo-500`}
                        >
                            <div className="px-4 py-2 text-sm font-medium hover:bg-slate-200/50 dark:hover:bg-slate-800/25">
                                {tabKey}
                            </div>
                        </Tabs.Trigger>
                    ))}
                </Tabs.List>
                {Object.keys(rolesByTab).map((tabKey) => (
                    <Tabs.Content key={tabKey} value={tabKey}>
                        <div className="space-y-6 py-6">
                            {tabKey == "Collaboration" ? (
                                !!usersByRole &&
                                Object.keys(usersByRole)?.length > 0 &&
                                rolesByTab[tabKey].map((role) => (
                                    <RoleSection
                                        key={role}
                                        role={role}
                                        users={usersByRole[role]}
                                        roles={roles}
                                        userEmails={userEmails}
                                        handleSetUserEmails={
                                            handleSetUserEmails
                                        }
                                    />
                                ))
                            ) : (
                                <>
                                    <UserEnrollment
                                        roles={roles}
                                        users={usersByRole["Participant"]}
                                        simulations={simulations}
                                    />
                                </>
                            )}
                        </div>
                    </Tabs.Content>
                ))}
            </Tabs.Root>
        </div>
    );
};
