import React, { useEffect, useState } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";

const modelSuiteColorModeClassAtom = atom<"" | "dark">("");

export const useModelSuiteColorModeClassAtom = () => {
    const colorModeClass = useAtomValue(modelSuiteColorModeClassAtom);
    return colorModeClass;
};

const useSetModelSuiteColorModeClassAtom = () => {
    const setColorModeClass = useSetAtom(modelSuiteColorModeClassAtom);
    return setColorModeClass;
};

const handleSetModelSuiteThemeInStorage = (
    storageKey: string,
    theme: "" | "dark",
) => {
    localStorage.setItem(storageKey, theme);
};

const handleGetModelSuiteThemeInStorage = (storageKey: string) => {
    return localStorage.getItem(storageKey) as "" | "dark";
};

const themeLocalStorageKey = "model-suite-theme";

export const useModelSuiteTheme = () => {
    const setModelSuiteColorModeClassAtom =
        useSetModelSuiteColorModeClassAtom();

    const [colorModeClass, setColorModeClass] = useState<"" | "dark">(
        handleGetModelSuiteThemeInStorage(themeLocalStorageKey) ?? "dark",
    );

    useEffect(() => {
        setModelSuiteColorModeClassAtom(colorModeClass);
    }, []);

    useEffect(() => {
        if (
            colorModeClass !==
            handleGetModelSuiteThemeInStorage(themeLocalStorageKey)
        ) {
            handleSetModelSuiteThemeInStorage(
                themeLocalStorageKey,
                colorModeClass,
            );
            setModelSuiteColorModeClassAtom(colorModeClass);
        }
    }, [colorModeClass]);

    return { colorModeClass, setColorModeClass };
};

export function ModelSuiteColorModeButton({
    colorModeClass,
    setColorModeClass,
}: {
    colorModeClass: "" | "dark";
    setColorModeClass: (colorModeClass: "" | "dark") => void;
}) {
    return (
        <button
            id="theme-toggle"
            type="button"
            title={
                colorModeClass === "" ? "Toggle Dark Mode" : "Toggle Light Mode"
            }
            className={`rounded-lg p-1 text-sm text-slate-300 hover:text-slate-100 focus:outline-none
                focus:ring-0 lg:p-2`}
            onClick={() => {
                if (colorModeClass === "") {
                    setColorModeClass("dark");
                } else {
                    setColorModeClass("");
                }
            }}
        >
            {colorModeClass === "" && (
                <svg
                    id="theme-toggle-dark-icon"
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                </svg>
            )}
            {colorModeClass === "dark" && (
                <svg
                    id="theme-toggle-light-icon"
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                        fillRule="evenodd"
                        clipRule="evenodd"
                    ></path>
                </svg>
            )}
        </button>
    );
}
