import { useCallback, useEffect, useState } from "react";
import {
    DataCardValueMapItem,
    InputDataItem,
    InteractiveModelBlock,
    InteractiveModuleArchetype,
    InteractiveModuleChartType,
} from "./types";
import {
    getChartValues,
    getFullVariableWatchlist,
    getLocalStorageInteractiveModelObject,
    getNewChartBlocksOnTimespanChange,
    processInputDataObject,
    setLocalStorageInteractiveModelObject,
} from "./utils";
import {
    useChartBlocks,
    useChartValuesMap,
    useChartVariablesMap,
    useComparisonArchetypeId,
    useComparisonArchetypePythonValuesYearly,
    useDashboardDataCardVariables,
    useDefaultArchetype,
    useEditedArchetypeId,
    useModelBlockId,
    useModelTimespan,
    useModelVariables,
    usePythonValuesMonthly,
    usePythonValuesYearly,
    useSetArchetypes,
    useSetChartBlocks,
    useSetChartValuesMap,
    useSetComparisonArchetypeId,
    useSetComparisonArchetypePythonValuesYearly,
    useSetDataCardValuesMap,
    useSetEditedArchetypeId,
    useSetEnableGetArchetypeValues,
    useSetInputDataObject,
    useSetInteractiveModelBlock,
    useSetPythonValuesMonthly,
    useSetPythonValuesYearly,
    useSetTargetVariables,
    useSetVariableWatchlist,
    useVariableWatchlist,
} from "./state";
import { useFormatVariableValue } from "@/hooks";
import { ModelVariable, VariableValue } from "@/models";
import { isEqual } from "lodash";
import {
    useCalculateInputItemChangeMutation,
    useCalculateInputObjectChangeMutation,
} from "./useCalculateInteractiveModel";
import {
    useArchetypeValuesMap,
    useGuidesChartVariablesMap,
    useSetArchetypeValuesMap,
    useSetGuidesChartVariablesMap,
} from "./useInterfaceState";
import { useAuthStore } from "@/hooks/store";

export const useResetInteractiveModuleState = () => {
    const setInputDataObject = useSetInputDataObject();
    const setChartBlocks = useSetChartBlocks();
    const setVariableWatchlist = useSetVariableWatchlist();
    const setComparisonArchetypeId = useSetComparisonArchetypeId();
    const setEditedArchetypeId = useSetEditedArchetypeId();
    const setEnableGetArchetypeValues = useSetEnableGetArchetypeValues();
    const setInteractiveModelBlock = useSetInteractiveModelBlock();
    const setDataCardValuesMap = useSetDataCardValuesMap();
    const setChartValuesMap = useSetChartValuesMap();
    const setArchetypes = useSetArchetypes();
    const setComparisonArchetypePythonValuesYearly =
        useSetComparisonArchetypePythonValuesYearly();
    const setTargetVariables = useSetTargetVariables();
    const setPythonValuesMonthly = useSetPythonValuesMonthly();
    const setPythonValuesYearly = useSetPythonValuesYearly();
    const setGuidesChartVariablesMap = useSetGuidesChartVariablesMap();

    useEffect(() => {
        setInteractiveModelBlock(undefined);
        setInputDataObject({});
        setEditedArchetypeId("");
        setEnableGetArchetypeValues(false);
        setChartBlocks({});
        setTargetVariables([]);
        setPythonValuesMonthly({});
        setPythonValuesYearly({});
        setDataCardValuesMap({});
        setChartValuesMap({});
        setArchetypes([]);
        setComparisonArchetypeId("");
        setComparisonArchetypePythonValuesYearly({});
        setVariableWatchlist({});
        setGuidesChartVariablesMap({});
    }, []);
};

export const useInteractiveModule = ({
    modelBlock,
    inputDataObject,
    targetVariables,
    valuesMonthly,
    valuesYearly,
    archetypeValuesYearly,
    archetypeId,
    simulationSlug,
}: {
    modelBlock: InteractiveModelBlock;
    inputDataObject: Record<string, InputDataItem>;
    targetVariables: ModelVariable[];
    valuesMonthly: Record<string, VariableValue[]>;
    valuesYearly: Record<string, VariableValue[]>;
    archetypeValuesYearly: Record<string, VariableValue[]>;
    archetypeId: string;
    simulationSlug: string;
}) => {
    const setModelBlock = useSetInteractiveModelBlock();
    const setArchetypes = useSetArchetypes();
    const setInputDataObject = useSetInputDataObject();
    const setComparisonArchetypeId = useSetComparisonArchetypeId();
    const setChartBlocks = useSetChartBlocks();
    const setVariableWatchlist = useSetVariableWatchlist();
    const setEditedArchetypeId = useSetEditedArchetypeId();
    const setEnableGetArchetypeValues = useSetEnableGetArchetypeValues();
    const setTargetVariables = useSetTargetVariables();
    const setPythonValuesMonthly = useSetPythonValuesMonthly();
    const setPythonValuesYearly = useSetPythonValuesYearly();
    const setComparisonArchetypePythonValuesYearly =
        useSetComparisonArchetypePythonValuesYearly();
    const setGuidesChartVariablesMap = useSetGuidesChartVariablesMap();

    const { mutateAsync } = useCalculateInputObjectChangeMutation();
    const handleCalculate = useCallback(
        async (
            newInputDataObject: Record<string, InputDataItem>,
            modelBlockId: string,
            archetypeId: string,
            modelVariables: ModelVariable[],
        ) => {
            await mutateAsync({
                model_block_id: modelBlockId,
                archetype_id: archetypeId || null,
                inputDataObject: processInputDataObject(
                    modelVariables,
                    newInputDataObject,
                ),
            });
        },
        [],
    );

    useEffect(() => {
        const modelVariables =
            modelBlock?.modelVariables?.length > 0 ||
            modelBlock?.modelBlocks?.length > 0
                ? [
                      ...modelBlock.modelVariables,
                      ...modelBlock.modelBlocks.reduce(
                          (variableArray, block) => [
                              ...variableArray,
                              ...block.modelVariables,
                          ],
                          [],
                      ),
                  ]
                : [];
        const transformedBlock = {
            ...modelBlock,
            modelVariables: modelVariables,
            simulation_slug: simulationSlug,
        } as InteractiveModelBlock;
        setModelBlock(transformedBlock);

        setArchetypes(modelBlock?.interactiveModuleArchetypes ?? []);

        setEditedArchetypeId(archetypeId || "");

        let defaultArchetype: InteractiveModuleArchetype =
            modelBlock.interactiveModuleArchetypes?.find(
                (archetype) => archetype.is_default,
            ) ?? {
                model_block_id: modelBlock.id,
                name: "Archetype",
                archetype: { inputDataObject: {} },
            };

        setComparisonArchetypeId(defaultArchetype?.id ?? "");

        if (
            !!defaultArchetype?.id &&
            (!archetypeValuesYearly ||
                Object.keys(archetypeValuesYearly)?.length == 0)
        ) {
            setEnableGetArchetypeValues(true);
        } else {
            setEnableGetArchetypeValues(false);
        }

        const newInputDataObject = processInputDataObject(
            modelVariables,
            inputDataObject,
            processInputDataObject(
                modelVariables,
                defaultArchetype.archetype.inputDataObject,
            ),
        );

        setInputDataObject(newInputDataObject);

        setTargetVariables(targetVariables);
        setPythonValuesMonthly(valuesMonthly);
        setPythonValuesYearly(valuesYearly);
        setComparisonArchetypePythonValuesYearly(archetypeValuesYearly);

        // check that we have an input data object, it's complete, and user data from cache exists
        const doesNotRequireCalculation =
            isEqual(inputDataObject, newInputDataObject) &&
            targetVariables?.length > 0;

        if (
            !doesNotRequireCalculation &&
            Object.keys(newInputDataObject)?.length > 0
        ) {
            handleCalculate(
                newInputDataObject,
                modelBlock.id,
                archetypeId,
                modelVariables,
            );
        }

        const localStorageInteractiveModelObject =
            getLocalStorageInteractiveModelObject(modelBlock.id);

        const localStorageChartBlocks =
            localStorageInteractiveModelObject?.chartBlocks ?? {};
        const newChartBlocks = {
            ...localStorageChartBlocks,
            ...modelBlock.interactiveModuleCharts.reduce((map, chartBlock) => {
                return { ...map, [chartBlock.id]: chartBlock };
            }, {}),
        };
        setChartBlocks(newChartBlocks);

        const localStorageVariableWatchlist =
            localStorageInteractiveModelObject?.variableWatchlist ?? {};
        const newVariableWatchlist = getFullVariableWatchlist(
            { ...localStorageVariableWatchlist },
            modelVariables,
        );
        setVariableWatchlist(newVariableWatchlist);

        const localStorageArchetypeChartVariables =
            localStorageInteractiveModelObject?.archetypeChartVariables ?? {};
        if (Object.keys(localStorageArchetypeChartVariables)?.length > 0) {
            setGuidesChartVariablesMap(localStorageArchetypeChartVariables);
        }
    }, [modelBlock, archetypeId]);

    const chartBlocks = useChartBlocks();
    const variableWatchlist = useVariableWatchlist();
    const guidesChartVariablesMap = useGuidesChartVariablesMap();
    const modelTimespan = useModelTimespan();

    useEffect(() => {
        if (
            Object.keys(chartBlocks)?.length > 0 &&
            Object.values(chartBlocks).every(
                (chartBlock) => chartBlock.model_block_id == modelBlock?.id,
            ) &&
            Object.values(chartBlocks).some(
                (chartBlock) => chartBlock.time_index > modelTimespan - 1,
            )
        ) {
            const newChartBlocks = getNewChartBlocksOnTimespanChange(
                chartBlocks,
                modelTimespan,
            );
            setChartBlocks(newChartBlocks);
        }
    }, [modelTimespan]);

    useEffect(() => {
        if (!!modelBlock?.id) {
            setLocalStorageInteractiveModelObject(
                modelBlock.id,
                chartBlocks,
                variableWatchlist,
                guidesChartVariablesMap,
            );
        }
    }, [chartBlocks, variableWatchlist, guidesChartVariablesMap]);

    return {};
};

export const useHandleSetInputDataItem = () => {
    const { mutateAsync, isPending } = useCalculateInputItemChangeMutation();

    const handleSetInputDataItem = useCallback(
        async (inputDataItem: InputDataItem) => {
            await mutateAsync(inputDataItem);
        },
        [],
    );

    return { handleSetInputDataItem, isPending };
};

export const useHandleSetInputDataObjectFull = () => {
    const modelVariables = useModelVariables();
    const modelBlockId = useModelBlockId();
    const editedArchetypeId = useEditedArchetypeId();
    const { mutateAsync, isPending } = useCalculateInputObjectChangeMutation();

    const handleSetInputDataObjectFull = useCallback(
        async (newInputDataObject: Record<string, InputDataItem>) => {
            await mutateAsync({
                model_block_id: modelBlockId,
                archetype_id: editedArchetypeId || null,
                inputDataObject: processInputDataObject(
                    modelVariables,
                    newInputDataObject,
                ),
            });
        },
        [modelVariables, modelBlockId, editedArchetypeId],
    );

    return { handleSetInputDataObjectFull, isPending };
};

export const useHandleInputDataReset = () => {
    const modelVariables = useModelVariables();
    const modelBlockId = useModelBlockId();
    const editedArchetypeId = useEditedArchetypeId();
    const defaultArchetype = useDefaultArchetype();
    const { mutateAsync, isPending } = useCalculateInputObjectChangeMutation();

    const handleInputDataReset = useCallback(async () => {
        if (!modelVariables?.length) return;
        let newInputDataObject = processInputDataObject(
            modelVariables,
            {},
            !!defaultArchetype &&
                Object.keys(defaultArchetype?.archetype?.inputDataObject)
                    ?.length > 0
                ? defaultArchetype.archetype.inputDataObject
                : undefined,
        );
        await mutateAsync({
            model_block_id: modelBlockId,
            archetype_id: editedArchetypeId || null,
            inputDataObject: newInputDataObject,
        });
    }, [modelVariables, modelBlockId, editedArchetypeId, defaultArchetype]);

    return { handleInputDataReset, isPending };
};

export const useHandleChartAndDataCardValueMaps = () => {
    const formatVariableValue = useFormatVariableValue();
    const chartBlocks = useChartBlocks();
    const chartVariablesMap = useChartVariablesMap();
    const pythonValuesYearly = usePythonValuesYearly();
    const pythonValuesMonthly = usePythonValuesMonthly();
    const dashboardDataCardVariables = useDashboardDataCardVariables();
    const setChartValuesMap = useSetChartValuesMap();
    const setDataCardValuesMap = useSetDataCardValuesMap();
    // const comparisonArchetypePythonValuesYearly =
    //     useComparisonArchetypePythonValuesYearly();

    useEffect(() => {
        if (
            Object.keys(chartBlocks)?.length > 0 &&
            Object.keys(chartVariablesMap)?.length > 0
        ) {
            const newChartValuesMap = Object.values(chartBlocks)?.reduce(
                (map, chartBlock) => {
                    const chartValues = getChartValues(
                        chartVariablesMap[chartBlock.id] ?? [],
                        chartBlock,
                        chartBlock.time_increment == "year"
                            ? pythonValuesYearly ?? {}
                            : pythonValuesMonthly ?? {},
                        formatVariableValue,
                    );
                    return {
                        ...map,
                        [chartBlock.id]: chartValues ?? {},
                    };
                },
                {},
            );
            setChartValuesMap(newChartValuesMap);
        }
    }, [
        chartBlocks,
        chartVariablesMap,
        pythonValuesYearly,
        pythonValuesMonthly,
    ]);

    useEffect(() => {
        if (dashboardDataCardVariables?.length > 0) {
            const newDataCardValuesMap: Record<string, DataCardValueMapItem> =
                dashboardDataCardVariables?.reduce((map, variable) => {
                    // const value =
                    //     Object.keys(pythonValuesYearly)?.length > 0
                    //         ? pythonValuesYearly[variable.id][
                    //               pythonValuesYearly[variable.id].length - 1
                    //           ]
                    //         : undefined;

                    const finalValue =
                        Object.keys(pythonValuesYearly)?.length > 0 &&
                        !!pythonValuesYearly[variable.id]
                            ? pythonValuesYearly[variable.id][
                                  pythonValuesYearly[variable.id].length - 1
                              ]
                            : undefined;
                    const initialValue =
                        Object.keys(pythonValuesYearly)?.length > 0 &&
                        !!pythonValuesYearly[variable.id]
                            ? pythonValuesYearly[variable.id][0]
                            : undefined;

                    // const archetypeValue =
                    //     Object.keys(comparisonArchetypePythonValuesYearly)
                    //         ?.length > 0 &&
                    //     comparisonArchetypePythonValuesYearly[variable.id]
                    //         ?.length > 0
                    //         ? comparisonArchetypePythonValuesYearly[
                    //               variable.id
                    //           ][
                    //               comparisonArchetypePythonValuesYearly[
                    //                   variable.id
                    //               ].length - 1
                    //           ]
                    //         : undefined;
                    // const difference =
                    //     value !== undefined && archetypeValue !== undefined
                    //         ? variable.is_integer
                    //             ? Math.round(value.numerical_value) -
                    //               Math.round(archetypeValue.numerical_value)
                    //             : (Math.round(value.numerical_value * 100) -
                    //                   Math.round(
                    //                       archetypeValue.numerical_value * 100,
                    //                   )) /
                    //               100
                    //         : undefined;

                    const differenceNumerical =
                        finalValue !== undefined && initialValue !== undefined
                            ? variable.is_integer
                                ? Math.round(finalValue.numerical_value) -
                                  Math.round(initialValue.numerical_value)
                                : (Math.round(
                                      finalValue.numerical_value * 100,
                                  ) -
                                      Math.round(
                                          initialValue.numerical_value * 100,
                                      )) /
                                  100
                            : undefined;

                    const dataCardValueMapItem: DataCardValueMapItem = {
                        // variableValue: value,
                        // formattedValue:
                        //     value !== undefined
                        //         ? formatVariableValue(
                        //               variable.unit,
                        //               value.numerical_value,
                        //               variable.is_integer,
                        //           )
                        //         : "",
                        // archetypeValue: archetypeValue,
                        // formattedArchetypeValue:
                        //     archetypeValue !== undefined
                        //         ? formatVariableValue(
                        //               variable.unit,
                        //               archetypeValue.numerical_value,
                        //               variable.is_integer,
                        //           )
                        //         : "",
                        // difference: difference,
                        // formattedDifference:
                        //     difference !== undefined
                        //         ? formatVariableValue(
                        //               variable.unit,
                        //               difference,
                        //               variable.is_integer,
                        //           )
                        //         : "",

                        finalValue: finalValue,
                        finalFormatted:
                            finalValue !== undefined
                                ? formatVariableValue(
                                      variable.unit,
                                      finalValue.numerical_value,
                                      variable.is_integer,
                                  )
                                : "",
                        initialValue: initialValue,
                        initialFormatted:
                            initialValue !== undefined
                                ? formatVariableValue(
                                      variable.unit,
                                      initialValue.numerical_value,
                                      variable.is_integer,
                                  )
                                : "",
                        differenceNumerical: differenceNumerical,
                        differenceFormatted:
                            differenceNumerical !== undefined
                                ? formatVariableValue(
                                      variable.unit,
                                      differenceNumerical,
                                      variable.is_integer,
                                  )
                                : "",
                    };
                    return {
                        ...map,
                        [variable.id]: dataCardValueMapItem,
                    };
                }, {});
            setDataCardValuesMap(newDataCardValuesMap);
        }
    }, [
        dashboardDataCardVariables,
        pythonValuesYearly,
        // comparisonArchetypePythonValuesYearly,
    ]);
};

export const useDashboardChartValuesMap = () => {
    const formatVariableValue = useFormatVariableValue();
    const modelTimespan = useModelTimespan();
    const chartBlocks = useChartBlocks();
    const chartVariablesMap = useChartVariablesMap();
    const chartValuesMap = useChartValuesMap();
    const pythonValuesYearly = usePythonValuesYearly();
    const pythonValuesMonthly = usePythonValuesMonthly();

    const [dashboardChartValuesMap, setDashboardChartValuesMap] =
        useState(chartValuesMap);

    const [chartTimeIndex, setChartTimeIndex] = useState<number>(
        modelTimespan > 0 ? modelTimespan - 1 : 0,
    );

    useEffect(() => {
        if (modelTimespan > 0) {
            setChartTimeIndex(modelTimespan - 1);
        }
    }, [modelTimespan]);

    useEffect(() => {
        setDashboardChartValuesMap(chartValuesMap);
    }, [chartValuesMap]);

    const handleSetChartTimeIndex = useCallback(
        (timeIndex: number) => {
            setChartTimeIndex(timeIndex);

            if (
                Object.keys(chartBlocks)?.length > 0 &&
                Object.keys(chartVariablesMap)?.length > 0 &&
                Object.keys(dashboardChartValuesMap)?.length > 0
            ) {
                const newChartValuesMap = Object.keys(
                    dashboardChartValuesMap,
                )?.reduce((map, cbId) => {
                    return {
                        ...map,
                        [cbId]:
                            chartBlocks[cbId].chart_type !==
                            InteractiveModuleChartType.PIE
                                ? dashboardChartValuesMap[cbId]
                                : getChartValues(
                                      chartVariablesMap[cbId] ?? [],
                                      {
                                          ...chartBlocks[cbId],
                                          time_index: timeIndex,
                                      },
                                      chartBlocks[cbId].time_increment == "year"
                                          ? pythonValuesYearly ?? {}
                                          : pythonValuesMonthly ?? {},
                                      formatVariableValue,
                                  ),
                    };
                }, {});
                setDashboardChartValuesMap(newChartValuesMap);
            }
        },
        [
            chartBlocks,
            chartVariablesMap,
            dashboardChartValuesMap,
            pythonValuesYearly,
            pythonValuesMonthly,
        ],
    );

    return { dashboardChartValuesMap, chartTimeIndex, handleSetChartTimeIndex };
};

export const useHandleArchetypeValuesMap = () => {
    const { isSapienSuperAdmin } = useAuthStore();
    const archetypeValuesMap = useArchetypeValuesMap();
    const setArchetypeValuesMap = useSetArchetypeValuesMap();
    const comparisonArchetypeId = useComparisonArchetypeId();
    const comparisonArchetypePythonValuesYearly =
        useComparisonArchetypePythonValuesYearly();
    const editedArchetypeId = useEditedArchetypeId();
    const pythonValuesYearly = usePythonValuesYearly();

    useEffect(() => {
        if (
            !!comparisonArchetypePythonValuesYearly &&
            Object.keys(comparisonArchetypePythonValuesYearly)?.length &&
            !!comparisonArchetypeId
        ) {
            setArchetypeValuesMap({
                ...archetypeValuesMap,
                [comparisonArchetypeId]: comparisonArchetypePythonValuesYearly,
            });
        }
    }, [comparisonArchetypePythonValuesYearly]);

    useEffect(() => {
        if (
            isSapienSuperAdmin &&
            !!editedArchetypeId &&
            !!pythonValuesYearly &&
            Object.keys(pythonValuesYearly)?.length
        ) {
            setArchetypeValuesMap({
                ...archetypeValuesMap,
                [editedArchetypeId]: pythonValuesYearly,
            });
        }
    }, [pythonValuesYearly, editedArchetypeId]);
};
