import React from "react";
import { Head } from "@inertiajs/react";
import Authenticated from "../Authenticated";

export const AdminContainer = ({
    children,
    headTitle,
    isELearningContext,
}: {
    children?: React.ReactNode;
    headTitle?: string;
    isELearningContext?: boolean;
}) => {
    return (
        <>
            <Head title={headTitle} />
            <Authenticated isELearningContext={isELearningContext}>
                <>
                    <div className="h-[calc(100vh-64px)] bg-slate-100 text-slate-900">
                        <div className="">{children}</div>
                    </div>
                </>
            </Authenticated>
        </>
    );
};
