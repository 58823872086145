import React, { FC, ReactNode } from "react";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import { useAuthStore } from "@/hooks/store";
import { SubNavLink } from "./SubNavLink";
import { Icon } from "react-icons-kit";
import { layout } from "react-icons-kit/feather/layout";
import { pieChart } from "react-icons-kit/feather/pieChart";
import { clipboard } from "react-icons-kit/feather/clipboard";
import { compass } from "react-icons-kit/feather/compass";
import { ic_lightbulb_outline } from "react-icons-kit/md/ic_lightbulb_outline";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { layers } from "react-icons-kit/feather/layers";

export const SimBuilderTopNav: FC<{
    title: string;
    slug: string;
    id: string;
    isCourse: boolean;
    children?: ReactNode;
}> = ({ title, slug, id, isCourse, children }) => {
    const { props } = usePage();
    const { isSapienSuperAdmin } = useAuthStore();

    return (
        <div className="flex flex-col items-center gap-4 md:flex-row md:gap-8">
            {!isCourse && (
                <>
                    <div>
                        <span className="text-nowrap text-xl font-semibold text-slate-900">
                            {title}
                        </span>
                        {/* {isCurrent("simulation.projects.show", props.route) ? (
                            <span className="text-nowrap text-xl font-semibold text-slate-900">
                                {title}
                            </span>
                        ) : (
                            <Link
                                href={sapienRoute("simulation.projects.show", {
                                    project: slug,
                                })}
                            >
                                <div className="text-nowrap text-xl font-semibold text-slate-900 hover:underline">
                                    {title}
                                </div>
                            </Link>
                        )} */}
                    </div>
                    <div className="flex w-full flex-wrap justify-between gap-2 text-sm font-semibold">
                        <div className="flex gap-2">
                            <SubNavLink
                                href={sapienRoute("simulation.projects.show", {
                                    project: slug,
                                })}
                                active={isCurrent(
                                    "simulation.projects.show",
                                    props.route,
                                )}
                            >
                                <span className={"mr-2"}>
                                    <Icon
                                        icon={layers}
                                        size={14}
                                        style={{
                                            display: "flex",
                                        }}
                                    />
                                </span>
                                <span>Rounds</span>
                            </SubNavLink>
                            <SubNavLink
                                href={sapienRoute(
                                    "simulation.projects.detail",
                                    {
                                        simulationSlug: slug,
                                    },
                                )}
                                active={isCurrent(
                                    "simulation.projects.detail",
                                    props.route,
                                )}
                            >
                                <span className={"mr-2"}>
                                    <Icon
                                        icon={layout}
                                        size={14}
                                        style={{
                                            display: "flex",
                                        }}
                                    />
                                </span>
                                <span>Detail</span>
                            </SubNavLink>
                            <SubNavLink
                                href={sapienRoute("facilitation.index", {
                                    simulationSlug: slug,
                                })}
                                active={isCurrent(
                                    "facilitation.index",
                                    props.route,
                                    ["facilitation.show"],
                                )}
                            >
                                <span className={"mr-2"}>
                                    <Icon
                                        icon={clipboard}
                                        size={14}
                                        style={{
                                            display: "flex",
                                        }}
                                    />
                                </span>
                                <span>Facilitation</span>
                            </SubNavLink>
                            {!!isSapienSuperAdmin && (
                                <SubNavLink
                                    href={sapienRoute("discovery.index", {
                                        simulationSlug: slug,
                                    })}
                                    active={isCurrent(
                                        "discovery.index",
                                        props.route,
                                        [
                                            "discovery.show-data",
                                            "discovery.show-statistics",
                                        ],
                                    )}
                                >
                                    <span className={"mr-2"}>
                                        <Icon
                                            icon={ic_lightbulb_outline}
                                            size={14}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Discovery</span>
                                </SubNavLink>
                            )}
                        </div>
                        <div className="flex gap-2">
                            <SubNavLink
                                href={sapienRoute("interactive.index", {
                                    simulationSlug: slug,
                                })}
                                active={isCurrent(
                                    "interactive.index",
                                    props.route,
                                )}
                            >
                                <span className={"mr-2"}>
                                    <Icon
                                        icon={compass}
                                        size={14}
                                        style={{
                                            display: "flex",
                                        }}
                                    />
                                </span>
                                <span>Interactive</span>
                                <span className={"ml-2 opacity-75"}>
                                    <ArrowUpRightIcon className="h-3 w-3" />
                                </span>
                            </SubNavLink>
                            {!!isSapienSuperAdmin && (
                                <SubNavLink
                                    href={sapienRoute("model-builder.index", {
                                        simulationSlug: slug,
                                    })}
                                    active={isCurrent(
                                        "model-builder.index",
                                        props.route,
                                    )}
                                >
                                    <span className={"mr-2"}>
                                        <Icon
                                            icon={pieChart}
                                            size={14}
                                            style={{
                                                display: "flex",
                                            }}
                                        />
                                    </span>
                                    <span>Model Builder</span>
                                    <span className={"ml-2 opacity-75"}>
                                        <ArrowUpRightIcon className="h-3 w-3" />
                                    </span>
                                </SubNavLink>
                            )}
                        </div>
                    </div>
                </>
            )}
            {!!isCourse && (
                <>
                    <span className="text-nowrap text-xl font-semibold text-slate-900">
                        {title}
                    </span>
                    {/* <div>
                        <Link
                            href={sapienRoute("e-learning.courses.edit", {
                                course: id,
                            })}
                        >
                            <div className="text-nowrap text-xl font-semibold text-slate-900 hover:underline">
                                {title}
                            </div>
                        </Link>
                    </div> */}
                    <div className="flex flex-wrap gap-2 text-sm font-semibold">
                        <SubNavLink
                            href={sapienRoute("e-learning.courses.edit", {
                                course: id,
                            })}
                            active={isCurrent(
                                "e-learning.courses.edit",
                                props.route,
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={layers}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Lessons</span>
                        </SubNavLink>
                        <SubNavLink
                            href={sapienRoute("facilitation.index", {
                                simulationSlug: slug,
                            })}
                            active={isCurrent(
                                "facilitation.index",
                                props.route,
                                ["facilitation.show"],
                            )}
                        >
                            <span className={"mr-2"}>
                                <Icon
                                    icon={clipboard}
                                    size={14}
                                    style={{
                                        display: "flex",
                                    }}
                                />
                            </span>
                            <span>Facilitation</span>
                        </SubNavLink>
                    </div>
                </>
            )}
        </div>
    );
};
