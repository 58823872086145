import React from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@radix-ui/react-popover";
import Icon from "react-icons-kit";
import { info } from "react-icons-kit/feather/info";
import { InteractiveDashboardChart } from "./InteractiveDashboardChart";
import { InteractiveDashboardCard } from "./InteractiveDashboardCard";
import { DashboardWidgetWrapper } from "../shared";
import {
    useChartBlocks,
    useDashboardDataCardVariables,
    useDataCardValuesMap,
    useModelTimespan,
} from "../state";
import { useDashboardChartValuesMap } from "../useInteractiveModule";
import { DataCardValueMapItem } from "../types";

const InteractiveSectionDashboardComponent = ({}: {}) => {
    const modelTimespan = useModelTimespan();
    const dashboardDataCardVariables = useDashboardDataCardVariables();
    const dataCardValuesMap = useDataCardValuesMap();
    const chartBlocks = useChartBlocks();
    const { dashboardChartValuesMap, chartTimeIndex, handleSetChartTimeIndex } =
        useDashboardChartValuesMap();

    return (
        <div className="flex w-full flex-col space-y-6">
            <div
                className="flex flex-col justify-between space-x-0 space-y-2 sm:flex-row sm:items-center
                    sm:space-x-2 sm:space-y-0"
            >
                <h1 className="text-xl font-bold text-slate-700 dark:text-slate-300">
                    {"Dashboard"}
                </h1>
                <div className="flex items-center space-x-2 text-slate-600 dark:text-slate-400">
                    <Popover>
                        <PopoverTrigger
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div
                                title={"Dashboard Information"}
                                className="relative inline-flex items-center text-sm font-medium"
                            >
                                <span className="sr-only">
                                    Dashboard Information
                                </span>
                                <Icon
                                    icon={info}
                                    size={20}
                                    className="flex h-8 items-center justify-center text-slate-400 transition-all
                                        hover:text-slate-600 focus:outline-none focus:ring-0 dark:text-slate-500
                                        dark:hover:text-slate-300"
                                    style={{ display: "flex" }}
                                />
                            </div>
                        </PopoverTrigger>
                        <PopoverContent
                            align={"end"}
                            sideOffset={0}
                            className="mr-2 mt-2 min-w-[300px] max-w-[300px] rounded-md border border-slate-200
                                bg-slate-100 p-4 text-sm text-slate-600 dark:border-slate-700 dark:bg-slate-800
                                dark:text-slate-400"
                            style={{
                                zIndex: 9999,
                            }}
                        >
                            <div className="">
                                The
                                <span className="font-semibold text-slate-700 dark:text-slate-300">{` Dashboard `}</span>
                                highlights key metrics based on the inputs you
                                provide and the model timespan you select in the
                                <span className="font-semibold text-slate-700 dark:text-slate-300">{` Settings `}</span>
                                section.
                                <br />
                                See the
                                <span className="font-semibold text-slate-700 dark:text-slate-300">{` Data `}</span>
                                section to explore outputs in more detail and
                                the
                                <span className="font-semibold text-slate-700 dark:text-slate-300">{` Insights `}</span>
                                section to find your progress with regard to
                                certain benchmarks.
                                <br />
                                Use the star icons to add or remove variables
                                presented in the customizable
                                <span className="font-semibold text-slate-700 dark:text-slate-300">{` Watchlist `}</span>
                                section and in the expandable drawer at the
                                bottom of the page.
                            </div>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
            <div className="space-y-4">
                <div
                    className={
                        "grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
                    }
                >
                    {dashboardDataCardVariables?.length > 0 &&
                        dashboardDataCardVariables.map((variable) => (
                            <DashboardWidgetWrapper key={variable.id}>
                                <InteractiveDashboardCard
                                    key={variable.id}
                                    modelVariable={variable}
                                    dataCardValueMapItem={
                                        dataCardValuesMap[variable.id] ||
                                        ({} as DataCardValueMapItem)
                                    }
                                />
                            </DashboardWidgetWrapper>
                        ))}
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                    {Object.values(chartBlocks)
                        ?.filter(
                            (chartBlock) => chartBlock.variableIds?.length > 0,
                        )
                        .map((chartBlock) => (
                            <DashboardWidgetWrapper key={chartBlock.id}>
                                <InteractiveDashboardChart
                                    key={chartBlock.id}
                                    modelTimespan={modelTimespan}
                                    setChartTimeIndex={handleSetChartTimeIndex}
                                    chartBlock={{
                                        ...chartBlock,
                                        time_index: chartTimeIndex,
                                    }}
                                    chartValues={
                                        Object.keys(dashboardChartValuesMap)
                                            ?.length > 0
                                            ? dashboardChartValuesMap[
                                                  chartBlock.id
                                              ] ?? {}
                                            : {}
                                    }
                                />
                            </DashboardWidgetWrapper>
                        ))}
                </div>
            </div>
        </div>
    );
};

export const InteractiveSectionDashboard = React.memo(
    InteractiveSectionDashboardComponent,
);
