import React, { FC, ReactNode, useState } from "react";
import { Cohort } from "@/models";
import DeleteCohortOverlay from "./DeleteCohortOverlay";
import { TrashIcon } from "@heroicons/react/24/solid";

export const CohortRowLayout: FC<{
    cohort: Cohort;
    children?: ReactNode;
    button?: ReactNode;
}> = ({ cohort, children, button }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <div
            key={cohort.id}
            style={{ minHeight: "100%" }}
            className="relative flex flex-row justify-between space-x-4 rounded-md bg-white/75 p-4
                shadow-sm transition-all hover:bg-white/100 hover:shadow-md"
        >
            <div className="flex w-full flex-col justify-between space-y-2">
                {!!children ? (
                    children
                ) : (
                    <div className="flex flex-row items-center pb-2.5">
                        <span className="text-lg font-medium">
                            {cohort.title}
                        </span>
                    </div>
                )}
                <div className="flex flex-col whitespace-nowrap text-xs tracking-tight text-slate-600">
                    <span>
                        {`Created ${cohort.created_at?.substring(0, 10)}`}
                    </span>
                </div>
            </div>
            <div className="flex items-center space-x-2">
                {button}
                <button
                    className="rounded-full p-3 transition-all hover:bg-slate-100 hover:text-red-600"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDeleting(true);
                    }}
                >
                    <span className="sr-only">Delete</span>
                    <TrashIcon className="h-5 w-5" />
                </button>
            </div>
            <div className="hidden space-x-2 divide-x sm:flex md:space-x-4">
                <div className="flex flex-col justify-evenly border-l pl-2 md:pl-4">
                    <div className="flex min-w-20 text-sm text-slate-600">{`Teams`}</div>
                    <div className="text-sm font-semibold">{`${cohort.teams_count}`}</div>
                </div>
                <div className="flex flex-col justify-evenly pl-2 md:pl-4">
                    <div className="flex min-w-20 text-sm text-slate-600">{`Participants`}</div>
                    <div className="text-sm font-semibold">{`${cohort.team_user_rounds_count}`}</div>
                </div>
                <div className="flex flex-col justify-evenly pl-2 md:pl-4">
                    <div className="flex min-w-20 text-sm text-slate-600">{`Selections`}</div>
                    <div className="text-sm font-semibold">{`${cohort.selections_count}`}</div>
                </div>
            </div>
            {!!isDeleting && (
                <DeleteCohortOverlay
                    cohort={cohort}
                    cancelDelete={() => setIsDeleting(false)}
                />
            )}
        </div>
    );
};
