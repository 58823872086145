import { atom, useAtom } from "jotai";
import { useCallback } from "react";
import { useEditedContentBlock } from "./useEditedContentBlock";
import { useEditedType } from "./editFunctions";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ContentBlockType } from "@/models";
import {
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "../useContentBlockState";
import { useSaveContentBlock } from "../content-block-management/useSaveContentBlock";
import { transformImageBlock } from "./theme-dropdowns/images-and-text/transformImageBlock";

const sidebarAtom = atom(false);

export function useLessonDesignSidebarState() {
    const [isOpen, setIsOpen] = useAtom(sidebarAtom);
    const { setEditedContentBlockId, editedContentBlock } =
        useEditedContentBlock();
    const { setEditedContentBlockType, editedContentBlockType } =
        useEditedType();
    const { mutate } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const editedContentBlockWithChildren = useGetContentBlockWithChildren(
        editedContentBlock?.id,
    );
    const handleClose = useCallback(() => {
        if (
            !!editedContentBlockWithChildren &&
            editedContentBlockType === ContentBlockType.Image
        ) {
            const updatedBlock = transformImageBlock(
                editedContentBlockWithChildren,
                editedContentBlockWithChildren.theme?.variant,
            );
            setContentBlock(updatedBlock);
            mutate(updatedBlock);
        }

        setIsOpen(false);
        setEditedContentBlockId(false);
        setEditedContentBlockType(false);

        SapienInertia.reload({
            only: ["lesson"],
        });
    }, [isOpen, editedContentBlockWithChildren]);

    return {
        isOpen,
        setIsOpen,
        handleClose,
    };
}
