import {
    ModelBlock,
    ModelVariable,
    ModelVariableDataType,
    VariableValue,
} from "@/models";
import { atom, useAtomValue, useSetAtom } from "jotai";
import {
    ChartBlock,
    DataCardValueMapItem,
    FormattedChartValue,
    InputDataItem,
    InteractiveModelBlock,
    InteractiveModuleArchetype,
} from "./types";
import { showSettingsTableRowsAtom } from "./useInterfaceState";
import { groupBy } from "lodash";
import {
    getIsModelTimeVariable,
    getTimespanFromInputDataObject,
} from "./utils";

// MODULE DETAIL DATA STATE

const interactiveModelBlockAtom = atom<InteractiveModelBlock>(
    undefined as InteractiveModelBlock,
);
// export const useInteractiveModelBlock = () => {
//     const modelBlock = useAtomValue(interactiveModelBlockAtom);
//     return modelBlock;
// };
export const useSetInteractiveModelBlock = () => {
    const setModelBlock = useSetAtom(interactiveModelBlockAtom);
    return setModelBlock;
};

const modelBlockIdAtom = atom<string | undefined>((get) => {
    const modelBlock = get(interactiveModelBlockAtom);
    return modelBlock?.id ?? undefined;
});
export const useModelBlockId = () => {
    const modelBlockId = useAtomValue(modelBlockIdAtom);
    return modelBlockId;
};

const simulationSlugAtom = atom<string | undefined>((get) => {
    const modelBlock = get(interactiveModelBlockAtom);
    return modelBlock?.simulation_slug ?? undefined;
});
export const useSimulationSlug = () => {
    const simulationSlug = useAtomValue(simulationSlugAtom);
    return simulationSlug;
};

const settingsModelBlocksAtom = atom<ModelBlock[] | undefined>((get) => {
    const modelBlock = get(interactiveModelBlockAtom);
    const modelTimeVariableId = get(modelTimeVariableIdAtom);
    return (
        modelBlock?.modelBlocks?.filter((block) =>
            block.modelVariables.some(
                (variable) =>
                    variable.expose_to_designer &&
                    variable.id !== modelTimeVariableId,
            ),
        ) ?? []
    );
});
export const useSettingsModelBlocks = () => {
    const settingsModelBlocks = useAtomValue(settingsModelBlocksAtom);
    return settingsModelBlocks;
};

export const dataModelBlocksAtom = atom<ModelBlock[] | undefined>((get) => {
    const modelBlock = get(interactiveModelBlockAtom);
    const modelTimeVariableId = get(modelTimeVariableIdAtom);
    const showSettingsTableRows = get(showSettingsTableRowsAtom);
    return modelBlock?.modelBlocks?.filter((block) =>
        block.modelVariables.some(
            (variable) =>
                variable.is_key_metric ||
                (showSettingsTableRows &&
                    variable.expose_to_designer &&
                    variable.id !== modelTimeVariableId),
        ),
    );
});
export const useDataModelBlocks = () => {
    const dataModelBlocks = useAtomValue(dataModelBlocksAtom);
    return dataModelBlocks;
};

const modelTimeVariableAtom = atom<ModelVariable | undefined>((get) => {
    const modelBlock = get(interactiveModelBlockAtom);
    return (
        modelBlock?.modelVariables?.find((variable) =>
            getIsModelTimeVariable(variable.label),
        ) ?? undefined
    );
});
// export const useModelTimeVariable = () => {
//     const modelTimeVariable = useAtomValue(modelTimeVariableAtom);
//     return modelTimeVariable;
// };

const modelTimeVariableIdAtom = atom<string | undefined>((get) => {
    const modelTimeVariable = get(modelTimeVariableAtom);
    return modelTimeVariable?.id ?? undefined;
});
// export const useModelTimeVariableId = () => {
//     const modelTimeVariableId = useAtomValue(modelTimeVariableIdAtom);
//     return modelTimeVariableId;
// };

const modelVariablesAtom = atom<ModelVariable[] | undefined>((get) => {
    const modelBlock = get(interactiveModelBlockAtom);
    return modelBlock?.modelVariables ?? [];
});
export const useModelVariables = () => {
    const modelVariables = useAtomValue(modelVariablesAtom);
    return modelVariables;
};

const inputVariablesAtom = atom<ModelVariable[] | undefined>((get) => {
    const modelVariables = get(modelVariablesAtom);
    const modelTimeVariableId = get(modelTimeVariableIdAtom);
    return (
        modelVariables
            ?.filter(
                (variable) =>
                    variable.expose_to_designer &&
                    variable.id !== modelTimeVariableId,
            )
            .map((variable) => ({
                ...variable,
                minimum: Number(variable.minimum),
                maximum: Number(variable.maximum),
                default_numerical_value: Number(
                    variable.default_numerical_value,
                ),
                resample_function: variable.resample_function || "mean",
            })) ?? []
    );
});
export const useInputVariables = () => {
    const inputVariables = useAtomValue(inputVariablesAtom);
    return inputVariables;
};

const inputModelVariableMapAtom = atom<
    Record<string, ModelVariable[]> | undefined
>((get) => {
    const inputVariables = get(inputVariablesAtom);
    return groupBy(inputVariables, "model_block_id") ?? {};
});
export const useInputModelVariableMap = () => {
    const inputModelVariableMap = useAtomValue(inputModelVariableMapAtom);
    return inputModelVariableMap;
};

const keyMetricVariablesAtom = atom<Record<string, ModelVariable> | undefined>(
    (get) => {
        const modelVariables = get(modelVariablesAtom);
        return (
            modelVariables
                ?.filter((variable) => variable.is_key_metric)
                .map((variable) => ({
                    ...variable,
                    resample_function: variable.resample_function || "sum",
                }))
                .reduce(
                    (map, variable) => {
                        return {
                            ...map,
                            [variable.id]: variable,
                        };
                    },
                    {} as { [index: string]: ModelVariable },
                ) ?? {}
        );
    },
);
// export const useKeyMetricVariables = () => {
//     const keyMetricVariables = useAtomValue(keyMetricVariablesAtom);
//     return keyMetricVariables;
// };

const inputDataObjectAtom = atom<Record<string, InputDataItem>>(
    {} as Record<string, InputDataItem>,
);
export const useInputDataObject = () => {
    const inputDataObject = useAtomValue(inputDataObjectAtom);
    return inputDataObject;
};
export const useSetInputDataObject = () => {
    const setInputDataObject = useSetAtom(inputDataObjectAtom);
    return setInputDataObject;
};

const editedArchetypeIdAtom = atom<string>("");
export const useEditedArchetypeId = () => {
    const editedArchetypeId = useAtomValue(editedArchetypeIdAtom);
    return editedArchetypeId;
};
export const useSetEditedArchetypeId = () => {
    const setEditedArchetypeId = useSetAtom(editedArchetypeIdAtom);
    return setEditedArchetypeId;
};

const enableGetArchetypeValuesAtom = atom<boolean>(false);
export const useEnableGetArchetypeValues = () => {
    const enableGetArchetypeValues = useAtomValue(enableGetArchetypeValuesAtom);
    return enableGetArchetypeValues;
};
export const useSetEnableGetArchetypeValues = () => {
    const setEnableGetArchetypeValues = useSetAtom(
        enableGetArchetypeValuesAtom,
    );
    return setEnableGetArchetypeValues;
};

const chartBlocksAtom = atom<Record<string, ChartBlock>>(
    {} as Record<string, ChartBlock>,
);
export const useChartBlocks = () => {
    const chartBlocks = useAtomValue(chartBlocksAtom);
    return chartBlocks;
};
export const useSetChartBlocks = () => {
    const setChartBlocks = useSetAtom(chartBlocksAtom);
    return setChartBlocks;
};

const modelTimespanAtom = atom<number | undefined>((get) => {
    const modelTimeVariableId = get(modelTimeVariableIdAtom);
    const inputDataObject = get(inputDataObjectAtom);
    return (
        getTimespanFromInputDataObject(modelTimeVariableId, inputDataObject) ||
        0
    );
});
export const useModelTimespan = () => {
    const modelTimespan = useAtomValue(modelTimespanAtom);
    return modelTimespan;
};

const modelTimespanArrayAtom = atom<number[] | undefined>((get) => {
    const modelTimeVariable = get(modelTimeVariableAtom);
    return !!modelTimeVariable
        ? [
              ...Array(
                  modelTimeVariable.maximum - modelTimeVariable.minimum + 1,
              ).keys(),
          ].map((val) => Number(val) + Number(modelTimeVariable.minimum))
        : [];
});
export const useModelTimespanArray = () => {
    const modelTimespanArray = useAtomValue(modelTimespanArrayAtom);
    return modelTimespanArray;
};

const targetVariablesAtom = atom<ModelVariable[]>([]);
// export const useTargetVariables = () => {
//     const targetVariables = useAtomValue(targetVariablesAtom);
//     return targetVariables;
// };
export const useSetTargetVariables = () => {
    const setTargetVariables = useSetAtom(targetVariablesAtom);
    return setTargetVariables;
};

const pythonValuesMonthlyAtom = atom<{
    [index: string]: VariableValue[];
}>({});
export const usePythonValuesMonthly = () => {
    const pythonValuesMonthly = useAtomValue(pythonValuesMonthlyAtom);
    return pythonValuesMonthly;
};
export const useSetPythonValuesMonthly = () => {
    const setPythonValuesMonthly = useSetAtom(pythonValuesMonthlyAtom);
    return setPythonValuesMonthly;
};

const pythonValuesYearlyAtom = atom<{
    [index: string]: VariableValue[];
}>({});
export const usePythonValuesYearly = () => {
    const pythonValuesYearly = useAtomValue(pythonValuesYearlyAtom);
    return pythonValuesYearly;
};
export const useSetPythonValuesYearly = () => {
    const setPythonValuesYearly = useSetAtom(pythonValuesYearlyAtom);
    return setPythonValuesYearly;
};

const orderedKeyMetricTargetVariablesAtom = atom<ModelVariable[] | undefined>(
    (get) => {
        const keyMetricVariables = get(keyMetricVariablesAtom);
        const targetVariables = get(targetVariablesAtom);
        return Object.keys(keyMetricVariables)?.length > 0
            ? targetVariables?.length > 0
                ? targetVariables
                      .filter((variable) => !!keyMetricVariables[variable.id])
                      .map((variable) => ({
                          ...variable,
                          resample_function:
                              keyMetricVariables[variable.id].resample_function,
                      }))
                      .sort(
                          (a, b) =>
                              keyMetricVariables[a.id].weight -
                              keyMetricVariables[b.id].weight,
                      )
                : Object.values(keyMetricVariables)
            : [];
    },
);
export const useOrderedKeyMetricTargetVariables = () => {
    const orderedKeyMetricTargetVariables = useAtomValue(
        orderedKeyMetricTargetVariablesAtom,
    );
    return orderedKeyMetricTargetVariables;
};

const dashboardDataCardVariablesAtom = atom<ModelVariable[] | undefined>(
    (get) => {
        const inputVariables = get(inputVariablesAtom);
        const orderedKeyMetricTargetVariables = get(
            orderedKeyMetricTargetVariablesAtom,
        );
        return (
            [...inputVariables, ...orderedKeyMetricTargetVariables].filter(
                (variable) =>
                    variable.expose_to_facilitator &&
                    variable.data_type === ModelVariableDataType.Number,
            ) ?? []
        );
    },
);
export const useDashboardDataCardVariables = () => {
    const dashboardDataCardVariables = useAtomValue(
        dashboardDataCardVariablesAtom,
    );
    return dashboardDataCardVariables;
};

const dataCardValuesMapAtom = atom<Record<string, DataCardValueMapItem>>({});
export const useDataCardValuesMap = () => {
    const dataCardValuesMap = useAtomValue(dataCardValuesMapAtom);
    return dataCardValuesMap;
};
export const useSetDataCardValuesMap = () => {
    const setDataCardValuesMap = useSetAtom(dataCardValuesMapAtom);
    return setDataCardValuesMap;
};

const chartVariablesMapAtom = atom<Record<string, ModelVariable[]> | undefined>(
    (get) => {
        const chartBlocks = get(chartBlocksAtom);
        const inputVariables = get(inputVariablesAtom);
        const orderedKeyMetricTargetVariables = get(
            orderedKeyMetricTargetVariablesAtom,
        );
        return Object.values(chartBlocks)?.reduce(
            (map, chartBlock) => ({
                ...map,
                [chartBlock.id]:
                    [
                        ...inputVariables,
                        ...orderedKeyMetricTargetVariables,
                    ].filter((variable) =>
                        chartBlock.variableIds?.includes(variable.id),
                    ) ?? [],
            }),
            {},
        );
    },
);
export const useChartVariablesMap = () => {
    const chartVariablesMap = useAtomValue(chartVariablesMapAtom);
    return chartVariablesMap;
};

const chartValuesMapAtom = atom<
    Record<string, Record<string, FormattedChartValue[]>>
>({});
export const useChartValuesMap = () => {
    const chartValuesMap = useAtomValue(chartValuesMapAtom);
    return chartValuesMap;
};
export const useSetChartValuesMap = () => {
    const setChartValuesMap = useSetAtom(chartValuesMapAtom);
    return setChartValuesMap;
};

const dataModelVariableMapAtom = atom<
    Record<string, ModelVariable[]> | undefined
>((get) => {
    const inputVariables = get(inputVariablesAtom);
    const orderedKeyMetricTargetVariables = get(
        orderedKeyMetricTargetVariablesAtom,
    );
    const showSettingsTableRows = get(showSettingsTableRowsAtom);
    const pythonValuesYearly = get(pythonValuesYearlyAtom);
    const pythonValuesMonthly = get(pythonValuesMonthlyAtom);
    return (
        groupBy(
            [
                ...inputVariables?.filter(
                    (variable) =>
                        showSettingsTableRows &&
                        !!pythonValuesYearly[variable.id] &&
                        !!pythonValuesMonthly[variable.id],
                ),
                ...orderedKeyMetricTargetVariables,
            ],
            "model_block_id",
        ) ?? {}
    );
});
export const useDataModelVariableMap = () => {
    const dataModelVariableMap = useAtomValue(dataModelVariableMapAtom);
    return dataModelVariableMap;
};

const archetypesAtom = atom<InteractiveModuleArchetype[] | undefined>([]);
export const useArchetypes = () => {
    const archetypes = useAtomValue(archetypesAtom);
    return archetypes;
};
export const useSetArchetypes = () => {
    const setArchetypes = useSetAtom(archetypesAtom);
    return setArchetypes;
};

const defaultArchetypeAtom = atom<InteractiveModuleArchetype | undefined>(
    (get) => {
        const archetypes = get(archetypesAtom);
        return (
            archetypes?.find((archetype) => archetype.is_default) ?? undefined
        );
    },
);
export const useDefaultArchetype = () => {
    const defaultArchetype = useAtomValue(defaultArchetypeAtom);
    return defaultArchetype;
};

const archetypeTimespanMapAtom = atom<Record<string, number> | undefined>(
    (get) => {
        const archetypes = get(archetypesAtom);
        const modelTimeVariableId = get(modelTimeVariableIdAtom);
        return archetypes?.reduce((map, archetype) => {
            return {
                ...map,
                [archetype.id]: getTimespanFromInputDataObject(
                    modelTimeVariableId,
                    archetype?.archetype?.inputDataObject,
                ),
            };
        }, {});
    },
);
export const useArchetypeTimespanMap = () => {
    const archetypeTimespanMap = useAtomValue(archetypeTimespanMapAtom);
    return archetypeTimespanMap;
};

const comparisonArchetypeIdAtom = atom<string>("");
export const useComparisonArchetypeId = () => {
    const comparisonArchetypeId = useAtomValue(comparisonArchetypeIdAtom);
    return comparisonArchetypeId;
};
export const useSetComparisonArchetypeId = () => {
    const setComparisonArchetypeId = useSetAtom(comparisonArchetypeIdAtom);
    return setComparisonArchetypeId;
};

const comparisonArchetypePythonValuesYearlyAtom = atom<{
    [index: string]: VariableValue[];
}>({});
export const useComparisonArchetypePythonValuesYearly = () => {
    const comparisonArchetypePythonValuesYearly = useAtomValue(
        comparisonArchetypePythonValuesYearlyAtom,
    );
    return comparisonArchetypePythonValuesYearly;
};
export const useSetComparisonArchetypePythonValuesYearly = () => {
    const setComparisonArchetypePythonValuesYearly = useSetAtom(
        comparisonArchetypePythonValuesYearlyAtom,
    );
    return setComparisonArchetypePythonValuesYearly;
};

const insightVariablesAtom = atom<ModelVariable[] | undefined>((get) => {
    const inputVariables = get(inputVariablesAtom);
    const orderedKeyMetricTargetVariables = get(
        orderedKeyMetricTargetVariablesAtom,
    );
    return (
        [...inputVariables, ...orderedKeyMetricTargetVariables].filter(
            (variable) =>
                variable.expose_to_facilitator &&
                variable.data_type === ModelVariableDataType.Boolean,
        ) ?? []
    );
});
export const useInsightVariables = () => {
    const insightVariables = useAtomValue(insightVariablesAtom);
    return insightVariables;
};

const variableWatchlistAtom = atom<Record<string, boolean>>(
    {} as Record<string, boolean>,
);
export const useVariableWatchlist = () => {
    const variableWatchlist = useAtomValue(variableWatchlistAtom);
    return variableWatchlist;
};
export const useSetVariableWatchlist = () => {
    const setVariableWatchlist = useSetAtom(variableWatchlistAtom);
    return setVariableWatchlist;
};

const watchlistVariablesAtom = atom<ModelVariable[] | undefined>((get) => {
    const inputVariables = get(inputVariablesAtom);
    const orderedKeyMetricTargetVariables = get(
        orderedKeyMetricTargetVariablesAtom,
    );
    const variableWatchlist = get(variableWatchlistAtom);
    return (
        [...inputVariables, ...orderedKeyMetricTargetVariables].filter(
            (variable) => variableWatchlist[variable.id],
        ) ?? []
    );
});
export const useWatchlistVariables = () => {
    const watchlistVariables = useAtomValue(watchlistVariablesAtom);
    return watchlistVariables;
};

const archetypeModelBlocksAtom = atom<ModelBlock[] | undefined>((get) => {
    const modelBlock = get(interactiveModelBlockAtom);
    const modelTimeVariableId = get(modelTimeVariableIdAtom);
    return modelBlock?.modelBlocks?.filter((block) =>
        block.modelVariables.some(
            (variable) =>
                variable.is_key_metric ||
                (variable.expose_to_designer &&
                    variable.id !== modelTimeVariableId),
        ),
    );
});
export const useArchetypeModelBlocks = () => {
    const archetypeModelBlocks = useAtomValue(archetypeModelBlocksAtom);
    return archetypeModelBlocks;
};

const archetypeModelVariableMapAtom = atom<
    Record<string, ModelVariable[]> | undefined
>((get) => {
    const inputVariables = get(inputVariablesAtom);
    const orderedKeyMetricTargetVariables = get(
        orderedKeyMetricTargetVariablesAtom,
    );
    const pythonValuesYearly = get(pythonValuesYearlyAtom);
    const pythonValuesMonthly = get(pythonValuesMonthlyAtom);
    return (
        groupBy(
            [
                ...inputVariables?.filter(
                    (variable) =>
                        !!pythonValuesYearly[variable.id] &&
                        !!pythonValuesMonthly[variable.id],
                ),
                ...orderedKeyMetricTargetVariables,
            ],
            "model_block_id",
        ) ?? {}
    );
});
export const useArchetypeModelVariableMap = () => {
    const archetypeModelVariableMap = useAtomValue(
        archetypeModelVariableMapAtom,
    );
    return archetypeModelVariableMap;
};
