import React, { useEffect } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Simulation, TimeHorizon } from "@/models";
import { AdminContainer } from "@/Layouts/admin";
import { RoundList } from "./detail";
import { useSelectedSimulation } from "@/hooks";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { useRoundStore, useTimeHorizonStore } from "@/hooks/store";
import { isCurrent, usePage } from "@/inertia-utils/hooks";
import { SimBuilderTopNav } from "@/Layouts/admin/SimBuilderTopNav";

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    timeHorizons: TimeHorizon[];
};

export default function ProjectDetail({ simulation, timeHorizons }: Props) {
    const { props } = usePage();
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { gotTimeHorizons } = useTimeHorizonStore();
    const { gotRounds } = useRoundStore();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
        gotRounds(simulation.rounds || []);
    }, [simulation]);

    useEffect(() => {
        gotTimeHorizons(timeHorizons || []);
    }, [timeHorizons]);

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <AdminContainer headTitle={selectedSimulation.title}>
                <AdminPageHeader>
                    <SimBuilderTopNav
                        title={selectedSimulation.title}
                        slug={selectedSimulation.slug}
                        id={selectedSimulation.id}
                        isCourse={selectedSimulation.is_course}
                    />
                </AdminPageHeader>
                <AdminPageSection>
                    <RoundList
                        simulationId={selectedSimulation.id}
                        displayOutline={isCurrent(
                            "simulation.projects.detail",
                            props.route,
                        )}
                    />
                </AdminPageSection>
            </AdminContainer>
        )
    );
}
