import React from "react";
import { Simulation } from "@/models";
import { SapienPageProps } from "@/inertia-utils/types";
import { InteractiveBlockLinkDisplay } from "./InteractiveBlockLinkDisplay";
import { CenterPanelPageWrapper } from "./shared";
import { InteractiveModuleWrapper } from "./InteractiveModuleWrapper";
import { useResetInteractiveModuleState } from "./useInteractiveModule";

type Props = SapienPageProps & {
    simulation: Simulation;
};

export default function InteractiveModuleContainer({ simulation }: Props) {
    useResetInteractiveModuleState();

    return (
        <InteractiveModuleWrapper
            simulationTitle={simulation?.title}
            simulationSlug={simulation?.slug}
        >
            <CenterPanelPageWrapper maxWidthClass={"max-w-7xl"}>
                <div className="grid w-full grid-cols-1 gap-4 p-6">
                    {simulation?.interactiveBlocks?.length > 0 ? (
                        simulation.interactiveBlocks.map((interactiveBlock) => (
                            <InteractiveBlockLinkDisplay
                                key={interactiveBlock.id}
                                simulationSlug={simulation?.slug}
                                modelBlock={interactiveBlock}
                            />
                        ))
                    ) : (
                        <div className="py-8 text-center text-lg text-slate-700 dark:text-slate-300">
                            No interactive models available.
                        </div>
                    )}
                </div>
            </CenterPanelPageWrapper>
        </InteractiveModuleWrapper>
    );
}
