import { useCallback, useMemo, useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { sapienAxios } from "@/inertia-utils/hooks";
import {
    ClusterAnalysisPayload,
    ClusterAnalysisResponse,
    DataDescription,
    ForecastPayload,
    ForecastResponse,
    RegressionPayload,
    RegressionResponse,
    RegressionType,
} from "./types";
import { DataFile, DataFileMetadata, DefaultParameters } from "@/models";

// REGRESSION

async function regressionFunction({
    body,
}: {
    body: Partial<RegressionPayload>;
}) {
    const { data } = await sapienAxios.post<
        RegressionResponse,
        "data-sandbox.api.regression"
    >("data-sandbox.api.regression", body as Partial<RegressionPayload>, {});

    return data;
}

const handleRegression = (regressionParameters: RegressionPayload) => {
    const { data } = useQuery({
        queryKey: ["regression", regressionParameters],
        queryFn: async () => {
            const data = await regressionFunction({
                body: regressionParameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled:
            !!regressionParameters?.regression_type &&
            !!regressionParameters?.x_variables &&
            regressionParameters?.x_variables?.length > 0 &&
            !!regressionParameters?.y_variables &&
            regressionParameters?.y_variables?.length > 0 &&
            regressionParameters.x_variables.every(
                (variable) =>
                    !regressionParameters.y_variables.includes(variable),
            ),
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

const useHandleRegression = (regressionParameters: RegressionPayload) => {
    const regressionResponse = handleRegression(regressionParameters);
    return regressionResponse;
};

// CLUSTER ANALYSIS

async function clusterAnalysisFunction({
    body,
}: {
    body: Partial<ClusterAnalysisPayload>;
}) {
    const { data } = await sapienAxios.post<
        ClusterAnalysisResponse,
        "data-sandbox.api.cluster-analysis"
    >(
        "data-sandbox.api.cluster-analysis",
        body as Partial<ClusterAnalysisPayload>,
        {},
    );

    return data;
}

const handleClusterAnalysis = (
    clusterAnalysisParameters: ClusterAnalysisPayload,
) => {
    const { data } = useQuery({
        queryKey: ["clusterAnalysis", clusterAnalysisParameters],
        queryFn: async () => {
            const data = await clusterAnalysisFunction({
                body: clusterAnalysisParameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled:
            !!clusterAnalysisParameters?.variables &&
            clusterAnalysisParameters?.variables?.length > 0 &&
            !!clusterAnalysisParameters?.clusters,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

const useHandleClusterAnalysis = (
    clusterAnalysisParameters: ClusterAnalysisPayload,
) => {
    const clusterAnalysisResponse = handleClusterAnalysis(
        clusterAnalysisParameters,
    );
    return clusterAnalysisResponse;
};

// FORECASTING

async function forecastFunction({ body }: { body: Partial<ForecastPayload> }) {
    const { data } = await sapienAxios.post<
        ForecastResponse,
        "data-sandbox.api.forecast"
    >("data-sandbox.api.forecast", body as Partial<ForecastPayload>, {});

    return data;
}

const handleForecast = (forecastParameters: ForecastPayload) => {
    const { data } = useQuery({
        queryKey: ["forecast", forecastParameters],
        queryFn: async () => {
            const data = await forecastFunction({
                body: forecastParameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled:
            !!forecastParameters?.variable &&
            !!forecastParameters?.forecast_length,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

const useHandleForecast = (forecastParameters: ForecastPayload) => {
    const forecastResponse = handleForecast(forecastParameters);
    return forecastResponse;
};

// MAIN HOOK

export const useDataSandbox = (
    defaultParameterVariables?: DefaultParameters,
) => {
    const [regressionParameters, setRegressionParameters] =
        useState<RegressionPayload>({
            regression_type: RegressionType["simple linear"],
            x_variables: defaultParameterVariables.x,
            y_variables: defaultParameterVariables.y,
            degree: 2,
        });

    const regressionResponse = useHandleRegression(regressionParameters);

    const [clusterAnalysisParameters, setClusterAnalysisParameters] =
        useState<ClusterAnalysisPayload>({
            variables: [
                ...defaultParameterVariables.y,
                ...defaultParameterVariables.x,
                ...defaultParameterVariables.z,
            ],
            clusters: 4,
        });

    const clusterAnalysisResponse = useHandleClusterAnalysis(
        clusterAnalysisParameters,
    );

    const [forecastParameters, setForecastParameters] =
        useState<ForecastPayload>({
            variable: defaultParameterVariables.y[0],
            forecast_length: defaultParameterVariables.forecast_length,
            example_count: 6,
        });

    const forecastResponse = useHandleForecast(forecastParameters);

    return {
        regressionProps: {
            regressionParameters,
            setRegressionParameters,
            regressionResponse:
                regressionResponse ||
                ({
                    regressionData: {},
                    regressionSummary: { ...regressionParameters },
                    regressionDataPoints: [],
                } as RegressionResponse),
        },
        clusterAnalysisProps: {
            clusterAnalysisParameters,
            setClusterAnalysisParameters,
            clusterAnalysisResponse:
                clusterAnalysisResponse ||
                ({
                    clusterAnalysisData: {},
                    clusterAnalysisSummary: { ...clusterAnalysisParameters },
                    clusterDescriptions: {},
                } as ClusterAnalysisResponse),
        },
        forecastProps: {
            forecastParameters,
            setForecastParameters,
            forecastResponse:
                forecastResponse ||
                ({
                    forecastData: {},
                    forecastSummary: { ...forecastParameters },
                    forecastExamples: {},
                    originalData: {},
                } as ForecastResponse),
        },
    };
};

// ATOMS

const datasetDescriptionAtom = atom<DataDescription>({} as DataDescription);
export const useDataDescription = () => {
    const dataDescription = useAtomValue(datasetDescriptionAtom);
    const setDataDescription = useSetAtom(datasetDescriptionAtom);
    return { dataDescription, setDataDescription };
};

const dataFileAtom = atom<DataFile>({} as DataFile);
const dataFileMetadataAtom = atom<DataFileMetadata>((get) => {
    const dataFile = get(dataFileAtom);
    return dataFile?.file_metadata ?? {};
});
export const useDataFile = () => {
    const dataFile = useAtomValue(dataFileAtom);
    const dataFileMetadata = useAtomValue(dataFileMetadataAtom);
    const setDataFile = useSetAtom(dataFileAtom);
    return { dataFile, dataFileMetadata, setDataFile };
};

// CLAUDE

type ClaudePayload = {
    message: string;
};

type ClaudeResponse = {
    message: string;
};

export type ClaudeMessage = {
    type: "user" | "claude";
    content: string;
};

const claudeMessagesAtom = atom<ClaudeMessage[]>([]);
// export const useClaudeMessages = () => {
//     const claudeMessages = useAtomValue(claudeMessagesAtom);
//     const setClaudeMessages = useSetAtom(claudeMessagesAtom);
//     return { claudeMessages, setClaudeMessages };
// };

const MAX_ENTRY_LENGTH = 1500;

export const useClaudeChat = ({
    selectedVariables,
}: {
    selectedVariables?: string[];
}) => {
    const dataDescription = useAtomValue(datasetDescriptionAtom);
    const dataFile = useAtomValue(dataFileAtom);
    const claudeMessages = useAtomValue(claudeMessagesAtom);
    const setClaudeMessages = useSetAtom(claudeMessagesAtom);

    const variablesForClaude = useMemo(() => {
        return selectedVariables?.length > 0
            ? selectedVariables
            : !!dataFile?.file_metadata?.default_parameters
              ? [
                    ...dataFile?.file_metadata?.default_parameters?.y,
                    ...dataFile?.file_metadata?.default_parameters?.x,
                    ...dataFile?.file_metadata?.default_parameters?.z,
                ]
              : [];
    }, [dataFile, selectedVariables]);

    const statisticsForClaude = useMemo(() => {
        return variablesForClaude?.reduce(
            (map, variable) => ({
                ...map,
                [variable]: {
                    unit:
                        dataFile?.file_metadata?.variable_unit_map[variable] ||
                        "",
                    ...dataDescription[variable],
                },
            }),
            {},
        );
    }, [dataDescription, dataFile, variablesForClaude]);

    const baseMessage = useMemo(() => {
        const dataJsonStringify = JSON.stringify(statisticsForClaude, null, 1);

        // max message length = 235 (temporary limit setting)
        const baseMessage = `\n\nInstructions: Emphasize insights and interpretation over simple reporting.\n\nData description: ${dataFile?.description || "none"}\n\nData statistics:\n\n${dataJsonStringify}`;

        return baseMessage;
    }, [statisticsForClaude, dataFile]);

    const allowedEntryLength = useMemo(() => {
        return MAX_ENTRY_LENGTH - baseMessage?.length - `Message `.length;
    }, [baseMessage]);

    const structureMessage = useCallback(
        (message: string) => {
            return `Message: ${message}${baseMessage}`;
        },
        [baseMessage],
    );

    const sendMessage = useCallback(
        async (message: string) => {
            const dataMessage = structureMessage(message);
            const userMessage: ClaudeMessage = {
                type: "user",
                content: dataMessage,
            };
            setClaudeMessages((prev) => [...prev, userMessage]);

            let newResponse: ClaudeMessage;
            try {
                const { data } = await sapienAxios.post<
                    ClaudeResponse,
                    "data-sandbox.claude.message"
                >(
                    "data-sandbox.claude.message",
                    {
                        message: dataMessage,
                    } as Partial<ClaudePayload>,
                    {},
                );

                newResponse = {
                    type: "claude",
                    content: data.message,
                };
            } catch (error) {
                console.error("Error sending message:", error);
                newResponse = {
                    type: "claude",
                    content: "I'm sorry, but I couldn't process your request.",
                };
            }

            setClaudeMessages((prev) => [...prev, newResponse]);
        },
        [structureMessage, setClaudeMessages],
    );

    return {
        variablesForClaude,
        claudeMessages,
        sendMessage,
        allowedEntryLength,
    };
};
