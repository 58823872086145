import { sapienAxios } from "@/inertia-utils/hooks";
import { useQuery } from "@tanstack/react-query";

export const useRoles = () => {
    const { data: roles, isPending } = useQuery({
        queryKey: ["roles"],
        queryFn: async () => {
            const { data } = await sapienAxios.get<
                { id: number; name: string }[],
                "roles.list.index"
            >("roles.list.index");
            return data;
        },
        initialData: [],
    });

    return { roles, isPending };
};
