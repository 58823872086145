import React, { useState } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";

export function PermissionForm() {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        const newPermission = { name, description };

        SapienInertia.post(
            "permissions.store",
            newPermission,
            {},
            {
                onSuccess: () => {
                    setName("");
                    setDescription("");
                    setIsSubmitting(false);
                },
                onError: () => {
                    setIsSubmitting(false);
                },
                preserveScroll: true,
            },
        );
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="flex w-full max-w-4xl items-center gap-4 self-start rounded-md border
                border-gray-300 p-4"
        >
            <label htmlFor="name" className="text-sm font-medium">
                Create Permission
            </label>
            <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="rounded-md border border-gray-300 p-2"
            />
            <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-md bg-sapien-blue p-2 text-white"
            >
                {isSubmitting ? "Submitting..." : "Submit"}
            </button>
        </form>
    );
}
