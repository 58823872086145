import { useAtom } from "jotai";
import {
    modelBuilderActiveModelVariableAtom,
    useModelBuilderModelBlocks,
    useModelBuilderModelVariableMap,
    useModelBuilderNestedModelBlocks,
    useModelBuilderRelationshipsBySourceId,
    useModelBuilderRelationshipsByTargetId,
} from "./atoms";

export function useModelBlockArray() {
    const modelBlockArray = useModelBuilderModelBlocks();

    return {
        modelBlockArray,
    };
}

export function useModelBlocks() {
    const modelBlocks = useModelBuilderNestedModelBlocks();

    return {
        modelBlocks,
    };
}

export function useModelVariables() {
    const [_activeModelVariable, _setActiveModelVariable] = useAtom(
        modelBuilderActiveModelVariableAtom,
    );

    const _modelVariables = useModelBuilderModelVariableMap();

    return {
        activeModelVariable: _activeModelVariable,
        setActiveModelVariable: _setActiveModelVariable,
        modelVariables: _modelVariables,
    };
}

export function useVariableRelationships() {
    const relationshipsBySourceId = useModelBuilderRelationshipsBySourceId();
    const relationshipsByTargetId = useModelBuilderRelationshipsByTargetId();

    return {
        relationshipsBySourceId,
        relationshipsByTargetId,
    };
}
