import React from "react";
import ForumRichText from "./ForumRichText";
import { WithId } from "./WithId";
import { Link, usePrefetch } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";

type Props = {
    thread: WithId<App.Data.ThreadData>;
};

export function ThreadIcon({ thread }: Props) {
    console.log(thread.threadable_type);
    switch (thread.threadable_type) {
        case "App\\Models\\Simulation":
            return (
                <span
                    className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium
                        text-gray-600"
                >
                    Simulation: {thread.categorizable_title}
                </span>
            );

        case "App\\Models\\Cohort":
            return (
                <span
                    className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium
                        text-yellow-800"
                >
                    Cohort: {thread.categorizable_title}
                </span>
            );
        case "App\\Models\\ELearning\\Course":
            return (
                <span
                    className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium
                        text-green-700"
                >
                    Course: {thread.categorizable_title}
                </span>
            );
    }

    return null;
}

export default function ThreadPreviewCard({ thread }: Props) {
    // const {lastUpdatedAt, isPrefetched, isPrefetching} = usePrefetch()
    return (
        <Link
            href={sapienRoute("forum.threads.show", { thread: thread.id })}
            className="transition:all flex cursor-pointer flex-col space-y-1 rounded-md p-4
                hover:bg-gray-100"
            prefetch={["hover"]}
            cacheFor={"45s"}
        >
            <div className="flex items-center space-x-2 text-sm">
                <ThreadIcon thread={thread} /> &bull;
                <span className="text-xs text-gray-400">
                    {new Date(thread.created_at).toLocaleDateString()}
                </span>
            </div>
            <h2 className="font-bold">{thread.title}</h2>

            <div className="text-gray-400">
                <ForumRichText
                    content={thread.rich_text}
                    parentObject={thread}
                    editable={false}
                />
            </div>
        </Link>
    );
}
