import React from "react";

type Props = {
    message: string;
};

export default function EmptyState({ message }: Props) {
    return (
        <div className="flex h-64 w-full items-center justify-center">
            <p className="text-gray-400">{message}</p>
        </div>
    );
}
