import React, { useMemo } from "react";
import { plus } from "react-icons-kit/feather/plus";
import styled from "styled-components";
import { PrimaryButtonOutline } from "@/components/admin-components";
import { SapienInertia } from "@/inertia-utils/hooks";
import { groupBy } from "lodash";
import { useModelBuilderRounds, useModelBuilderTimeHorizons } from "../atoms";

const Timeline = styled.div<{
    backgroundOverride?: string;
    textOverrideColor?: string;
}>`
    width: 100%;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background: #5e5e5e;
        top: 0;
        left: 20%;
        transform: translateX(-50%);
    }

    .item {
        position: relative;
        padding: 8px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .title {
            font-size: 24px;
        }

        > div {
            letter-spacing: 1px;
            width: 50%;
            &:nth-child(odd) {
                width: 20%;
                text-align: right;
                &:after {
                }
            }

            &:nth-child(even) {
                width: 80%;
                text-align: left;
            }
        }

        &:nth-child(odd),
        &:nth-child(even) {
            &:after {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: solid 2px #5e5e5e;
                top: 50%;
                transform: translateY(-50%);
                background-color: #5e5e5e;
                z-index: 10;
                left: calc(20% - 5px);
            }
        }
    }
`;

export const TimeHorizonList = ({ simulationId }: { simulationId: string }) => {
    const rounds = useModelBuilderRounds();
    const timeHorizons = useModelBuilderTimeHorizons();

    const roundsGroupedByTimeHorizonId = useMemo(() => {
        return groupBy(
            rounds.map((round, i) => ({
                ...round,
                round_number: i + 1,
            })),
            "time_horizon_id",
        );
    }, [rounds]);

    return (
        <div>
            <div className="text-l mb-2 font-bold">Time Horizons</div>
            {!!timeHorizons.length && (
                <div
                    className="mb-4 text-gray-300"
                    style={{
                        background: "rgba(255,255,255,0.03)",
                        borderRadius: "12px",
                    }}
                >
                    <div className="rounded-xl p-2">
                        <Timeline>
                            {timeHorizons.map((timeHorizon) => (
                                <React.Fragment key={timeHorizon.id}>
                                    <div className="item">
                                        <div>
                                            <span
                                                style={{ paddingRight: "20px" }}
                                            >
                                                {`${timeHorizon.time_index}`}
                                            </span>
                                        </div>
                                        <div>
                                            <div
                                                style={{
                                                    padding: "0px 0px 0px 8px",
                                                }}
                                            >
                                                {!!roundsGroupedByTimeHorizonId &&
                                                    !!roundsGroupedByTimeHorizonId[
                                                        timeHorizon.id
                                                    ] &&
                                                    roundsGroupedByTimeHorizonId[
                                                        timeHorizon.id
                                                    ].map((round) => (
                                                        <div
                                                            key={round.id}
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                                letterSpacing:
                                                                    "0px",
                                                                marginBottom:
                                                                    "4px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    paddingRight:
                                                                        "4px",
                                                                }}
                                                            >
                                                                {`ROUND ${round.round_number} —`}
                                                            </span>
                                                            <span>{`${round.title}`}</span>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </Timeline>
                    </div>
                </div>
            )}
            <div>
                <PrimaryButtonOutline
                    text={"Create Time Horizon"}
                    icon={plus}
                    handler={() =>
                        SapienInertia.post(
                            "model-builder.time-horizons.store",
                            {
                                time_index: timeHorizons?.length || 0,
                                simulation_id: simulationId,
                            },
                            {},
                            {
                                preserveScroll: true,
                            },
                        )
                    }
                />
            </div>
        </div>
    );
};
