import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/Accordion";
import React from "react";
import { Course } from "../ELearning/types/Course";

type Props = {
    course: Course;
    setCohortIds: (cohortIds: string[]) => void;
    cohortIds: string[];
    setCourseIds: (courseIds: string[]) => void;
    courseIds: string[];
};

export default function CourseSelector({
    course,
    setCohortIds,
    cohortIds,
    setCourseIds,
    courseIds,
}: Props) {
    return (
        <AccordionItem key={course.id} title={course.title} value={course.id}>
            <AccordionTrigger className="flex w-full items-center justify-between p-4">
                <div className="flex w-full items-center justify-between pr-4">
                    <span>{course.title}</span>
                    <label
                        className="flex items-center gap-2 text-sm text-slate-500"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <input
                            className="pointer-events-none"
                            type="checkbox"
                            checked={courseIds.includes(course.id)}
                            onChange={(e) => {
                                e.stopPropagation();
                                if (courseIds.includes(course.id)) {
                                    setCourseIds(
                                        courseIds.filter(
                                            (id) => id !== course.id,
                                        ),
                                    );
                                } else {
                                    setCourseIds([
                                        ...courseIds,
                                        course.id,
                                    ]);
                                }
                            }}
                        />
                        Create new cohort for this participant
                    </label>
                </div>
            </AccordionTrigger>
            <AccordionContent className={"flex flex-col gap-2 p-6"}>
                {course.cohorts.map((cohort) => (
                    <div className="flex flex-col" key={cohort.id}>
                        <div className="flex w-full items-center justify-between pr-4">
                            <label
                                onClick={(e) => e.stopPropagation()}
                                className="flex items-center gap-2 text-sm text-slate-500"
                            >
                                <input
                                    type="checkbox"
                                    checked={cohortIds.includes(cohort.id)}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        if (cohortIds.includes(cohort.id)) {
                                            setCohortIds(
                                                cohortIds.filter(
                                                    (id) => id !== cohort.id,
                                                ),
                                            );
                                        } else {
                                            setCohortIds([
                                                ...cohortIds,
                                                cohort.id,
                                            ]);
                                        }
                                    }}
                                />
                                <span>{cohort.title}</span>
                            </label>
                        </div>
                    </div>
                ))}
            </AccordionContent>
        </AccordionItem>
    );
}
