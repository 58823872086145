import React, { useMemo } from "react";
import { InteractiveModuleArchetype } from "../types";
import {
    useArchetypeModelBlocks,
    useArchetypeModelVariableMap,
    useInputVariables,
    useModelTimespan,
    useOrderedKeyMetricTargetVariables,
    usePythonValuesYearly,
    useVariableWatchlist,
} from "../state";
import { ModelVariableDataType } from "@/models";
import { ArchetypeComparisonCharts } from "../shared";
import { useArchetypeValuesMap } from "../useInterfaceState";

const ArchetypeSectionDetailComponent = ({
    archetypes,
    archetypeTimespanMap,
    comparisonArchetypeId,
}: {
    archetypes: InteractiveModuleArchetype[];
    archetypeTimespanMap: Record<string, number>;
    comparisonArchetypeId: string;
}) => {
    const modelTimespan = useModelTimespan();
    const pythonValuesYearly = usePythonValuesYearly();

    const archetypeModelBlocks = useArchetypeModelBlocks();
    const archetypeModelVariableMap = useArchetypeModelVariableMap();
    const archetypeValuesMap = useArchetypeValuesMap();

    const variableWatchlist = useVariableWatchlist();
    const inputVariables = useInputVariables();
    const orderedKeyMetricTargetVariables =
        useOrderedKeyMetricTargetVariables();

    const variables = useMemo(() => {
        return (
            [...inputVariables, ...orderedKeyMetricTargetVariables].filter(
                (variable) =>
                    (variable.expose_to_facilitator &&
                        variable.data_type === ModelVariableDataType.Number) ||
                    (!!variableWatchlist && variableWatchlist[variable.id]),
            ) ?? []
        );
    }, [inputVariables, orderedKeyMetricTargetVariables, variableWatchlist]);

    const maxTimespan = useMemo(() => {
        const includedTimespanValues =
            !!archetypeTimespanMap && !!modelTimespan
                ? [
                      modelTimespan,
                      ...Object.keys(archetypeTimespanMap)
                          ?.filter((key) => key == comparisonArchetypeId)
                          .map((key) => archetypeTimespanMap[key]),
                  ]
                : [modelTimespan];
        return includedTimespanValues?.length > 0
            ? Math.max(...includedTimespanValues)
            : 0;
    }, [archetypeTimespanMap, comparisonArchetypeId, modelTimespan]);

    const localArchetypeValuesMap = useMemo(() => {
        return {
            user_archetype_id: pythonValuesYearly,
            ...archetypeValuesMap,
        };
    }, [pythonValuesYearly, archetypeValuesMap]);

    const visibleArchetypes = useMemo(() => {
        const selectedArchetypes =
            archetypes?.filter(
                (archetype) =>
                    archetype.id == comparisonArchetypeId &&
                    !!localArchetypeValuesMap[archetype.id],
            ) || [];
        return [
            {
                id: "user_archetype_id",
                name: "My Model",
            } as InteractiveModuleArchetype,
            ...selectedArchetypes,
        ];
    }, [archetypes, comparisonArchetypeId, localArchetypeValuesMap]);

    const chartVariablesMap = useMemo(() => {
        return (
            variables?.reduce(
                (map, variable) => ({ ...map, [variable.id]: true }),
                {},
            ) || {}
        );
    }, [variables]);

    return (
        <>
            {!!chartVariablesMap &&
                !!archetypeModelVariableMap &&
                !!archetypeModelBlocks && (
                    <ArchetypeComparisonCharts
                        {...{
                            maxTimespan,
                            visibleArchetypes,
                            chartVariablesMap,
                            archetypeValuesMap: localArchetypeValuesMap,
                            archetypeModelBlocks,
                            archetypeModelVariableMap,
                            showComparisonTable: true,
                        }}
                    />
                )}
        </>
    );
};

export const ArchetypeSectionDetail = React.memo(
    ArchetypeSectionDetailComponent,
);
