import React from "react";
import usePageTransition from "./usePageTransition";
import { useInitAuthFromInertiaProps } from "@/hooks/useInitAuthFromInertiaProps";

export default function LayoutWithPageTransition({children}) {
    const {className, direction} = usePageTransition(children.props.lesson);
    useInitAuthFromInertiaProps();
    return (
        <div className={`with-transition transition  w-full ${className} ${direction}`}>
            {children}
        </div>
    );
}
