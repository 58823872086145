import React, { FC, ReactNode } from "react";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import { SubNavLink } from "./SubNavLink";
import { UserGroupIcon as UsersIcon } from "@heroicons/react/24/outline";
import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/feather/home";

export const AdminDashboardTopNav: FC<{
    header?: string;
    children?: ReactNode;
}> = ({ header, children }) => {
    const { props } = usePage();
    return (
        <div className="flex flex-row items-center space-x-8">
            {/* <div>
                <span className="text-xl font-semibold text-slate-900">
                    {"Dashboard"}
                </span> */}
            {/* {isCurrent("admin.index", props.route) ? (
                    <span className="text-xl font-semibold text-slate-900">
                        {header}
                    </span>
                ) : (
                    <Link href={sapienRoute("admin.index")}>
                        <div className="text-xl font-semibold text-slate-900 hover:underline">
                            {header}
                        </div>
                    </Link>
                )} */}
            {/* </div> */}
            <div className="flex flex-wrap gap-2 text-sm font-semibold">
                <SubNavLink
                    href={sapienRoute("admin.index")}
                    active={isCurrent("admin.index", props.route)}
                >
                    <span className={"mr-2"}>
                        <Icon
                            icon={home}
                            size={14}
                            style={{
                                display: "flex",
                            }}
                        />
                    </span>
                    <span>Home</span>
                </SubNavLink>
                <SubNavLink
                    href={sapienRoute("admin.users.index")}
                    active={isCurrent("admin.users.index", props.route, [
                        "admin.users.show",
                    ])}
                >
                    <span className={"mr-2"}>
                        <UsersIcon className="h-5 w-5" />
                    </span>
                    <span>Users</span>
                </SubNavLink>
            </div>
        </div>
    );
};
