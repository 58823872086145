import React from "react";
import { ContentBlockTemplateShape } from "@/models";
import { ModalContainer } from "@/modules/shared";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import ContentBlockTemplateCard from "@/Pages/Admin/editor/content-block-templates/ContentBlockTemplateCard";

type Props = {
    template: ContentBlockTemplateShape;
    cancelDelete: () => void;
};

export default function ConfirmDeleteTemplateModal({
    template,
    cancelDelete,
}: Props) {
    return (
        <ModalContainer
            isModalOpen={!!template}
            setIsModalOpen={cancelDelete}
            styles={{
                top: "0px",
                margin: "20vh auto",
                zIndex: 99999,
                boxShadow:
                    "0px 4px 6px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1",
            }}
            backgroundColor="#111928"
            size="m"
            classNames={"z-100"}
        >
            {!!template && (
                <div className="flex flex-col space-y-4 p-6 text-white">
                    <div>
                        <h3 className="text-lg font-medium">
                            Are you sure you want to delete this template?
                        </h3>
                        <p className="text-xs text-gray-200">
                            This will delete the template from your templates,
                            but others might still have access to it.
                        </p>
                    </div>
                    <div className="mx-auto w-72">
                        <ContentBlockTemplateCard
                            template={template}
                            isSelected={false}
                            handleClickAdd={function () {}}
                        />
                    </div>
                    <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                        <div className="flex items-center justify-center">
                            <button
                                onClick={() => {
                                    cancelDelete();
                                }}
                                className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5
                                    text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none
                                    focus:ring-2 focus:ring-blue-300"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="flex items-center justify-center">
                            <button
                                onClick={() => {
                                    SapienInertia.delete(
                                        sapienRoute(
                                            "creator.design.templates.destroy",
                                            {
                                                template: template.id,
                                            },
                                        ),
                                        {
                                            onSuccess: () => {
                                                cancelDelete();
                                            },
                                        },
                                    );
                                }}
                                className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                                    py-2.5 text-center text-sm font-medium text-white transition-all
                                    hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                    disabled:cursor-not-allowed disabled:opacity-75"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </ModalContainer>
    );
}
