import React, { useCallback, useMemo } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { UserShape } from "@/models";
import { getHighestRole } from "./utils";

type Props = {
    user: UserShape;
    roles: { id: number; name: string }[];
};

export const RoleSelectionMenu = ({ user, roles }: Props) => {
    const highestRole = useMemo(() => {
        return user?.roles?.length > 0 ? getHighestRole(user.roles) : undefined;
    }, [user]);

    const handleUpdateRoles = useCallback((roleId: string) => {
        SapienInertia.post(
            "admin.user.update-role",
            {
                user_id: user.id,
                role_id: roleId,
            },
            {
                user: user.id,
            },
            {
                preserveScroll: true,
            },
        );
    }, []);

    return (
        <form>
            <div className="flex max-w-80 items-center">
                <select
                    id={`role-${user.id}`}
                    name={`role-${user.id}`}
                    className="h-full rounded-md border-slate-300 py-2 text-sm text-slate-500 focus:ring-2
                        focus:ring-inset focus:ring-indigo-600"
                    value={!!highestRole ? highestRole.id : ""}
                    onChange={(e) => handleUpdateRoles(e.target.value)}
                >
                    <option value={""} disabled>
                        Role
                    </option>
                    {roles.map((role) => (
                        <option key={`${user.id}_${role.name}`} value={role.id}>
                            {role.name}
                        </option>
                    ))}
                </select>
            </div>
        </form>
    );
};
