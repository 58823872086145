import React from "react";
import CardDeckModal from "../CardDeckModal";
import MovePageModal from "../MovePageModal";
import NotificationModal from "../NotificationModal";
import { VideoEditor } from "../VideoEditor";
import DynamicContentHideRevealModal from "../blocks/dynamic-content/DynamicContentHideRevealModal";
import DynamicContentModal from "../blocks/dynamic-content/DynamicContentModal";
import ModelDataSourceModal from "../model-variable-source-management/ModelDataSourceModal";
import ChooseModalTriggerModal from "../pre-save-forms/ChooseModalTriggerModal";
import BehaviorModal from "../question-forms/BehaviorModal";
import QuestionModal from "../question-forms/QuestionModal";
import TimelineBuilder from "../question-forms/TimelineBuilder";
import { ImageEditor } from "../uploads/ImageEditor";
import DynamicValidationRuleModal from "../blocks/dynamic-validation/DynamicValidationRuleModal";
import DragAndDropBuilder from "../question-forms/DragAndDropBuilder";
import ContentBlockTemplateCreationModal from "../content-block-templates/ContentBlockTemplateCreationModal";
import AddFromTemplateModal from "../content-block-templates/AddFromTemplateModal";
import PublishTemplateModal from "../content-block-templates/PublishTemplateModal";
import AddContentBlockModal from "../blocks/content-block-selection/AddContentBlockModal";
import ChartWizard from "../charts-and-tables/chart-wizard/ChartWizard";
import TableWizard from "../charts-and-tables/tables/TableWizard";
import TopDrawerModal from "../pre-save-forms/TopDrawerModal";
import CreateCarouselModal from "@/styles/themes/carousel/CreateCarouselModal";

type Props = {
    currentPageId: string;
};

export default function EditorModals({ currentPageId }: Props) {
    return (
        <>
            <AddContentBlockModal />
            <AddFromTemplateModal />
            <BehaviorModal currentPageId={currentPageId} />
            <CardDeckModal />
            <ChartWizard />
            <ModelDataSourceModal />
            <ChooseModalTriggerModal />
            <ContentBlockTemplateCreationModal />
            <CreateCarouselModal />
            <DragAndDropBuilder />
            <DynamicContentModal />
            <DynamicContentHideRevealModal />
            <DynamicValidationRuleModal />
            <ImageEditor />
            <NotificationModal />
            <MovePageModal />
            <PublishTemplateModal />
            <QuestionModal />
            <TableWizard />
            <TimelineBuilder />
            <TopDrawerModal currentPageId={currentPageId}/>
            <VideoEditor />
        </>
    );
}
