import React from "react";
import { WithId } from "./WithId";
import { SapienPageProps } from "@/inertia-utils/types";
import Authenticated from "@/Layouts/Authenticated";
import { Head } from "@inertiajs/react";
import ForumSideNav from "./ForumSideNav";
import QueryClient from "../ELearning/QueryClient/QueryClient";
import ThreadPreviewCard from "./ThreadPreviewCard";
import WhenVisible from "@/components/WhenVisible";

type Props = {
    threads: App.Data.ThreadData[];
    categories: WithId<App.Data.Forum.CategoryData>[];
    currentPage: number;
    totalPages: number;
} & SapienPageProps;

function ThreadSkeleton() {
    return (
        <div className="py-2 px-4">
            <div
                className="transition:all flex animate-pulse cursor-pointer flex-col space-y-1 rounded-lg
                    bg-gray-200 p-4"
            >
                <div className="flex h-4 w-48 animate-pulse items-center rounded-md bg-gray-300 text-sm"></div>
                <div className="h-6 w-64 animate-pulse rounded-md bg-gray-300"></div>
                <div className="h-20 w-full animate-pulse rounded-md bg-gray-300"></div>
            </div>
        </div>
    );
}

export default function ThreadsByUser({
    auth,
    threads,
    impersonating_user,
    categories,
    currentPage,
    totalPages,
}: Props) {
    return (
        <Authenticated
            errors={[]}
            impersonatingUser={impersonating_user}
        >
            <QueryClient>
                <Head title="Threads" />{" "}
                <div className="flex w-full justify-center">
                    <ForumSideNav categories={categories} />
                    <div className="flex w-full flex-col items-center space-y-2">
                        <div className="w-full max-w-3xl">
                            <h1 className="p-4 text-2xl font-bold">
                                Your Threads
                            </h1>
                            <div className="flex flex-col divide-y pb-16">
                                {threads.map((thread) => (
                                    <div className="py-3">
                                        <ThreadPreviewCard
                                            key={thread.id}
                                            thread={thread}
                                        />
                                    </div>
                                ))}

                                {currentPage < totalPages && (
                                    <WhenVisible
                                        always={true}
                                        as="div"
                                        fallback={<ThreadSkeleton />}
                                        data={["threads", "currentPage"]}
                                        params={{
                                            only: ["threads", "currentPage"],
                                            data: {
                                                page: currentPage + 1,
                                            },
                                            preserveUrl: true,
                                        }}
                                    >
                                        <></>
                                    </WhenVisible>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </QueryClient>
        </Authenticated>
    );
}
