import React from "react";
import {
    useModelBlockArray,
    useModelBlocks,
    useModelVariables,
} from "../state";
import { SortableModelVariableTree } from "./SortableModelVariableTree";
import { ModelBlock } from "@/models";

export const VariableOrganization = () => {
    const { modelBlocks } = useModelBlocks();
    const { modelBlockArray } = useModelBlockArray();
    const { modelVariables } = useModelVariables();

    return (
        <div>
            <SortableModelVariableTree
                modelBlocks={modelBlocks}
                modelBlockArray={modelBlockArray?.map((modelBlock) => ({
                    ...(modelBlock as Omit<ModelBlock, "modelVariables">),
                    modelVariables: modelBlock.modelVariables.map(
                        (modelVariable) => modelVariable.id,
                    ),
                }))}
                modelVariables={modelVariables}
            />
        </div>
    );
};
