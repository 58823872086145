import { useForm } from "@inertiajs/react";
import React from "react";

type Props = {
    teams: { id: number; name: string }[];
    setTeamIds: (teamIds: number[]) => void;
};
export function TeamSelection({ teams, setTeamIds }: Props) {
    return <div>TeamSelection</div>;
};
