import React, { useState, useMemo, useEffect } from "react";
import ApplicationLogo from "@/components/ApplicationLogo";
import NavLink from "@/components/NavLink";
import { Link } from "@inertiajs/react";
import { User, UserShape } from "@/models";
import DropdownAvatar from "./DropdownAvatar";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import ResponsiveNavLink from "@/components/ResponsiveNavLink";
import { useAuthStore } from "@/hooks/store";
import { LaravelRoutes } from "@/ziggy-shims";

type LinkConditionContext = {
    isNotParticipant: boolean;
    isSapienSuperAdmin: boolean;
    hasELearningFeature: boolean;
    isELearningContext: boolean;
    showAdditionalParticipantLinks: boolean;
};

interface INavLink {
    label: string;
    route: keyof LaravelRoutes;
    condition?: (context: LinkConditionContext) => boolean;
    activeRoutes?: (keyof LaravelRoutes)[];
    // requiredRoles?: string[];
}

const NAV_LINKS: INavLink[] = [
    {
        label: "Dashboard",
        route: "admin.index",
        condition: ({ isNotParticipant }) => !!isNotParticipant,
        activeRoutes: ["admin.users.index", "admin.users.show"],
    },
    {
        label: "Simulations",
        route: "simulation.projects.index",
        condition: ({ isNotParticipant, isELearningContext }) =>
            !!isNotParticipant && !isELearningContext,
        activeRoutes: [
            "simulation.projects.show",
            "simulation.projects.detail",
            "facilitation.index",
            "facilitation.show",
            "discovery.index",
            "discovery.show-data",
            "discovery.show-statistics",
            "templates.index",
            "templates.edit",
        ],
    },
    {
        label: "Simulations",
        route: "simulation.projects.index",
        condition: ({ isNotParticipant, isELearningContext }) =>
            !!isNotParticipant && !!isELearningContext,
        activeRoutes: [],
    },
    {
        label: "Models",
        route: "model-dashboard.index",
        condition: ({ isSapienSuperAdmin }) => !!isSapienSuperAdmin,
        activeRoutes: [
            "model-builder.structure.index",
            "model-builder.wizard.index",
        ],
    },
    {
        label: "E-learning",
        route: "e-learning.courses.index",
        condition: ({
            isNotParticipant,
            hasELearningFeature,
            isELearningContext,
        }) =>
            !!isNotParticipant && !!hasELearningFeature && !isELearningContext,
        activeRoutes: ["e-learning.courses.edit"],
    },
    {
        label: "E-learning",
        route: "e-learning.courses.index",
        condition: ({
            isNotParticipant,
            hasELearningFeature,
            isELearningContext,
        }) =>
            !!isNotParticipant && !!hasELearningFeature && !!isELearningContext,
        activeRoutes: [
            "e-learning.courses.edit",
            "facilitation.index",
            "facilitation.show",
        ],
    },
    {
        label: "Participant",
        route: "dashboard",
        condition: ({ isNotParticipant }) => !!isNotParticipant,
        activeRoutes: [],
    },
    {
        label: "Dashboard",
        route: "dashboard",
        condition: ({ isNotParticipant }) => !isNotParticipant,
        activeRoutes: [],
    },
    {
        label: "Resources",
        route: "resources",
        condition: ({ showAdditionalParticipantLinks }) =>
            !!showAdditionalParticipantLinks,
        activeRoutes: [],
    },
];

// breakpoints in non-participant view for links disappearing above sm
const RESPONSIVE_LINK_PRIORITY: Array<{
    route: keyof LaravelRoutes;
    breakpoint: number;
}> = [
    // { route: "admin.index", breakpoint: 640 },
    // { route: "simulation.projects.index", breakpoint: 640 },
    // { route: "e-learning.courses.index", breakpoint: 640 },
    // { route: "model-dashboard.index", breakpoint: 640 },
    { route: "dashboard", breakpoint: 768 },
    { route: "resources", breakpoint: 1024 },
];

function filterNavLinks(links: INavLink[], context: LinkConditionContext) {
    return links.filter((link) => !link.condition || link.condition(context));
}

const useVisibleLinks = (context: LinkConditionContext) => {
    const visibleLinks = useMemo(
        () => filterNavLinks(NAV_LINKS, context),
        [context],
    );
    return visibleLinks;
};

const NavigationDropdownIcon: React.FC<{
    isNavDropdownOpen: boolean;
}> = ({ isNavDropdownOpen }) => (
    <svg
        className="h-6 w-6"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            className={!isNavDropdownOpen ? "inline-flex" : "hidden"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
        />
        <path
            className={isNavDropdownOpen ? "inline-flex" : "hidden"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
        />
    </svg>
);

export default function Authenticated({
    header,
    children,
    impersonatingUser,
    isELearningContext,
}: {
    header?: string | JSX.Element;
    children?: React.ReactNode;
    impersonatingUser?: User | UserShape;
    isELearningContext?: boolean;
    // unused props
    errors?: any;
    // links?: React.ReactNode;
    // auth?: Auth;
}) {
    const [isNavDropdownOpen, setIsNavDropdownOpen] = useState(false);

    const [windowWidth, setWindowWidth] = useState(
        typeof window !== "undefined" ? window.innerWidth : 640,
    );

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const { props } = usePage();

    const { auth, tenant, isNotParticipant, isSapienSuperAdmin } =
        useAuthStore();

    const visibleLinks = useVisibleLinks({
        isNotParticipant,
        isSapienSuperAdmin,
        hasELearningFeature: tenant?.has_e_learning_feature,
        isELearningContext,
        showAdditionalParticipantLinks:
            // participants always see all participant links
            !isNotParticipant ||
            // for non-participants, only show additional participant links when route is among the following
            isCurrent("dashboard", props.route, [
                "resources",
                "forum.threads.index",
                "forum.threads.show",
                "forum.category",
                "forum.user.threads",
            ]),
    });

    const navbarLinks = useMemo(() => {
        return windowWidth < 640
            ? []
            : !isNotParticipant
              ? visibleLinks
              : visibleLinks?.filter(
                    (link) =>
                        !RESPONSIVE_LINK_PRIORITY.some(
                            (priority) =>
                                priority.route === link.route &&
                                windowWidth < priority.breakpoint,
                        ),
                );
    }, [visibleLinks, windowWidth, isNotParticipant]);

    const dropdownLinks = useMemo(() => {
        return visibleLinks?.filter(
            (link) =>
                !navbarLinks?.some(
                    (visibleLink) => visibleLink.route === link.route,
                ),
        );
    }, [visibleLinks, navbarLinks]);

    return (
        <div className="min-h-[100vh]">
            <nav className="fixed z-[9999] w-full border-b border-slate-200 bg-white px-8 shadow-sm">
                <div className="w-full">
                    <div className="flex h-16 justify-between">
                        <div className="flex">
                            {/* Logo */}
                            <div className="flex shrink-0 items-center">
                                <Link
                                    href={
                                        isNotParticipant
                                            ? sapienRoute("admin.index")
                                            : sapienRoute("dashboard")
                                    }
                                >
                                    <ApplicationLogo className="block h-9 w-auto text-slate-500" />
                                </Link>
                            </div>

                            {/* Desktop Navigation */}
                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                {navbarLinks.map((link) => (
                                    <NavLink
                                        key={link.route}
                                        href={sapienRoute(link.route)}
                                        active={isCurrent(
                                            link.route,
                                            props.route,
                                            link.activeRoutes,
                                        )}
                                    >
                                        {link.label}
                                    </NavLink>
                                ))}
                            </div>
                        </div>

                        {/* Desktop User Dropdown */}
                        <div
                            className={`hidden items-center ${!isNotParticipant ? "sm:flex" : "lg:flex"}`}
                        >
                            <DropdownAvatar
                                auth={auth}
                                impersonatingUser={impersonatingUser}
                            />
                        </div>

                        {/* Mobile Menu Toggle */}
                        <div
                            className={`-mr-2 flex items-center ${!isNotParticipant ? "sm:hidden" : "lg:hidden"}`}
                        >
                            <button
                                onClick={() =>
                                    setIsNavDropdownOpen(
                                        (previousState) => !previousState,
                                    )
                                }
                                className="inline-flex items-center justify-center rounded-md p-2 text-slate-500 transition
                                    duration-150 ease-in-out hover:bg-slate-100 hover:text-slate-600
                                    focus:outline-none"
                            >
                                <NavigationDropdownIcon
                                    isNavDropdownOpen={isNavDropdownOpen}
                                />
                            </button>
                        </div>
                    </div>
                </div>

                {/* Mobile Navigation */}
                <div
                    className={`${isNavDropdownOpen ? "block" : "hidden"} ${
                        !isNotParticipant ? "sm:hidden" : "lg:hidden"
                    }`}
                >
                    <div className="space-y-1 pb-3 pt-2">
                        {/* All links in mobile dropdown */}
                        {dropdownLinks.map((link) => (
                            <ResponsiveNavLink
                                key={link.route}
                                href={sapienRoute(link.route)}
                                active={isCurrent(
                                    link.route,
                                    props.route,
                                    link.activeRoutes,
                                )}
                            >
                                {link.label}
                            </ResponsiveNavLink>
                        ))}
                    </div>

                    {/* Mobile User Info and Logout */}
                    <div className="border-t border-slate-200 pb-1 pt-4">
                        <div className="px-4">
                            <div className="text-base font-medium text-slate-800">
                                {auth.user?.name}
                            </div>
                            <div className="text-sm font-medium text-slate-500">
                                {auth.user?.email}
                            </div>
                        </div>

                        <div className="mt-3 space-y-1">
                            <ResponsiveNavLink
                                method="post"
                                href={sapienRoute("logout")}
                                as="button"
                            >
                                Log Out
                            </ResponsiveNavLink>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Header */}
            {header && (
                <header className="bg-white shadow">
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        {header}
                    </div>
                </header>
            )}

            {/* Spacer and Main Content */}
            <div className="h-16 w-full"></div>
            <main>{children}</main>
        </div>
    );
}
