import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SapienPageProps } from "@/inertia-utils/types";
import { useNotificationStore } from "@/hooks/useNotificationStore";
import { AdminPageHeader } from "@/components/admin-components";
import StyledToast from "@/components/toasts/StyledToast";
import { AdminContainer } from "@/Layouts/admin";
import { AdminDashboardTopNav } from "@/Layouts/admin/AdminDashboardTopNav";

const queryClient = new QueryClient();

type Props = {
    children?: React.ReactNode;
} & Partial<SapienPageProps>;

export function UserManagementWrapper({ toasts, children }: Props) {
    const { notification, setNotification } = useNotificationStore();

    useEffect(() => {
        if (!!toasts && (toasts as any)?.length) {
            console.log(toasts);
            setNotification({
                autoClose: toasts[0].autoClose || 6000,
                text: toasts[0].message,
                isInline: true,
                textColor: "#4B5563",
                backgroundColor: "#F0FFF4",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
            });
        }
    }, [toasts]);

    return (
        <QueryClientProvider client={queryClient}>
            <AdminContainer headTitle={"Users"}>
                {!!notification && <StyledToast props={notification} />}
                <AdminPageHeader>
                    <AdminDashboardTopNav header={"Home"} />
                </AdminPageHeader>
                {children}
            </AdminContainer>
        </QueryClientProvider>
    );
}
