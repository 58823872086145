import { useState, useCallback, useMemo, useEffect } from "react";
import { groupBy } from "lodash";
import {
    ModelVariable,
    VariableValue,
    SelectionShape,
    Prompt,
    ModelVariableScope,
    Round,
    Team,
    ModelBlock,
    Participant,
    Option,
    TeamWithProgressData,
} from "@/models";
import { getLineageIds, flatten } from "@/util";

export type DiscoveryLayerObject = {
    variableMap: { [index: string]: ModelVariable };
    valueMap: {
        [variableId: string]: {
            [scopeId: string]:
                | VariableValue
                | { [timeHorizonId: string]: VariableValue };
        };
    };
    selectionMap: { [promptId: string]: SelectionShape[] };
    promptMap: { [promptId: string]: Prompt };
    teamCount: number;
    participantCount: number;
    lineageBlockIdsObject: {};
    blockVariableMap: { [index: string]: ModelVariable[] };
    selectedBlockIds: {};
    selectedPromptIds: {};
    selectedRoundIds: {};
    toggleBlockId: (blockId?: string) => void;
    togglePromptId: (promptId?: string) => void;
    toggleRoundId: (roundId?: string) => void;
    teamMap: { [teamId: string]: Team };
    participantMap: { [participantId: string]: Participant };
    promptTypeAndScopeMap: {
        [promptType: string]: {
            [promptScope: string]: Prompt[];
        };
    };
    promptScopeAndTypeMap: {
        [promptScope: string]: {
            [promptType: string]: Prompt[];
        };
    };
    selectionsGrouping: "question" | "people" | "type";
    setSelectionsGrouping: (
        selectionGrouping: "question" | "people" | "type",
    ) => void;
    resultsGrouping: "variable" | "people" | "time";
    setResultsGrouping: (
        resultsGrouping: "variable" | "people" | "time",
    ) => void;
};

export function useDiscoveryLayer(
    rounds: Round[],
    teams: (Team | TeamWithProgressData)[],
    modelVariables: ModelVariable[],
    variableValues: VariableValue[],
    selections: SelectionShape[],
    modelBlocks: ModelBlock[],
): DiscoveryLayerObject {
    const [variableMap, setVariableMap] = useState<{
        [index: string]: ModelVariable;
    }>({});

    const [valueMap, setValueMap] = useState<{
        [variableId: string]: {
            [scopeId: string]:
                | VariableValue
                | { [timeHorizonId: string]: VariableValue };
        };
    }>({});

    const [selectionMap, setSelectionMap] = useState<{
        [promptId: string]: SelectionShape[];
    }>({});

    const [blockVariableMap, setBlockVariableMap] = useState<{
        [index: string]: ModelVariable[];
    }>({});

    const [selectedRoundIds, setSelectedRoundIds] = useState(
        rounds?.reduce((map, round) => {
            return round.prompts_count > 0 ? { ...map, [round.id]: true } : map;
        }, {}) || {},
    );

    const [selectedPromptIds, setSelectedPromptIds] = useState(
        rounds
            ?.filter((round) => round.prompts_count > 0)
            .reduce((map, round) => {
                return {
                    ...map,
                    ...round.prompts.reduce(
                        (pMap, prompt) => ({
                            ...pMap,
                            [prompt.id]: true,
                        }),
                        {},
                    ),
                };
            }, {}) || {},
    );

    const toggleRoundId = useCallback(
        (roundId?: string) => {
            if (!roundId) {
                // deselect all rounds and prompts
                setSelectedRoundIds({});
                setSelectedPromptIds({});
            } else if (roundId == "ALL") {
                // select all rounds and prompts
                setSelectedRoundIds(
                    rounds?.reduce((map, round) => {
                        return round.prompts_count > 0
                            ? { ...map, [round.id]: true }
                            : map;
                    }, {}) || {},
                );
                setSelectedPromptIds(
                    rounds
                        ?.filter((round) => round.prompts_count > 0)
                        .reduce((map, round) => {
                            return {
                                ...map,
                                ...round.prompts.reduce(
                                    (pMap, prompt) => ({
                                        ...pMap,
                                        [prompt.id]: true,
                                    }),
                                    {},
                                ),
                            };
                        }, {}) || {},
                );
            } else {
                const round = rounds?.find((round) => round.id === roundId);
                if (!!selectedRoundIds[roundId]) {
                    // if round is selected, deselect the round and all prompts in it
                    const newSelectedRoundIds = Object.keys(
                        selectedRoundIds,
                    ).reduce((map, key) => {
                        return key === roundId ? map : { ...map, [key]: true };
                    }, {});
                    setSelectedRoundIds(newSelectedRoundIds);
                    setSelectedPromptIds(
                        Object.keys(selectedPromptIds).reduce((map, key) => {
                            return !!round?.prompts.find(
                                (prompt) => prompt.id === key,
                            )
                                ? map
                                : { ...map, [key]: true };
                        }, {}),
                    );
                } else {
                    // otherwise, select the round and any prompts in it that aren't selected
                    const newSelectedRoundIds = {
                        ...selectedRoundIds,
                        [roundId]: true,
                    };
                    setSelectedRoundIds(newSelectedRoundIds);
                    setSelectedPromptIds({
                        ...selectedPromptIds,
                        ...round?.prompts.reduce(
                            (pMap, prompt) => ({
                                ...pMap,
                                [prompt.id]: true,
                            }),
                            {},
                        ),
                    });
                }
            }
        },
        [selectedRoundIds, rounds, selectedPromptIds],
    );

    const togglePromptId = useCallback(
        (promptId?: string) => {
            if (!promptId) {
                setSelectedPromptIds({});
            } else {
                // handle selectedRoundIds here too
                const round = rounds?.find(
                    (round) =>
                        !!round?.prompts?.find(
                            (prompt) => prompt.id === promptId,
                        ),
                );

                if (!!selectedPromptIds[promptId]) {
                    // if prompt is selected, deselect it and its round
                    const newSelectedPromptIds = Object.keys(
                        selectedPromptIds,
                    ).reduce((map, key) => {
                        return key === promptId ? map : { ...map, [key]: true };
                    }, {});
                    setSelectedPromptIds(newSelectedPromptIds);

                    const newSelectedRoundIds = Object.keys(
                        selectedRoundIds,
                    ).reduce((map, key) => {
                        return key === round.id ? map : { ...map, [key]: true };
                    }, {});
                    setSelectedRoundIds(newSelectedRoundIds);
                } else {
                    // otherwise, select the prompt (and if its round now has all its prompts selected, select the round too)
                    const newSelectedPromptIds = {
                        ...selectedPromptIds,
                        [promptId]: true,
                    };
                    setSelectedPromptIds(newSelectedPromptIds);

                    if (
                        round?.prompts.every(
                            (prompt) => newSelectedPromptIds[prompt.id],
                        )
                    ) {
                        const newSelectedRoundIds = {
                            ...selectedRoundIds,
                            [round.id]: true,
                        };
                        setSelectedRoundIds(newSelectedRoundIds);
                    }
                }
            }
        },
        [selectedPromptIds, rounds, selectedRoundIds],
    );

    const [selectedBlockIds, setSelectedBlockIds] = useState({});

    const toggleBlockId = useCallback(
        (blockId?: string) => {
            if (!blockId) {
                setSelectedBlockIds({});
            } else if (blockId == "ALL") {
                setSelectedBlockIds(
                    Object.keys(blockVariableMap).reduce((map, blockId) => {
                        return { ...map, [blockId]: true };
                    }, {}),
                );
            } else {
                const newSelectedBlockIds = !!selectedBlockIds[blockId]
                    ? Object.keys(selectedBlockIds).reduce((map, key) => {
                          return key === blockId
                              ? map
                              : { ...map, [key]: true };
                      }, {})
                    : { ...selectedBlockIds, [blockId]: true };
                setSelectedBlockIds(newSelectedBlockIds);
            }
        },
        [selectedBlockIds, blockVariableMap],
    );

    const promptMap = useMemo<{
        [promptId: string]: Prompt;
    }>(() => {
        return (
            rounds
                ?.filter((round) => round.prompts?.length > 0)
                .reduce((map, round) => {
                    return {
                        ...map,
                        ...round.prompts
                            .filter(
                                (prompt) =>
                                    !!selectedPromptIds &&
                                    selectedPromptIds[prompt.id],
                            )
                            .reduce(
                                (pMap, prompt) => ({
                                    ...pMap,
                                    [prompt.id]: {
                                        ...prompt,
                                        round_id: round.id,
                                        time_horizon_id: round.time_horizon_id,
                                    },
                                }),
                                {},
                            ),
                    };
                }, {}) || {}
        );
    }, [rounds, selectedPromptIds]);

    useEffect(() => {
        if (!!modelVariables?.length) {
            let newBlockVariableMap = groupBy(modelVariables, "model_block_id");
            setBlockVariableMap(newBlockVariableMap);
            setSelectedBlockIds(
                Object.keys(newBlockVariableMap).reduce((map, blockId) => {
                    return { ...map, [blockId]: true };
                }, {}),
            );
        }
    }, [modelVariables]);

    useEffect(() => {
        if (!!modelVariables?.length && !!variableValues?.length) {
            let valuesGroupedByVariableId = groupBy(
                variableValues,
                "model_variable_id",
            );

            let newModelVariableMap = {};

            let newVariableValueMap = modelVariables.reduce((map, variable) => {
                newModelVariableMap = {
                    ...newModelVariableMap,
                    [variable.id]: variable,
                };

                let variableValuesMap = {};

                switch (variable.scope) {
                    case ModelVariableScope.User:
                        variableValuesMap = groupBy(
                            valuesGroupedByVariableId[variable.id],
                            "user_id",
                        );
                        break;
                    case ModelVariableScope.Team:
                        variableValuesMap = groupBy(
                            valuesGroupedByVariableId[variable.id],
                            "team_id",
                        );
                        break;
                    case ModelVariableScope.Cohort:
                        variableValuesMap = groupBy(
                            valuesGroupedByVariableId[variable.id],
                            "cohort_id",
                        );
                        break;
                }

                let scopedMap = Object.keys(variableValuesMap).reduce(
                    (m, scopeId) => {
                        if (variable.uses_time) {
                            let mapByTimeHorizonId = variableValuesMap[
                                scopeId
                            ].reduce(
                                (thMap, value: VariableValue) => ({
                                    ...thMap,
                                    [value.time_horizon_id]: value,
                                }),
                                {},
                            );
                            return {
                                ...m,
                                [scopeId]: mapByTimeHorizonId,
                            };
                        } else {
                            return {
                                ...m,
                                [scopeId]: variableValuesMap[scopeId][0],
                            };
                        }
                    },
                    {},
                );

                return { ...map, [variable.id]: scopedMap };
            }, {});

            setVariableMap(newModelVariableMap);
            setValueMap(newVariableValueMap);
        }
    }, [modelVariables, variableValues]);

    useEffect(() => {
        if (!!selections?.length) {
            setSelectionMap(groupBy(selections, "prompt_id"));
        }
    }, [selections]);

    const teamCount = useMemo(() => {
        return teams?.length || 0;
    }, [teams]);

    const participantCount = useMemo(() => {
        return (
            teams?.reduce((count, team) => {
                return count + (team?.participants?.length || 0);
            }, 0) || 0
        );
    }, [teams]);

    const lineageBlockIdsObject = useMemo(() => {
        if (!modelBlocks?.length) return {};

        const flattened = flatten(modelBlocks, "modelBlocks", "id") || [];
        let filtered = {};
        const flatObject = flattened.reduce(
            (map, item) => ({
                ...map,
                [item.id]: item,
            }),
            {},
        );
        Object.keys(blockVariableMap).forEach((blockId) => {
            let lineageIds =
                getLineageIds(blockId, flatObject, "parent_model_block_id") ||
                [];
            filtered = {
                ...filtered,
                [blockId]: true,
                ...lineageIds.reduce((map, id) => ({ ...map, [id]: true }), {}),
            };
        });
        return filtered;
    }, [modelBlocks, blockVariableMap]);

    const teamMap = useMemo<{
        [teamId: string]: Team;
    }>(() => {
        return teams.reduce((map, team) => ({ ...map, [team.id]: team }), {});
    }, [teams]);

    const participantMap = useMemo<{
        [participantId: string]: Participant;
    }>(() => {
        return teams.reduce(
            (map, team) => ({
                ...map,
                ...team.participants.reduce(
                    (pMap, participant) => ({
                        ...pMap,
                        [participant.id]: participant,
                    }),
                    {},
                ),
            }),
            {},
        );
    }, [teams]);

    const promptTypeAndScopeMap = useMemo<{
        [promptType: string]: { [promptScope: string]: Prompt[] };
    }>(() => {
        return Object.values(promptMap)
            .filter(
                (prompt) =>
                    !!selectedPromptIds[prompt.id] &&
                    !!selectionMap[prompt.id]?.length,
            )
            .reduce((map, prompt) => {
                return map[prompt.prompt_type]
                    ? {
                          ...map,
                          [prompt.prompt_type]: map[prompt.prompt_type][
                              prompt.scope
                          ]
                              ? {
                                    ...map[prompt.prompt_type],
                                    [prompt.scope]: [
                                        ...map[prompt.prompt_type][
                                            prompt.scope
                                        ],
                                        prompt,
                                    ],
                                }
                              : {
                                    ...map[prompt.prompt_type],
                                    [prompt.scope]: [prompt],
                                },
                      }
                    : {
                          ...map,
                          [prompt.prompt_type]: { [prompt.scope]: [prompt] },
                      };
            }, {});
    }, [promptMap, selectedPromptIds, selectionMap]);

    const promptScopeAndTypeMap = useMemo<{
        [promptScope: string]: { [promptType: string]: Prompt[] };
    }>(() => {
        return Object.values(promptMap)
            .filter(
                (prompt) =>
                    !!selectedPromptIds[prompt.id] &&
                    !!selectionMap[prompt.id]?.length,
            )
            .reduce((map, prompt) => {
                return map[prompt.scope]
                    ? {
                          ...map,
                          [prompt.scope]: map[prompt.scope][prompt.prompt_type]
                              ? {
                                    ...map[prompt.scope],
                                    [prompt.prompt_type]: [
                                        ...map[prompt.scope][
                                            prompt.prompt_type
                                        ],
                                        prompt,
                                    ],
                                }
                              : {
                                    ...map[prompt.scope],
                                    [prompt.prompt_type]: [prompt],
                                },
                      }
                    : {
                          ...map,
                          [prompt.scope]: { [prompt.prompt_type]: [prompt] },
                      };
            }, {});
    }, [promptMap, selectedPromptIds, selectionMap]);

    const [selectionsGrouping, setSelectionsGrouping] = useState<
        "question" | "people" | "type"
    >("question");
    const [resultsGrouping, setResultsGrouping] = useState<
        "variable" | "people" | "time"
    >("variable");

    return {
        variableMap,
        valueMap,
        selectionMap,
        promptMap,
        teamCount,
        participantCount,
        lineageBlockIdsObject,
        blockVariableMap,
        selectedBlockIds,
        selectedPromptIds,
        selectedRoundIds,
        toggleBlockId,
        togglePromptId,
        toggleRoundId,
        teamMap,
        participantMap,
        promptTypeAndScopeMap,
        promptScopeAndTypeMap,
        selectionsGrouping,
        setSelectionsGrouping,
        resultsGrouping,
        setResultsGrouping,
    };
}

export function useSelectionsGroupedByScopeEntity(
    selectionMap: { [index: string]: SelectionShape[] },
    teamMap: {
        [teamId: string]: Team;
    },
    participantMap: {
        [participantId: string]: Participant;
    },
    scope: "Team" | "Participant",
    prompts: Prompt[],
): { [index: string]: SelectionShape[] } {
    const selectionsGroupedByScopeEntity: {
        [index: string]: SelectionShape[];
    } = useMemo(() => {
        if (
            !!selectionMap &&
            !!Object.keys(selectionMap)?.length &&
            !!teamMap &&
            !!Object.keys(teamMap)?.length &&
            !!participantMap &&
            !!Object.keys(participantMap)?.length &&
            !!prompts?.length
        ) {
            const groupedPrompts = groupBy(prompts, "id");

            const flatSelections: SelectionShape[] = Object.keys(selectionMap)
                .reduce((map, key) => [...map, ...selectionMap[key]], [])
                .map((selection) => ({
                    ...selection,
                    prompt_text: !!groupedPrompts[selection.prompt_id]?.length
                        ? groupedPrompts[selection.prompt_id][0].content
                        : selection.prompt_text,
                    option_text:
                        !!groupedPrompts[selection.prompt_id]?.length &&
                        !!groupedPrompts[selection.prompt_id][0].options.find(
                            (option) => option.id === selection.option_id,
                        )
                            ? groupedPrompts[
                                  selection.prompt_id
                              ][0].options.find(
                                  (option) => option.id === selection.option_id,
                              ).content
                            : selection.option_text,
                }));

            if (scope === "Participant") {
                const newSelectionsGroupedByScopeEntity = groupBy(
                    flatSelections,
                    "user_id",
                );
                return Object.keys(participantMap)
                    .filter(
                        (participantId) =>
                            !!newSelectionsGroupedByScopeEntity[participantId],
                    )
                    .reduce(
                        (map, participantId) => ({
                            ...map,
                            [participantMap[participantId]?.name ||
                            participantId]:
                                newSelectionsGroupedByScopeEntity[
                                    participantId
                                ],
                        }),
                        {},
                    );
            } else {
                const newSelectionsGroupedByScopeEntity = groupBy(
                    flatSelections,
                    "team_id",
                );
                return Object.keys(teamMap)
                    .filter(
                        (teamId) => !!newSelectionsGroupedByScopeEntity[teamId],
                    )
                    .reduce(
                        (map, teamId) => ({
                            ...map,
                            [teamMap[teamId]?.name || teamId]:
                                newSelectionsGroupedByScopeEntity[teamId],
                        }),
                        {},
                    );
            }
        }
    }, [selectionMap, teamMap, participantMap, prompts]);

    return selectionsGroupedByScopeEntity;
}

export function useSelectionsGroupedByPromptText(
    prompts: Prompt[],
    selectionMap: { [index: string]: SelectionShape[] },
): { [index: string]: SelectionShape[] } {
    const selectionsGroupedByPromptText: {
        [index: string]: SelectionShape[];
    } = useMemo(() => {
        if (
            !!prompts?.length &&
            !!selectionMap &&
            !!Object.keys(selectionMap)?.length
        ) {
            const promptsGroupedByText = groupBy(prompts, "content");
            return Object.keys(promptsGroupedByText).reduce(
                (map, promptText) => ({
                    ...map,
                    [promptText]: promptsGroupedByText[promptText].reduce(
                        (selections, prompt) =>
                            !!selectionMap[prompt.id]
                                ? [...selections, ...selectionMap[prompt.id]]
                                : selections,
                        [],
                    ),
                }),
                {},
            );
        }
    }, [prompts, selectionMap]);

    return selectionsGroupedByPromptText;
}

export function useSelectionsGroupedByOptionText(
    prompts: Prompt[],
    selectionMap: { [index: string]: SelectionShape[] },
): { [index: string]: SelectionShape[] } {
    const selectionsGroupedByOptionText: {
        [index: string]: SelectionShape[];
    } = useMemo(() => {
        if (
            !!prompts?.length &&
            !!selectionMap &&
            !!Object.keys(selectionMap)?.length
        ) {
            const optionsGroupedByText = groupBy(
                prompts.reduce(
                    (map, prompt) => [...map, ...prompt.options],
                    [],
                ) as Option[],
                "content",
            );
            return Object.keys(optionsGroupedByText).reduce(
                (map, optionText) => ({
                    ...map,
                    [optionText]: optionsGroupedByText[optionText].reduce(
                        (selections, option) =>
                            !!selectionMap[option.prompt_id]
                                ? [
                                      ...selections,
                                      ...selectionMap[option.prompt_id].filter(
                                          (selection) =>
                                              selection.option_id == option.id,
                                      ),
                                  ]
                                : selections,
                        [],
                    ),
                }),
                {},
            );
        }
    }, [prompts, selectionMap]);

    return selectionsGroupedByOptionText;
}
