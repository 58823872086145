import { useFindContentBlockMethods } from "@/hooks";
import { ContentBlockShape, ContentBlockType } from "@/models";
import React, { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useInView } from "react-intersection-observer";
import { ContentBlockEditPopover } from "../content-block-selection/ContentBlockEditPopover";
import { SmallAddSiblingButton } from "./SmallAddSiblingButton";
import {
    ContentBlockConfig,
    getContentBlockConfigByType,
} from "@/model-configs";
import { useGetActiveContentBlock } from "@/hooks/activeContentBlock";

type Props = {
    contentBlock: ContentBlockShape;
    parentType?: ContentBlockType;
    siblingIndex: number;
    siblingCount: number;
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
};

function sequence(len: number, max: number) {
    return Array.from({ length: len }, (v, k) =>
        Number(((k * max) / (len - 1)).toFixed(2)),
    );
}

const threshold = sequence(99, 1);

const richTextTypes = [
    ContentBlockType["Rich Text"],
    ContentBlockType["Button"],
    ContentBlockType["Link"],
];

export default function ContentBlockOutline({
    contentBlock,
    parentType,
    siblingIndex,
    siblingCount,
    editorSizes,
}: Props) {
    const [isTopOfElementVisible, setIsTopOfElementVisible] = useState(true);
    const [isBottomOfElementVisible, setIsBottomOfElementVisible] =
        useState(true);
    const [
        elementIsCloseToRightSideOfViewPort,
        setElementIsCloseToRightSideOfViewPort,
    ] = useState(false);

    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { getContentBlock } = useFindContentBlockMethods();
    const isActive = useMemo(() => {
        return activeContentBlock?.id === contentBlock?.id;
    }, [activeContentBlock]);

    const canAddSiblings = useMemo(() => {
        if (isActive && activeContentBlock) {
            const parent = getContentBlock(
                activeContentBlock.parent_content_block_id,
            );
            if (parent) {
                const config = getContentBlockConfigByType(
                    parent.content_block_type,
                ) as ContentBlockConfig;
                return (
                    config?.addableChildBlocks?.length ||
                    config?.addableLayouts?.length
                );
                return false;
            }
        }
    }, [parentType, isActive]);

    const { ref } = useInView({
        root: document.querySelector("#main"),
        rootMargin: parentType !== ContentBlockType.Page ? "-150px" : "-72px",
        threshold,
        onChange(inView, interSectionObserverEntry) {
            const viewPort = interSectionObserverEntry.target.closest("#main");
            if (interSectionObserverEntry.boundingClientRect.width < 200) {
                if (viewPort) {
                    setElementIsCloseToRightSideOfViewPort(
                        () =>
                            viewPort.getBoundingClientRect().right -
                                interSectionObserverEntry.boundingClientRect
                                    .right <
                            200,
                    );
                }
            }

            if (
                parentType !== ContentBlockType.Page &&
                contentBlock?.content_block_type !== ContentBlockType.Page
            ) {
                console.log("rightere");
                if (
                    interSectionObserverEntry.intersectionRect.top <
                    interSectionObserverEntry.boundingClientRect.top
                ) {
                    //which top menu is visible

                    setIsTopOfElementVisible(
                        () =>
                            interSectionObserverEntry.boundingClientRect.top >
                            150,
                    );
                } else {
                    //is the top menu visible?
                    const main = document.querySelector("#main");
                    const widthWhenVisible =
                        72 *
                        parseFloat(
                            getComputedStyle(document.documentElement).fontSize,
                        );
                    const topMenuIsVisible =
                        main.getBoundingClientRect().width < widthWhenVisible;
                    const padding = topMenuIsVisible ? 150 : 72;
                    setIsTopOfElementVisible(
                        () =>
                            interSectionObserverEntry.intersectionRatio === 1 ||
                            (interSectionObserverEntry.intersectionRect
                                .bottom <=
                                interSectionObserverEntry.boundingClientRect
                                    .bottom &&
                                interSectionObserverEntry.intersectionRect.top >
                                    padding &&
                                interSectionObserverEntry.boundingClientRect
                                    .top > padding),
                    );
                }
            } else {
                console.log("down here");

                setIsBottomOfElementVisible((wasVisible) => {
                    if (
                        viewPort &&
                        viewPort.getBoundingClientRect().bottom <=
                            interSectionObserverEntry.boundingClientRect.bottom
                    ) {
                        return false;
                    }
                    return true;
                });
                setIsTopOfElementVisible((wasVisibile) => {
                    /**
                     * if none of this element is visible,
                     * allow child element to scroll away with it
                     */
                    if (!inView) return true;

                    /**
                     * if the element is above the edge of the wrapping element
                     * highjack and set false
                     */
                    if (
                        viewPort &&
                        viewPort.getBoundingClientRect().top >=
                            interSectionObserverEntry.boundingClientRect.top
                    ) {
                        console.log(
                            "this is the top 166",
                            interSectionObserverEntry.boundingClientRect.top,
                            viewPort.getBoundingClientRect().top,
                        );
                        return false;
                    }

                    if (wasVisibile) {
                        /**
                         * prevent jank of child element being set to fixed position
                         * by allowing 72 pixels of padding
                         */
                        return (
                            interSectionObserverEntry.boundingClientRect.top >
                            150
                        );
                    } else {
                        console.log("this",interSectionObserverEntry.boundingClientRect.top)
                        /**
                         * prevent child element, which has already been set to fixed position
                         * from being stuck to the top of the viewport
                         * by setting to false as soon as top is visible
                         */
                        return (
                            interSectionObserverEntry.boundingClientRect.top > 160
                        );
                    }
                });
            }
        },
    });

    const onRefChange = useCallback(
        (element: HTMLDivElement) => {
            if (element) {
                ref(element);
            }
        },
        [contentBlock?.id],
    );

    if (!contentBlock) return <></>;

    const mobilePopoverWrapper = document.querySelector("#popover-portal");
    return (
        <>
            <div
                ref={onRefChange}
                className={`cb-outline pointer-events-none absolute inset-0 z-[9000] flex h-full w-full
                justify-center overflow-visible border-2 pb-4 opacity-100 shadow-md ${
                    isActive &&
                    !richTextTypes.includes(contentBlock.content_block_type)
                        ? "active-content-block z-100 border-blue-600/90 shadow-black/50"
                        : "z-90 border-dashed border-blue-600/5"
                } ${
                    activeContentBlock?.content_block_type ===
                    ContentBlockType["Rich Text"]
                        ? "-inset-2"
                        : ""
                }`}
                style={{ borderRadius: "inherit" }}
                id="cb-outline"
                data-testid="cb-outline"
            >
                {/* {!isActive && (
                <div
                    className={`absolute z-90 p-1 text-white bg-blue-600 text-sm ${contentBlockTypeLabelPositionClasses}`}
                >
                    {contentBlock.title ||
                        titleCase(contentBlock.content_block_type)}
                </div>
            )} */}

                {canAddSiblings && (
                    <SmallAddSiblingButton contentBlock={contentBlock} />
                )}
                {/* {isActive &&
                    parentTypesThatAllowAddingSiblings.includes(parentType) && (
                        <>
                            <AddSiblingButton
                                position="before"
                                contentBlock={contentBlock}
                                isParentTopVisible={isTopOfElementVisible}
                                editorSizes={editorSizes}
                            />
                            <AddSiblingButton
                                position="after"
                                contentBlock={contentBlock}
                                isParentTopVisible={isTopOfElementVisible}
                                editorSizes={editorSizes}
                            />

                            {contentBlock?.content_block_type ===
                                ContentBlockType.Question &&
                                (contentBlock.prompt?.prompt_type ===
                                    PromptType.Timeline ||
                                    contentBlock.prompt?.prompt_type ===
                                        PromptType["Drag and Drop"]) && (
                                    <TimelineAndDndForms
                                        prompt={contentBlock.prompt}
                                    />
                                )}

                            {contentBlock?.content_block_type ===
                                ContentBlockType.Question &&
                                !!contentBlock?.prompt && (
                                    <OptionConditionalDisplayTrigger
                                        prompt={contentBlock.prompt}
                                    />
                                )}
                        </>
                    )} */}
            </div>
            {isActive && (
                <>
                    {!!mobilePopoverWrapper ? (
                        createPortal(
                            <ContentBlockEditPopover
                                activeId={contentBlock.id}
                                contentBlockNested={contentBlock}
                                siblingIndex={siblingIndex}
                                siblingCount={siblingCount}
                                isParentTopVisible={isTopOfElementVisible}
                                isParentBottomVisible={isBottomOfElementVisible}
                                parentIsCloseToRightOfViewPort={
                                    elementIsCloseToRightSideOfViewPort
                                }
                                parentType={parentType}
                                editorSizes={editorSizes}
                            />,
                            mobilePopoverWrapper,
                        )
                    ) : (
                        <ContentBlockEditPopover
                            activeId={contentBlock.id}
                            contentBlockNested={contentBlock}
                            siblingIndex={siblingIndex}
                            siblingCount={siblingCount}
                            isParentTopVisible={isTopOfElementVisible}
                            isParentBottomVisible={
                                parentType !== ContentBlockType.Page &&
                                isBottomOfElementVisible
                            }
                            parentIsCloseToRightOfViewPort={
                                elementIsCloseToRightSideOfViewPort
                            }
                            parentType={parentType}
                            editorSizes={editorSizes}
                        />
                    )}
                </>
            )}
        </>
    );
}
