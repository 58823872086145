import React, { useCallback, useState } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";

type RolePermissionSelectorProps = {
    role: App.Data.RoleData;
    permissions: App.Data.PermissionData[];
};

const RolePermissionSelector: React.FC<RolePermissionSelectorProps> = ({
    role,
    permissions,
}) => {
    const [isPending, setIsPending] = useState(false);
    const handlePermissionChange = useCallback(
        (permissionId: number, checked: boolean) => {
            const permission = permissions.find(
                (permission) => permission.id === permissionId,
            );
            const newRole = {
                ...role,
                permissions: checked
                    ? [...role.permissions, permission]
                    : role.permissions.filter(
                          (permission) => permission.id !== permissionId,
                      ),
            };

            SapienInertia.put(
                "roles.update",
                newRole,
                {
                    role: role.id.toString(),
                },
                {
                    onSuccess: () => {
                        setIsPending(false);
                    },
                    onError: () => {
                        setIsPending(false);
                    },
                },
            );
        },
        [role, permissions],
    );

    return (
        <div className="flex flex-col gap-2 rounded-lg bg-white p-4 shadow">
            <h2>{role.name}</h2>
            <div className="flex flex-col px-4">
                {permissions
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((permission) => (
                        <label
                            key={role.id + permission.id}
                            className="flex items-center gap-2 data-[pending=true]:animate-pulse"
                            data-pending={isPending}
                        >
                            <input
                                disabled={isPending}
                                type="checkbox"
                                defaultChecked={role.permissions.some(
                                    (rolePermission) =>
                                        rolePermission.id === permission.id,
                                )}
                                onChange={(e) =>
                                    handlePermissionChange(
                                        permission.id,
                                        e.target.checked,
                                    )
                                }
                            />
                            {permission.name}
                        </label>
                    ))}
            </div>
        </div>
    );
};

export default RolePermissionSelector;
