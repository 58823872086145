import React, { FC, ReactNode, useMemo } from "react";
import { useModelBlockArray, useModelBlocks } from "../state";
import { ModelBlock } from "@/models";
import {
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
} from "@heroicons/react/24/outline";

export const VariablePanel = ({
    toggleModelBlockAccordionKey,
    expandMultipleModelBlockAccordionKeys,
    collapseMultipleModelBlockAccordionKeys,
    getIsModelBlockExpanded,
}: {
    toggleModelBlockAccordionKey: (key: string) => void;
    expandMultipleModelBlockAccordionKeys: (keys: string[]) => void;
    collapseMultipleModelBlockAccordionKeys: (keys?: string[]) => void;
    getIsModelBlockExpanded: (modelBlockId: string) => boolean;
}) => {
    const { modelBlocks } = useModelBlocks();
    const { modelBlockArray } = useModelBlockArray();

    return (
        <div>
            <div className="">
                <div className="mb-2 flex items-baseline justify-between">
                    <div className="text-l font-bold">Variables</div>
                    <div className="text-xs">
                        <span
                            className="mr-2 cursor-pointer hover:underline"
                            onClick={() =>
                                !!expandMultipleModelBlockAccordionKeys &&
                                !!modelBlockArray?.length &&
                                expandMultipleModelBlockAccordionKeys(
                                    modelBlockArray.map((block) => block.id),
                                )
                            }
                        >
                            Expand
                        </span>
                        <span
                            className="cursor-pointer hover:underline"
                            onClick={() =>
                                !!collapseMultipleModelBlockAccordionKeys &&
                                collapseMultipleModelBlockAccordionKeys()
                            }
                        >
                            Collapse
                        </span>
                    </div>
                </div>
                {Object.values(modelBlocks)
                    .filter((modelBlock) => !modelBlock.has_connections)
                    .map((modelBlock) => (
                        <VariablePanelBlockDisplay
                            key={modelBlock.id}
                            modelBlock={modelBlock}
                            toggleModelBlockAccordionKey={
                                toggleModelBlockAccordionKey
                            }
                            expandMultipleModelBlockAccordionKeys={
                                expandMultipleModelBlockAccordionKeys
                            }
                            collapseMultipleModelBlockAccordionKeys={
                                collapseMultipleModelBlockAccordionKeys
                            }
                            getIsModelBlockExpanded={getIsModelBlockExpanded}
                        />
                    ))}
                <div className="mt-4">
                    {Object.values(modelBlocks)
                        .filter((modelBlock) => modelBlock.has_connections)
                        .map((modelBlock) => (
                            <VariablePanelBlockDisplay
                                key={modelBlock.id}
                                modelBlock={modelBlock}
                                toggleModelBlockAccordionKey={
                                    toggleModelBlockAccordionKey
                                }
                                expandMultipleModelBlockAccordionKeys={
                                    expandMultipleModelBlockAccordionKeys
                                }
                                collapseMultipleModelBlockAccordionKeys={
                                    collapseMultipleModelBlockAccordionKeys
                                }
                                getIsModelBlockExpanded={
                                    getIsModelBlockExpanded
                                }
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

const VariablePanelBlockDisplay = ({
    modelBlock,
    toggleModelBlockAccordionKey,
    expandMultipleModelBlockAccordionKeys,
    collapseMultipleModelBlockAccordionKeys,
    getIsModelBlockExpanded,
}: {
    modelBlock: ModelBlock;
    toggleModelBlockAccordionKey: (key: string) => void;
    expandMultipleModelBlockAccordionKeys: (keys: string[]) => void;
    collapseMultipleModelBlockAccordionKeys: (keys?: string[]) => void;
    getIsModelBlockExpanded: (modelBlockId: string) => boolean;
}) => {
    const isExpanded = useMemo(
        () => getIsModelBlockExpanded(modelBlock.id),
        [getIsModelBlockExpanded],
    );

    return (
        <div className="w-full text-sm">
            <div
                className={`my-0.5 cursor-pointer py-0.5 ${
                    isExpanded
                        ? "bg-[rgba(255,255,255,0.1)]"
                        : // : !!modelBlock?.modelVariables?.length
                          // ? "bg-[rgba(255,255,255,0.06)]"
                          "bg-[rgba(255,255,255,0.03)]"
                }`}
                onClick={() =>
                    !!toggleModelBlockAccordionKey &&
                    toggleModelBlockAccordionKey(modelBlock.id)
                }
            >
                <div className="flex items-center">
                    <svg
                        className={`-mr-0.5 ml-2 h-5 w-5 transition-all ${
                            isExpanded ? "rotate-0" : "-rotate-90"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <div className="block w-full border-[1px] border-[rgba(0,0,0,0)] px-1.5 py-1">
                        <div>{modelBlock.label}</div>
                    </div>
                    <div className="flex items-center">
                        <div className="px-2 text-xs font-bold">{`${
                            modelBlock?.modelVariables?.length || ""
                        }`}</div>
                        <BlockItemButton
                            onClick={() =>
                                !!expandMultipleModelBlockAccordionKeys &&
                                expandMultipleModelBlockAccordionKeys(
                                    modelBlock.modelBlocks?.length
                                        ? [
                                              modelBlock.id,
                                              ...modelBlock.modelBlocks.map(
                                                  (block) => block.id,
                                              ),
                                          ]
                                        : [modelBlock.id],
                                )
                            }
                        >
                            <ChevronDoubleDownIcon className="h-3 w-3" />
                        </BlockItemButton>
                        <BlockItemButton
                            onClick={() =>
                                !!collapseMultipleModelBlockAccordionKeys &&
                                collapseMultipleModelBlockAccordionKeys(
                                    modelBlock.modelBlocks?.length
                                        ? [
                                              modelBlock.id,
                                              ...modelBlock.modelBlocks.map(
                                                  (block) => block.id,
                                              ),
                                          ]
                                        : [modelBlock.id],
                                )
                            }
                        >
                            <ChevronDoubleUpIcon className="h-3 w-3" />
                        </BlockItemButton>
                    </div>
                </div>
            </div>
            {!!isExpanded && !!modelBlock?.modelVariables?.length && (
                <div className="border-box -mt-0.5 border-l border-[rgba(255,255,255,0.09)] text-xs">
                    <div className="py-1">
                        {modelBlock.modelVariables.map((variable) => (
                            <div
                                key={variable.id}
                                className="overflow-hidden text-ellipsis whitespace-nowrap pl-1"
                            >
                                {variable.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {modelBlock.modelBlocks && modelBlock.modelBlocks.length !== 0 && (
                <div className="pl-4">
                    {modelBlock.modelBlocks.map((child) => (
                        <VariablePanelBlockDisplay
                            key={child.id}
                            modelBlock={child}
                            toggleModelBlockAccordionKey={
                                toggleModelBlockAccordionKey
                            }
                            expandMultipleModelBlockAccordionKeys={
                                expandMultipleModelBlockAccordionKeys
                            }
                            collapseMultipleModelBlockAccordionKeys={
                                collapseMultipleModelBlockAccordionKeys
                            }
                            getIsModelBlockExpanded={getIsModelBlockExpanded}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const BlockItemButton: FC<{
    onClick?: () => void;
    children?: ReactNode;
}> = ({ onClick, children }) => {
    return (
        <button
            type="button"
            className="m-0.5 inline-flex items-center rounded-full bg-neutral-800 p-1 text-center text-sm font-medium text-neutral-100 hover:bg-neutral-700 focus:outline-none focus:ring-0"
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
        >
            {children}
        </button>
    );
};
