import React, { useMemo } from "react";
import {
    Participant,
    Prompt,
    PromptType,
    Round,
    SelectionShape,
    Team,
    TimeHorizon,
} from "@/models";
import {
    useSelectionsGroupedByOptionText,
    useSelectionsGroupedByPromptText,
    useSelectionsGroupedByScopeEntity,
} from "../useDiscoveryLayer";
import { DiscoveryBoxPlot, DiscoveryPieChart } from "./DiscoveryCharts";
import { useSelectionsGroupedByPromptProperty } from "../useDiscoveryStatistics";
import { Tuple } from "victory";

export const DiscoveryDataCharts = ({
    prompts,
    selectionMap,
    teamMap,
    participantMap,
    promptScope,
    promptType,
    hideCategoryQuestionScopeGrouping,
    roundMap,
    timeHorizonMap,
}: {
    prompts: Prompt[];
    selectionMap: { [index: string]: SelectionShape[] };
    teamMap: {
        [teamId: string]: Team;
    };
    participantMap: {
        [participantId: string]: Participant;
    };
    promptScope: "Team" | "Participant";
    promptType: PromptType;
    hideCategoryQuestionScopeGrouping?: boolean;
    roundMap: {
        [id: string]: Partial<Round>;
    };
    timeHorizonMap: {
        [id: string]: Partial<TimeHorizon>;
    };
}) => {
    const selectionsGroupedByScopeEntity = useSelectionsGroupedByScopeEntity(
        selectionMap,
        teamMap,
        participantMap,
        promptScope,
        prompts,
    );
    const selectionsGroupedByOptionText = useSelectionsGroupedByOptionText(
        prompts,
        selectionMap,
    );
    const selectionsGroupedByPromptText = useSelectionsGroupedByPromptText(
        prompts,
        selectionMap,
    );
    const selectionsGroupedByRoundId = useSelectionsGroupedByPromptProperty(
        prompts,
        selectionMap,
        "round_id",
    );
    const selectionsGroupedByTimeHorizonId =
        useSelectionsGroupedByPromptProperty(
            prompts,
            selectionMap,
            "time_horizon_id",
        );

    const selectionsGroupedByRound = useMemo(() => {
        return !!selectionsGroupedByRoundId &&
            Object.keys(selectionsGroupedByRoundId)?.length > 1 &&
            // Object.keys(selectionsGroupedByRoundId).some(
            //     (roundId) =>
            //         Object.keys(
            //             groupBy(
            //                 selectionsGroupedByRoundId[roundId],
            //                 "prompt_id",
            //             ),
            //         ).length > 1,
            // ) &&
            !!roundMap
            ? Object.keys(roundMap)
                  ?.filter((roundId) => !!selectionsGroupedByRoundId[roundId])
                  .reduce(
                      (map, roundId) => ({
                          ...map,
                          [roundMap[roundId].title]:
                              selectionsGroupedByRoundId[roundId],
                      }),
                      {},
                  )
            : {};
    }, [selectionsGroupedByRoundId, roundMap]);

    const selectionsGroupedByTimeHorizon = useMemo(() => {
        return !!selectionsGroupedByTimeHorizonId &&
            Object.keys(selectionsGroupedByTimeHorizonId)?.length > 1 &&
            // Object.keys(selectionsGroupedByTimeHorizonId).some(
            //     (timeHorizonId) =>
            //         Object.keys(
            //             groupBy(
            //                 selectionsGroupedByTimeHorizonId[timeHorizonId],
            //                 "prompt_id",
            //             ),
            //         ).length > 1,
            // ) &&
            !!timeHorizonMap
            ? Object.keys(timeHorizonMap)
                  ?.filter(
                      (timeHorizonId) =>
                          !!selectionsGroupedByTimeHorizonId[timeHorizonId],
                  )
                  .reduce(
                      (map, timeHorizonId) => ({
                          ...map,
                          [`Time Horizon ${timeHorizonMap[timeHorizonId].time_index}`]:
                              selectionsGroupedByTimeHorizonId[timeHorizonId],
                      }),
                      {},
                  )
            : {};
    }, [selectionsGroupedByTimeHorizonId, timeHorizonMap]);

    return (
        <div className="px-3">
            {!!prompts?.length &&
                !!selectionMap &&
                !!Object.keys(selectionMap).length && (
                    <div className="">
                        {(promptType == PromptType["Numerical Slider"] ||
                            promptType == PromptType["Numerical Input"]) && (
                            <NumericalSliderCharts
                                prompts={prompts}
                                selectionMap={selectionMap}
                                scope={promptScope as "Team" | "Participant"}
                                selectionsGroupedByScopeEntity={
                                    selectionsGroupedByScopeEntity
                                }
                                selectionsGroupedByPromptText={
                                    selectionsGroupedByPromptText
                                }
                                selectionsGroupedByRound={
                                    selectionsGroupedByRound
                                }
                                selectionsGroupedByTimeHorizon={
                                    selectionsGroupedByTimeHorizon
                                }
                            />
                        )}
                        {(promptType == PromptType["Multiple Choice"] ||
                            promptType == PromptType["Multiple Select"] ||
                            promptType == PromptType["Toggle Switch"] ||
                            promptType == PromptType["Dropdown List"]) && (
                            <MultipleChoiceCharts
                                prompts={prompts}
                                selectionMap={selectionMap}
                                scope={promptScope as "Team" | "Participant"}
                                hideCategoryQuestionScopeGrouping={
                                    hideCategoryQuestionScopeGrouping
                                }
                                selectionsGroupedByScopeEntity={
                                    selectionsGroupedByScopeEntity
                                }
                                selectionsGroupedByOptionText={
                                    selectionsGroupedByOptionText
                                }
                                selectionsGroupedByRound={
                                    selectionsGroupedByRound
                                }
                                selectionsGroupedByTimeHorizon={
                                    selectionsGroupedByTimeHorizon
                                }
                                selectionsGroupedByPromptText={
                                    selectionsGroupedByPromptText
                                }
                            />
                        )}
                    </div>
                )}
        </div>
    );
};

type ChartProps = {
    prompts: Prompt[];
    selectionMap: { [index: string]: SelectionShape[] };
    scope: "Team" | "Participant";
    hideCategoryQuestionScopeGrouping?: boolean;
    selectionsGroupedByScopeEntity: {
        [index: string]: SelectionShape[];
    };
    selectionsGroupedByOptionText?: {
        [index: string]: SelectionShape[];
    };
    selectionsGroupedByPromptText?: {
        [index: string]: SelectionShape[];
    };
    selectionsGroupedByRound?: {
        [index: string]: SelectionShape[];
    };
    selectionsGroupedByTimeHorizon?: {
        [index: string]: SelectionShape[];
    };
};

const BoxPlotGroup = ({
    title,
    groupedSelections,
    domain,
}: {
    title: string;
    groupedSelections: { [index: string]: SelectionShape[] };
    domain?: {
        y?: Tuple<number>;
    };
}) => {
    return (
        !!groupedSelections &&
        !!Object.keys(groupedSelections).length && (
            <>
                <div className="pt-3 text-lg font-bold">{title}</div>
                <DiscoveryBoxPlot
                    data={Object.keys(groupedSelections)
                        .filter((key) => !!groupedSelections[key]?.length)
                        .reduce(
                            (map, key) => [
                                {
                                    x: key,
                                    y: groupedSelections[key].map(
                                        (selection) =>
                                            selection.numerical_value,
                                    ),
                                },
                                ...map,
                            ],
                            [],
                        )}
                    domain={domain}
                />
            </>
        )
    );
};

const NumericalSliderCharts = ({
    prompts,
    selectionMap,
    scope,
    selectionsGroupedByScopeEntity,
    selectionsGroupedByPromptText,
    selectionsGroupedByRound,
    selectionsGroupedByTimeHorizon,
}: ChartProps) => {
    const domain: { y?: Tuple<number> } = useMemo(() => {
        const min = Math.min(...prompts?.map((prompt) => prompt.min)) || 0;
        const max = Math.max(...prompts?.map((prompt) => prompt.max)) || 0;
        return min < max
            ? {
                  y: [min, max],
              }
            : undefined;
    }, [prompts]);

    return (
        <div>
            <BoxPlotGroup
                title={`Selection Data Grouped by Prompt Text`}
                groupedSelections={selectionsGroupedByPromptText}
                domain={domain}
            />
            <BoxPlotGroup
                title={`Selection Data Grouped by Round`}
                groupedSelections={selectionsGroupedByRound}
                domain={domain}
            />
            <BoxPlotGroup
                title={`Selection Data Grouped by Time Horizon`}
                groupedSelections={selectionsGroupedByTimeHorizon}
                domain={domain}
            />
            <BoxPlotGroup
                title={`Selection Data Grouped by ${scope}`}
                groupedSelections={selectionsGroupedByScopeEntity}
                domain={domain}
            />
            <BoxPlotGroup
                title={`Selection Data by Question`}
                groupedSelections={prompts
                    .filter((prompt) => !!selectionMap[prompt.id]?.length)
                    .reduce(
                        (map, prompt, index) => ({
                            ...map,
                            [`Q${index + 1}: ${prompt.content}`]:
                                selectionMap[prompt.id],
                        }),
                        {},
                    )}
                domain={domain}
            />
        </div>
    );
};

const PieChartGroup = ({
    title,
    groupedSelections,
}: {
    title: string;
    groupedSelections: { [index: string]: SelectionShape[] };
}) => {
    return (
        !!groupedSelections &&
        !!Object.keys(groupedSelections).length && (
            <>
                <div className="pt-3 text-lg font-bold">{title}</div>
                {Object.keys(groupedSelections)
                    .filter((key) => !!groupedSelections[key]?.length)
                    .map((key) => (
                        <DiscoveryPieChart
                            key={key}
                            title={key}
                            useColorScale={true}
                            data={groupedSelections[key]
                                .reduce((map, selection) => {
                                    return !!map?.some(
                                        (dataItem) =>
                                            dataItem.x == selection.option_text,
                                    )
                                        ? map.map((dataItem) => ({
                                              x: dataItem.x,
                                              y:
                                                  dataItem.x ==
                                                  selection.option_text
                                                      ? dataItem.y + 1
                                                      : dataItem.y,
                                          }))
                                        : [
                                              ...map,
                                              {
                                                  x: selection.option_text,
                                                  y: 1,
                                              },
                                          ];
                                }, [])
                                .map((dataItem) => ({
                                    x: dataItem.x,
                                    y: dataItem.y,
                                    label: dataItem.x,
                                    formattedValue: dataItem.y,
                                }))}
                        />
                    ))}
            </>
        )
    );
};

const MultipleChoiceCharts = ({
    prompts,
    selectionMap,
    scope,
    hideCategoryQuestionScopeGrouping,
    selectionsGroupedByScopeEntity,
    selectionsGroupedByOptionText,
    selectionsGroupedByRound,
    selectionsGroupedByTimeHorizon,
}: ChartProps) => {
    return (
        <div>
            <PieChartGroup
                title={`Selection Data Grouped by Option Text`}
                groupedSelections={{
                    "All Questions": Object.keys(
                        selectionsGroupedByOptionText,
                    ).reduce(
                        (map, key) => [
                            ...map,
                            ...selectionsGroupedByOptionText[key].map(
                                (selection) => ({
                                    ...selection,
                                    option_text: key,
                                }),
                            ),
                        ],
                        [],
                    ),
                }}
            />
            <PieChartGroup
                title={`Selection Data Grouped by Round`}
                groupedSelections={selectionsGroupedByRound}
            />
            <PieChartGroup
                title={`Selection Data Grouped by Time Horizon`}
                groupedSelections={selectionsGroupedByTimeHorizon}
            />
            {!hideCategoryQuestionScopeGrouping && (
                <PieChartGroup
                    title={`Selection Data Grouped by ${scope}`}
                    groupedSelections={selectionsGroupedByScopeEntity}
                />
            )}
            {!hideCategoryQuestionScopeGrouping && (
                <PieChartGroup
                    title={`Selection Data by Question`}
                    groupedSelections={prompts
                        .filter((prompt) => !!selectionMap[prompt.id]?.length)
                        .reduce(
                            (map, prompt, index) => ({
                                ...map,
                                [`Q${index + 1}: ${prompt.content}`]:
                                    prompt.options.reduce(
                                        (map, option) => [
                                            ...map,
                                            ...selectionMap[prompt.id]
                                                .filter(
                                                    (selection) =>
                                                        selection.option_id ==
                                                        option.id,
                                                )
                                                .map((selection) => ({
                                                    ...selection,
                                                    option_text: option.content,
                                                })),
                                        ],
                                        [],
                                    ),
                            }),
                            {},
                        )}
                />
            )}
        </div>
    );
};
