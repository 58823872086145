import React, { useMemo, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { Link as InertiaLink } from "@inertiajs/react";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { plus } from "react-icons-kit/feather/plus";
import { minimize2 } from "react-icons-kit/feather/minimize2";
import { maximize2 } from "react-icons-kit/feather/maximize2";
import {
    Cohort,
    CohortWithProgressData,
    Simulation,
    TeamWithProgressData,
} from "@/models";
import { PrimaryButtonOutline } from "@/components/admin-components";
import { CohortProgressDisplay } from "./CohortProgressDisplay";
import { CohortPlacementHeader } from "./CohortPlacementHeader";
import { TeamList } from "./TeamList";
import { CohortRoundMenu } from "./RoundMenu";
import { useGetProgressData } from "./useProgressData";

const tabKeys = ["Placement", "Progress"];

export const CohortDetail = ({
    simulation,
    cohort,
}: {
    simulation: Simulation & { is_course: boolean };
    cohort: CohortWithProgressData;
}) => {
    const { progressDataMap } = useGetProgressData(cohort?.id);

    const teams = useMemo<TeamWithProgressData[]>(() => {
        return [...(cohort?.teams || [])]
            .map((team) =>
                !!progressDataMap && !!progressDataMap[team.id]
                    ? {
                          ...team,
                          progress_map: progressDataMap[team.id],
                      }
                    : team,
            )
            .sort((a, b) => a.team_number - b.team_number);
    }, [cohort, progressDataMap]);

    const [activeTab, setActiveTab] = useState(tabKeys[0]);

    const [expandedTeamMap, setExpandedTeamMap] = useState<{
        [index: string]: boolean;
    }>({});

    const allAreExpanded = useMemo(() => {
        return (
            !!teams?.length && teams.every((team) => expandedTeamMap[team.id])
        );
    }, [teams?.length, expandedTeamMap]);

    const [showAdvanceControls] = useState<boolean>(false);

    const isCourse = simulation?.is_course;

    return (
        !!cohort && (
            <div className="space-y-4">
                <div className="flex min-h-9 flex-row items-center justify-between">
                    <span className="text-xl font-normal">{cohort.title}</span>
                </div>
                <Tabs.Root
                    onValueChange={setActiveTab}
                    value={activeTab}
                    className="w-full"
                >
                    <Tabs.List className="flex w-full rounded-sm border-b border-slate-300/75 dark:border-slate-800">
                        {tabKeys.map((tabKey) => (
                            <Tabs.Trigger
                                key={tabKey}
                                value={tabKey}
                                className={`text-slate-900 data-[state="active"]:border-b
                                data-[state="active"]:border-indigo-600 data-[state="active"]:text-indigo-700
                                dark:text-slate-300 dark:data-[state="active"]:border-indigo-600
                                dark:data-[state="active"]:text-indigo-500`}
                            >
                                <div className="px-4 py-2 text-sm font-medium hover:bg-slate-200/50 dark:hover:bg-slate-800/25">
                                    {tabKey}
                                </div>
                            </Tabs.Trigger>
                        ))}
                    </Tabs.List>
                    {tabKeys.map((tabKey) => (
                        <Tabs.Content key={tabKey} value={tabKey}>
                            {tabKey == "Placement" ? (
                                <div className="space-y-8 py-6">
                                    <CohortPlacementHeader
                                        cohort={cohort as Cohort}
                                        showAdvanceControls={
                                            showAdvanceControls
                                        }
                                        roundMenu={
                                            <CohortRoundMenu
                                                cohort={cohort as Cohort}
                                                teams={teams}
                                                rounds={
                                                    simulation?.rounds || []
                                                }
                                                label={
                                                    !isCourse
                                                        ? "Round"
                                                        : "Lesson"
                                                }
                                            />
                                        }
                                    />
                                    <div className="flex flex-col space-y-4">
                                        <div className="flex flex-row items-center justify-between">
                                            <div className="items center flex space-x-2 text-lg font-medium">
                                                Teams
                                            </div>
                                            <span className="flex items-center space-x-2">
                                                <PrimaryButtonOutline
                                                    text={
                                                        allAreExpanded
                                                            ? "Collapse All"
                                                            : "Expand All"
                                                    }
                                                    icon={
                                                        allAreExpanded
                                                            ? minimize2
                                                            : maximize2
                                                    }
                                                    handler={() => {
                                                        setExpandedTeamMap(
                                                            teams.reduce(
                                                                (acc, team) => {
                                                                    return {
                                                                        ...acc,
                                                                        [team.id]:
                                                                            !allAreExpanded,
                                                                    };
                                                                },
                                                                {},
                                                            ),
                                                        );
                                                    }}
                                                />
                                                <PrimaryButtonOutline
                                                    text={"Create Team"}
                                                    icon={plus}
                                                    handler={() => {
                                                        SapienInertia.post(
                                                            "facilitation.save-team",
                                                            {
                                                                name: `Team ${(teams?.length || 0) + 1}`,
                                                                cohort_id:
                                                                    cohort.id,
                                                                team_number:
                                                                    (teams?.length ||
                                                                        0) + 1,
                                                            },
                                                            {},
                                                            {
                                                                preserveScroll:
                                                                    true,
                                                            },
                                                        );
                                                    }}
                                                />
                                            </span>
                                        </div>
                                        <TeamList
                                            teams={teams}
                                            rounds={simulation?.rounds || []}
                                            simulation={simulation}
                                            showAdvanceControls={
                                                showAdvanceControls
                                            }
                                            isCourse={isCourse}
                                            expandedTeamMap={expandedTeamMap}
                                            setExpandedTeamMap={
                                                setExpandedTeamMap
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="space-y-8 py-6">
                                    <div className="space-y-4">
                                        <div className="text-lg font-medium">
                                            Progress
                                        </div>
                                        <CohortProgressDisplay
                                            teams={
                                                teams as TeamWithProgressData[]
                                            }
                                            rounds={simulation?.rounds || []}
                                            isCourse={isCourse}
                                        />
                                    </div>
                                    {!isCourse && (
                                        <div className="flex flex-col justify-center space-y-1">
                                            <div className="text-lg font-medium">
                                                Links
                                            </div>
                                            <InertiaLink
                                                href={sapienRoute(
                                                    "facilitation.reflection-journal",
                                                    {
                                                        simulationSlug:
                                                            simulation?.slug,
                                                        cohortId: cohort.id,
                                                    },
                                                )}
                                                className="text-indigo-500 transition-all hover:text-indigo-800"
                                            >
                                                Reflection Journal Entries
                                            </InertiaLink>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Tabs.Content>
                    ))}
                </Tabs.Root>
            </div>
        )
    );
};
