import React, { useMemo } from "react";
import { ForecastChart } from "./Charts";
import { StatisticsTable } from "./StatisticsTable";
import { defaultStatisticsObject, ForecastProps } from "./types";

const ForecastDisplayComponent = ({
    variables,
    timeHorizons,
    forecastParameters,
    setForecastParameters,
    forecastResponse,
}: {
    variables: string[];
    timeHorizons: number[];
} & ForecastProps) => {
    const { forecastData, forecastSummary, forecastExamples, originalData } =
        forecastResponse;

    const isPending = false;

    const tableData = useMemo(() => {
        return !!forecastExamples && Object.keys(forecastExamples)?.length > 0
            ? Object.keys(forecastExamples).reduce(
                  (map, k, i) => ({
                      ...map,
                      [`Prediction ${i + 1}`]: forecastExamples[k],
                      [`Actual ${i + 1}`]: !!originalData && originalData[k],
                  }),
                  {},
              )
            : Array.from({
                  length: forecastParameters?.example_count || 6,
              }).reduce(
                  (map: Record<string, Record<string, number>>, _, i) => ({
                      ...map,
                      [`Prediction ${i + 1}`]:
                          timeHorizons?.length > 0
                              ? [
                                    ...timeHorizons,
                                    ...Array.from({
                                        length:
                                            forecastParameters?.forecast_length ||
                                            2,
                                    }).map(
                                        (_, i) =>
                                            i +
                                            timeHorizons[
                                                timeHorizons.length - 1
                                            ],
                                    ),
                                ].reduce(
                                    (map: Record<string, number>, _, i) => ({
                                        ...map,
                                        [`${i + (timeHorizons[0] || 0)}`]: 0,
                                    }),
                                    {},
                                )
                              : {},
                      [`Actual ${i + 1}`]: {},
                  }),
                  {},
              ) || {};
    }, [forecastExamples, timeHorizons]);

    return (
        <>
            <div className="w-full pt-2">
                <div className="pb-4 text-base font-semibold">Settings</div>
                <div className="grid grid-cols-1 gap-4 text-sm md:grid-cols-2">
                    <div className="">
                        <div className="flex h-full w-full flex-col justify-center text-slate-700 dark:text-slate-300">
                            <ForecastChart
                                values={
                                    (!!forecastExamples &&
                                        Object.keys(forecastExamples)?.length >
                                            0 &&
                                        forecastExamples[
                                            Object.keys(forecastExamples)[0]
                                        ]) ||
                                    {}
                                }
                                originalValues={
                                    (!!originalData &&
                                        Object.keys(originalData)?.length > 0 &&
                                        originalData[
                                            Object.keys(originalData)[0]
                                        ]) ||
                                    {}
                                }
                                variable={forecastParameters?.variable}
                                dataTimeHorizons={
                                    forecastSummary?.data_time_horizons ||
                                    timeHorizons ||
                                    []
                                }
                                forecastTimeHorizons={
                                    forecastSummary?.forecast_time_horizons ||
                                    []
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <span className="text-base">Parameters</span>
                        <div className="space-y-2">
                            <div>{`Variable`}</div>
                            <select
                                data-state={
                                    !!forecastParameters?.variable
                                        ? "selected"
                                        : "empty"
                                }
                                className="min-w-[200px] rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm
                                    text-blue-700 marker:border data-[state=empty]:text-orange-600
                                    focus:border-slate-300 focus:outline-none focus:ring-0 dark:border-slate-800
                                    dark:bg-slate-800/20 dark:text-blue-700 data-[state=empty]:dark:text-orange-400
                                    dark:focus:border-slate-700"
                                value={forecastParameters?.variable || ""}
                                disabled={isPending}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setForecastParameters({
                                        variable: e.target.value,
                                        forecast_length:
                                            forecastParameters.forecast_length,
                                        example_count:
                                            forecastParameters.example_count,
                                    });
                                }}
                            >
                                <option
                                    value={""}
                                    disabled
                                >{`-- Select Variable --`}</option>
                                {variables?.map((variable) => (
                                    <option
                                        key={variable}
                                        value={variable}
                                        className="dark:bg-slate-800"
                                    >
                                        {variable}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="space-y-2">
                            <div>{`Forecast Length`}</div>
                            <select
                                className="min-w-[200px] rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm
                                    text-blue-700 marker:border focus:border-slate-300 focus:outline-none
                                    focus:ring-0 dark:border-slate-800 dark:bg-slate-800/20 dark:text-blue-700
                                    dark:focus:border-slate-700"
                                value={forecastParameters?.forecast_length || 2}
                                disabled={isPending}
                                onChange={(e) => {
                                    setForecastParameters({
                                        variable: forecastParameters.variable,
                                        forecast_length: Number(e.target.value),
                                        example_count:
                                            forecastParameters.example_count,
                                    });
                                }}
                            >
                                {Array.from({
                                    length: timeHorizons?.length || 1,
                                }).map((_, n) => (
                                    <option
                                        key={n}
                                        value={n + 1}
                                        className="dark:bg-slate-800"
                                    >
                                        {`time steps: ${n + 1}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="space-y-1">
                            <span className="flex items-center">
                                {`Outliers removed: ${forecastSummary?.outliers || "---"}`}
                            </span>
                            <span className="flex items-center">
                                {`Included datasets: ${forecastSummary?.count || "---"}`}
                            </span>
                            <span className="flex items-center">
                                {`Example clusters: ${forecastSummary?.example_count || "---"}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full pt-2">
                <div className="pb-6 text-base font-semibold">
                    Statistical Summary
                </div>
                <StatisticsTable
                    data={
                        !!forecastData && Object.keys(forecastData)?.length > 0
                            ? Object.keys(forecastData).reduce(
                                  (map, timeHorizon) => ({
                                      ...map,
                                      [`${forecastParameters?.variable} ${timeHorizon}`]:
                                          forecastData[timeHorizon],
                                  }),
                                  {},
                              )
                            : timeHorizons?.reduce(
                                  (map, timeHorizon) => ({
                                      ...map,
                                      [`${forecastParameters?.variable || ""} ${timeHorizon}`]:
                                          defaultStatisticsObject,
                                  }),
                                  {},
                              ) || {}
                    }
                    formatKey={forecastParameters?.variable}
                />
            </div>
            <div className="w-full pt-2">
                <div className="pb-4 text-base font-semibold">
                    Charts: Actual Values and Fitted/Forecasted Values of
                    Smoothed Data
                </div>
                <div className="w-full">
                    <div
                        className={
                            "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
                        }
                    >
                        {Array.from({
                            length: forecastSummary?.example_count || 6,
                        }).map((_, i) => (
                            <ForecastChart
                                key={`example-chart-${i}`}
                                values={
                                    (!!forecastExamples &&
                                        Object.keys(forecastExamples)?.length >
                                            0 &&
                                        forecastExamples[i]) ||
                                    {}
                                }
                                originalValues={
                                    (!!originalData &&
                                        Object.keys(originalData)?.length > 0 &&
                                        originalData[i]) ||
                                    {}
                                }
                                variable={forecastParameters?.variable}
                                dataTimeHorizons={
                                    forecastSummary?.data_time_horizons ||
                                    timeHorizons ||
                                    []
                                }
                                forecastTimeHorizons={
                                    forecastSummary?.forecast_time_horizons ||
                                    []
                                }
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-full pt-2">
                <div className="pb-6 text-base font-semibold">Data Table</div>
                <StatisticsTable
                    data={tableData || {}}
                    outerKeysAsRows={true}
                    formatKey={forecastParameters?.variable}
                    label={"Time Horizon"}
                />
            </div>
        </>
    );
};

export const ForecastDisplay = React.memo(ForecastDisplayComponent);
