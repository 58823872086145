import React from "react";
import { ModelSuiteWrapper } from "@/Layouts/model-suite";
import { useAuthStore } from "@/hooks/store";

const InteractiveModuleWrapperComponent = ({
    simulationTitle,
    simulationSlug,
    modelBlockLabel,
    children,
}: {
    simulationTitle: string;
    simulationSlug: string;
    modelBlockLabel?: string;
    children: React.ReactNode;
}) => {
    const { isNotParticipant } = useAuthStore();

    return (
        <ModelSuiteWrapper
            simulationTitle={simulationTitle}
            simulationSlug={simulationSlug}
            logoRoute={
                !!isNotParticipant ? "model-dashboard.index" : "dashboard"
            }
            subtitle={"Interactive Models"}
            childrenNavCenter={
                !!modelBlockLabel && (
                    <div className="px-1 text-sm font-normal sm:px-3">
                        {`${modelBlockLabel}`}
                    </div>
                )
            }
            pageHeadTitle={"Interactive"}
        >
            {children}
        </ModelSuiteWrapper>
    );
};

export const InteractiveModuleWrapper = React.memo(
    InteractiveModuleWrapperComponent,
);
