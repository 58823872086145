import React from "react";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { Cohort } from "@/models";
import { AllowTeamOrCohortAdvance } from "./AllowAdvanceControls";

export const CohortPlacementHeader = ({
    cohort,
    showAdvanceControls,
    roundMenu,
}: {
    cohort: Cohort;
    showAdvanceControls: boolean;
    roundMenu: React.ReactNode;
}) => {
    return (
        <div className="rounded-md">
            <div className="rounded-md bg-indigo-900/5 p-4">
                <div className="flex flex-row items-center justify-between space-x-4">
                    <div className="flex items-center space-x-3">
                        <UserGroupIcon className="h-6 w-6" />
                        <label
                            htmlFor="location"
                            className="whitespace-nowrap text-nowrap text-base font-semibold leading-6 text-slate-900"
                        >
                            Cohort Placement
                        </label>
                    </div>
                    <div className="flex min-w-[25%] items-center">
                        {roundMenu}
                    </div>
                    {!!showAdvanceControls && (
                        <span className="flex items-center">
                            <span className="pr-2">Allow Advance</span>
                            <AllowTeamOrCohortAdvance
                                // status={cohortStatus}
                                targetType={"Cohort"}
                                cohort_id={cohort.id}
                                cohort={cohort}
                            />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
