import React, { useMemo } from "react";
import {
    Squares2X2Icon,
    MapIcon,
    CubeIcon,
    GlobeAltIcon,
    HomeIcon,
} from "@heroicons/react/24/outline";
import { ScrollableWrapper } from "../InteractiveModule/shared";

const iconClassname =
    "flex h-6 w-6 items-center justify-center text-slate-500 transition-all dark:text-slate-400";

const menuItemObject = {
    Dashboard: <HomeIcon className={iconClassname} />,
    Design: <Squares2X2Icon className={iconClassname} />,
    Structure: <CubeIcon className={iconClassname} />,
    Connections: <MapIcon className={iconClassname} />,
    Submodules: <GlobeAltIcon className={iconClassname} />,
};

const simulationModelSections: string[] = [
    "Dashboard",
    "Design",
    "Structure",
    "Connections",
    "Submodules",
];
const interactiveModelSections: string[] = [
    "Design",
    "Structure",
    "Submodules",
];

function ModelBuilderMenuComponent({
    pageSection,
    setPageSection,
    isInteractive,
}: {
    pageSection: string;
    setPageSection: (pageSection: string) => void;
    isInteractive: boolean;
}) {
    const sections = useMemo(() => {
        return !isInteractive
            ? simulationModelSections
            : interactiveModelSections;
    }, [isInteractive]);

    return (
        <div
            className={`h-full w-11 min-w-11 overflow-hidden border-r border-slate-300 bg-slate-200
                text-slate-700 transition-all dark:border-slate-700 dark:bg-slate-800
                dark:text-slate-300`}
        >
            <ScrollableWrapper>
                <div className={"h-full w-full"}>
                    {sections?.map((key) => (
                        <div
                            key={key}
                            className={`flex w-full cursor-pointer flex-col items-center justify-center py-2
                            transition-all hover:bg-slate-300 hover:bg-opacity-50 dark:hover:bg-slate-700
                            dark:hover:bg-opacity-50 ${
                                pageSection == key
                                    ? "bg-slate-300 dark:bg-slate-700"
                                    : ""
                            }`}
                            onClick={() => {
                                setPageSection(key as string);
                            }}
                            data-testid={`builder_${key}`}
                        >
                            {menuItemObject[key]}
                            {/* <Icon
                                icon={menuItemObject[key]}
                                size={24}
                                className="flex h-8 items-center justify-center text-slate-500 transition-all
                                    dark:text-slate-400"
                                style={{ display: "flex" }}
                            /> */}
                            <span className="hidden text-xs font-light sm:hidden">
                                {key}
                            </span>
                        </div>
                    ))}
                </div>
            </ScrollableWrapper>
        </div>
    );
}

export const ModelBuilderMenu = React.memo(ModelBuilderMenuComponent);
