import React, { useEffect, useMemo, useState } from "react";
import { Link } from "@inertiajs/react";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { SapienPageProps } from "@/inertia-utils/types";
import { useWebSockets } from "@/hooks/websockets/useWebsockets";
import { useNotificationStore } from "@/hooks/useNotificationStore";
import StyledToast from "@/components/toasts/StyledToast";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { AdminContainer } from "@/Layouts/admin";
import { SimBuilderTopNav } from "@/Layouts/admin/SimBuilderTopNav";
import {
    Cohort,
    ModelBlock,
    ModelVariable,
    Round,
    SelectionShape,
    Simulation,
    VariableValue,
} from "@/models";
import {
    useSelectedSimulation,
    useSelectedCohort,
    useAllCohorts,
    useTeamsOfSelectedCohort,
} from "@/hooks";
import { useDiscoveryLayer } from "./useDiscoveryLayer";
import { DiscoveryCohortList } from "./DiscoveryCohortList";
import {
    DiscoveryCohortDetail,
    DiscoveryMenu,
    DiscoveryMenuHeader,
} from "./data-detail";
import { CohortQuestionStatistics } from "./question-statistics";

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    cohort: Cohort | null;
    modelVariables: ModelVariable[] | null;
    variableValues: VariableValue[] | null;
    rounds: Round[];
    selections: SelectionShape[];
    modelBlocks: ModelBlock[];
};

export default function DiscoveryContainer(props: Props) {
    const {
        simulation,
        cohort,
        modelVariables,
        variableValues,
        rounds,
        selections,
        modelBlocks,
        toasts,
    } = props;

    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { selectedCohort, gotCohort } = useSelectedCohort();
    const { cohorts, gotCohorts } = useAllCohorts();
    const { teams } = useTeamsOfSelectedCohort();

    const discoveryLayerObject = useDiscoveryLayer(
        rounds,
        teams,
        modelVariables,
        variableValues,
        selections,
        modelBlocks,
    );

    const [isDropdownMenuOpen, setIsDropdownMenuOpen] =
        useState<boolean>(false);
    const [dropdownMenuSection, setDropdownMenuSection] =
        useState<string>("data");

    const [simpleStatisticsDisplay, setSimpleStatisticsDisplay] =
        useState<boolean>(true);

    useEffect(() => {
        if (
            !selectedSimulation ||
            selectedSimulation.id !== simulation.id ||
            !selectedSimulation.timeHorizons
        ) {
            gotSimulation(simulation);
        }
        gotCohorts(simulation.cohorts);
    }, [simulation]);

    function reloadOnNotify(_: any) {
        SapienInertia.reload({
            preserveScroll: true,
            preserveState: true,
        });
    }

    const { setUpFacilitatorSockets } = useWebSockets();
    useEffect(() => {
        gotCohort(cohort);

        if (cohort) {
            setUpFacilitatorSockets(cohort.id, {
                "facilitator.notify": reloadOnNotify,
            });
        }
    }, [cohort?.id]);

    const { notification, setNotification } = useNotificationStore();

    useEffect(() => {
        if (toasts && (toasts as any).length) {
            console.log("TOASTS FROM SERVER", toasts);
            setNotification({
                autoClose: toasts[0].autoClose || 3000,
                text: toasts[0].message,
                isInline: true,
                textColor: "#4B5563",
                backgroundColor: "#F0FFF4",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
            });
        }
    }, [toasts]);

    const isStatisticsPage = useMemo(() => {
        return !modelVariables && !variableValues && !modelBlocks && !!cohort;
    }, [cohort, modelVariables, variableValues, modelBlocks]);

    return (
        selectedSimulation !== undefined &&
        simulation.id === selectedSimulation.id && (
            <>
                <AdminContainer headTitle={"Discovery"}>
                    <AdminPageHeader>
                        <SimBuilderTopNav
                            title={selectedSimulation.title}
                            slug={selectedSimulation.slug}
                            id={selectedSimulation.id}
                            isCourse={selectedSimulation.is_course}
                        />
                    </AdminPageHeader>
                    <AdminPageSection>
                        {!cohort ? (
                            <div className="">
                                <DiscoveryCohortList
                                    simulation={selectedSimulation}
                                    cohorts={cohorts}
                                />
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div className="flex min-h-9 flex-row items-center justify-between">
                                    <span className="text-xl font-normal">
                                        {cohort.title}
                                    </span>
                                    <div className="flex items-center space-x-2">
                                        <Link
                                            href={sapienRoute(
                                                "discovery.show-data",
                                                {
                                                    simulationSlug:
                                                        selectedSimulation?.slug,
                                                    cohortId: cohort.id,
                                                },
                                            )}
                                        >
                                            <div
                                                className={`text-base font-normal hover:underline ${
                                                    !isStatisticsPage
                                                        ? "text-slate-900 underline"
                                                        : "text-slate-700"
                                                }`}
                                            >{`All Data`}</div>
                                        </Link>
                                        <Link
                                            href={sapienRoute(
                                                "discovery.show-statistics",
                                                {
                                                    simulationSlug:
                                                        selectedSimulation?.slug,
                                                    cohortId: cohort.id,
                                                },
                                            )}
                                        >
                                            <div
                                                className={`text-base font-normal hover:underline ${
                                                    !!isStatisticsPage
                                                        ? "text-slate-900 underline"
                                                        : "text-slate-700"
                                                }`}
                                            >{`Statistics`}</div>
                                        </Link>
                                    </div>
                                </div>
                                {!!isStatisticsPage ? (
                                    <CohortQuestionStatistics
                                        simulation={selectedSimulation}
                                        rounds={rounds}
                                        cohort={selectedCohort}
                                        teams={teams}
                                        selections={selections}
                                        simpleDisplay={simpleStatisticsDisplay}
                                        setSimpleDisplay={
                                            setSimpleStatisticsDisplay
                                        }
                                    />
                                ) : (
                                    <div className="relative">
                                        <DiscoveryMenuHeader
                                            isDropdownMenuOpen={
                                                isDropdownMenuOpen
                                            }
                                            setIsDropdownMenuOpen={
                                                setIsDropdownMenuOpen
                                            }
                                            dropdownMenuSection={
                                                dropdownMenuSection
                                            }
                                            setDropdownMenuSection={
                                                setDropdownMenuSection
                                            }
                                        />
                                        {!!isDropdownMenuOpen && (
                                            <div
                                                className="discovery-dropdown-menu border-box absolute left-0 right-0 top-10 z-50
                                                    max-h-[50vh] min-h-10 w-full overflow-auto border-y border-slate-200 bg-slate-50
                                                    transition duration-150 ease-in-out"
                                            >
                                                <DiscoveryMenu
                                                    cohort={cohort}
                                                    simulation={simulation}
                                                    dropdownMenuSection={
                                                        dropdownMenuSection
                                                    }
                                                    rounds={rounds}
                                                    modelBlocks={modelBlocks}
                                                    discoveryLayerObject={
                                                        discoveryLayerObject
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className="discovery-bottom h-full overflow-hidden">
                                            <div className="discovery-center-panel h-full w-full overflow-auto transition-all">
                                                <div className="mx-auto my-0 w-full min-w-80">
                                                    <div className="">
                                                        <DiscoveryCohortDetail
                                                            rounds={rounds}
                                                            modelBlocks={
                                                                modelBlocks
                                                            }
                                                            simulation={
                                                                selectedSimulation
                                                            }
                                                            cohort={
                                                                selectedCohort
                                                            }
                                                            teams={teams}
                                                            discoveryLayerObject={
                                                                discoveryLayerObject
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </AdminPageSection>
                </AdminContainer>
                {!!notification && (
                    <>
                        <StyledToast props={notification} />
                    </>
                )}
            </>
        )
    );
}
