import React from "react";

export const DiscoveryMenuButton = ({
    label,
    isSelected,
    handleClick,
}: {
    label: string;
    isSelected: boolean;
    handleClick: () => void;
}) => {
    return (
        <div className="flex flex-row items-center">
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    handleClick();
                }}
            >
                <span
                    className={`inline-flex rounded-md ${isSelected && "underline"}`}
                >
                    <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-transparent px-3 py-2 text-sm
                            font-semibold leading-4 text-neutral-800 transition duration-150 ease-in-out
                            hover:text-neutral-700 focus:outline-none"
                    >
                        {label}
                        <svg
                            className="-mr-0.5 ml-1 h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </span>
            </div>
        </div>
    );
};
