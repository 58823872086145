import { Link } from "@inertiajs/react";
import React from "react";
import HighlightText from "./HighlightText";
import { sapienRoute } from "@/inertia-utils/hooks";

type Props = {
    categorySearchResult: App.Data.Forum.CategorySearchResultData;
    searchString: string;
};

export default function CatgorySearchResult({
    categorySearchResult,
    searchString,
}: Props) {
    return (
        <li className="block px-0">
            <Link
                href={sapienRoute("forum.category", {
                    category: categorySearchResult.category.id,
                })}
                className="group block rounded-md bg-gray-100 px-4 py-3 text-sapien-blue outline-none
                    hover:bg-sapien-blue hover:text-white focus:bg-sapien-blue focus:text-white"
            >
                <h3 className="text-bold mb-1 text-lg">
                    <HighlightText
                        text={ categorySearchResult.category.title}
                        highlight={searchString}
                    />
                </h3>
                <p className="text-sm">
                    <HighlightText
                        text={categorySearchResult.content}
                        highlight={searchString}
                    />
                </p>
            </Link>
        </li>
    );
}
