import React, { useMemo } from "react";
import { PromptType, Round, TimeHorizon } from "@/models";
import { groupBy } from "lodash";
import { displayPromptType } from "@/util";

type ModelWizardTimelineProps = {
    rounds: Round[];
    timeHorizons: TimeHorizon[];
};

export const ModelWizardTimeline: React.FC<ModelWizardTimelineProps> = ({
    rounds,
    timeHorizons,
}) => {
    const roundsGroupedByTimeHorizonId = useMemo(() => {
        return groupBy(
            rounds
                .map((round, i) => ({
                    ...round,
                    round_number: i + 1,
                    prompts: round.prompts.filter(
                        (prompt) =>
                            prompt.prompt_type !== PromptType["Short Text"] &&
                            prompt.prompt_type !== PromptType["Long Text"],
                    ),
                }))
                .filter((round) => round.prompts.length > 0),
            "time_horizon_id",
        );
    }, [rounds]);

    const rowCountByTimeHorizonId = useMemo(() => {
        return Object.keys(roundsGroupedByTimeHorizonId).reduce(
            (map, thId) => ({
                ...map,
                [thId]: roundsGroupedByTimeHorizonId[thId].reduce(
                    (rowCount, round) =>
                        rowCount + Math.max(1, round.prompts.length),
                    0,
                ),
            }),
            {},
        );
    }, [roundsGroupedByTimeHorizonId]);

    return (
        <div className="">
            {/* <div>
                {!!timeHorizons.length &&
                    !!Object.keys(roundsGroupedByTimeHorizonId)?.length && (
                        <div className="mb-4 overflow-auto text-neutral-700">
                            <table className="border text-left align-top text-xs">
                                <thead className="border">
                                    <tr className="border">
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Time Horizon"}
                                        </th>
                                        {timeHorizons.map((timeHorizon) => (
                                            <th
                                                key={timeHorizon.id}
                                                colSpan={Math.max(
                                                    roundsGroupedByTimeHorizonId[
                                                        timeHorizon.id
                                                    ]?.length ?? 1,
                                                    1,
                                                )}
                                                className="border px-2 py-1"
                                            >
                                                {timeHorizon.time_index}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="border">
                                    <tr className="border">
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Round Number"}
                                        </th>
                                        {timeHorizons.map((timeHorizon) => (
                                            <React.Fragment
                                                key={timeHorizon.id}
                                            >
                                                {!!roundsGroupedByTimeHorizonId[
                                                    timeHorizon.id
                                                ] ? (
                                                    roundsGroupedByTimeHorizonId[
                                                        timeHorizon.id
                                                    ].map((round) => (
                                                        <td
                                                            key={round.id}
                                                            className="border px-2 py-1"
                                                        >
                                                            {`${round.round_number}`}
                                                        </td>
                                                    ))
                                                ) : (
                                                    <td
                                                        key={timeHorizon.id}
                                                        className="px-2 py-1"
                                                    ></td>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                    <tr className="border">
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Round Title"}
                                        </th>
                                        {timeHorizons.map((timeHorizon) => (
                                            <React.Fragment
                                                key={timeHorizon.id}
                                            >
                                                {!!roundsGroupedByTimeHorizonId[
                                                    timeHorizon.id
                                                ] ? (
                                                    roundsGroupedByTimeHorizonId[
                                                        timeHorizon.id
                                                    ].map((round) => (
                                                        <td
                                                            key={round.id}
                                                            className="text-nowrap border px-2 py-1"
                                                        >
                                                            {`${round.title}`}
                                                        </td>
                                                    ))
                                                ) : (
                                                    <td
                                                        key={timeHorizon.id}
                                                        className="px-2 py-1"
                                                    ></td>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
            </div> */}

            <div>
                {!!timeHorizons.length &&
                    !!Object.keys(roundsGroupedByTimeHorizonId)?.length &&
                    !!Object.keys(rowCountByTimeHorizonId)?.length && (
                        <div className="mb-4 overflow-auto text-neutral-700">
                            <table className="w-full border text-left align-top text-xs">
                                <thead className="border-2 border-neutral-400">
                                    <tr className="border bg-neutral-100">
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Time"}
                                        </th>
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Round"}
                                        </th>
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Round Title"}
                                        </th>
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Prompt"}
                                        </th>
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Question Type"}
                                        </th>
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Scope"}
                                        </th>
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Content"}
                                        </th>
                                        <th className="text-nowrap border px-2 py-1">
                                            {"Options"}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="border-2 border-neutral-400">
                                    {timeHorizons
                                        .filter(
                                            (timeHorizon) =>
                                                !!roundsGroupedByTimeHorizonId[
                                                    timeHorizon.id
                                                ],
                                        )
                                        .map((timeHorizon) => (
                                            <React.Fragment
                                                key={timeHorizon.id}
                                            >
                                                {roundsGroupedByTimeHorizonId[
                                                    timeHorizon.id
                                                ].map((round, i) => (
                                                    <React.Fragment
                                                        key={round.id}
                                                    >
                                                        {round.prompts?.length >
                                                        0 ? (
                                                            round.prompts.map(
                                                                (prompt, j) => (
                                                                    <tr
                                                                        key={
                                                                            prompt.id
                                                                        }
                                                                        className={`border align-top ${i === 0 && j === 0 ? "border-t-2 border-t-neutral-400" : ""}
                                                                            ${j === 0 ? "border-t-2 border-t-neutral-300" : ""}`}
                                                                    >
                                                                        {i ===
                                                                            0 &&
                                                                            j ===
                                                                                0 && (
                                                                                <td
                                                                                    key={
                                                                                        timeHorizon.id
                                                                                    }
                                                                                    className="px-2 py-1"
                                                                                    rowSpan={
                                                                                        rowCountByTimeHorizonId[
                                                                                            timeHorizon
                                                                                                .id
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        timeHorizon.time_index
                                                                                    }
                                                                                </td>
                                                                            )}
                                                                        {j ===
                                                                            0 && (
                                                                            <>
                                                                                <td
                                                                                    className="border px-2 py-1"
                                                                                    rowSpan={
                                                                                        round
                                                                                            .prompts
                                                                                            .length
                                                                                    }
                                                                                >
                                                                                    {`${round.round_number}`}
                                                                                </td>
                                                                                <td
                                                                                    className="border px-2 py-1"
                                                                                    rowSpan={
                                                                                        round
                                                                                            .prompts
                                                                                            .length
                                                                                    }
                                                                                >
                                                                                    {`${round.title}`}
                                                                                </td>
                                                                            </>
                                                                        )}
                                                                        <td className="border px-2 py-1">
                                                                            {`${j + 1}`}
                                                                        </td>
                                                                        <td className="text-nowrap border px-2 py-1">
                                                                            {`${displayPromptType(prompt.prompt_type)}`}
                                                                        </td>
                                                                        <td className="border px-2 py-1">
                                                                            {`${prompt.scope == "Participant" ? "User" : prompt.scope}`}
                                                                        </td>
                                                                        <td className="border px-2 py-1">
                                                                            {`${prompt.content}`}
                                                                        </td>
                                                                        <td className="border px-2 py-1 text-neutral-500">
                                                                            {prompt.prompt_type ===
                                                                                PromptType[
                                                                                    "Numerical Input"
                                                                                ] ||
                                                                            prompt.prompt_type ===
                                                                                PromptType[
                                                                                    "Numerical Slider"
                                                                                ] ? (
                                                                                `${prompt.min < prompt.max ? `range: ${prompt.min ?? 0} to ${prompt.max ?? 0}` : ""}`
                                                                            ) : (
                                                                                <div className="space-y-2">
                                                                                    {prompt.options.map(
                                                                                        (
                                                                                            option,
                                                                                        ) => (
                                                                                            <div
                                                                                                key={
                                                                                                    option.id
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    option.content
                                                                                                }
                                                                                            </div>
                                                                                        ),
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ),
                                                            )
                                                        ) : (
                                                            <tr
                                                                key={round.id}
                                                                className="border"
                                                            >
                                                                {i === 0 && (
                                                                    <td
                                                                        key={
                                                                            timeHorizon.id
                                                                        }
                                                                        className="px-2 py-1"
                                                                        rowSpan={
                                                                            rowCountByTimeHorizonId[
                                                                                timeHorizon
                                                                                    .id
                                                                            ]
                                                                        }
                                                                    >
                                                                        {
                                                                            timeHorizon.time_index
                                                                        }
                                                                    </td>
                                                                )}
                                                                <td className="border px-2 py-1">
                                                                    {`${round.round_number}`}
                                                                </td>
                                                                <td className="border px-2 py-1">
                                                                    {`${round.title}`}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    )}
            </div>
        </div>
    );
};
