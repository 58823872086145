import React from "react";
import { Round, RoundShape, Simulation } from "@/models";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { RoundOutlineDisplay } from "./RoundOutlineDisplay";
import { PencilIcon } from "@heroicons/react/24/solid";

export const RoundDisplay = ({
    round,
    index,
    setEditedRound,
    simulation,
    children,
    displayOutline,
}: {
    round: Round | RoundShape;
    index: number;
    setEditedRound?: () => void;
    simulation: Simulation | Partial<Simulation>;
    children?: React.ReactNode;
    backgroundColor?: string;
    displayOutline?: boolean;
}) => {
    const backgroundImage =
        simulation?.theme?.image_url ||
        "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/noun-image-5275349.png";

    return (
        <div className="w-full max-w-full">
            <Link
                href={sapienRoute("simulation.design.show", {
                    roundId: round.id,
                })}
                data-testid={`launch_${round.title}`}
                className="flex flex-col justify-between rounded-md bg-white/75 shadow-sm transition-all
                    hover:bg-white/100 hover:shadow-md"
            >
                <div className={"space-y-4 p-4"}>
                    <div className="flex w-full items-center space-x-4">
                        {children}
                        <div
                            className={`h-16 w-full max-w-20 rounded-md ${
                                !!simulation?.theme?.image_url
                                    ? "bg-cover bg-center bg-no-repeat shadow-sm"
                                    : "border-2 bg-contain bg-center bg-no-repeat"
                            }`}
                            style={{
                                backgroundImage: `url(${backgroundImage})`,
                            }}
                        />
                        <div className="flex w-full flex-col space-y-1">
                            <span className="text-sm tracking-wide text-slate-600">
                                {`ROUND ${index + 1}`}
                            </span>
                            <span className="text-lg font-medium">
                                {round.title}
                            </span>
                        </div>
                        <div className="">
                            {!!setEditedRound && (
                                <div className="">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setEditedRound();
                                        }}
                                        className="rounded-full p-3 transition-all hover:bg-slate-100 hover:text-indigo-600"
                                    >
                                        <PencilIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {displayOutline && !!round.parentContentBlocks?.length && (
                        <div className="text-xs">
                            <RoundOutlineDisplay
                                topLevelContentBlockLayers={
                                    round.parentContentBlocks
                                }
                            />
                        </div>
                    )}
                </div>
            </Link>
        </div>
    );
};
