import React from "react";
import { ModelSuiteWrapper } from "@/Layouts/model-suite";
import { CenterPanelPageWrapper } from "../InteractiveModule/shared";

const SandboxWrapperComponent = ({
    simulationTitle,
    simulationSlug,
    dataTitle,
    children,
}: {
    simulationTitle: string;
    simulationSlug: string;
    dataTitle?: string;
    children: React.ReactNode;
}) => {
    return (
        <ModelSuiteWrapper
            simulationTitle={simulationTitle}
            simulationSlug={simulationSlug}
            logoRoute={"model-dashboard.index"}
            subtitle={"Data Sandbox"}
            childrenNavCenter={
                !!dataTitle && (
                    <div className="px-1 text-sm font-normal sm:px-3">
                        {`${dataTitle}`}
                    </div>
                )
            }
            pageHeadTitle={"Data Sandbox"}
        >
            <CenterPanelPageWrapper maxWidthClass={""}>
                <div
                    className={
                        "flex flex-col text-slate-700 dark:text-slate-300"
                    }
                >
                    {children}
                </div>
            </CenterPanelPageWrapper>
        </ModelSuiteWrapper>
    );
};

export const SandboxWrapper = React.memo(SandboxWrapperComponent);
