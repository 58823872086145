import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { SimulationShape, UserShape } from "@/models";
import { AdminPageSection } from "@/components/admin-components";
import { UserList } from "./UserList";
import { UserInvitationForm } from "@/components/user-management/UserInvitationForm";
import { UserCreationForm } from "@/Pages/UserManagement/UserCreationForm";
import { UserManagementWrapper } from "./UserManagementWrapper";
import { ReusableMagicLinkForm } from "./ReusableMagicLinkForm";
import { useAuthStore } from "@/hooks/store";

type Props = {
    roles: { id: number; name: string }[];
    users: UserShape[];
    simulations: (SimulationShape & { is_course: boolean })[];
} & SapienPageProps;

export default function UserManagementIndex({
    roles,
    users,
    simulations,
    progress_messages,
    errors,
    ...pageProps
}: Props) {
    const { user } = useAuthStore();

    return (
        <UserManagementWrapper {...pageProps}>
            <>
                <AdminPageSection
                    className={
                        "flex flex-col gap-2 bg-slate-200/50 py-4 lg:flex-row lg:justify-between"
                    }
                >
                    <div className="flex flex-col gap-2 lg:flex-row lg:justify-between py-6">
                        <div className="flex flex-row items-center justify-between">
                            <span className="text-lg font-medium">
                                {"Actions"}
                            </span>
                        </div>
                        <div className="flex gap-2">
                            <UserInvitationForm>
                                <>
                                    {!!errors?.email && (
                                        <label className="block text-sm font-medium leading-6 text-red-700">
                                            {errors?.email}
                                        </label>
                                    )}
                                    {!!progress_messages &&
                                        progress_messages.map((message) => (
                                            <label
                                                key={message.message}
                                                className="block text-sm font-medium leading-6 text-green-700"
                                            >
                                                {message?.message}
                                            </label>
                                        ))}
                                </>
                            </UserInvitationForm>
                            <UserCreationForm roles={roles}>
                                <></>
                            </UserCreationForm>
                            {user.roles?.some(
                                (role) => role.name === "Sapien Super Admin",
                            ) && (
                                <ReusableMagicLinkForm
                                    roles={roles}
                                    users={users}
                                />
                            )}
                        </div>
                    </div>
                </AdminPageSection>
                <AdminPageSection>
                    <div className="space-y-4">
                        <div className="flex flex-row items-center justify-between">
                            <span className="text-lg font-medium">
                                {"People"}
                            </span>
                        </div>
                        <UserList
                            roles={roles}
                            users={users}
                            simulations={simulations}
                        />
                    </div>
                </AdminPageSection>
            </>
        </UserManagementWrapper>
    );
}
