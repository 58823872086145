import { sapienRoute } from "@/inertia-utils/hooks";
import { Link } from "@inertiajs/react";
import React from "react";

type Props = {
    postSearchResult: App.Data.Forum.PostSearchResultData;
};

export default function PostSearchResult({ postSearchResult }: Props) {
    return (
        <li className="block">
            <Link
                href={sapienRoute("forum.threads.show", {
                    thread: postSearchResult.post.thread.id,
                })}
                className="group block rounded-md bg-gray-100 px-4 py-3 text-sapien-blue outline-none
                    hover:bg-sapien-blue hover:text-white focus:bg-sapien-blue focus:text-white"
            >
                <h3 className="text-bold mb-1 text-lg">
                    {postSearchResult.post.thread.title}
                </h3>
                <p className="text-sm">{postSearchResult.content}</p>
            </Link>
        </li>
    );
}
