import React, { useCallback, useMemo } from "react";
import { CentroidBoxPlot, ClusteringElbowTestChart, Pairplot } from "./Charts";
import { StatisticsTable } from "./StatisticsTable";
import { ClusterAnalysisProps, defaultStatisticsObject } from "./types";
import { SubsectionWrapper, VariableMultiSelect } from "./SharedComponents";

const ClusterAnalysisDisplayComponent = ({
    variables,
    clusterAnalysisParameters,
    setClusterAnalysisParameters,
    clusterAnalysisResponse,
}: {
    variables: string[];
} & ClusterAnalysisProps) => {
    const { clusterAnalysisData, clusterAnalysisSummary, clusterDescriptions } =
        clusterAnalysisResponse;

    const isPending = useMemo(() => {
        const isSynced =
            !!clusterAnalysisData &&
            !!clusterDescriptions &&
            !!clusterAnalysisParameters &&
            Object.keys(clusterAnalysisData)?.length ==
                clusterAnalysisParameters?.variables?.length &&
            Object.keys(clusterAnalysisData).every((variableKey) =>
                clusterAnalysisParameters?.variables?.includes(variableKey),
            ) &&
            (Object.keys(clusterDescriptions)?.length ==
                clusterAnalysisParameters?.clusters ||
                clusterAnalysisParameters?.variables?.length == 0);
        return !isSynced;
    }, [
        variables,
        clusterAnalysisParameters,
        clusterAnalysisData,
        clusterDescriptions,
    ]);

    const handleSetVariables = useCallback(
        (selectedVariables: string[]) => {
            if (
                isPending ||
                selectedVariables.length < 1 ||
                selectedVariables.length > 6
            ) {
                return;
            }

            const orderedSelectedVariables = variables.filter((variable) =>
                selectedVariables.includes(variable),
            );

            setClusterAnalysisParameters({
                variables: orderedSelectedVariables,
                clusters: clusterAnalysisParameters.clusters,
            });
        },
        [variables, clusterAnalysisParameters, isPending],
    );

    const centroids = useMemo(() => {
        return (
            clusterAnalysisParameters?.variables?.reduce(
                (map, variable) => ({
                    ...map,
                    [variable]:
                        !!clusterDescriptions &&
                        Object.keys(clusterDescriptions)?.length > 0
                            ? Object.keys(clusterDescriptions).reduce(
                                  (map, key, i) => ({
                                      ...map,
                                      [`Centroid ${i + 1}`]:
                                          clusterDescriptions[key][variable]
                                              ?.mean || 0,
                                  }),
                                  {},
                              )
                            : Array.from({
                                  length:
                                      clusterAnalysisParameters?.clusters || 4,
                              }).reduce(
                                  (map: Record<string, number>, _, i) => ({
                                      ...map,
                                      [`Centroid ${i + 1}`]: 0,
                                  }),
                                  {},
                              ) || {},
                }),
                {},
            ) || {}
        );
    }, [clusterAnalysisParameters, clusterDescriptions]);

    return (
        <>
            <SubsectionWrapper title={"Settings"}>
                <div className="grid grid-cols-1 gap-4 text-sm md:grid-cols-2">
                    <div className="">
                        <div className="flex h-full w-full flex-col justify-center text-slate-700 dark:text-slate-300">
                            <ClusteringElbowTestChart
                                inertiaValues={
                                    clusterAnalysisSummary?.inertia_values || []
                                }
                                clusters={
                                    clusterAnalysisParameters?.clusters || 4
                                }
                            />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <span className="text-base">Parameters</span>
                        <div className="space-y-2">
                            <div>{`Number of Clusters`}</div>
                            <select
                                className="min-w-[200px] rounded-md border-slate-200 bg-slate-50/50 px-3 py-1.5 text-sm
                                    text-blue-700 marker:border focus:border-slate-300 focus:outline-none
                                    focus:ring-0 dark:border-slate-800 dark:bg-slate-800/20 dark:text-blue-700
                                    dark:focus:border-slate-700"
                                value={clusterAnalysisParameters?.clusters || 4}
                                disabled={isPending}
                                onChange={(e) => {
                                    setClusterAnalysisParameters({
                                        variables:
                                            clusterAnalysisParameters.variables,
                                        clusters: Number(e.target.value),
                                    });
                                }}
                            >
                                {[2, 3, 4, 5, 6].map((n) => (
                                    <option
                                        key={n}
                                        value={n}
                                        className="dark:bg-slate-800"
                                    >
                                        {`${n} clusters`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="space-y-2">
                            <div>{`Variables (at least one, at most six)`}</div>
                            {variables?.length > 0 &&
                                !!clusterAnalysisParameters && (
                                    <div className="flex w-full text-slate-700 dark:text-slate-300">
                                        <VariableMultiSelect
                                            variables={variables}
                                            selectedVariables={
                                                clusterAnalysisParameters?.variables ||
                                                []
                                            }
                                            handleSetVariables={
                                                handleSetVariables
                                            }
                                            isDisabled={isPending}
                                        />
                                    </div>
                                )}
                            <div className="text-blue-700 dark:text-blue-700">
                                {clusterAnalysisParameters?.variables?.length >
                                0 ? (
                                    clusterAnalysisParameters?.variables?.map(
                                        (variable) => (
                                            <div key={variable}>{variable}</div>
                                        ),
                                    )
                                ) : (
                                    <div className="text-orange-600 dark:text-orange-400">{`No variables selected`}</div>
                                )}
                            </div>
                        </div>
                        <div className="space-y-1">
                            <span className="flex items-center">
                                {`Outliers removed: ${clusterAnalysisSummary?.outliers || "---"}`}
                            </span>
                            <span className="flex items-center">
                                {`Included datasets: ${clusterAnalysisSummary?.count || "---"}`}
                            </span>
                        </div>
                    </div>
                </div>
            </SubsectionWrapper>
            <SubsectionWrapper title={"Statistical Summary"}>
                {clusterAnalysisParameters?.variables?.length > 0 && (
                    <div className="pt-2">
                        <StatisticsTable
                            data={
                                !!clusterAnalysisData &&
                                Object.keys(clusterAnalysisData)?.length > 0
                                    ? clusterAnalysisData
                                    : clusterAnalysisParameters?.variables?.reduce(
                                          (map, variable) => ({
                                              ...map,
                                              [variable]:
                                                  defaultStatisticsObject,
                                          }),
                                          {},
                                      ) || {}
                            }
                        />
                    </div>
                )}
            </SubsectionWrapper>
            <SubsectionWrapper title={"Cluster Data by Variable"}>
                <div className="grid grid-cols-1 items-center gap-4 text-sm text-slate-400 md:grid-cols-3">
                    {clusterAnalysisParameters?.variables?.map((variable) => (
                        <React.Fragment key={variable}>
                            <div className="flex h-full w-full flex-col justify-center text-slate-700 dark:text-slate-300">
                                <CentroidBoxPlot
                                    clusterDescriptions={
                                        clusterDescriptions || {}
                                    }
                                    variable={variable}
                                />
                            </div>
                            <div className="col-span-1 md:col-span-2">
                                <div className="mb-4 text-base text-slate-700 dark:text-slate-300">
                                    {variable}
                                </div>
                                <StatisticsTable
                                    data={
                                        !!clusterDescriptions &&
                                        Object.keys(clusterDescriptions)
                                            ?.length > 0
                                            ? Object.keys(
                                                  clusterDescriptions,
                                              ).reduce(
                                                  (map, key, i) => ({
                                                      ...map,
                                                      [`${i + 1}`]:
                                                          clusterDescriptions[
                                                              key
                                                          ][variable] ||
                                                          defaultStatisticsObject,
                                                  }),
                                                  {},
                                              )
                                            : Array.from({
                                                  length:
                                                      clusterAnalysisParameters?.clusters ||
                                                      4,
                                              }).reduce(
                                                  (
                                                      map: Record<
                                                          string,
                                                          Record<string, number>
                                                      >,
                                                      _,
                                                      i,
                                                  ) => ({
                                                      ...map,
                                                      [`${i + 1}`]:
                                                          defaultStatisticsObject,
                                                  }),
                                                  {},
                                              ) || {}
                                    }
                                    label="k"
                                    outerKeysAsRows={true}
                                    formatKey={variable}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </SubsectionWrapper>
            <SubsectionWrapper title={"Centroids"}>
                {!!centroids && Object.keys(centroids)?.length > 0 && (
                    <div className="pt-2">
                        <StatisticsTable data={centroids || {}} />
                    </div>
                )}
            </SubsectionWrapper>
            <SubsectionWrapper title={"Pairplot"}>
                <div
                    className="interactive-widget relative h-full w-full overflow-auto scrollbar-thin
                        scrollbar-track-slate-200/75 scrollbar-thumb-slate-300/75
                        scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                        scrollbar-corner-rounded-full dark:scrollbar-track-slate-800
                        dark:scrollbar-thumb-slate-700/75"
                >
                    <div className="w-full min-w-[896px]">
                        <Pairplot
                            variables={
                                !!clusterAnalysisData &&
                                Object.keys(clusterAnalysisData)?.length > 0
                                    ? Object.keys(clusterAnalysisData)
                                    : clusterAnalysisParameters?.variables || []
                            }
                            clusterDescriptions={clusterDescriptions || {}}
                        />
                    </div>
                </div>
            </SubsectionWrapper>
        </>
    );
};

export const ClusterAnalysisDisplay = React.memo(
    ClusterAnalysisDisplayComponent,
);
