import React, {
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";
import {
    closestCenter,
    pointerWithin,
    rectIntersection,
    CollisionDetection,
    DndContext,
    DragOverlay,
    getFirstCollision,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensors,
    useSensor,
    MeasuringStrategy,
    KeyboardCoordinateGetter,
    defaultDropAnimationSideEffects,
    KeyboardCode,
    DroppableContainer,
    closestCorners,
    DraggableSyntheticListeners,
} from "@dnd-kit/core";
import {
    SortableContext,
    useSortable,
    arrayMove,
    defaultAnimateLayoutChanges,
    verticalListSortingStrategy,
    SortingStrategy,
    horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS, Transform } from "@dnd-kit/utilities";
import styled from "styled-components";

const directions: string[] = [
    KeyboardCode.Down,
    KeyboardCode.Right,
    KeyboardCode.Up,
    KeyboardCode.Left,
];

const multipleContainersCoordinateGetter: KeyboardCoordinateGetter = (
    event,
    { context: { active, droppableRects, droppableContainers, collisionRect } },
) => {
    if (directions.includes(event.code)) {
        event.preventDefault();

        if (!active || !collisionRect) {
            return;
        }

        const filteredContainers: DroppableContainer[] = [];

        droppableContainers.getEnabled().forEach((entry) => {
            if (!entry || entry?.disabled) {
                return;
            }

            const rect = droppableRects.get(entry.id);

            if (!rect) {
                return;
            }

            const data = entry.data.current;

            if (data) {
                const { type, children } = data;

                if (type === "container" && children?.length > 0) {
                    if (active.data.current?.type !== "container") {
                        return;
                    }
                }
            }

            switch (event.code) {
                case KeyboardCode.Down:
                    if (collisionRect.top < rect.top) {
                        filteredContainers.push(entry);
                    }
                    break;
                case KeyboardCode.Up:
                    if (collisionRect.top > rect.top) {
                        filteredContainers.push(entry);
                    }
                    break;
                case KeyboardCode.Left:
                    if (collisionRect.left >= rect.left + rect.width) {
                        filteredContainers.push(entry);
                    }
                    break;
                case KeyboardCode.Right:
                    if (collisionRect.left + collisionRect.width <= rect.left) {
                        filteredContainers.push(entry);
                    }
                    break;
            }
        });

        const collisions = closestCorners({
            active,
            collisionRect: collisionRect,
            droppableRects,
            droppableContainers: filteredContainers,
            pointerCoordinates: null,
        });
        const closestId = getFirstCollision(collisions, "id");

        if (closestId != null) {
            const newDroppable = droppableContainers.get(closestId);
            const newNode = newDroppable?.node.current;
            const newRect = newDroppable?.rect.current;

            if (newNode && newRect) {
                if (newDroppable.id === "placeholder") {
                    return {
                        x:
                            newRect.left +
                            (newRect.width - collisionRect.width) / 2,
                        y:
                            newRect.top +
                            (newRect.height - collisionRect.height) / 2,
                    };
                }

                if (newDroppable.data.current?.type === "container") {
                    return {
                        x: newRect.left + 20,
                        y: newRect.top + 74,
                    };
                }

                return {
                    x: newRect.left,
                    y: newRect.top,
                };
            }
        }
    }

    return undefined;
};

interface ContainerProps {
    children: React.ReactNode;
    label?: string;
    style?: React.CSSProperties;
    horizontal?: boolean;
    hover?: boolean;
    scrollable?: boolean;
    shadow?: boolean;
    placeholder?: boolean;
    unstyled?: boolean;
    onClick?(): void;
    onRemove?(): void;
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-auto-rows: max-content;
    overflow: hidden;
    box-sizing: border-box;
    appearance: none;
    outline: none;
    /* min-width: 300px; */
    margin: 4px 8px;
    border-radius: 0px;
    min-height: 87px;
    transition: background-color 350ms ease;
    background-color: rgba(28, 78, 216, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 1em;

    ul {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(var(--columns, 1), 1fr);
        list-style: none;
        padding: 8px;
        margin: 0;
    }

    &.scrollable {
        ul {
            overflow-y: auto;
        }
    }

    &.placeholder {
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        background-color: transparent;
        border-style: dashed;
        border-color: rgba(0, 0, 0, 0.08);

        &:hover {
            border-color: rgba(0, 0, 0, 0.15);
        }
    }

    &.hover {
        background-color: rgba(28, 78, 216, 0.04);
    }

    &.unstyled {
        overflow: visible;
        background-color: transparent !important;
        border: none !important;
    }

    &.horizontal {
        width: 100%;

        ul {
            grid-auto-flow: column;
        }
    }

    &.shadow {
        box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
    }

    &:focus-visible {
        border-color: transparent;
        box-shadow:
            0 0 0 2px rgba(255, 255, 255, 0),
            0 0px 0px 2px #4c9ffe;
    }
`;

const StyledHeader = styled.div`
    display: flex;
    padding: 8px 8px;
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(28, 78, 216, 0.04);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

const Container = forwardRef<HTMLDivElement, ContainerProps>(
    (
        {
            children,
            horizontal,
            hover,
            onClick,
            onRemove,
            label,
            placeholder,
            style,
            scrollable,
            shadow,
            unstyled,
            ...props
        }: ContainerProps,
        ref,
    ) => {
        return (
            <StyledContainer
                {...props}
                ref={ref}
                style={style}
                className={`${unstyled && "unstyled"} ${horizontal && " horizontal"} ${hover && " hover"}
                ${placeholder && " placeholder"} ${scrollable && " scrollable"} ${
                    shadow && " shadow"
                }`}
                onClick={onClick}
                tabIndex={onClick ? 0 : undefined}
            >
                {label ? <StyledHeader>{label}</StyledHeader> : null}
                {placeholder ? children : <ul>{children}</ul>}
            </StyledContainer>
        );
    },
);

function DroppableContainerComponent({
    children,
    disabled,
    id,
    items,
    style,
    ...props
}: ContainerProps & {
    disabled?: boolean;
    id: UniqueIdentifier;
    items: UniqueIdentifier[];
    style?: React.CSSProperties;
}) {
    const { active, isDragging, over, setNodeRef, transition, transform } =
        useSortable({
            id,
            data: {
                type: "container",
                children: items,
            },
            animateLayoutChanges: (args) =>
                defaultAnimateLayoutChanges({ ...args, wasDragging: true }),
        });
    const isOverContainer = over
        ? (id === over.id && active?.data.current?.type !== "container") ||
          items.includes(over.id)
        : false;

    return (
        <Container
            ref={disabled ? undefined : setNodeRef}
            style={{
                ...style,
                transition,
                transform: CSS.Translate.toString(transform),
                opacity: isDragging ? 0.5 : undefined,
            }}
            hover={isOverContainer}
            {...props}
        >
            {children}
        </Container>
    );
}

export type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

interface ItemProps {
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    dragging?: boolean;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: React.CSSProperties;
    transition?: string | null;
    value: React.ReactNode;
    onRemove?(): void;
    renderItem?(args: {
        dragOverlay: boolean;
        dragging: boolean;
        sorting: boolean;
        index: number | undefined;
        fadeIn: boolean;
        listeners: DraggableSyntheticListeners;
        ref: React.Ref<HTMLElement>;
        style: React.CSSProperties | undefined;
        transform: ItemProps["transform"];
        transition: ItemProps["transition"];
        value: ItemProps["value"];
    }): React.ReactElement;
}

const StyledItemWrapper = styled.li`
    display: flex;
    box-sizing: border-box;
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
        scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
    transform-origin: 0 0;
    touch-action: manipulation;

    &.fadeIn {
        animation: fadeIn 500ms ease;
    }

    &.dragOverlay {
        z-index: 999;
    }
`;

const StyledItem = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    padding: 4px 8px 4px 16px;
    background-color: #fff;
    outline: none;
    border-radius: calc(4px / var(--scale-x, 1));
    box-sizing: border-box;
    list-style: none;
    transform-origin: 50% 50%;

    -webkit-tap-highlight-color: transparent;

    color: #333;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    /* white-space: nowrap; */

    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

    &:not(.withHandle) {
        touch-action: manipulation;
        cursor: grab;
    }

    &.dragging:not(.dragOverlay) {
        opacity: var(--dragging-opacity, 0.5);
        z-index: 0;
    }

    &.disabled {
        color: #999;
        background-color: #f1f1f1;
        cursor: not-allowed;
    }

    &.dragOverlay {
        cursor: inherit;
        animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
        transform: scale(var(--scale));
        box-shadow: var(--box-shadow-picked-up);
        opacity: 1;
    }

    &.color:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 100%;
        width: 8px;
        display: block;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: var(--color);
    }

    &:hover {
        .Remove {
            visibility: visible;
        }
    }
`;

const Item = React.memo(
    React.forwardRef<HTMLLIElement, ItemProps>(
        (
            {
                color,
                dragOverlay,
                dragging,
                disabled,
                fadeIn,
                height,
                index,
                listeners,
                onRemove,
                renderItem,
                sorting,
                style,
                transition,
                transform,
                value,
                ...props
            },
            ref,
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = "grabbing";

                return () => {
                    document.body.style.cursor = "";
                };
            }, [dragOverlay]);

            return renderItem ? (
                renderItem({
                    dragOverlay: Boolean(dragOverlay),
                    dragging: Boolean(dragging),
                    sorting: Boolean(sorting),
                    index,
                    fadeIn: Boolean(fadeIn),
                    listeners,
                    ref,
                    style,
                    transform,
                    transition,
                    value,
                })
            ) : (
                <StyledItemWrapper
                    className={`${fadeIn && "fadeIn"} ${sorting && " sorting"} ${dragOverlay && " dragOverlay"}`}
                    style={
                        {
                            transition: [transition].filter(Boolean).join(", "),
                            "--translate-x": transform
                                ? `${Math.round(transform.x)}px`
                                : undefined,
                            "--translate-y": transform
                                ? `${Math.round(transform.y)}px`
                                : undefined,
                            "--scale-x": transform?.scaleX
                                ? `${transform.scaleX}`
                                : undefined,
                            "--scale-y": transform?.scaleY
                                ? `${transform.scaleY}`
                                : undefined,
                            "--index": index,
                            "--color": color,
                        } as React.CSSProperties
                    }
                    ref={ref}
                >
                    <StyledItem
                        className={`${dragging && "dragging"} ${dragOverlay && " dragOverlay"} ${
                            disabled && " disabled"
                        } ${color && " color"}`}
                        style={style}
                        data-cypress="draggable-item"
                        {...listeners}
                        {...props}
                        tabIndex={0}
                    >
                        {value}
                    </StyledItem>
                </StyledItemWrapper>
            );
        },
    ),
);

function useMountStatus() {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => setIsMounted(true), 500);

        return () => clearTimeout(timeout);
    }, []);

    return isMounted;
}

interface SortableItemProps {
    containerId: UniqueIdentifier;
    id: UniqueIdentifier;
    index: number;
    disabled?: boolean;
    renderItem(): React.ReactElement;
    value: string;
}

function SortableItem({
    disabled,
    id,
    index,
    renderItem,
    value,
}: SortableItemProps) {
    const {
        setNodeRef,
        listeners,
        isDragging,
        isSorting,
        transform,
        transition,
    } = useSortable({
        id,
    });
    const mounted = useMountStatus();
    const mountedWhileDragging = isDragging && !mounted;

    return (
        <Item
            ref={disabled ? undefined : setNodeRef}
            value={value}
            dragging={isDragging}
            sorting={isSorting}
            index={index}
            color={"rgba(28,78,216,0.8)"}
            transition={transition}
            transform={transform}
            fadeIn={mountedWhileDragging}
            listeners={listeners}
            renderItem={renderItem}
        />
    );
}

interface ContainerData {
    label: string;
    depth: number;
}

interface MultipleContainersProps {
    items: Items;
    containerDataMap: { [index: UniqueIdentifier]: ContainerData };
    containerStyle?: React.CSSProperties;
    renderItem?: any;
    strategy?: SortingStrategy;
    scrollable?: boolean;
    vertical?: boolean;
    itemLabels: { [index: string]: string };
    handleUpdate?: (itemTree: Items) => void;
}

export function SortableMultiContainersTree({
    items: initialItems,
    containerDataMap,
    containerStyle,
    renderItem,
    strategy = verticalListSortingStrategy,
    vertical = true,
    scrollable,
    itemLabels,
    handleUpdate,
}: MultipleContainersProps) {
    const [items, setItems] = useState<Items>(initialItems);
    const [containers, setContainers] = useState(
        Object.keys(containerDataMap) as UniqueIdentifier[],
    );
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const lastOverId = useRef<UniqueIdentifier | null>(null);
    const recentlyMovedToNewContainer = useRef(false);
    const isSortingContainer = activeId ? containers.includes(activeId) : false;

    useEffect(() => {
        setItems(initialItems);
    }, [initialItems]);

    useEffect(() => {
        setContainers(Object.keys(containerDataMap));
    }, [containerDataMap]);

    /**
     * Custom collision detection strategy optimized for multiple containers
     *
     * - First, find any droppable containers intersecting with the pointer.
     * - If there are none, find intersecting containers with the active draggable.
     * - If there are no intersecting containers, return the last matched intersection
     *
     */
    const collisionDetectionStrategy: CollisionDetection = useCallback(
        (args) => {
            if (activeId && activeId in items) {
                return closestCenter({
                    ...args,
                    droppableContainers: args.droppableContainers.filter(
                        (container) => container.id in items,
                    ),
                });
            }

            const pointerIntersections = pointerWithin(args);
            const intersections =
                pointerIntersections.length > 0
                    ? pointerIntersections
                    : rectIntersection(args);
            let overId = getFirstCollision(intersections, "id");

            if (overId != null) {
                if (overId in items) {
                    const containerItems = items[overId];

                    if (containerItems.length > 0) {
                        overId = closestCenter({
                            ...args,
                            droppableContainers:
                                args.droppableContainers.filter(
                                    (container) =>
                                        container.id !== overId &&
                                        containerItems.includes(container.id),
                                ),
                        })[0]?.id;
                    }
                }

                lastOverId.current = overId;

                return [{ id: overId }];
            }

            if (recentlyMovedToNewContainer.current) {
                lastOverId.current = activeId;
            }

            return lastOverId.current ? [{ id: lastOverId.current }] : [];
        },
        [activeId, items],
    );

    const [clonedItems, setClonedItems] = useState<Items | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: multipleContainersCoordinateGetter,
        }),
    );
    const findContainer = (id: UniqueIdentifier) => {
        if (id in items) {
            return id;
        }

        return Object.keys(items).find((key) => items[key].includes(id));
    };

    useEffect(() => {
        requestAnimationFrame(() => {
            recentlyMovedToNewContainer.current = false;
        });
    }, [items]);

    const onDragStart = ({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
    };

    const onDragOver = ({ active, over }) => {
        const overId = over?.id;

        if (overId == null || active.id in items) {
            return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
            return;
        }

        if (activeContainer !== overContainer) {
            setItems((items) => {
                const activeItems = items[activeContainer];
                const overItems = items[overContainer];
                const overIndex = overItems.indexOf(overId);
                const activeIndex = activeItems.indexOf(active.id);

                let newIndex: number;

                if (overId in items) {
                    newIndex = overItems.length + 1;
                } else {
                    const isBelowOverItem =
                        over &&
                        active.rect.current.translated &&
                        active.rect.current.translated.top >
                            over.rect.top + over.rect.height;

                    const modifier = isBelowOverItem ? 1 : 0;

                    newIndex =
                        overIndex >= 0
                            ? overIndex + modifier
                            : overItems.length + 1;
                }

                recentlyMovedToNewContainer.current = true;

                return {
                    ...items,
                    [activeContainer]: items[activeContainer].filter(
                        (item) => item !== active.id,
                    ),
                    [overContainer]: [
                        ...items[overContainer].slice(0, newIndex),
                        items[activeContainer][activeIndex],
                        ...items[overContainer].slice(
                            newIndex,
                            items[overContainer].length,
                        ),
                    ],
                };
            });
        }
    };

    const onDragEnd = ({ active, over }) => {
        // if (active.id in items && over?.id) {
        //     setContainers((containers) => {
        //         const activeIndex = containers.indexOf(active.id);
        //         const overIndex = containers.indexOf(over.id);

        //         return arrayMove(containers, activeIndex, overIndex);
        //     });
        // }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
            setActiveId(null);
            return;
        }

        const overId = over?.id;

        if (overId == null) {
            setActiveId(null);
            return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
            const activeIndex = items[activeContainer].indexOf(active.id);
            const overIndex = items[overContainer].indexOf(overId);

            let newItems = { ...items };

            // only need to set local state again if index is different
            // (we've already accounted for the container change with onDragOver)
            if (activeIndex !== overIndex) {
                newItems = {
                    ...newItems,
                    [overContainer]: arrayMove(
                        newItems[overContainer],
                        activeIndex,
                        overIndex,
                    ),
                };

                setItems(newItems);
            }

            // whether index is changed or not, we now need to handle updating external state
            if (!!handleUpdate) handleUpdate(newItems);
        }

        setActiveId(null);
    };

    const onDragCancel = () => {
        if (clonedItems) {
            setItems(clonedItems);
        }

        setActiveId(null);
        setClonedItems(null);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={collisionDetectionStrategy}
            measuring={{
                droppable: {
                    strategy: MeasuringStrategy.Always,
                },
            }}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
        >
            <div
                style={{
                    display: "inline-grid",
                    boxSizing: "border-box",
                    // padding: 20,
                    gridAutoFlow: vertical ? "row" : "column",
                    width: vertical && "100%",
                }}
            >
                <SortableContext
                    items={[...containers]}
                    strategy={
                        vertical
                            ? verticalListSortingStrategy
                            : horizontalListSortingStrategy
                    }
                >
                    {containers.map((containerId) => (
                        <DroppableContainerComponent
                            key={containerId}
                            id={containerId}
                            label={
                                (!!containerDataMap &&
                                    containerDataMap[containerId]?.label) ||
                                `Column ${containerId}`
                            }
                            items={items[containerId]}
                            scrollable={scrollable}
                            style={{
                                ...containerStyle,
                                marginLeft: `${
                                    ((!!containerDataMap &&
                                        containerDataMap[containerId]?.depth) ||
                                        0) *
                                        24 +
                                    8
                                }px`,
                            }}
                            unstyled={false}
                        >
                            <SortableContext
                                items={items[containerId]}
                                strategy={strategy}
                            >
                                {!!itemLabels &&
                                    items[containerId].map((value, index) => {
                                        return (
                                            <SortableItem
                                                disabled={isSortingContainer}
                                                key={value}
                                                id={value}
                                                index={index}
                                                renderItem={renderItem}
                                                containerId={containerId}
                                                value={
                                                    itemLabels[value] ||
                                                    (value as string)
                                                }
                                            />
                                        );
                                    })}
                            </SortableContext>
                        </DroppableContainerComponent>
                    ))}
                </SortableContext>
            </div>
            {createPortal(
                <DragOverlay
                    dropAnimation={{
                        sideEffects: defaultDropAnimationSideEffects({
                            styles: {
                                active: {
                                    opacity: "0.5",
                                },
                            },
                        }),
                    }}
                >
                    {activeId
                        ? // containers.includes(activeId)
                          // ? renderContainerDragOverlay(activeId) :
                          renderSortableItemDragOverlay(
                              (!!itemLabels && itemLabels[activeId]) ||
                                  activeId,
                          )
                        : null}
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );

    function renderSortableItemDragOverlay(id: UniqueIdentifier) {
        return (
            <Item
                value={id}
                color={"rgba(28,78,216,0.9)"}
                renderItem={renderItem}
                dragOverlay
            />
        );
    }

    // function renderContainerDragOverlay(containerId: UniqueIdentifier) {
    //     return (
    //         <Container
    //             label={`Column ${containerId}`}
    //             style={{
    //                 height: "100%",
    //             }}
    //             shadow
    //             unstyled={false}
    //         >
    //             {items[containerId].map((item, index) => (
    //                 <Item
    //                     key={item}
    //                     value={item}
    //                     color={"rgb(29, 78, 216)"}
    //                     renderItem={renderItem}
    //                 />
    //             ))}
    //         </Container>
    //     );
    // }
}
