import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/redux-state/AppStore";
import { gotVariables } from "@/redux-state/modelBuilder/modelVariableSlice";

export function useModelVariableStore() {
    const _modelVariables = useAppSelector(
        (state) => state.modelBuilderStore.modelVariableStore.modelVariables,
    );

    const timeSeriesModelVariables = useAppSelector((state) =>
        Object.values(
            state.modelBuilderStore.modelVariableStore.modelVariables,
        ).filter((variable) => variable.uses_time),
    );

    const nonTimeSeriesModelVariables = useAppSelector((state) =>
        Object.values(
            state.modelBuilderStore.modelVariableStore.modelVariables,
        ).filter((variable) => !variable.uses_time),
    );

    return {
        modelVariables: _modelVariables,
        timeSeriesModelVariables,
        nonTimeSeriesModelVariables,
    };
}

export function useGotModelVariables() {
    const dispatch = useAppDispatch();

    const _gotModelVariables = useCallback(
        (modelVariables) => {
            dispatch(gotVariables(modelVariables));
        },
        [dispatch],
    );

    return {
        gotModelVariables: _gotModelVariables,
    };
}
