import React from "react";
import { Link } from "@inertiajs/react";

export function SubNavLink({
    href,
    active,
    children,
}: {
    href: string;
    active: boolean;
    children: React.ReactNode;
}) {
    return (
        <Link
            href={href}
            className={
                active
                    ? `inline-flex items-center text-nowrap rounded-md bg-indigo-400 bg-opacity-15 px-2
                        py-1 text-indigo-800 transition duration-150 ease-in-out hover:bg-indigo-400
                        hover:bg-opacity-10 focus:outline-none focus:ring-0`
                    : `inline-flex items-center text-nowrap rounded-md bg-slate-200/25 px-2 py-1
                        text-slate-500 transition duration-150 ease-in-out hover:bg-indigo-400
                        hover:bg-opacity-10 hover:text-indigo-800 focus:outline-none focus:ring-0`
            }
        >
            {children}
        </Link>
    );
}
