import React, { useState } from "react";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTrigger,
} from "@/components/Dialog";
import ImageOrVideoSearch from "./ImageOrVideoSearch";
import FileSelector from "./FileSelector";
import { useEditorImageContentBlock } from "@/hooks";
import { PencilIcon } from "lucide-react";

type Props = {
    imageUrl: string;
    save: (imageUrl: string) => Promise<any>;
    saving: boolean;
};

export default function ImageModal({ imageUrl, save, saving }: Props) {
    const [imageSourceType, setImageSourceType] = React.useState<
        "upload" | "api"
    >("api");
    const [open, setOpen] = useState(false);

    const { uploadedFileUrl } = useEditorImageContentBlock();

    return (
        <Dialog open={open} onOpenChange={() => !open && setOpen(true)}>
            <DialogTrigger>
                <button
                    className="relative flex items-center justify-center gap-2 data-[has-image=true]:h-32
                        data-[has-image=true]:w-32 data-[has-image=false]:rounded-md
                        data-[has-image=false]:border-sapien-blue data-[has-image=false]:p-2"
                    data-has-image={!!imageUrl}
                    data-testid="add-image-button"
                >
                    <PencilIcon className="h-4 w-4" />
                    <span
                        className="text-sm data-[has-image=false]:inline data-[has-image=true]:hidden"
                        data-has-image={!!imageUrl}
                    >
                        Add Image
                    </span>
                    {!!imageUrl && (
                        <div
                            className="absolute h-32 w-32 rounded-md bg-cover bg-no-repeat"
                            style={{
                                backgroundImage: `url(${imageUrl})`,
                            }}
                        />
                    )}
                </button>
            </DialogTrigger>
            <DialogContent className="z-[99999] w-full max-w-3xl bg-white p-0">
                <DialogHeader
                    className="max-h-[90vh] overflow-y-auto p-4 scrollbar scrollbar-thumb-gray-500
                        scrollbar-thumb-rounded-full scrollbar-w-2"
                >
                    <div className="border-b border-[#374151] md:grid md:grid-cols-2 md:gap-2">
                        <div
                            className={
                                "flex items-center px-6 py-4 data-[image-source-type=api]:bg-black/15"
                            }
                            data-image-source-type={imageSourceType}
                        >
                            <input
                                data-testid="select-image-search"
                                type="radio"
                                value=""
                                name="select-image-search"
                                className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={imageSourceType === "api"}
                                onChange={(_) => setImageSourceType("api")}
                            />
                            <label
                                htmlFor="copy-old-prompt"
                                className="ml-3.5 flex w-full flex-col text-sm font-medium"
                            >
                                Search
                                <span className="mt-1 text-[#6B7280]">
                                    Search millions of images
                                </span>
                            </label>
                        </div>
                        <div
                            className={
                                "flex items-center px-6 py-4 data-[image-source-type=upload]:bg-gray-100"
                            }
                            data-image-source-type={imageSourceType}
                        >
                            <input
                                id="create-new"
                                type="radio"
                                value=""
                                name="create-new"
                                className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2
                                    focus:ring-blue-500"
                                checked={imageSourceType === "upload"}
                                onChange={(_) => setImageSourceType("upload")}
                            />
                            <label
                                htmlFor="create-new"
                                className="ml-3.5 flex w-full flex-col text-sm font-medium"
                            >
                                Upload
                                <span className="mt-1 text-[#6B7280]">
                                    Choose an image from your device
                                </span>
                            </label>
                        </div>
                    </div>
                    {imageSourceType === "upload" && (
                        <FileSelector imageUrl={imageUrl} />
                    )}
                    {imageSourceType === "api" && (
                        <ImageOrVideoSearch mediaType={"backgroundImage"} />
                    )}
                </DialogHeader>
                <DialogFooter className="flex gap-2 p-4">
                    <button
                        data-is-saving={saving}
                        className="rounded-md bg-sapien-blue p-2 text-white data-[is-saving=true]:animate-pulse"
                        disabled={saving}
                        type="button"
                        onClick={async () => {
                            await save(uploadedFileUrl);
                            setOpen(false);
                        }}
                    >
                        Save
                    </button>
                    <DialogClose asChild>
                        <button
                            onClick={() => setOpen(false)}
                            disabled={saving}
                            type="button"
                            className="rounded-md bg-red-500 p-2 text-white data-[is-saving=true]:animate-pulse"
                        >
                            Cancel
                        </button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
