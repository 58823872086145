import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import {
    ModelBlock,
    ModelVariable,
    Round,
    Simulation,
    TimeHorizon,
    VariableDepthObject,
    VariableRelationship,
} from "@/models";
import { InteractiveBlockList } from "./interactive";
import {
    QuestionList,
    QuestionPanel,
    QuestionSubmoduleData,
    QuestionSubmoduleForm,
} from "./connections";
import { ModelBuilderDashboard, TimeHorizonList } from "./dashboard";
import { NestedDataList, VariablePanel } from "./design";
import { ModelBlockList, VariableOrganization } from "./structure";
import { ModelTrees, SubmodulePanel } from "./submodules";
import { ModelVariableForm, BuilderWorkspace } from "./variables";
import { useModelBlockAccordionState } from "./hooks";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useSelectedSimulation } from "@/hooks";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { Switch } from "@/components/Switch";
import { useSetModelBuilderPageProps } from "./atoms";
import { useBuilderWorkspace } from "./useModelBuilderWorkspace";
import { ModelBuilderWrapper } from "./ModelBuilderWrapper";
import { ScrollableWrapper } from "../InteractiveModule/shared";

export type ModelBuilderPageProps = {
    simulation: Simulation & { is_course: boolean };
    timeHorizons: TimeHorizon[];
    modelBlocks: ModelBlock[];
    modelVariables: ModelVariable[];
    rounds: Round[];
    variableRelationships: VariableRelationship[];
    variableDepths: VariableDepthObject[];
    type?: string;
    interactiveBlockId?: string;
    interactiveBlocks?: ModelBlock[];
    simulations?: Partial<Simulation>[];
};

const breakpoint = 996;

const rightMenuItemObject = {
    workspace: <Cog6ToothIcon className="h-5 w-5" />,
};

export default function ModelBuilder(props: ModelBuilderPageProps) {
    const {
        simulation,
        modelBlocks,
        variableRelationships,
        type: modelBuilderPageType,
        interactiveBlockId,
        interactiveBlocks,
        simulations,
    } = props;

    const setModelBuilderPageProps = useSetModelBuilderPageProps();
    useEffect(() => {
        setModelBuilderPageProps(props);
    }, [props]);

    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    const {
        toggleModelBlockAccordionKey,
        expandMultipleModelBlockAccordionKeys,
        collapseMultipleModelBlockAccordionKeys,
        getIsModelBlockExpanded,
    } = useModelBlockAccordionState();
    const { sourceVariables, targetVariable, toggleTargetVariable } =
        useBuilderWorkspace();

    const [section, setSection] = useState<string>("Design");
    const [isLeftMenuOpen, setIsLeftMenuOpen] = useState<boolean>(true);
    const [isRightMenuOpen, setIsRightMenuOpen] = useState<boolean>(false);
    const [rightMenuSection, setRightMenuSection] =
        useState<string>("Workspace");

    const [selectedBuilderRoundId, setSelectedBuilderRoundId] =
        useState<string>("");

    const [questionSubmoduleData, setQuestionSubmoduleData] =
        useState<QuestionSubmoduleData>();

    useEffect(() => {
        if (!!targetVariable) {
            toggleTargetVariable(targetVariable);
        }
    }, [variableRelationships]);

    useEffect(() => {
        if (!!sourceVariables && !!Object.keys(sourceVariables).length) {
            expandMultipleModelBlockAccordionKeys(
                Object.values(sourceVariables).map(
                    (sourceVariable) => sourceVariable.model_block_id,
                ),
            );
        }
    }, [sourceVariables]);

    useEffect(() => {
        if (
            ((!!sourceVariables && !!Object.keys(sourceVariables).length) ||
                !!targetVariable) &&
            !isRightMenuOpen
        ) {
            setIsRightMenuOpen(true);
        }
    }, [sourceVariables, targetVariable]);

    const [exceedsBreakpoint, setExceedsBreakpoint] = useState<boolean>(false);

    useLayoutEffect(() => {
        setExceedsBreakpoint(window.innerWidth > breakpoint);

        const handleResize = () => {
            setExceedsBreakpoint(window.innerWidth > breakpoint);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [breakpoint]);

    useLayoutEffect(() => {
        setIsLeftMenuOpen(exceedsBreakpoint);
        // setIsRightMenuOpen(exceedsBreakpoint);
        if (isRightMenuOpen) {
            setIsRightMenuOpen(exceedsBreakpoint);
        }
    }, [exceedsBreakpoint]);

    const handleLeftMenuClick = useCallback(
        (key: string) => {
            if (isLeftMenuOpen && section === key) {
                setIsLeftMenuOpen(false);
            } else {
                setIsLeftMenuOpen(true);
                setSection(key);
                if (!exceedsBreakpoint) {
                    setIsRightMenuOpen(false);
                }
            }
        },
        [isLeftMenuOpen, section, exceedsBreakpoint],
    );

    const handleRightMenuClick = useCallback(
        (key: string) => {
            if (isRightMenuOpen && rightMenuSection === key) {
                setIsRightMenuOpen(false);
            } else {
                setIsRightMenuOpen(true);
                setRightMenuSection(key);
                if (!exceedsBreakpoint) {
                    setIsLeftMenuOpen(false);
                }
            }
        },
        [isRightMenuOpen, rightMenuSection, exceedsBreakpoint],
    );

    return (
        simulation !== undefined && (
            <>
                <ModelBuilderWrapper
                    simulationTitle={simulation.title}
                    simulationSlug={simulation.slug}
                    isInteractive={!!modelBuilderPageType}
                    interactiveBlockId={interactiveBlockId}
                    interactiveBlockLabel={
                        modelBlocks?.find(
                            (block) => block.id == interactiveBlockId,
                        )?.label
                    }
                    pageSection={section}
                    setPageSection={handleLeftMenuClick}
                >
                    <div className={""}>
                        {!!modelBuilderPageType && !interactiveBlockId && (
                            <div
                                className={
                                    "flex w-full flex-col items-center p-6 text-slate-700 dark:text-slate-300"
                                }
                            >
                                {interactiveBlocks !== undefined && (
                                    <InteractiveBlockList
                                        simulation={simulation}
                                        modelBlocks={interactiveBlocks}
                                        simulations={simulations}
                                    />
                                )}
                            </div>
                        )}

                        {(!modelBuilderPageType || !!interactiveBlockId) && (
                            <div className="flex h-[calc(100vh-44px)]">
                                <div
                                    className={`builder-left-menu dark h-full bg-slate-100 text-slate-700 transition-all
                                    dark:bg-slate-900 dark:text-slate-300 ${
                                        isLeftMenuOpen
                                            ? "block w-80 min-w-80"
                                            : "hidden w-0 min-w-0"
                                    }`}
                                >
                                    <ScrollableWrapper>
                                        <div className="hidden items-center px-1 text-sm">
                                            {!!modelBuilderPageType &&
                                                interactiveBlocks?.length >
                                                    0 && (
                                                    <div className="flex py-px">
                                                        <Switch
                                                            className="data-[state=checked]:bg-sapien-blue data-[state=unchecked]:bg-slate-200"
                                                            checked={
                                                                !!interactiveBlocks[0]
                                                                    .published_at
                                                            }
                                                            onCheckedChange={(
                                                                checked,
                                                            ) => {}}
                                                        />
                                                        <select
                                                            className="flex w-full items-center rounded-sm border-gray-600 bg-white/5 py-1 pl-2 pr-8
                                                                text-sm text-white focus:outline-none focus:ring-0"
                                                            value={
                                                                interactiveBlockId ||
                                                                ""
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target
                                                                        .value !==
                                                                    ""
                                                                )
                                                                    SapienInertia.visit(
                                                                        sapienRoute(
                                                                            "model-builder.interactive-detail",
                                                                            {
                                                                                simulationSlug:
                                                                                    simulation.slug,
                                                                                type: "interactive",
                                                                                interactiveBlockId:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            },
                                                                        ),
                                                                    );
                                                            }}
                                                            disabled={false}
                                                        >
                                                            <option
                                                                key={""}
                                                                value={""}
                                                                className="bg-[#29303e]"
                                                                disabled
                                                            >
                                                                {""}
                                                            </option>
                                                            {interactiveBlocks.map(
                                                                (block) => (
                                                                    <option
                                                                        key={
                                                                            block.id
                                                                        }
                                                                        value={
                                                                            block.id
                                                                        }
                                                                        className="bg-[#29303e]"
                                                                    >
                                                                        {
                                                                            block.label
                                                                        }
                                                                    </option>
                                                                ),
                                                            )}
                                                        </select>
                                                    </div>
                                                )}
                                        </div>
                                        {section === "Dashboard" && (
                                            <div className="p-3">
                                                <div className="mb-3 text-xl">
                                                    Overview
                                                </div>
                                                <TimeHorizonList
                                                    simulationId={simulation.id}
                                                />
                                            </div>
                                        )}
                                        {section === "Design" && (
                                            <div className="p-3">
                                                <div className="mb-3 text-xl">
                                                    Design
                                                </div>
                                                <VariablePanel
                                                    toggleModelBlockAccordionKey={
                                                        toggleModelBlockAccordionKey
                                                    }
                                                    expandMultipleModelBlockAccordionKeys={
                                                        expandMultipleModelBlockAccordionKeys
                                                    }
                                                    collapseMultipleModelBlockAccordionKeys={
                                                        collapseMultipleModelBlockAccordionKeys
                                                    }
                                                    getIsModelBlockExpanded={
                                                        getIsModelBlockExpanded
                                                    }
                                                />
                                            </div>
                                        )}
                                        {section === "Structure" && (
                                            <div className="p-3">
                                                <div className="mb-3 text-xl">
                                                    Structure
                                                </div>
                                                <ModelBlockList
                                                    simulationId={simulation.id}
                                                    interactive={
                                                        !!modelBuilderPageType
                                                    }
                                                />
                                            </div>
                                        )}
                                        {section === "Connections" && (
                                            <div className="p-3">
                                                <div className="mb-3 text-xl">
                                                    Connections
                                                </div>
                                                <QuestionPanel
                                                    selectedBuilderRoundId={
                                                        selectedBuilderRoundId
                                                    }
                                                    setSelectedBuilderRoundId={
                                                        setSelectedBuilderRoundId
                                                    }
                                                />
                                            </div>
                                        )}
                                        {section === "Submodules" && (
                                            <div className="p-3">
                                                <div className="mb-3 text-xl">
                                                    Submodules
                                                </div>
                                                <SubmodulePanel />
                                            </div>
                                        )}
                                    </ScrollableWrapper>
                                </div>

                                <div
                                    className="builder-center-panel h-full w-full bg-slate-100 text-slate-700 transition-all
                                        dark:bg-slate-100 dark:text-slate-700"
                                >
                                    <ScrollableWrapper>
                                        <div className="flex h-full w-full flex-col items-center transition-all">
                                            <div className="mx-auto my-0 w-full min-w-80 max-w-screen-2xl px-8 py-4">
                                                {section === "Dashboard" && (
                                                    <div className="">
                                                        <ModelBuilderDashboard />
                                                    </div>
                                                )}
                                                {section === "Design" && (
                                                    <div className="">
                                                        <NestedDataList
                                                            toggleModelBlockAccordionKey={
                                                                toggleModelBlockAccordionKey
                                                            }
                                                            getIsModelBlockExpanded={
                                                                getIsModelBlockExpanded
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {section === "Structure" && (
                                                    <div className="">
                                                        <VariableOrganization />
                                                    </div>
                                                )}
                                                {section === "Connections" && (
                                                    <div className="">
                                                        <QuestionList
                                                            simulationId={
                                                                simulation.id
                                                            }
                                                            selectedBuilderRoundId={
                                                                selectedBuilderRoundId
                                                            }
                                                            setSelectedBuilderRoundId={
                                                                setSelectedBuilderRoundId
                                                            }
                                                            setQuestionSubmoduleData={
                                                                setQuestionSubmoduleData
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {section === "Submodules" && (
                                                    <div className="">
                                                        <ModelTrees />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </ScrollableWrapper>
                                </div>

                                <div className={`relative ${""}`}>
                                    <div
                                        className={`fixed top-11 mr-1 text-slate-700 dark:text-slate-700 ${
                                            isRightMenuOpen
                                                ? "right-80"
                                                : "right-0"
                                        }`}
                                    >
                                        {Object.keys(rightMenuItemObject).map(
                                            (key) => (
                                                <div
                                                    key={key}
                                                    className="flex cursor-pointer items-center justify-center p-2"
                                                    onClick={() =>
                                                        handleRightMenuClick(
                                                            key,
                                                        )
                                                    }
                                                >
                                                    {rightMenuItemObject[key]}
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <div
                                        className={`builder-right-menu dark z-100 h-full bg-slate-100 text-slate-700 transition-all
                                        dark:bg-slate-900 dark:text-slate-300 ${
                                            isRightMenuOpen
                                                ? "block w-80 min-w-80"
                                                : "hidden w-0 min-w-0"
                                        }`}
                                    >
                                        <ScrollableWrapper>
                                            {rightMenuSection ===
                                                "workspace" && (
                                                <div className="p-3">
                                                    <div className="mb-3 text-xl">
                                                        Workspace
                                                    </div>
                                                    <BuilderWorkspace />
                                                </div>
                                            )}
                                        </ScrollableWrapper>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </ModelBuilderWrapper>
                <ModelVariableForm />
                {!!questionSubmoduleData && (
                    <QuestionSubmoduleForm
                        questionSubmoduleData={questionSubmoduleData}
                        setQuestionSubmoduleData={setQuestionSubmoduleData}
                    />
                )}
            </>
        )
    );
}
