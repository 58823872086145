import React, { useEffect, useState } from "react";
import {
    Participant,
    Prompt,
    PromptType,
    Round,
    SelectionShape,
    Team,
} from "@/models";
import { displayPromptType } from "@/util";
import { DiscoveryLayerObject } from "../useDiscoveryLayer";
import { Dictionary, groupBy } from "lodash";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { DiscoveryWidgetWrapper } from "./DiscoveryWidgetWrapper";
import { DiscoveryStatisticsObject } from "../useDiscoveryStatistics";

const numericTypes = [
    PromptType["Numerical Slider"],
    PromptType["Numerical Input"],
];

const multipleSelectionTypes = [
    PromptType["Multiple Select"],
    PromptType["Drag and Drop"],
    PromptType["Rank Order"],
    // add in others for consistent layout?
    PromptType["Multiple Choice"],
    PromptType["Dropdown List"],
    PromptType.Timeline,
];

export const DiscoverySelectionsByPeople = ({
    discoveryLayerObject,
    rounds,
}: {
    discoveryLayerObject: DiscoveryLayerObject | DiscoveryStatisticsObject;
    rounds: Round[];
}) => {
    const { selectionMap, teamMap, participantMap, promptMap } =
        discoveryLayerObject;

    const [groupedParticipantSelections, setGroupedParticipantSelections] =
        useState<Dictionary<SelectionShape[]>>();
    const [groupedTeamSelections, setGroupedTeamSelections] =
        useState<Dictionary<SelectionShape[]>>();

    useEffect(() => {
        if (
            !!selectionMap &&
            !!Object.keys(selectionMap)?.length &&
            !!promptMap
        ) {
            const flatSelections: SelectionShape[] = Object.keys(
                selectionMap,
            ).reduce((map, key) => [...map, ...selectionMap[key]], []);

            setGroupedParticipantSelections(
                groupBy(
                    flatSelections.filter(
                        (selection) =>
                            promptMap[selection.prompt_id]?.scope ===
                            "Participant",
                    ),
                    "user_id",
                ),
            );
            setGroupedTeamSelections(
                groupBy(
                    flatSelections.filter(
                        (selection) =>
                            promptMap[selection.prompt_id]?.scope === "Team",
                    ),
                    "team_id",
                ),
            );
        }
    }, [selectionMap, promptMap]);

    return (
        <div className="flex flex-col space-y-6">
            {!!groupedTeamSelections &&
                Object.keys(groupedTeamSelections).length > 0 && (
                    <DiscoveryWidgetWrapper className="w-full bg-lime-50/80">
                        <div className="pb-3">Team Selections</div>
                        <SelectionTableByPeople
                            rounds={rounds}
                            selectionMap={selectionMap}
                            selectedPromptIds={promptMap}
                            entityMap={teamMap}
                            scope={"Team"}
                            groupedSelections={groupedTeamSelections}
                            backgroundColor={"bg-lime-50"}
                        />
                    </DiscoveryWidgetWrapper>
                )}
            {!!groupedParticipantSelections &&
                Object.keys(groupedParticipantSelections).length > 0 && (
                    <DiscoveryWidgetWrapper className="w-full bg-emerald-50/80">
                        <div className="pb-3">Participant Selections</div>
                        <SelectionTableByPeople
                            rounds={rounds}
                            selectionMap={selectionMap}
                            selectedPromptIds={promptMap}
                            entityMap={participantMap}
                            scope={"Participant"}
                            groupedSelections={groupedParticipantSelections}
                            backgroundColor={"bg-emerald-50"}
                        />
                    </DiscoveryWidgetWrapper>
                )}
        </div>
    );
};

const SelectionTableByPeople = ({
    rounds,
    selectionMap,
    selectedPromptIds,
    entityMap,
    scope,
    groupedSelections,
    backgroundColor,
}: {
    rounds: Round[];
    selectionMap: {
        [promptId: string]: SelectionShape[];
    };
    selectedPromptIds: { [index: string]: Prompt };
    entityMap: { [index: string]: Team | Participant };
    scope: "Team" | "Participant";
    groupedSelections: Dictionary<SelectionShape[]>;
    backgroundColor: string;
}) => {
    return (
        <div className="relative overflow-x-auto border border-neutral-200">
            <table
                className={
                    "w-full table-auto border text-left text-sm text-gray-500"
                }
                style={{
                    fontSize: "0.8rem",
                }}
            >
                <thead className="border-b border-t text-xs text-gray-700">
                    <tr>
                        <th
                            className={`sticky left-0 border-b border-r px-2 py-1 uppercase ${backgroundColor}`}
                        >
                            {"Type"}
                        </th>
                        {rounds
                            .filter(
                                (round) =>
                                    !!round.prompts && round.prompts_count > 0,
                            )
                            .map((round) => (
                                <React.Fragment key={round.id}>
                                    {round.prompts
                                        .filter(
                                            (prompt) =>
                                                !!selectedPromptIds[
                                                    prompt.id
                                                ] &&
                                                !!selectionMap[prompt.id]
                                                    ?.length &&
                                                prompt.scope === scope,
                                        )
                                        .map((prompt) => (
                                            <th
                                                className="border-b border-r px-2 py-1"
                                                key={prompt.id}
                                                colSpan={
                                                    multipleSelectionTypes.includes(
                                                        prompt.prompt_type,
                                                    )
                                                        ? prompt.options.length
                                                        : 1
                                                }
                                            >
                                                <div className="flex flex-col">
                                                    <div className="whitespace-nowrap font-thin">
                                                        {displayPromptType(
                                                            prompt.prompt_type,
                                                        )}
                                                    </div>
                                                </div>
                                            </th>
                                        ))}
                                </React.Fragment>
                            ))}
                    </tr>
                    <tr className="align-top">
                        <th
                            className={`sticky left-0 border-b border-r px-2 py-1 uppercase ${backgroundColor}`}
                        >
                            {"Prompt"}
                        </th>
                        {rounds
                            .filter(
                                (round) =>
                                    !!round.prompts && round.prompts_count > 0,
                            )
                            .map((round) => (
                                <React.Fragment key={round.id}>
                                    {round.prompts
                                        .filter(
                                            (prompt) =>
                                                !!selectedPromptIds[
                                                    prompt.id
                                                ] &&
                                                !!selectionMap[prompt.id]
                                                    ?.length &&
                                                prompt.scope === scope,
                                        )
                                        .map((prompt) => (
                                            <th
                                                className="border-b border-r px-2 py-1"
                                                key={prompt.id}
                                                colSpan={
                                                    multipleSelectionTypes.includes(
                                                        prompt.prompt_type,
                                                    )
                                                        ? prompt.options.length
                                                        : 1
                                                }
                                            >
                                                <div className="flex flex-col">
                                                    <div className="font-normal">
                                                        {prompt.content}
                                                    </div>
                                                </div>
                                            </th>
                                        ))}
                                </React.Fragment>
                            ))}
                    </tr>
                    <tr className="align-top">
                        <th
                            className={`sticky left-0 border-b border-r px-2 py-1 uppercase ${backgroundColor}`}
                        >
                            {"Option"}
                        </th>
                        {rounds
                            .filter(
                                (round) =>
                                    !!round.prompts && round.prompts_count > 0,
                            )
                            .map((round) => (
                                <React.Fragment key={round.id}>
                                    {round.prompts
                                        .filter(
                                            (prompt) =>
                                                !!selectedPromptIds[
                                                    prompt.id
                                                ] &&
                                                !!selectionMap[prompt.id]
                                                    ?.length &&
                                                prompt.scope === scope,
                                        )
                                        .map((prompt) => (
                                            <React.Fragment key={prompt.id}>
                                                {prompt.options.map(
                                                    (option) => (
                                                        <th
                                                            className="min-w-[150px] border-r border-t px-2 py-1"
                                                            key={option.id}
                                                        >
                                                            {multipleSelectionTypes.includes(
                                                                prompt.prompt_type,
                                                            )
                                                                ? option.content
                                                                : "---"}
                                                        </th>
                                                    ),
                                                )}
                                            </React.Fragment>
                                        ))}
                                </React.Fragment>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.values(entityMap)
                        .filter(
                            (entity) => !!groupedSelections[entity.id]?.length,
                        )
                        .map((entity) => (
                            <React.Fragment key={entity.id}>
                                <tr className="border-b odd:bg-white/50 even:bg-white/10">
                                    <td
                                        className={`sticky left-0 min-w-[150px] border-r px-2 py-1 ${backgroundColor}`}
                                    >{`${entity.name}`}</td>
                                    {rounds
                                        .filter(
                                            (round) =>
                                                !!round.prompts &&
                                                round.prompts_count > 0,
                                        )
                                        .map((round) => (
                                            <React.Fragment key={round.id}>
                                                {round.prompts
                                                    .filter(
                                                        (prompt) =>
                                                            !!selectedPromptIds[
                                                                prompt.id
                                                            ] &&
                                                            !!selectionMap[
                                                                prompt.id
                                                            ]?.length &&
                                                            prompt.scope ===
                                                                scope,
                                                    )
                                                    .map((prompt) => (
                                                        <React.Fragment
                                                            key={prompt.id}
                                                        >
                                                            {multipleSelectionTypes.includes(
                                                                prompt.prompt_type,
                                                            ) ? (
                                                                prompt.options.map(
                                                                    (
                                                                        option,
                                                                    ) => (
                                                                        <th
                                                                            className="border-r px-2 py-1"
                                                                            key={
                                                                                option.id
                                                                            }
                                                                        >
                                                                            {!!groupedSelections[
                                                                                entity
                                                                                    .id
                                                                            ]?.filter(
                                                                                (
                                                                                    selection,
                                                                                ) =>
                                                                                    selection.prompt_id ==
                                                                                    prompt.id,
                                                                            )
                                                                                ?.length && (
                                                                                <>
                                                                                    {prompt.prompt_type ===
                                                                                    PromptType[
                                                                                        "Rank Order"
                                                                                    ] ? (
                                                                                        `${
                                                                                            Number(
                                                                                                !!groupedSelections[
                                                                                                    entity
                                                                                                        .id
                                                                                                ]?.find(
                                                                                                    (
                                                                                                        selection,
                                                                                                    ) =>
                                                                                                        selection.option_id ==
                                                                                                        option.id,
                                                                                                )
                                                                                                    ?.numerical_value ||
                                                                                                    0,
                                                                                            ) +
                                                                                            1
                                                                                        }`
                                                                                    ) : !!groupedSelections[
                                                                                          entity
                                                                                              .id
                                                                                      ]?.find(
                                                                                          (
                                                                                              selection,
                                                                                          ) =>
                                                                                              selection.option_id ==
                                                                                              option.id,
                                                                                      )
                                                                                          ?.is_selected ? (
                                                                                        <span className="inline-flex items-center">
                                                                                            <CheckIcon className="h-3 w-3 text-gray-700" />
                                                                                            {
                                                                                                "true"
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className="inline-flex items-center text-gray-300">
                                                                                            <XMarkIcon className="h-3 w-3" />
                                                                                            {
                                                                                                "false"
                                                                                            }
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </th>
                                                                    ),
                                                                )
                                                            ) : (
                                                                <th className="border-r px-2 py-1">
                                                                    {!!groupedSelections[
                                                                        entity
                                                                            .id
                                                                    ]?.filter(
                                                                        (
                                                                            selection,
                                                                        ) =>
                                                                            selection.prompt_id ==
                                                                            prompt.id,
                                                                    ) && (
                                                                        <>
                                                                            {numericTypes.includes(
                                                                                prompt.prompt_type,
                                                                            )
                                                                                ? groupedSelections[
                                                                                      entity
                                                                                          .id
                                                                                  ]?.filter(
                                                                                      (
                                                                                          selection,
                                                                                      ) =>
                                                                                          selection.prompt_id ==
                                                                                          prompt.id,
                                                                                  )[0]
                                                                                      ?.numerical_value
                                                                                : groupedSelections[
                                                                                      entity
                                                                                          .id
                                                                                  ]?.filter(
                                                                                      (
                                                                                          selection,
                                                                                      ) =>
                                                                                          selection.prompt_id ==
                                                                                          prompt.id,
                                                                                  )[0]
                                                                                      ?.string_value}
                                                                        </>
                                                                    )}
                                                                </th>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                </tr>
                            </React.Fragment>
                        ))}
                </tbody>
            </table>
        </div>
    );
};
