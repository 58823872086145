import React, { useEffect, useState } from "react";
import { Form, FormGroup, FormInput } from "@/components";
import { Cohort, Simulation } from "@/models";
import { plus } from "react-icons-kit/feather/plus";
import { PrimaryButtonOutline } from "@/components/admin-components";
import { Link as InertiaLink } from "@inertiajs/react";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { CheckIcon, PencilIcon } from "@heroicons/react/24/solid";
import { CohortRowLayout } from "./CohortRowLayout";

export const CohortList = ({
    simulation,
}: {
    simulation: Simulation & { is_course: boolean };
}) => {
    const [editedCohort, setEditedCohort] = useState<Cohort>();

    useEffect(() => {
        if (!!editedCohort) {
            document.getElementById(`cohort-${editedCohort.id}`).focus();
        }
    }, [editedCohort?.id]);

    return (
        <div className="space-y-4">
            <div className="flex flex-row items-center justify-between">
                <span className="text-xl font-normal">Cohorts</span>
                <PrimaryButtonOutline
                    text={"Create Cohort"}
                    icon={plus}
                    handler={() =>
                        SapienInertia.post(
                            "facilitation.save-cohort",
                            {
                                title: `Cohort ${(simulation?.cohorts?.length || 0) + 1}`,
                                simulation_id: simulation?.id,
                            },
                            {},
                            {
                                preserveScroll: true,
                            },
                        )
                    }
                />
            </div>
            <div className="flex w-full flex-col gap-4">
                {simulation?.cohorts
                    ?.filter((cohort) => cohort.title !== "Sandbox Cohort")
                    .reverse()
                    .map((cohort) =>
                        editedCohort?.id !== cohort.id ? (
                            <InertiaLink
                                key={cohort.id}
                                href={sapienRoute("facilitation.show", {
                                    simulationSlug: simulation?.slug,
                                    cohortId: cohort.id,
                                })}
                            >
                                <CohortRowLayout
                                    cohort={cohort}
                                    button={
                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setEditedCohort(cohort);
                                            }}
                                            className="rounded-full p-3 transition-all hover:bg-slate-100 hover:text-indigo-600"
                                        >
                                            <PencilIcon className="h-5 w-5" />
                                        </button>
                                    }
                                />
                            </InertiaLink>
                        ) : (
                            <CohortRowLayout
                                key={cohort.id}
                                cohort={cohort}
                                button={
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            SapienInertia.post(
                                                "facilitation.save-cohort",
                                                {
                                                    ...editedCohort,
                                                },
                                                {},
                                                {
                                                    preserveScroll: true,
                                                },
                                            );
                                            setEditedCohort(undefined);
                                        }}
                                        className="rounded-full p-3 transition-all hover:bg-slate-100 hover:text-indigo-600"
                                    >
                                        <CheckIcon className="h-5 w-5" />
                                    </button>
                                }
                            >
                                <div className="flex flex-row items-center">
                                    <div className="w-full pr-1">
                                        <Form>
                                            <FormGroup style={{ margin: 0 }}>
                                                <FormInput
                                                    type="text"
                                                    placeholder="Title"
                                                    value={editedCohort.title}
                                                    id={`cohort-${cohort.id}`}
                                                    onChange={(e) => {
                                                        setEditedCohort({
                                                            ...editedCohort,
                                                            title: e.target
                                                                .value,
                                                        } as Cohort);
                                                    }}
                                                />
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </div>
                            </CohortRowLayout>
                        ),
                    )}
            </div>
        </div>
    );
};
