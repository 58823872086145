import * as React from "react";

interface Props {
    className?: string;
    children: React.ReactNode;
}

export function DiscoveryWidgetWrapper({ className, children }: Props) {
    return (
        <div className={`rounded-md border p-4 shadow ${className}`}>
            {children}
        </div>
    );
}
