import React from "react";
import { Participant } from "@/models";
import { LinkIcon } from "@heroicons/react/24/solid";
import { SapienInertia } from "@/inertia-utils/hooks";
import { AllowParticipantAdvance } from "./AllowAdvanceControls";
import AllowAccess from "./AllowAccess";

export const ParticipantDisplay = ({
    participant,
    cohortId,
    simulationSlug,
    showAdvanceControls = false,
    isCourse = false,
    roundMenu,
    // participantCanMove,
    // setParticipantToMove,
}: {
    participant: Participant;
    cohortId: string;
    simulationSlug: string;
    showAdvanceControls: boolean;
    isCourse?: boolean;
    roundMenu: React.ReactNode;
    // participantsCanMove?: boolean;
    // setParticipantToMove?: (participantToMove: Participant) => void;
}) => {
    return (
        <>
            <li
                key={participant.id}
                className="flex items-center justify-between space-x-3 text-sm"
            >
                <div className="grid w-full grid-cols-4 gap-3 md:grid-cols-4">
                    <div className="flex items-center">
                        <span className="font-medium text-slate-900">
                            {participant.name}
                        </span>
                    </div>
                    <div className="flex items-center justify-start md:justify-start">
                        <span className="overflow-hidden overflow-ellipsis text-slate-700">
                            {participant.email}
                        </span>
                    </div>
                    <div className="flex items-center justify-start">
                        {!!isCourse ? (
                            <AllowAccess user={participant} />
                        ) : (
                            <div className="flex w-full">
                                {!!participant.pivot.login_link_url ? (
                                    <span className="overflow-hidden overflow-ellipsis text-slate-700">
                                        {participant.pivot.login_link_url}
                                    </span>
                                ) : (
                                    // <Link
                                    //     href={sapienRoute(
                                    //         "participant.impersonate",
                                    //     )}
                                    //     as="button"
                                    //     method={"post"}
                                    //     data={{
                                    //         participant_email:
                                    //             participant.email,
                                    //         simulation_slug: simulationSlug,
                                    //     }}
                                    // >
                                    //     {participant.pivot.login_link_url}
                                    // </Link>
                                    <LinkIcon
                                        className="h-6 w-6 cursor-pointer"
                                        onClick={() => {
                                            SapienInertia.post(
                                                "facilitation.participant.link",
                                                {
                                                    team_user_round_id:
                                                        participant?.pivot?.[
                                                            "team_user_round.id"
                                                        ],
                                                    simulation_slug:
                                                        simulationSlug,
                                                },
                                                {
                                                    simulationSlug:
                                                        simulationSlug,
                                                    cohortId: cohortId,
                                                },
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-start md:justify-start">
                        {roundMenu}
                    </div>
                    {!!showAdvanceControls && (
                        <div>
                            <AllowParticipantAdvance
                                team_user_round_id={
                                    participant?.pivot["team_user_round.id"]
                                }
                                status={
                                    participant?.pivot[
                                        "team_user_round.advance_allowed"
                                    ]
                                        ? "ALL_ALLOWED"
                                        : "NONE_ALLOWED"
                                }
                            />
                        </div>
                    )}
                </div>
            </li>
            {/* <tr
                key={participant.id}
                className="border-t border-slate-200 text-slate-800"
            >
                {columnLabels?.map((label, index) => (
                    <td
                        key={`${label}-${index}`}
                        className={`px-2 py-1 ${
                            label == "Email"
                                ? "max-w-[200px] overflow-hidden text-ellipsis"
                                : ""
                        }`}
                    >
                        {label == "Participant" && participant.name}
                        {label == "Email" && participant.email}
                        {(label == "Round" || label == "Lesson") && roundMenu}
                        {label == "Unpublished Access" && (
                            <AllowAccess user={participant} />
                        )}
                        {label == "Link" && (
                            <div className="flex w-full">
                                {!!participant.pivot.login_link_url ? (
                                    <Link
                                        href={sapienRoute(
                                            "participant.impersonate",
                                        )}
                                        as="button"
                                        method={"post"}
                                        data={{
                                            participant_email:
                                                participant.email,
                                            simulation_slug: simulationSlug,
                                        }}
                                    >
                                        {participant.pivot.login_link_url}
                                    </Link>
                                ) : (
                                    <LinkIcon
                                        className="h-6 w-6 cursor-pointer"
                                        onClick={() => {
                                            SapienInertia.post(
                                                "facilitation.participant.link",
                                                {
                                                    team_user_round_id:
                                                        participant?.pivot?.[
                                                            "team_user_round.id"
                                                        ],
                                                    simulation_slug:
                                                        simulationSlug,
                                                },
                                                {
                                                    simulationSlug:
                                                        simulationSlug,
                                                    cohortId: cohortId,
                                                },
                                            );
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </td>
                ))}

                {!!showAdvanceControls && (
                    <td className="px-2 py-1">
                        <AllowParticipantAdvance
                            team_user_round_id={
                                participant?.pivot["team_user_round.id"]
                            }
                            status={
                                participant?.pivot[
                                    "team_user_round.advance_allowed"
                                ]
                                    ? "ALL_ALLOWED"
                                    : "NONE_ALLOWED"
                            }
                        />
                    </td>
                )} */}

            {/* {participantsCanMove ? (
                <td className="px-2 py-1">
                    <button
                        onClick={() => {
                            setParticipantToMove(participant);
                        }}
                        className="inline-flex items-center rounded-full p-2 text-center text-sm font-medium
                            text-gray-800 hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0
                            focus:ring-blue-300"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            version="1.1"
                            viewBox="0 0 1200 1200"
                            fill="currentColor"
                        >
                            <g>
                                <path d="m881.11 943.49c-296.62 0-281.25 219.01-281.25 219.01h562.5s15.371-219.01-281.25-219.01z" />
                                <path d="m1011.8 730.67c0 72.168-58.504 130.67-130.67 130.67-72.164 0-130.67-58.504-130.67-130.67 0-72.164 58.504-130.67 130.67-130.67 72.168 0 130.67 58.504 130.67 130.67" />
                                <path d="m318.89 380.99c-296.62 0-281.25 219.01-281.25 219.01h562.5s15.367-219.01-281.25-219.01z" />
                                <path d="m449.55 168.17c0 72.168-58.504 130.67-130.67 130.67-72.168 0-130.67-58.504-130.67-130.67 0-72.164 58.504-130.67 130.67-130.67 72.164 0 130.67 58.504 130.67 130.67" />
                                <path d="m498.56 1052.5h-123.27c-51.781 0-93.914-42.133-93.914-93.914v-227.88c0-20.711 16.789-37.5 37.5-37.5 20.711 0 37.5 16.789 37.5 37.5v227.88c0 10.438 8.4766 18.914 18.914 18.914h123.27c20.711 0 37.5 16.789 37.5 37.5s-16.789 37.5-37.5 37.5z" />
                                <path d="m892.75 506.84c-20.711 0-37.5-16.789-37.5-37.5v-227.88c0-10.438-8.4766-18.914-18.914-18.914h-123.27c-20.711 0-37.5-16.789-37.5-37.5s16.789-37.5 37.5-37.5h123.27c51.781 0 93.914 42.133 93.914 93.914v227.88c0 20.711-16.793 37.5-37.5 37.5z" />
                                <path d="m892.75 506.84c-9.9414 0-19.484-3.957-26.516-10.988l-98.273-98.273c-14.648-14.648-14.648-38.379 0-53.027 14.648-14.648 38.379-14.648 53.027 0l71.758 71.758 71.742-71.758c14.648-14.648 38.379-14.648 53.027 0 14.648 14.629 14.648 38.379 0 53.027l-98.254 98.273c-7.0312 7.0352-16.57 10.988-26.512 10.988z" />
                                <path d="m417.15 866.44c-9.5938 0-19.191-3.6641-26.516-10.988l-71.758-71.758-71.742 71.758c-14.648 14.648-38.379 14.648-53.027 0-14.648-14.629-14.648-38.379 0-53.027l98.254-98.273c14.062-14.062 38.965-14.062 53.027 0l98.273 98.273c14.648 14.648 14.648 38.379 0 53.027-7.3242 7.3242-16.918 10.988-26.512 10.988z" />
                            </g>
                        </svg>
                    </button>
                </td>
            ) : (
                <td className="px-2 py-1"></td>
            )} */}
            {/* </tr> */}
        </>
    );
};
