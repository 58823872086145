import { useCallback } from "react";
import { CurrencyType, ModelVariableUnit } from "@/models";
import { variableValueFormatter } from "@/util";
import { useDataFile } from "./useDataSandbox";

export const useFormatSandboxValue = () => {
    const { dataFileMetadata } = useDataFile();

    const formatSandboxValue = useCallback(
        (variable: string, value: number) =>
            variableValueFormatter(
                dataFileMetadata?.currency_type || CurrencyType.USD,
                (!!dataFileMetadata?.variable_unit_map &&
                    dataFileMetadata?.variable_unit_map[variable]) ||
                    ModelVariableUnit.None,
                value,
                !!dataFileMetadata?.variable_unit_map &&
                    dataFileMetadata?.variable_unit_map[variable] ===
                        ModelVariableUnit.Dollars,
            ),
        [dataFileMetadata],
    );

    return { formatSandboxValue };
};
