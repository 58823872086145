import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SapienPageProps } from "@/inertia-utils/types";
import { Cohort, CohortWithProgressData, Simulation } from "@/models";
import {
    useSelectedSimulation,
    useSelectedCohort,
    useAllCohorts,
} from "@/hooks";
import { useRoundStore } from "@/hooks/store";
import { SapienInertia } from "@/inertia-utils/hooks";
import { useWebSockets } from "@/hooks/websockets/useWebsockets";
import { useModalComponentStore } from "@/hooks/useModalComponentStore";
import { AdminContainer } from "@/Layouts/admin";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { SimBuilderTopNav } from "@/Layouts/admin/SimBuilderTopNav";
import { CohortDetail } from "./cohort-detail";
import { CohortList } from "./cohort-list";

const queryClient = new QueryClient();

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    cohort: CohortWithProgressData | null;
};

export default function FacilitationContainer(props: Props) {
    const { simulation, cohort } = props;
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const { gotCohort } = useSelectedCohort();
    const { gotCohorts } = useAllCohorts();
    const { gotRounds } = useRoundStore();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
        gotRounds(simulation.rounds || []);
        gotCohorts(simulation.cohorts);
    }, [simulation]);

    function reloadOnNotify(_: any) {
        console.log("doing reload");
        SapienInertia.reload({
            preserveScroll: true,
            preserveState: true,
        });
    }

    useEffect(() => {
        gotCohort(cohort as Cohort);
    }, [cohort]);

    const { setUpFacilitatorSockets } = useWebSockets();

    useEffect(() => {
        if (cohort) {
            setUpFacilitatorSockets(cohort.id, {
                "facilitator.notify": reloadOnNotify,
            });
        }
    }, [cohort?.id]);

    const { setModalProps } = useModalComponentStore();
    setModalProps(props);

    return (
        simulation !== undefined && (
            //simulation.id === selectedSimulation.id && (
            <QueryClientProvider client={queryClient}>
                <AdminContainer
                    headTitle={"Facilitation"}
                    isELearningContext={simulation.is_course}
                >
                    <AdminPageHeader>
                        <SimBuilderTopNav
                            title={simulation.title}
                            slug={simulation.slug}
                            id={simulation.id}
                            isCourse={simulation.is_course}
                        />
                    </AdminPageHeader>
                    <AdminPageSection>
                        <div className="">
                            {!cohort ? (
                                <CohortList simulation={simulation} />
                            ) : (
                                <CohortDetail
                                    simulation={simulation}
                                    cohort={cohort}
                                />
                            )}
                        </div>
                    </AdminPageSection>
                </AdminContainer>
            </QueryClientProvider>
        )
    );
}
