import React from "react";
import { CohortShape, Round, Simulation, TeamUserRoundPivot } from "@/models";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SandboxDisplay from "./SandboxDisplay";
import { SandboxModelComparisonProps } from "./SandboxVariablesDisplay";

const queryClient = new QueryClient();

type CohortWithTeamUserRounds = CohortShape & {
    team_user_rounds: (TeamUserRoundPivot & {
        user: { name: string };
        id: string;
    })[];
};
type Props = {
    cohorts: { [index: string]: CohortWithTeamUserRounds };
    simulation: Simulation;
    rounds: Round[];
    baseModelCalculations?: SandboxModelComparisonProps;
};

export default function CompareVariableCalculations({
    cohorts,
    simulation,
    rounds,
    baseModelCalculations,
}: Props) {
    return (
        <QueryClientProvider client={queryClient}>
            <SandboxDisplay
                cohorts={cohorts}
                simulation={simulation}
                rounds={rounds}
                baseModelCalculations={baseModelCalculations}
            />
        </QueryClientProvider>
    );
}
