import React, {
    useEffect,
    useState,
    useMemo,
    useRef,
    useCallback,
} from "react";
import { Combobox, Transition, Switch } from "@headlessui/react";
import { AdminContainer } from "@/Layouts/admin";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { ContentBlockTemplateShape, Tag } from "@/models";
import { ChevronDoubleLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { Link, useForm } from "@inertiajs/react";
import FileSelector from "@/Pages/Admin/editor/uploads/FileSelector";
import { useEditorImageContentBlock, useUploadStore } from "@/hooks";
import { ProjectDashboardTopNav } from "@/Layouts/admin/ProjectDashboardTopNav";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
type Props = SapienAdminPageProps & {
    template: ContentBlockTemplateShape;
    tags: Tag[];
};

export default function EditContentBlockTemplate({
    template,
    tags,
    auth,
    tenant,
    locationInfo,
}: Props) {
    const defaultRoute = sapienRoute("templates.index");
    const route = locationInfo?.query?.returnTo || defaultRoute;

    const { uploadedFileUrl } = useEditorImageContentBlock();
    const { uploadPercentage, uploading } = useUploadStore();

    const { data, setData } = useForm({
        ...template,
        is_published_to_simulation: !!template.published_to_simulation_at,
        is_published_to_tenant: !!template.published_to_all_at,
        is_published_to_public: !!template.published_to_all_tenants_at,
    });

    useEffect(() => {
        if (uploadPercentage === 100 && uploadedFileUrl)
            setData({ ...data, image_url: uploadedFileUrl });
    }, [uploadedFileUrl]);

    const [query, setQuery] = useState("");
    const ref = useRef(null);
    const filteredTags = useMemo(() => {
        return query === ""
            ? tags || []
            : tags?.filter((tag) => {
                  return tag.name?.en
                      .toLowerCase()
                      .includes(query.toLowerCase());
              });
    }, [query, tags]);

    const addTag = useCallback(
        (tag: Tag) => {
            if (data.tags.find((t) => t.name.en === tag.name.en)) return;
            setData("tags", [...data.tags, tag]);
            console.log("ADDING TAG", tag);
            setQuery(() => "");
            if (ref?.current) ref.current.value = "";
        },
        [query, data],
    );

    return (
        <AdminContainer headTitle={"Templates"}>
            <AdminPageHeader>
                <ProjectDashboardTopNav />
            </AdminPageHeader>
            <AdminPageSection>
                <div className="space-y-12 rounded-lg bg-white p-6 shadow-sm">
                    <div className="border-b border-gray-900/10 pb-12">
                        {!!locationInfo?.query?.returnTo && (
                            <div className="flex w-full justify-end">
                                <Link
                                    href={locationInfo?.query?.returnTo}
                                    className="flex items-center space-x-3"
                                >
                                    <ChevronDoubleLeftIcon className="h-4 w-4 text-gray-500" />
                                    {route !== defaultRoute
                                        ? "Back"
                                        : "Templates"}
                                </Link>
                            </div>
                        )}
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                            Edit Template
                        </h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be
                            careful what you share.
                        </p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label
                                    htmlFor="title"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Label
                                </label>
                                <div className="mt-2">
                                    <div
                                        className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2
                                            focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"
                                    >
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900
                                                placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            value={data?.label}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    label: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label
                                    htmlFor="description"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Description
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        id="about"
                                        name="about"
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                                            ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
                                            focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={data?.description || ""}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                description: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-span-full mt-2 flex max-w-2xl flex-col space-y-4 rounded-lg border
                                    border-dashed border-gray-900/25 p-6"
                            >
                                <Switch.Group
                                    as="div"
                                    className="flex items-center justify-between"
                                >
                                    <span className="flex flex-grow flex-col">
                                        <Switch.Label
                                            as="span"
                                            className="text-sm font-medium leading-6 "
                                            passive
                                        >
                                            Publish to Simulation
                                        </Switch.Label>
                                        <Switch.Description
                                            as="span"
                                            className="text-sm text-gray-400"
                                        >
                                            Share this template with anyone
                                            working on this simulation.
                                        </Switch.Description>
                                    </span>
                                    <Switch
                                        checked={
                                            data.is_published_to_simulation
                                        }
                                        onChange={() => {
                                            if (
                                                !data.is_published_to_simulation
                                            )
                                                setData({
                                                    ...data,
                                                    is_published_to_simulation:
                                                        true,
                                                });
                                        }}
                                        className={classNames(
                                            data.is_published_to_simulation
                                                ? "bg-indigo-600"
                                                : "bg-gray-200",
                                            `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2
                                            border-transparent transition-colors duration-200 ease-in-out focus:outline-none
                                            focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`,
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                data.is_published_to_simulation
                                                    ? "translate-x-5"
                                                    : "translate-x-0",
                                                `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0
                                                transition duration-200 ease-in-out transform`,
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>

                                <Switch.Group
                                    as="div"
                                    className="flex items-center justify-between"
                                >
                                    <span className="flex flex-grow flex-col">
                                        <Switch.Label
                                            as="span"
                                            className="text-sm font-medium leading-6 "
                                            passive
                                        >
                                            Publish to everyone at{" "}
                                            {tenant?.tenant_name}
                                        </Switch.Label>
                                        <Switch.Description
                                            as="span"
                                            className="text-sm text-gray-400"
                                        >
                                            Share this template with all
                                            creators at {tenant?.tenant_name}
                                        </Switch.Description>
                                    </span>
                                    <Switch
                                        checked={data.is_published_to_tenant}
                                        onChange={() => {
                                            if (!data.is_published_to_tenant)
                                                setData({
                                                    ...data,
                                                    is_published_to_tenant:
                                                        true,
                                                });
                                        }}
                                        className={classNames(
                                            data.is_published_to_tenant
                                                ? "bg-indigo-600"
                                                : "bg-gray-200",
                                            `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2
                                            border-transparent transition-colors duration-200 ease-in-out focus:outline-none
                                            focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`,
                                        )}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                data.is_published_to_tenant
                                                    ? "translate-x-5"
                                                    : "translate-x-0",
                                                `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0
                                                transition duration-200 ease-in-out transform`,
                                            )}
                                        />
                                    </Switch>
                                </Switch.Group>
                                {auth?.user.roles?.some(
                                    (role) =>
                                        role.name === "Sapien Super Admin",
                                ) && (
                                    <Switch.Group
                                        as="div"
                                        className="flex items-center justify-between"
                                    >
                                        <span className="flex flex-grow flex-col">
                                            <Switch.Label
                                                as="span"
                                                className="text-sm font-medium leading-6 "
                                                passive
                                            >
                                                Publish to everyone
                                            </Switch.Label>
                                            <Switch.Description
                                                as="span"
                                                className="text-sm text-gray-400"
                                            >
                                                Share this template with all
                                                creators, accross all tenants
                                            </Switch.Description>
                                        </span>
                                        <Switch
                                            checked={
                                                data.is_published_to_public
                                            }
                                            onChange={() => {
                                                if (
                                                    !data.is_published_to_public
                                                )
                                                    setData({
                                                        ...data,
                                                        is_published_to_public:
                                                            true,
                                                    });
                                            }}
                                            className={classNames(
                                                data.is_published_to_public
                                                    ? "bg-indigo-600"
                                                    : "bg-gray-200",
                                                `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2
                                                border-transparent transition-colors duration-200 ease-in-out focus:outline-none
                                                focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`,
                                            )}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    data.is_published_to_public
                                                        ? "translate-x-5"
                                                        : "translate-x-0",
                                                    `pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0
                                                    transition duration-200 ease-in-out transform`,
                                                )}
                                            />
                                        </Switch>
                                    </Switch.Group>
                                )}
                            </div>
                            <div className="col-span-full">
                                <label
                                    htmlFor="description"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Tags
                                </label>
                                <div className="mt-2">
                                    <Combobox
                                        value={data?.tags}
                                        onChange={(value) => {
                                            setData("tags", value);
                                        }}
                                        multiple
                                        nullable
                                    >
                                        {({ open }) => (
                                            <>
                                                <div className="relative mt-1">
                                                    <div className="relative flex items-center">
                                                        <Combobox.Input
                                                            ref={ref}
                                                            value={query}
                                                            onChange={(e) => {
                                                                setQuery(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                            autoComplete="off"
                                                            className="relative block h-10 w-[42rem] rounded-md border-gray-300 text-sm sm:text-xs"
                                                        />
                                                        <ul className="absolute left-[42rem] z-100 ml-2 flex max-w-sm flex-wrap gap-2 pl-0">
                                                            {data?.tags?.map(
                                                                (tag) => (
                                                                    <li
                                                                        key={
                                                                            tag
                                                                                .name
                                                                                .en
                                                                        }
                                                                        className={`mr-1 flex list-none rounded-md px-2 py-1 shadow-sm ${
                                                                            !!tag.id
                                                                                ? "bg-amber-100 text-amber-900"
                                                                                : "bg-green-100 text-green-900"
                                                                        }"}`}
                                                                    >
                                                                        <button
                                                                            className="mr-1 cursor-pointer"
                                                                            onClick={() => {
                                                                                setData(
                                                                                    "tags",
                                                                                    data.tags.filter(
                                                                                        (
                                                                                            t,
                                                                                        ) =>
                                                                                            t
                                                                                                .name
                                                                                                .en !==
                                                                                            tag
                                                                                                .name
                                                                                                .en,
                                                                                    ),
                                                                                );
                                                                            }}
                                                                        >
                                                                            <XMarkIcon className="h-4 w-4" />
                                                                        </button>
                                                                        <span>
                                                                            {
                                                                                tag
                                                                                    .name
                                                                                    .en
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                ),
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <Transition
                                                        show={
                                                            open &&
                                                            !!query.length
                                                        }
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0"
                                                    >
                                                        <Combobox.Options
                                                            className="max-h-60 absolute ml-0 mt-1 w-full overflow-auto rounded-md bg-white py-1 pl-0
                                                                text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                                                                sm:text-sm"
                                                        >
                                                            {query.length >
                                                                0 && (
                                                                <Combobox.Option
                                                                    value={{
                                                                        id: null,
                                                                        name: {
                                                                            en: query,
                                                                        },
                                                                    }}
                                                                    className={({
                                                                        active,
                                                                    }) =>
                                                                        `relative cursor-default select-none list-none py-2 pl-10 pr-4 ${
                                                                            active
                                                                                ? "bg-amber-100 text-amber-900"
                                                                                : "text-gray-900"
                                                                        }`
                                                                    }
                                                                    onClick={() => {
                                                                        addTag({
                                                                            name: {
                                                                                en: query,
                                                                            },
                                                                            type: "content_block_template",
                                                                        });
                                                                    }}
                                                                >
                                                                    Create "
                                                                    {query}"
                                                                </Combobox.Option>
                                                            )}
                                                            {filteredTags.map(
                                                                (tag) => (
                                                                    <Combobox.Option
                                                                        key={
                                                                            tag.id
                                                                        }
                                                                        value={
                                                                            tag
                                                                        }
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            `relative cursor-default select-none list-none py-2 pl-10 pr-4 ${
                                                                                active
                                                                                    ? "bg-amber-100 text-amber-900"
                                                                                    : "text-gray-900"
                                                                            }`
                                                                        }
                                                                        onClick={() => {
                                                                            addTag(
                                                                                tag,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            tag
                                                                                .name
                                                                                .en
                                                                        }
                                                                    </Combobox.Option>
                                                                ),
                                                            )}
                                                        </Combobox.Options>
                                                    </Transition>
                                                </div>
                                            </>
                                        )}
                                    </Combobox>
                                </div>
                            </div>
                            <div className="col-span-full max-w-2xl">
                                <label
                                    htmlFor="cover-photo"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Image
                                </label>
                                <div
                                    className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-4
                                        py-4"
                                >
                                    <div className="text-center">
                                        {!data?.image_url &&
                                            !!data?.html_string && (
                                                <div
                                                    className="overflow-hidden rounded-md"
                                                    dangerouslySetInnerHTML={{
                                                        __html: data?.html_string,
                                                    }}
                                                />
                                            )}
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                            <FileSelector
                                                imageUrl={data?.image_url}
                                            />
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">
                                            PNG, JPG, GIF up to 10MB
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <Link
                            href={route}
                            type="button"
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            Cancel
                        </Link>
                        <button
                            disabled={uploading}
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                                console.log(locationInfo?.query?.returnTo);

                                SapienInertia.put(
                                    "creator.design.templates.update",
                                    { ...data, returnTo: route },
                                    {
                                        template: data.id,
                                    },
                                );
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </AdminPageSection>
        </AdminContainer>
    );
}
