import React, { useState } from "react";
import { useForm } from "@inertiajs/react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { ModalContainer } from "@/modules/shared";
import { TeamShape } from "@/models";

type Props = {
    team: TeamShape;
    children?: React.ReactNode;
    roundId: string;
};

export const CreateEphemeralParticipantForm = ({
    children,
    team,
    roundId,
}: Props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data, setData } = useForm({
        name: "",
        team_id: team?.id || null,
        round_id: roundId,
    });

    return (
        <>
            <button
                className="inline-flex items-center gap-2 rounded-md bg-indigo-600 px-4 py-2 text-sm
                    font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpen(true)}
            >
                <UserPlusIcon className="h-6 w-6" />
                Create new participant for {team.name}
            </button>
            <ModalContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                size="l"
            >
                <div className="rounded-md border border-slate-100/50 bg-white/75 p-3">
                    <div className="flex flex-col gap-4 ">
                        <div className="flex min-w-60 items-center space-x-3">
                            <UserPlusIcon className="h-6 w-6" />
                            <h2 className="text-nowrap text-base font-semibold leading-6 text-slate-900">
                                Create new user
                            </h2>
                        </div>
                        {!!team && (
                            <p className="-mt-3 block text-sm text-slate-500">
                                User will be added to {team.name}
                            </p>
                        )}
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                SapienInertia.post(
                                    "facilitation.participant.create",
                                    data,
                                    {},
                                    {
                                        onSuccess: () => {
                                            setData({
                                                name: "",
                                                team_id: team?.id || null,
                                                round_id: roundId,
                                            });
                                            setIsModalOpen(false);
                                        },
                                    },
                                );
                            }}
                            className="flex w-full"
                        >
                            <div className="flex w-full flex-col gap-4">
                                <div>
                                    <label
                                        htmlFor="user-name"
                                        className="sr-only"
                                    >
                                        Name
                                    </label>
                                    <input
                                        name="user-name"
                                        id="user-name"
                                        className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-900
                                            ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                            focus:ring-inset focus:ring-indigo-600"
                                        placeholder={`Name`}
                                        value={data.name}
                                        onChange={(e) => {
                                            setData("name", e.target.value);
                                        }}
                                    />
                                </div>

                                <div className="flex justify-end gap-2">
                                    <button
                                        className="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                            hover:bg-red-700 focus-visible:outline focus-visible:outline-2
                                            focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setData({
                                                name: "",
                                                team_id: team?.id || null,
                                                round_id: roundId,
                                            });
                                            setIsModalOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                            hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2
                                            focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {!!children && <div>{children}</div>}
                </div>
            </ModalContainer>
        </>
    );
};
