import { sapienAxios } from "@/inertia-utils/hooks";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

type ProgressDataPayload = {
    cohortId: string;
};

type ProgressDataResponse = {
    progressDataMap: {
        [teamId: string]: { [roundId: string]: { percent_complete: number } };
    };
};

async function getProgressData({
    body,
}: {
    body: Partial<ProgressDataPayload>;
}) {
    const { data } = await sapienAxios.get<
        ProgressDataResponse,
        "facilitation.progress-data.cohort"
    >("facilitation.progress-data.cohort", body as ProgressDataPayload);

    return data;
}

const handleGetProgressData = (
    cohortId: string,
    isEnabled: boolean,
    callback: () => void,
) => {
    const { data } = useQuery({
        queryKey: ["progressMapData", cohortId],
        queryFn: async () => {
            const data = await getProgressData({
                body: {
                    cohortId,
                },
            });
            callback();
            return data;
        },
        select: (data) => data,
        // placeholderData: keepPreviousData,
        initialData: { progressDataMap: {} },
        enabled: isEnabled,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

export const useGetProgressData = (cohortId: string) => {
    const isEnabled = useMemo(() => {
        return !!cohortId;
    }, [cohortId]);

    const progressMapData = handleGetProgressData(
        cohortId,
        isEnabled,
        () => null,
    );

    return { ...progressMapData };
};
