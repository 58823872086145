import React, { useEffect, useState } from "react";
import { H4ThinSpan } from "@/components";
import { Simulation, SimulationShape } from "@/models";
import { SimulationForm } from "./SimulationForm";
import { useAllSimulations, useEditorImageContentBlock } from "@/hooks";
import { SimulationCard } from "./SimulationCard";
import { ModalContainer } from "@/modules/shared";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import { plus } from "react-icons-kit/feather/plus";
import { PrimaryButton } from "@/components/admin-components";

const deleteSimulation = (simulationId: string) => {
    SapienInertia.delete(
        sapienRoute("creator.simulations.destroy", {
            id: simulationId,
        }),
    );
};

export const SimulationList = ({
    editedSimulation,
    setEditedSimulation,
    domain,
}: {
    editedSimulation?: Simulation;
    setEditedSimulation?: (simulation: Simulation) => void;
    domain?: string;
}) => {
    const { simulations } = useAllSimulations();

    const [clonedSimulation, setClonedSimulation] =
        useState<SimulationShape | null>();
    const [parentTitle, setParentTitle] = useState("");
    const { uploadedFileUrl } = useEditorImageContentBlock();
    useEffect(() => {
        if (uploadedFileUrl && editedSimulation) {
            console.log("uploadedFileUrl");
            setEditedSimulation({
                ...editedSimulation,
                theme: {
                    ...editedSimulation.theme,
                    image_url: uploadedFileUrl,
                },
            } as Simulation);
        }
    }, [uploadedFileUrl]);

    useEffect(() => {
        setParentTitle(clonedSimulation?.title);
    }, [clonedSimulation?.id]);

    const [isBusy, setIsBusy] = useState(false);

    return (
        <>
            <div className="space-y-4">
                <div className="flex flex-row items-center justify-between">
                    <span className="text-xl font-normal">Projects</span>
                    <PrimaryButton
                        text={"Create Project"}
                        icon={plus}
                        handler={() => {
                            setEditedSimulation(
                                new Simulation({
                                    weight: !!simulations?.length
                                        ? Math.max(
                                              ...simulations.map(
                                                  (simulation) =>
                                                      simulation.weight,
                                              ),
                                          ) + 1
                                        : 0,
                                }),
                            );
                        }}
                    />
                </div>
                <div className="grid w-full gap-5 py-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {simulations !== undefined &&
                        simulations.map((simulation, i) => (
                            <SimulationCard
                                key={simulation.id}
                                simulation={simulation}
                                setEditedSimulation={
                                    !!setEditedSimulation
                                        ? () => setEditedSimulation(simulation)
                                        : undefined
                                }
                                setClonedSimulation={() => {
                                    setClonedSimulation(simulation);
                                }}
                                deleteSimulation={(simulationId: string) => {
                                    if (
                                        window.confirm("Delete this project?")
                                    ) {
                                        deleteSimulation(simulationId);
                                    }
                                }}
                            />
                        ))}
                </div>
            </div>
            <ModalContainer
                isModalOpen={!!editedSimulation}
                setIsModalOpen={() => setEditedSimulation(undefined)}
                styles={{
                    // top: "0px",
                    // margin: "20vh auto",
                    boxShadow:
                        "0px 4px 6px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1",
                }}
                size={"m"}
                classNames={"z-100"}
            >
                {!!editedSimulation && (
                    <div className="px-4">
                        <div className="pb-8 pt-4">
                            <H4ThinSpan>
                                {editedSimulation?.id
                                    ? `Edit Project`
                                    : `Create Project`}
                            </H4ThinSpan>
                        </div>
                        <div className="pb-2">
                            {!!editedSimulation && (
                                <SimulationForm
                                    simulation={editedSimulation}
                                    saveSimulation={(sim: Simulation) => {
                                        if (sim.id) {
                                            SapienInertia.put(
                                                "creator.simulations.update",
                                                {
                                                    ...sim,
                                                },
                                                {},
                                                {
                                                    onSuccess() {
                                                        setEditedSimulation(
                                                            null,
                                                        );
                                                    },
                                                },
                                            );
                                        } else {
                                            SapienInertia.post(
                                                "creator.simulations.store",
                                                {
                                                    ...sim,
                                                },
                                                {},
                                                {
                                                    onSuccess() {
                                                        setEditedSimulation(
                                                            null,
                                                        );
                                                    },
                                                },
                                            );
                                        }
                                    }}
                                    cancel={() => setEditedSimulation(null)}
                                />
                            )}
                        </div>
                    </div>
                )}
            </ModalContainer>
            <ModalContainer
                isModalOpen={!!clonedSimulation}
                setIsModalOpen={() => setClonedSimulation(undefined)}
                // styles={{ margin: "6em auto" }}
                size={"m"}
                classNames={"z-100"}
                backgroundColor="#111928"
            >
                <div className="p-6 text-white">
                    {!!clonedSimulation && (
                        <div className="flex flex-col space-y-4">
                            <div>Copy {parentTitle}</div>
                            <div>
                                <label
                                    htmlFor="title"
                                    className="block text-sm font-medium"
                                >
                                    Title
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={(e) =>
                                            setClonedSimulation({
                                                ...clonedSimulation,
                                                title: e.target.value,
                                            })
                                        }
                                        value={clonedSimulation.title}
                                        type="text"
                                        name="title"
                                        id="title"
                                        className={`block w-full max-w-3xl rounded-md border-gray-300 bg-white/10 text-gray-200
                                            focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm`}
                                        placeholder="Round Title"
                                    />
                                </div>
                            </div>
                            <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
                                <div className="flex items-center justify-center">
                                    <button
                                        type="button"
                                        className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5
                                            text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none
                                            focus:ring-2 focus:ring-blue-300"
                                        disabled={isBusy}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setClonedSimulation(null);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>{" "}
                                <div className="flex items-center justify-center">
                                    <button
                                        onClick={() => {
                                            setIsBusy(true);
                                            SapienInertia.post(
                                                "creator.variants.store",
                                                {
                                                    simulation_id:
                                                        clonedSimulation.id,
                                                    title: clonedSimulation.title,
                                                },
                                            );
                                        }}
                                        className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                                            py-2.5 text-center text-sm font-medium text-white transition-all
                                            hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                                            disabled:cursor-not-allowed disabled:opacity-75"
                                        disabled={isBusy}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ModalContainer>
        </>
    );
};
