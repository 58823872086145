import { DataFile, SimulationShape } from "@/models";

export type StatisticsObject = {
    count: number;
    mean: number;
    std: number;
    min: number;
    "25%": number;
    "50%": number;
    "75%": number;
    max: number;
};

export const defaultStatisticsObject: StatisticsObject = {
    count: 0,
    mean: 0,
    std: 0,
    min: 0,
    "25%": 0,
    "50%": 0,
    "75%": 0,
    max: 0,
};

export type DataDescription = Record<string, StatisticsObject>;

export type DataSandboxProps = {
    simulation: SimulationShape;
    data: DataDescription;
    timeHorizons: number[];
    variables: string[];
    dataFile: DataFile;
};

export enum RegressionType {
    "simple linear" = "LINEAR_SIMPLE",
    "multiple linear" = "LINEAR_MULTIPLE",
    "multivariate linear" = "LINEAR_MULTIVARIATE",
    "polynomial" = "POLYNOMIAL",
}

export type PredictionDataObject = {
    x: string;
    y: string;
    points: { x: number; y: number }[];
};

type RegressionSummary = {
    regression_type: RegressionType;
    x_variables: string[];
    y_variables: string[];
    degree?: number;
    count: number;
    outliers: number;
    coefficients: number[][];
    intercepts: number[];
    r2_scores: number[];
    x_min_values: number[];
    x_max_values: number[];
    prediction_data: PredictionDataObject[];
};

export type RegressionPayload = {
    regression_type: RegressionType;
    x_variables: string[];
    y_variables: string[];
    degree?: number;
};

export type RegressionResponse = {
    regressionData: DataDescription;
    regressionSummary: RegressionSummary;
    regressionDataPoints: Record<string, number>[];
};

export type RegressionProps = {
    regressionParameters: RegressionPayload;
    setRegressionParameters: (regressionParameters: RegressionPayload) => void;
    regressionResponse: RegressionResponse;
};

type ClusterAnalysisSummary = {
    variables: string[];
    clusters: number;
    count: number;
    outliers: number;
    inertia_values: number[];
};

export type ClusterAnalysisPayload = {
    variables: string[];
    clusters: number;
};

export type ClusterAnalysisResponse = {
    clusterAnalysisData: DataDescription;
    clusterAnalysisSummary: ClusterAnalysisSummary;
    clusterDescriptions: Record<number, DataDescription>;
};

export type ClusterAnalysisProps = {
    clusterAnalysisParameters: ClusterAnalysisPayload;
    setClusterAnalysisParameters: (
        clusterAnalysisParameters: ClusterAnalysisPayload,
    ) => void;
    clusterAnalysisResponse: ClusterAnalysisResponse;
};

type ForecastSummary = {
    variable: string;
    forecast_length: string | number;
    example_count: number;
    data_time_horizons: number[];
    forecast_time_horizons: number[];
    count: number;
    outliers: number;
    time_horizon_counts: Record<string, number>;
};

export type ForecastPayload = {
    variable: string;
    forecast_length: number;
    example_count: number;
};

export type ForecastResponse = {
    forecastData: DataDescription;
    forecastSummary: ForecastSummary;
    forecastExamples: Record<string, Record<string, number>>;
    originalData: Record<string, Record<string, number>>;
};

export type ForecastProps = {
    forecastParameters: ForecastPayload;
    setForecastParameters: (forecastParameters: ForecastPayload) => void;
    forecastResponse: ForecastResponse;
};
