import React, { useMemo } from "react";
import { ChartBarIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ChevronRight } from "lucide-react";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { useFormatVariableValue, VariableValueFormatFunction } from "@/hooks";
import {
    ModelVariable,
    VariableValue,
    ModelVariableDataType,
    ModelBlock,
} from "@/models";
import {
    ChartBlock,
    FormattedChartValue,
    InteractiveModuleArchetype,
    InteractiveModuleChartType,
} from "../types";
import { ChartDisplay, CompactTableWrapper, DashboardWidgetWrapper } from ".";
import { getChartValues } from "../utils";

const ArchetypeVariableDataTableRowComponent = ({
    archetypeLabel,
    archetypeTimespan,
    modelVariable,
    variableValues,
    index,
    showResampleFunction,
    formatVariableValue,
    maxTimespan,
}: {
    archetypeLabel: string;
    archetypeTimespan: number;
    modelVariable: ModelVariable;
    variableValues: VariableValue[];
    index: number;
    showResampleFunction?: boolean;
    formatVariableValue: VariableValueFormatFunction;
    maxTimespan: number;
}) => {
    return (
        <tr
            key={modelVariable.id}
            className={`table-row border border-slate-300 bg-slate-50 transition-all
            dark:border-slate-600 dark:bg-slate-900 ${
                index % 2 == 0
                    ? modelVariable.expose_to_designer
                        ? "bg-slate-50 bg-opacity-10 dark:bg-opacity-30"
                        : "bg-slate-100 bg-opacity-20 dark:bg-opacity-40"
                    : "bg-slate-50 bg-opacity-80 dark:bg-opacity-10"
            }
            ${modelVariable.expose_to_designer ? "text-slate-500 dark:text-slate-400" : ""}`}
        >
            <th
                className={`table-col px-1 py-1.5 ${showResampleFunction ? "flex justify-between" : ""}`}
            >
                <span
                    className={`text-nowrap text-sm ${
                        modelVariable.expose_to_designer
                            ? "font-normal"
                            : "font-semibold"
                    }`}
                >
                    {archetypeLabel}
                    {!!showResampleFunction && (
                        <span className="pl-1 text-xs font-thin text-slate-500 dark:text-slate-400">{` (${archetypeTimespan} years)`}</span>
                    )}
                </span>
            </th>
            {variableValues?.map((value, index) => (
                <td
                    key={index}
                    className={`border-l border-slate-300 px-1 py-1 text-right text-sm transition-all
                        dark:border-slate-600`}
                    colSpan={1}
                >
                    {modelVariable.data_type === ModelVariableDataType.Number
                        ? formatVariableValue(
                              modelVariable.unit,
                              value.numerical_value,
                              modelVariable.is_integer,
                          )
                        : value.boolean_value.toString()}
                </td>
            ))}
            {archetypeTimespan < maxTimespan && (
                <td
                    key={"placeholder"}
                    className={`border-l border-slate-300 px-1 py-1 text-right text-sm transition-all
                        dark:border-slate-600`}
                    colSpan={maxTimespan - archetypeTimespan}
                />
            )}
        </tr>
    );
};

const ArchetypeVariableDataTableRow = React.memo(
    ArchetypeVariableDataTableRowComponent,
);

const ArchetypeVariableAccordionTableRowComponent = ({
    label,
    handleSetDisplayMap,
    isExpanded,
    colSpan,
    children,
}: {
    label: string;
    handleSetDisplayMap: () => void;
    isExpanded: boolean;
    colSpan: number;
    children: React.ReactNode;
}) => {
    return (
        <tr
            className="border border-slate-300 bg-slate-200/80 transition-all hover:cursor-pointer
                hover:bg-slate-200 dark:border-slate-600 dark:bg-slate-700/80
                dark:hover:bg-slate-700"
            onClick={() => handleSetDisplayMap()}
        >
            <th
                className="group px-1 py-1 text-base font-semibold text-slate-700 transition-all
                    dark:text-slate-300"
                colSpan={colSpan}
                data-is-shown={isExpanded}
            >
                <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                        <ChevronRight className="h-4 w-4 transition-all group-data-[is-shown='true']:rotate-90" />
                        <span>{label}</span>
                    </div>
                    <div>{children}</div>
                </div>
            </th>
        </tr>
    );
};

const ArchetypeVariableAccordionTableRow = React.memo(
    ArchetypeVariableAccordionTableRowComponent,
);

type ComparisonTableProps = {
    maxTimespan: number;
    visibleArchetypes: InteractiveModuleArchetype[];
    archetypeTimespanMap: Record<string, number>;
    selectedModelBlock: ModelBlock;
    modelVariableDisplayMap: Record<string, boolean>;
    setModelVariableDisplayMap: (map: Record<string, boolean>) => void;
    chartVariablesMap: Record<string, boolean>;
    setChartVariablesMap?: (
        value: React.SetStateAction<Record<string, boolean>>,
    ) => void;
    archetypeValuesMap: Record<string, Record<string, VariableValue[]>>;
    archetypeModelBlocks: ModelBlock[];
    archetypeModelVariableMap: Record<string, ModelVariable[]>;
};

const ArchetypeComparisonTableComponent = ({
    maxTimespan,
    visibleArchetypes,
    archetypeTimespanMap,
    selectedModelBlock,
    modelVariableDisplayMap,
    setModelVariableDisplayMap,
    chartVariablesMap,
    setChartVariablesMap,
    archetypeValuesMap,
    archetypeModelBlocks,
    archetypeModelVariableMap,
}: ComparisonTableProps) => {
    const formatVariableValue = useFormatVariableValue();

    const blockColSpan = useMemo(() => {
        return 1 + maxTimespan;
    }, [maxTimespan]);

    const columnLabels = useMemo(() => {
        return !!maxTimespan
            ? [...Array(maxTimespan)]?.map((_, index) => (index + 1).toString())
            : [];
    }, [maxTimespan]);

    return (
        <CompactTableWrapper
            header={""}
            columnLabels={columnLabels}
            alignRight={true}
        >
            {!!archetypeModelVariableMap &&
                !!selectedModelBlock &&
                archetypeModelBlocks?.length > 0 &&
                archetypeModelBlocks
                    .filter(
                        (block) =>
                            !!archetypeModelVariableMap[block.id] &&
                            selectedModelBlock.id === block.id,
                    )
                    .map((block) => (
                        <React.Fragment key={block.id}>
                            {archetypeModelVariableMap[block.id].map(
                                (modelVariable) => (
                                    <React.Fragment key={modelVariable.id}>
                                        <ArchetypeVariableAccordionTableRow
                                            key={modelVariable.id}
                                            label={modelVariable.label}
                                            handleSetDisplayMap={() =>
                                                setModelVariableDisplayMap({
                                                    ...modelVariableDisplayMap,
                                                    [modelVariable.id]:
                                                        !modelVariableDisplayMap[
                                                            modelVariable.id
                                                        ],
                                                })
                                            }
                                            isExpanded={
                                                modelVariableDisplayMap[
                                                    modelVariable.id
                                                ]
                                            }
                                            colSpan={blockColSpan}
                                        >
                                            {!!setChartVariablesMap &&
                                                modelVariable.data_type ===
                                                    ModelVariableDataType.Number && (
                                                    <button
                                                        type="button"
                                                        className={`rounded-md border border-blue-600 p-1 ${
                                                            chartVariablesMap[
                                                                modelVariable.id
                                                            ]
                                                                ? "bg-blue-600 text-white dark:bg-blue-600 dark:text-white"
                                                                : "bg-slate-300 text-blue-600 dark:bg-slate-800 dark:text-blue-600"
                                                        }`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setChartVariablesMap(
                                                                {
                                                                    ...chartVariablesMap,
                                                                    [modelVariable.id]:
                                                                        !chartVariablesMap[
                                                                            modelVariable
                                                                                .id
                                                                        ],
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        <ChartBarIcon className="h-4 w-4" />
                                                    </button>
                                                )}
                                        </ArchetypeVariableAccordionTableRow>
                                        {!!modelVariableDisplayMap[
                                            modelVariable.id
                                        ] &&
                                            !!visibleArchetypes &&
                                            visibleArchetypes.map(
                                                (archetype, i) => (
                                                    <ArchetypeVariableDataTableRow
                                                        key={archetype.id}
                                                        archetypeLabel={
                                                            archetype.name
                                                        }
                                                        archetypeTimespan={
                                                            archetypeTimespanMap[
                                                                archetype.id
                                                            ]
                                                        }
                                                        modelVariable={
                                                            modelVariable
                                                        }
                                                        variableValues={
                                                            archetypeValuesMap[
                                                                archetype.id
                                                            ][
                                                                modelVariable.id
                                                            ] ?? []
                                                        }
                                                        index={i}
                                                        showResampleFunction={
                                                            true
                                                        }
                                                        formatVariableValue={
                                                            formatVariableValue
                                                        }
                                                        maxTimespan={
                                                            maxTimespan
                                                        }
                                                    />
                                                ),
                                            )}
                                    </React.Fragment>
                                ),
                            )}
                        </React.Fragment>
                    ))}
        </CompactTableWrapper>
    );
};

export const ArchetypeComparisonTable = React.memo(
    ArchetypeComparisonTableComponent,
);

type ComparisonSelectionMenuProps = {
    archetypes: InteractiveModuleArchetype[];
    archetypeTimespanMap: Record<string, number>;
    isBusy: boolean;
    setIsBusy: (busy: boolean) => void;
    handleSetArchetypeDisplayMap: (archetypeIds: string[]) => Promise<void>;
    isPending: boolean;
    archetypeDisplayMap: Record<string, boolean>;
};

const ArchetypeComparisonSelectionMenuComponent = ({
    archetypes,
    archetypeTimespanMap,
    isBusy,
    setIsBusy,
    handleSetArchetypeDisplayMap,
    isPending,
    archetypeDisplayMap,
}: ComparisonSelectionMenuProps) => {
    return (
        <div className="flex text-slate-700 dark:text-slate-300">
            <Listbox
                value={Object.keys(archetypeDisplayMap).filter(
                    (key) => !!archetypeDisplayMap[key],
                )}
                onChange={async (archetypeIds) => {
                    setIsBusy(true);
                    await handleSetArchetypeDisplayMap(archetypeIds);
                }}
                disabled={isPending || isBusy}
                multiple
            >
                <div
                    className="flex w-full items-center rounded-md border border-slate-200 bg-opacity-50
                        hover:border-slate-300 dark:border-slate-700 dark:hover:border-slate-600"
                >
                    <ListboxButton
                        className="h-full rounded-md bg-slate-100 bg-opacity-30 p-4 hover:bg-opacity-50
                            dark:bg-slate-700 dark:bg-opacity-30 dark:hover:bg-opacity-50"
                    >
                        <div className="text-md font-medium">Archetypes</div>
                    </ListboxButton>
                    {Object.keys(archetypeDisplayMap).filter(
                        (key) => !!archetypeDisplayMap[key],
                    )?.length > 0 && (
                        <span className="flex flex-wrap px-4 py-1 text-sm">
                            {archetypes
                                .filter(
                                    (archetype) =>
                                        !!archetypeDisplayMap[archetype.id],
                                )
                                .map((archetype) => (
                                    <span
                                        key={archetype.id}
                                        className="flex items-center"
                                    >
                                        <button
                                            type="button"
                                            className="m-1 flex items-center rounded-md bg-transparent p-1 text-center text-sm
                                                font-light text-slate-700 hover:text-slate-900 focus:outline-none focus:ring-0
                                                dark:text-slate-300 dark:hover:text-slate-100"
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                setIsBusy(true);
                                                await handleSetArchetypeDisplayMap(
                                                    Object.keys(
                                                        archetypeDisplayMap,
                                                    ).filter(
                                                        (key) =>
                                                            !!archetypeDisplayMap[
                                                                key
                                                            ] &&
                                                            key !==
                                                                archetype.id,
                                                    ),
                                                );
                                            }}
                                            disabled={isPending || isBusy}
                                        >
                                            {archetype.name}
                                            {` (${archetypeTimespanMap[archetype.id]} years)`}
                                            <XMarkIcon className="ml-2 h-5 w-5" />
                                        </button>
                                    </span>
                                ))}
                        </span>
                    )}
                </div>
                <ListboxOptions
                    anchor="bottom start"
                    className="[--anchor-gap:4px]"
                >
                    {archetypes.map((archetype) => (
                        <ListboxOption
                            key={archetype.id}
                            value={archetype.id}
                            className="group flex gap-3 bg-slate-900 p-2 text-sm text-white data-[focus]:bg-slate-700"
                            disabled={isPending || isBusy}
                        >
                            <CheckIcon className="invisible size-5 text-blue-600 group-data-[selected]:visible" />
                            {archetype.name}
                            {` (${archetypeTimespanMap[archetype.id]} years)`}
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </Listbox>
        </div>
    );
};

export const ArchetypeComparisonSelectionMenu = React.memo(
    ArchetypeComparisonSelectionMenuComponent,
);

const SmallComparisonTable = ({
    modelVariable,
    visibleArchetypes,
    archetypeValuesMap,
    formatVariableValue,
}: {
    modelVariable: ModelVariable;
    visibleArchetypes: InteractiveModuleArchetype[];
    archetypeValuesMap: Record<string, Record<string, VariableValue[]>>;
    formatVariableValue: VariableValueFormatFunction;
}) => {
    return (
        <div className="w-full px-4 pb-4">
            <table
                className="w-full table-auto border-0 border-slate-300 text-right text-sm text-slate-700
                    transition-all dark:border-slate-600 dark:text-slate-300"
            >
                <thead
                    className="border-b border-slate-300 text-xs font-normal uppercase text-slate-400
                        transition-all dark:border-slate-600 dark:text-slate-500"
                >
                    <tr>
                        <th></th>
                        <th>Initial</th>
                        <th>Final</th>
                    </tr>
                </thead>
                <tbody className="">
                    {visibleArchetypes
                        ?.filter(
                            (archetype) =>
                                !!archetypeValuesMap[archetype.id][
                                    modelVariable.id
                                ],
                        )
                        .map((archetype, i) => (
                            <tr key={archetype.id} className="">
                                <td className="text-left">
                                    {i == 0 ? archetype.name : "Archetype"}
                                </td>
                                <td className="font-semibold tracking-wide">
                                    {formatVariableValue(
                                        modelVariable.unit,
                                        archetypeValuesMap[archetype.id][
                                            modelVariable.id
                                        ][0]?.numerical_value,
                                        modelVariable.is_integer,
                                    )}
                                </td>
                                <td className="font-semibold tracking-wide">
                                    {formatVariableValue(
                                        modelVariable.unit,
                                        archetypeValuesMap[archetype.id][
                                            modelVariable.id
                                        ][
                                            archetypeValuesMap[archetype.id][
                                                modelVariable.id
                                            ]?.length - 1
                                        ]?.numerical_value,
                                        modelVariable.is_integer,
                                    )}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

type ComparisonChartProps = {
    maxTimespan: number;
    visibleArchetypes: InteractiveModuleArchetype[];
    chartVariablesMap: Record<string, boolean>;
    setChartVariablesMap?: (
        value: React.SetStateAction<Record<string, boolean>>,
    ) => void;
    archetypeValuesMap: Record<string, Record<string, VariableValue[]>>;
    archetypeModelBlocks: ModelBlock[];
    archetypeModelVariableMap: Record<string, ModelVariable[]>;
    showComparisonTable?: boolean;
};

const ArchetypeComparisonChartsComponent = ({
    maxTimespan,
    visibleArchetypes,
    chartVariablesMap,
    setChartVariablesMap,
    archetypeValuesMap,
    archetypeModelBlocks,
    archetypeModelVariableMap,
    showComparisonTable,
}: ComparisonChartProps) => {
    const formatVariableValue = useFormatVariableValue();

    const chartBlock = useMemo<ChartBlock>(() => {
        return {
            id: "",
            label: "",
            chart_type: InteractiveModuleChartType.LINE,
            time_index: maxTimespan,
            time_increment: "year",
            stacked: false,
            interpolation: "linear",
            model_block_id: "",
            variableIds:
                visibleArchetypes?.map((archetype, i) => archetype.id) ?? [],
            weight: 0,
        };
    }, [maxTimespan, visibleArchetypes]);

    const guidesChartValuesMap = useMemo<
        Record<string, Record<string, FormattedChartValue[]>>
    >(() => {
        return !!chartVariablesMap && !!archetypeModelVariableMap
            ? Object.values(archetypeModelVariableMap)
                  .reduce((array, variables) => [...array, ...variables], [])
                  .filter((variable) => chartVariablesMap[variable.id])
                  .reduce(
                      (map, variable) => ({
                          ...map,
                          [variable.id]: getChartValues(
                              visibleArchetypes?.map((archetype) => ({
                                  ...variable,
                                  id: archetype.id,
                                  label: archetype.name,
                              })) ?? [],
                              chartBlock,
                              visibleArchetypes?.reduce(
                                  (map, archetype) => ({
                                      ...map,
                                      [archetype.id]:
                                          archetypeValuesMap[archetype.id][
                                              variable.id
                                          ] ?? [],
                                  }),
                                  {},
                              ) ?? {},
                              formatVariableValue,
                          ),
                      }),
                      {},
                  )
            : {};
    }, [
        chartVariablesMap,
        archetypeModelVariableMap,
        visibleArchetypes,
        archetypeValuesMap,
    ]);

    return (
        !!guidesChartValuesMap &&
        Object.keys(guidesChartValuesMap)?.length > 0 && (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                {archetypeModelBlocks?.map((block) => (
                    <React.Fragment key={block.id}>
                        {archetypeModelVariableMap[block.id]
                            ?.filter(
                                (modelVariable) =>
                                    !!chartVariablesMap[modelVariable.id] &&
                                    !!guidesChartValuesMap[modelVariable.id],
                            )
                            .map((modelVariable) => (
                                <DashboardWidgetWrapper key={modelVariable.id}>
                                    <div className="relative">
                                        <ChartDisplay
                                            modelTimespan={maxTimespan}
                                            chartBlock={{
                                                ...chartBlock,
                                                label: modelVariable.label,
                                            }}
                                            chartValues={
                                                guidesChartValuesMap[
                                                    modelVariable.id
                                                ]
                                            }
                                            setChartTimeIndex={(
                                                timeIndex: number,
                                            ) => null}
                                        />
                                        {!!setChartVariablesMap && (
                                            <button
                                                type="button"
                                                className="absolute right-0 top-0 p-2 text-blue-600 opacity-15 hover:opacity-100"
                                                onClick={() =>
                                                    setChartVariablesMap({
                                                        ...chartVariablesMap,
                                                        [modelVariable.id]:
                                                            !chartVariablesMap[
                                                                modelVariable.id
                                                            ],
                                                    })
                                                }
                                            >
                                                <XMarkIcon className="h-5 w-5" />
                                            </button>
                                        )}
                                        {!!showComparisonTable && (
                                            <SmallComparisonTable
                                                {...{
                                                    visibleArchetypes,
                                                    modelVariable,
                                                    archetypeValuesMap,
                                                    formatVariableValue,
                                                }}
                                            />
                                        )}
                                    </div>
                                </DashboardWidgetWrapper>
                            ))}
                    </React.Fragment>
                ))}
            </div>
        )
    );
};

export const ArchetypeComparisonCharts = React.memo(
    ArchetypeComparisonChartsComponent,
);

export const useComparisonProperties = ({
    archetypes,
    archetypeTimespanMap,
    archetypeDisplayMap,
    archetypeValuesMap,
}: {
    archetypes: InteractiveModuleArchetype[];
    archetypeTimespanMap: Record<string, number>;
    archetypeDisplayMap: Record<string, boolean>;
    archetypeValuesMap: Record<string, Record<string, VariableValue[]>>;
}) => {
    const maxTimespan = useMemo(() => {
        const includedTimespanValues =
            !!archetypeTimespanMap &&
            !!archetypeDisplayMap &&
            Object.keys(archetypeDisplayMap)?.length > 0
                ? Object.keys(archetypeTimespanMap)
                      ?.filter((key) => !!archetypeDisplayMap[key])
                      .map((key) => archetypeTimespanMap[key])
                : [];
        return includedTimespanValues?.length > 0
            ? Math.max(...includedTimespanValues)
            : 0;
    }, [archetypeTimespanMap, archetypeDisplayMap]);

    const visibleArchetypes = useMemo(() => {
        return !!archetypes && !!archetypeDisplayMap && !!archetypeValuesMap
            ? archetypes.filter(
                  (archetype) =>
                      !!archetypeDisplayMap[archetype.id] &&
                      !!archetypeValuesMap[archetype.id] &&
                      !!Object.keys(archetypeValuesMap[archetype.id])?.length,
              )
            : [];
    }, [archetypeDisplayMap, archetypeValuesMap]);

    return { maxTimespan, visibleArchetypes };
};
