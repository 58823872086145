import React, { useEffect, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { SapienPageProps } from "@/inertia-utils/types";
import { DataSandboxProps } from "./types";
import { DataSandboxDetail } from "./DataSandboxDetail";
import { SandboxWrapper } from "./SandboxWrapper";
import { useDataDescription, useDataFile } from "./useDataSandbox";

type Props = SapienPageProps & DataSandboxProps;

const tabKeys = [
    "Data Overview",
    "Regression",
    "Cluster Analysis",
    "Forecasting",
    "AI Resources",
];

export default function DataSandbox({
    simulation,
    data,
    timeHorizons,
    variables,
    dataFile,
}: Props) {
    const { setDataDescription } = useDataDescription();
    const { setDataFile } = useDataFile();

    const [activeTab, setActiveTab] = useState(tabKeys[0]);

    useEffect(() => {
        setDataDescription(data);
        setDataFile(dataFile);
    }, [data, dataFile]);

    return (
        <SandboxWrapper
            simulationTitle={simulation?.title}
            simulationSlug={simulation?.slug}
            dataTitle={dataFile?.title || undefined}
        >
            <div className="flex w-full justify-center">
                <Tabs.Root
                    onValueChange={setActiveTab}
                    value={activeTab}
                    className="w-full"
                >
                    <Tabs.List
                        className="flex w-full justify-center border-b border-slate-300/75 bg-slate-200
                            bg-opacity-25 dark:border-slate-800 dark:bg-slate-950 dark:bg-opacity-50"
                    >
                        {tabKeys.map((tabKey, i) => (
                            <Tabs.Trigger
                                key={tabKey}
                                value={tabKey}
                                className={`text-slate-900 data-[state="active"]:border-b
                                data-[state="active"]:border-blue-600 data-[state="active"]:text-blue-700
                                dark:text-slate-300 dark:data-[state="active"]:border-blue-600
                                dark:data-[state="active"]:text-blue-500`}
                            >
                                <div
                                    className="px-1 py-2 text-xs font-light tracking-wide hover:bg-slate-300/25 sm:px-2
                                        sm:text-sm md:px-4 md:text-base dark:hover:bg-slate-800/25"
                                >
                                    {tabKey}
                                </div>
                            </Tabs.Trigger>
                        ))}
                    </Tabs.List>
                    <DataSandboxDetail
                        tabKeys={tabKeys}
                        data={data}
                        timeHorizons={timeHorizons}
                        variables={variables}
                        dataFile={dataFile}
                    />
                </Tabs.Root>
            </div>
        </SandboxWrapper>
    );
}
