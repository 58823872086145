import React, { useCallback, useMemo } from "react";
import { ModelBlock } from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";
import { FlattenedItem, ITreeItem, SortableTree } from "./SortableTree";

const buildItemTree = (modelBlocks: ModelBlock[]) => {
    return modelBlocks.map((modelBlock) => {
        return {
            id: modelBlock.id,
            label: modelBlock.label,
            children: modelBlock.modelBlocks?.length
                ? buildItemTree(modelBlock.modelBlocks)
                : [],
        };
    });
};

const flatten = (
    items: ITreeItem[],
    parentId: string | number | null = null,
    depth = 0,
): FlattenedItem[] => {
    return items.reduce<FlattenedItem[]>((acc, item, index) => {
        return [
            ...acc,
            { ...item, parentId, depth, index },
            ...flatten(item.children, item.id, depth + 1),
        ];
    }, []);
};

const flattenItemTreeIntoModelBlockArray = (
    items: ITreeItem[],
    originalModelBlocks: ModelBlock[],
): ModelBlock[] => {
    const flattenedItemMap: { [index: string]: FlattenedItem } = flatten(
        items,
    ).reduce((map, item) => {
        return { ...map, ...{ [item.id]: item } };
    }, {});

    return originalModelBlocks.map((modelBlock) => {
        const item = flattenedItemMap[modelBlock.id];
        return {
            ...modelBlock,
            weight: item.index,
            parent_model_block_id: item.parentId,
        } as ModelBlock;
    });
};

export const SortableModelBlockTree = ({
    modelBlocks,
    modelBlockArray,
    preventMixedLevelSort,
}: {
    modelBlocks: { [index: string]: ModelBlock };
    modelBlockArray: ModelBlock[];
    preventMixedLevelSort?: boolean;
}) => {
    const itemTree = useMemo(() => {
        return buildItemTree(
            Object.values(modelBlocks).filter(
                (modelBlock) => !modelBlock.has_connections,
            ),
        );
    }, [modelBlocks]);

    const reorganizeModelBlocks = async (modelBlocksToSave: ModelBlock[]) => {
        await SapienInertia.post(
            "model-builder.model-blocks.reorganize",
            {
                modelBlocks: modelBlocksToSave,
            } as any,
            {},
            { preserveScroll: true, only: ["modelBlocks"] },
        );
    };

    const handleTreeUpdate = useCallback(
        (itemTree: ITreeItem[]) => {
            const modelBlocksToSave = flattenItemTreeIntoModelBlockArray(
                itemTree,
                modelBlockArray.filter(
                    (modelBlock) => !modelBlock.has_connections,
                ),
            );

            if (preventMixedLevelSort) {
                const canReorganize = modelBlocksToSave.every((block) => {
                    return (
                        block.parent_model_block_id ==
                        modelBlockArray.find(
                            (modelBlock) => modelBlock.id === block.id,
                        )?.parent_model_block_id
                    );
                });
                if (canReorganize) {
                    reorganizeModelBlocks(modelBlocksToSave);
                } else {
                    reorganizeModelBlocks(modelBlockArray);
                }
            } else {
                reorganizeModelBlocks(modelBlocksToSave);
            }
        },
        [modelBlockArray, preventMixedLevelSort],
    );

    return (
        <SortableTree defaultItems={itemTree} handleUpdate={handleTreeUpdate} />
    );
};
