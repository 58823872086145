import React, { useMemo } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Link } from "@inertiajs/react";
import { sapienRoute, usePage } from "@/inertia-utils/hooks";
import { ContentBlockTemplateShape } from "@/models";
import { bigFlatten, useContentBlockMetadata } from "@/model-configs";
import ContentBlocksToWireframe from "./ContentBlocksToWireframe";

type Props = {
    template: ContentBlockTemplateShape;
    isSelected: boolean;
    handleClickAdd: (template: ContentBlockTemplateShape) => void;
    parentContentBlockId?: string;
    colorClasses?: {
        selected: string;
        unselected: string;
    };
    onClickDelete?: () => void;
};

export default function ContentBlockTemplateCard({
    template,
    isSelected,
    handleClickAdd,
    parentContentBlockId,
    colorClasses,
    onClickDelete,
}: Props) {
    const { url } = usePage();

    const defaultColorClasses = {
        selected: "bg-white shadow-md border-2 border-blue-600",
        unselected:
            "bg-white/80 border-2 border-white/80 hover:bg-white hover:border-white ",
    };

    const classes = colorClasses || defaultColorClasses;

    const { categories } = useContentBlockMetadata();
    const icon = useMemo(() => {
        return bigFlatten(categories).find(
            (category) =>
                category.blockOrLayoutType ===
                template.content_block?.content_block_type,
        )?.icon;
    }, [categories, template]);

    return (
        <div
            className={`relative flex h-full flex-col space-y-4 overflow-hidden rounded-md py-2
            shadow-lg ${isSelected ? classes.selected : classes.unselected}`}
            key={template.id}
        >
            <div
                key={template.id}
                role="button"
                className={
                    "flex h-full flex-col justify-start space-y-4 self-stretch pb-4 transition-all"
                }
                onClick={() => {
                    handleClickAdd(template);
                }}
            >
                <div className="overflow-hidden">
                    <ContentBlocksToWireframe
                        contentBlock={template.content_block}
                    />
                </div>
                <div className="flex flex-col space-y-1 px-4">
                    <p className=" text-sm">{template.label}</p>
                    <p className="text-xs ">{template.description}</p>
                    <ul className="flex max-w-sm space-x-1 p-0">
                        {template.tags.map((tag) => (
                            <li
                                key={tag.id}
                                className="list-none rounded-md bg-blue-600 px-2 py-1 text-xs text-white"
                            >
                                {tag.name.en}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="absolute bottom-2 right-2 flex gap-2">
                <Link
                    href={
                        sapienRoute("templates.edit", {
                            contentBlockId: template.id,
                        }) + `?returnTo=${url}`
                    }
                    className="rounded-full bg-blue-600 p-2 text-gray-200 shadow-md hover:text-white
                        hover:shadow-lg"
                >
                    <PencilIcon className="h-4 w-4 " />
                </Link>
                {typeof onClickDelete === "function" && (
                    <button
                        className="rounded-full bg-red-500 p-2 text-gray-200 shadow-md hover:text-white
                            hover:shadow-lg"
                        onClick={onClickDelete}
                    >
                        <TrashIcon className="h-4 w-4 " />
                    </button>
                )}
            </div>
        </div>
    );
}
