import React, { useState } from "react";
import { sapienAxios } from "@/inertia-utils/hooks";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { UserShape } from "@/models";
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
} from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import { ModalContainer } from "@/modules/shared";

type Props = {
    roles: { id: number; name: string }[];
    role?: { id: number; name: string };
    users: UserShape[];
};

export const ReusableMagicLinkForm = ({ roles, role, users }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserShape | null>(null);
    const [query, setQuery] = useState("");
    const [sent, setSent] = useState(false);
    const filteredUsers = users.filter(
        (user) =>
            !query ||
            user.name.toLowerCase().includes(query.toLowerCase()) ||
            user.email.toLowerCase().includes(query.toLowerCase()),
    );

    const { mutate, isPending } = useMutation({
        mutationFn: () => {
            const data = sapienAxios.post(
                "passwordless-login.reusable.admin.store",
                {
                    email: selectedUser?.email,
                },
            );
            return data;
        },
        onSuccess: () => {
            setSent(true);
        },
    });

    return (
        <>
            <button
                className="inline-flex items-center gap-2 rounded-md bg-indigo-600 px-4 py-2 text-sm
                    font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpen(true)}
            >
                <EnvelopeIcon className="h-6 w-6" />
                Send a Reusable Magic Link
            </button>
            <ModalContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                size="m"
            >
                <div className="rounded-md border border-slate-100/50 bg-white/75 p-3">
                    <div className="flex flex-col gap-4">
                        <div className="flex min-w-60 items-center space-x-3">
                            <EnvelopeIcon className="h-6 w-6" />
                            <h2 className="text-nowrap text-base font-semibold leading-6 text-slate-900">
                                Send a Reusable Magic Link
                            </h2>
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                mutate();
                            }}
                            className="flex w-full flex-col gap-4"
                        >
                            <div className="relative col-span-2 w-full">
                                <Combobox
                                    value={
                                        !selectedUser
                                            ? ""
                                            : `${selectedUser?.name} | ${selectedUser?.email}`
                                    }
                                    onChange={(id) => {
                                        const user = users.find(
                                            (user) => user.id === id,
                                        );
                                        if (!user) return;
                                        setSelectedUser(user);
                                        setQuery(
                                            `${user.name} | ${user.email}`,
                                        );
                                    }}
                                >
                                    <ComboboxInput
                                        onChange={(e) =>
                                            setQuery(e.target.value)
                                        }
                                        value={query}
                                        className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-900
                                            ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                            focus:ring-inset focus:ring-indigo-600"
                                        placeholder="Select a user"
                                        autoComplete={"off"}
                                    />
                                    <ComboboxOptions
                                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-2
                                            text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
                                            sm:text-sm"
                                    >
                                        {filteredUsers.map((user) => (
                                            <ComboboxOption
                                                key={user.id}
                                                value={user.id}
                                                className="relative flex cursor-pointer select-none flex-row items-center justify-between
                                                    rounded-sm py-2 pl-3 pr-9 hover:bg-sapien-blue hover:text-white"
                                            >
                                                <span className="block truncate">
                                                    {user.name}
                                                </span>
                                                <span>{user.email}</span>
                                            </ComboboxOption>
                                        ))}
                                    </ComboboxOptions>
                                </Combobox>
                            </div>
                            <div className="flex items-center justify-end gap-2">
                                <button
                                    className="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                        hover:bg-red-700 focus-visible:outline focus-visible:outline-2
                                        focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsModalOpen(false);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    data-sending={isPending}
                                    disabled={!selectedUser || isPending}
                                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                        data-[sending=true]:animate-pulse hover:bg-indigo-700 focus-visible:outline
                                        focus-visible:outline-2 focus-visible:outline-offset-2
                                        focus-visible:outline-indigo-600 disabled:cursor-not-allowed"
                                >
                                    <span>Send</span>
                                </button>
                                {sent && (
                                    <span className="text-sm text-green-500">
                                        Email Sent!
                                    </span>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};
