import React from "react";
import { WithId } from "./WithId";
import { SapienPageProps } from "@/inertia-utils/types";
import Authenticated from "@/Layouts/Authenticated";
import { Head, WhenVisible } from "@inertiajs/react";
import ForumSideNav from "./ForumSideNav";
import QueryClient from "../ELearning/QueryClient/QueryClient";
import ThreadPreviewCard from "./ThreadPreviewCard";

type Props = {
    category: App.Data.Forum.CategoryData;
    threads: App.Data.PaginatedThreadsData;
    categories: WithId<App.Data.Forum.CategoryData>[];
} & SapienPageProps;

export default function ThreadsByCategory({
    auth,
    threads,
    category,
    impersonating_user,
    categories,
}: Props) {
    return (
        <Authenticated
            errors={[]}
            impersonatingUser={impersonating_user}
        >
            <QueryClient>
                <Head title="Threads" />{" "}
                <div className="flex w-full justify-center">
                    <ForumSideNav categories={categories} />
                    <div className="flex w-full flex-col items-center space-y-2 py-8">
                        <div className="flex max-w-4xl flex-col items-center py-4">
                            <h1 className="p-4 text-2xl font-bold w-full" data-testid="category-title">
                                {category.title}
                            </h1>
                            <div className="flex flex-col space-y-2">
                                {threads.data.map((thread) => (
                                    <ThreadPreviewCard
                                        key={thread.id}
                                        thread={thread}
                                    />
                                ))}
                                <WhenVisible
                                    children={<></>}
                                    fallback={"loading"}
                                    data={["threads"]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </QueryClient>
        </Authenticated>
    );
}
