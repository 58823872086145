import React, { useState } from "react";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { ContentBlockTemplateShape } from "@/models";
import { AdminContainer } from "@/Layouts/admin";
import {
    AdminPageHeader,
    AdminPageSection,
} from "@/components/admin-components";
import ContentBlockTemplateCard from "@/Pages/Admin/editor/content-block-templates/ContentBlockTemplateCard";
import ConfirmDeleteTemplateModal from "./ConfirmDeleteTemplateModal";
import { ProjectDashboardTopNav } from "@/Layouts/admin/ProjectDashboardTopNav";

type Props = SapienAdminPageProps & {
    templates: ContentBlockTemplateShape[];
};

export default function ContentBlockTemplateList({ templates }: Props) {
    const [templateToDelete, setTemplateToDelete] =
        useState<ContentBlockTemplateShape>();
    return (
        <AdminContainer headTitle={"Templates"}>
            <AdminPageHeader>
                <ProjectDashboardTopNav />
            </AdminPageHeader>
            <AdminPageSection>
                <div className="flex flex-col space-y-4">
                    <div className="flex min-h-9 flex-row items-center justify-between">
                        <span className="text-xl font-normal">Templates</span>
                    </div>
                    <div className="@container">
                        <div className="grid gap-4 bg-slate-100 @sm:grid-cols-1 @md:grid-cols-2 @lg:grid-cols-3">
                            {templates.map((template) => (
                                <div
                                    className="relative rounded-md"
                                    key={template.id}
                                >
                                    <ContentBlockTemplateCard
                                        template={template}
                                        isSelected={false}
                                        handleClickAdd={function () {}}
                                        colorClasses={{
                                            selected:
                                                "shadow-md bg-white h-full",
                                            unselected:
                                                "bg-white/75 shadow-sm hover:shadow-md hover:bg-white h-full",
                                        }}
                                        onClickDelete={() => {
                                            setTemplateToDelete(template);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </AdminPageSection>{" "}
            <ConfirmDeleteTemplateModal
                template={templateToDelete}
                cancelDelete={() => {
                    setTemplateToDelete(undefined);
                }}
            />
        </AdminContainer>
    );
}
