import React from "react";
import { useInitAuthFromInertiaProps } from "./hooks";

export default function InitFromProps({
    children,
}: {
    children: React.ReactNode;
}) {
    useInitAuthFromInertiaProps();
    return <div>{children}</div>;
}
