import { ParticipantShape, SimulationShape, UserShape } from "@/models";
import React, { useState } from "react";
import { RoleSection } from "./RoleSection";

interface ParticipantFilterProps {
    simulations: (SimulationShape & { is_course: boolean })[];
    onFilterChange?: (
        simulationId: string | null,
        cohortId: string | null,
    ) => void;
    isLoading: boolean;
}

const ParticipantFilter: React.FC<ParticipantFilterProps> = ({
    simulations,
    onFilterChange,
    isLoading,
}) => {
    const [selectedSimulation, setSelectedSimulation] = useState<string | null>(
        null,
    );
    const [selectedCohort, setSelectedCohort] = useState<string | null>(null);

    const handleSimulationChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const simulationId = event.target.value || null;
        setSelectedSimulation(simulationId);
        setSelectedCohort(null); // Reset cohort when simulation changes
        onFilterChange(simulationId, null);
    };

    const handleCohortChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const cohortId = event.target.value || null;
        setSelectedCohort(cohortId);
        onFilterChange(selectedSimulation, cohortId);
    };

    const cohorts = selectedSimulation
        ? simulations.find((sim) => sim.id === selectedSimulation)?.cohorts ||
          []
        : [];

    return (
        <div className="grid w-full grid-cols-2 gap-4 px-2 text-sm">
            <div className="flex flex-col gap-2">
                <label htmlFor="simulationDropdown" className="font-medium">
                    Simulation or Course
                </label>
                <select
                    id="simulationDropdown"
                    value={selectedSimulation || ""}
                    onChange={handleSimulationChange}
                    disabled={isLoading}
                    className="block w-full rounded-md border-0 bg-slate-50 px-2 py-1.5 text-sm leading-6
                        text-slate-700 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400
                        hover:bg-white/75 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                >
                    <option value="">-- Select Simulation or Course --</option>
                    <optgroup label={"Simulations"}>
                        {simulations
                            .filter((sim) => !sim.is_course)
                            .map((sim) => (
                                <option key={sim.id} value={sim.id}>
                                    {sim.title}
                                </option>
                            ))}
                    </optgroup>
                    <optgroup label={"Courses"}>
                        {simulations
                            .filter((sim) => sim.is_course)
                            .map((sim) => (
                                <option key={sim.id} value={sim.id}>
                                    {sim.title}
                                </option>
                            ))}
                    </optgroup>
                </select>
            </div>
            <div className="flex flex-col gap-2">
                <label htmlFor="cohortDropdown" className="font-medium">
                    Cohort
                </label>
                <select
                    id="cohortDropdown"
                    value={selectedCohort || ""}
                    onChange={handleCohortChange}
                    disabled={!selectedSimulation || isLoading}
                    className="block w-full rounded-md border-0 bg-slate-50 px-2 py-1.5 text-sm leading-6
                        text-slate-700 ring-1 ring-inset ring-slate-300 placeholder:text-slate-400
                        hover:bg-white/75 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                >
                    <option value="">-- Select Cohort --</option>
                    {cohorts.map((cohort) => (
                        <option key={cohort.id} value={cohort.id}>
                            {cohort.title}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

type Props = {
    roles: { id: number; name: string }[];
    users: UserShape[];
    simulations: (SimulationShape & { is_course: boolean })[];

    onFilterChange?: (
        simulationId: string | null,
        cohortId: string | null,
    ) => void;
};

export const UserEnrollment: React.FC<Props> = ({
    roles,
    users,
    simulations,
    onFilterChange,
}) => {
    const [filteredParticipants, setFilteredParticipants] = useState<
        Partial<ParticipantShape[]>
    >(users as Partial<ParticipantShape[]>);
    const [isLoading, setIsLoading] = useState(false);

    const handleFilterChange = (
        simulationId: string | null,
        cohortId: string | null,
    ) => {
        setIsLoading(true);
        // Mock API call
        setTimeout(() => {
            const filtered = users?.filter(
                (participant) => (simulationId && cohortId ? true : true), // to do
            );
            setFilteredParticipants(filtered as ParticipantShape[]);
            setIsLoading(false);
        }, 1000);
    };

    return (
        <div className="space-y-6">
            <div className="flex flex-col gap-4 rounded-md bg-slate-200 p-4">
                <h3 className="text-base font-semibold text-slate-600">{`Demo Filter and Search Feature`}</h3>
                <ParticipantFilter
                    simulations={simulations}
                    onFilterChange={handleFilterChange}
                    isLoading={isLoading}
                />
            </div>
            <div>
                {filteredParticipants?.length > 0 && (
                    <RoleSection
                        role={"Participant"}
                        users={filteredParticipants}
                        roles={roles}
                        userEmails={[]}
                        handleSetUserEmails={(email: string) => null}
                    />
                )}
            </div>
        </div>
    );
};
