import React, { useEffect } from "react";
import { RoundShape, TeamWithProgressData } from "@/models";
import { useAddableParticipants } from "@/hooks";
import { UserInvitationForm } from "@/components/user-management/UserInvitationForm";
import { AddUserToTeam } from "./AddUserToTeam";
import { CreateEphemeralParticipantForm } from "@/components/user-management/CreateEphemeralParticipantForm";

export const TeamParticipantForm = (props: {
    team: TeamWithProgressData;
    exit: () => void;
    rounds: RoundShape[];
}) => {
    const { team, exit, rounds } = props;

    const { addableParticipants, getParticipantsToAdd } =
        useAddableParticipants();

    useEffect(() => {
        getParticipantsToAdd(team.cohort_id);
    }, []);

    return (
        <div className="flex gap-2">
            <UserInvitationForm role={{ name: "Participant" }} team={team} />
            <AddUserToTeam
                team={team}
                participants={addableParticipants}
                roundId={rounds[0]?.id}
            />
            <CreateEphemeralParticipantForm
                team={team}
                roundId={rounds[0]?.id}
            />
            <button
                type="button"
                className="mr-2 rounded-lg border-2 border-red-600 px-5 py-2.5 text-sm font-medium
                    text-red-600 hover:bg-red-800 hover:bg-opacity-5 focus:outline-none focus:ring-4
                    focus:ring-red-300"
                onClick={(e) => {
                    exit();
                }}
            >
                Cancel
            </button>
        </div>
    );
};
