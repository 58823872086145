import { useDebounce } from "@/hooks";
import { sapienAxios } from "@/inertia-utils/hooks";
import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { SearchIcon } from "lucide-react";
import { Tabs } from "@/components/Tabs";
import { TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import PostSearchResult from "./PostSearchResult";
import ThreadSearchResult from "./ThreadSearchResult";
import CategorySearchResult from "./CategorySearchResult";
import EmptyState from "./EmptyState";
import ReactDOM from "react-dom";
import { useKeyBinding } from "@/hooks/useKeyBinding";

type Props = {};

export default function SearchBar({}: Props) {
    const [searchText, setSearchText] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const searchTerm = useDebounce(searchText, 200);
    useKeyBinding("Escape", () => setModalIsOpen(false));

    const { data, isPending } = useQuery({
        queryKey: ["forum.search", searchTerm],
        queryFn: async () => {
            console.log("CALLED", searchTerm);
            const { data } = await sapienAxios.post<{
                searchResults: {
                    threads: App.Data.Forum.ThreadSearchResultData[];
                    posts: App.Data.Forum.PostSearchResultData[];
                    categories: App.Data.Forum.CategorySearchResultData[];
                };
            }>("forum.search", { searchTerm });
            console.log("asdf", data);
            return data.searchResults;
        },

        initialData: {
            threads: [],
            posts: [],
            categories: [],
        },
    });

    return (
        <>
            <div className="relative flex w-full max-w-4xl flex-col items-start justify-center">
                <input
                    className="w-full rounded-lg border border-gray-200 p-2 pl-10"
                    placeholder="Search the forum"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    autoComplete={"off"}
                    onFocus={() => setModalIsOpen(true)}
                />
                <SearchIcon className="absolute left-3 top-3 h-5 w-5 text-gray-400" />

                {ReactDOM.createPortal(
                    <>
                        <div
                            role="button"
                            className="fixed inset-0 z-[99998] bg-[#0003] backdrop-blur data-[isopen=true]:block
                                data-[isopen=false]:hidden"
                            data-isopen={modalIsOpen}
                            onClick={() => setModalIsOpen(false)}
                        ></div>
                        <div
                            className="data-[isopen=false]-top-32 fixed left-1/2 top-[10vh] z-[99999] flex max-h-[90vh]
                                min-h-64 w-full max-w-4xl flex-col items-center justify-start overflow-auto
                                rounded-md bg-white shadow-[0_10px_15px_-3px_#0000001a,0_4px_6px_-4px_#0000001a]
                                transition-all -translate-x-1/2 data-[isopen=false]:pointer-events-none
                                data-[isopen=false]:opacity-0 data-[isopen=true]:opacity-100
                                data-[isopen=false]:-translate-y-[200%]"
                            data-isopen={modalIsOpen}
                        >
                            <div className="relative flex w-full flex-col justify-start">
                                <Combobox>
                                    {!!modalIsOpen && (
                                        <ComboboxInput
                                            className="w-full rounded-none border-b border-gray-100 p-4 pl-10 outline-none ring-0
                                                focus:border-b focus:border-gray-100 focus:outline-none focus:ring-0"
                                            placeholder="Search the forum"
                                            value={searchText}
                                            onChange={(e) =>
                                                setSearchText(e.target.value)
                                            }
                                            autoComplete={"off"}
                                            autoFocus
                                        />
                                    )}
                                    <SearchIcon className="absolute left-3 top-5 h-5 w-5 text-gray-400" />
                                    <button
                                        onClick={() => setModalIsOpen(false)}
                                        className="absolute right-3 top-3 rounded-md border p-2 text-gray-400
                                            hover:bg-gray-100 text-center text-sm"
                                        aria-label="Close search"
                                    >
                                        ESC
                                    </button>
                                    <ComboboxOptions
                                        className="flex w-full flex-col space-y-2 bg-white py-4 data-[ispending=true]:animate-pulse"
                                        data-ispending={isPending}
                                    >
                                        <Tabs defaultValue="categories">
                                            <TabsList className="px-4 pb-4 shadow">
                                                <TabsTrigger
                                                    value={`categories`}
                                                    className="ring-offset-background focus-visible:ring-ring
                                                        data-[state=active]:text-foreground inline-flex items-center justify-center
                                                        gap-2 whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium
                                                        transition-all data-[state=active]:bg-gray-100 data-[state=active]:shadow-sm
                                                        focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
                                                        disabled:pointer-events-none disabled:opacity-50"
                                                >
                                                    <span>Categories</span>
                                                    <span
                                                        className="inline-flex items-center rounded-full bg-blue-100 px-1.5 py-0.5 text-xs
                                                            font-medium text-gray-600"
                                                    >
                                                        {data.categories
                                                            ?.length || 0}
                                                    </span>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value={`threads`}
                                                    className="ring-offset-background focus-visible:ring-ring
                                                        data-[state=active]:text-foreground inline-flex items-center justify-center
                                                        gap-2 whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium
                                                        transition-all data-[state=active]:bg-gray-100 data-[state=active]:shadow-sm
                                                        focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
                                                        disabled:pointer-events-none disabled:opacity-50"
                                                >
                                                    <span>Threads</span>
                                                    <span
                                                        className="inline-flex items-center rounded-full bg-blue-100 px-1.5 py-0.5 text-xs
                                                            font-medium text-gray-600"
                                                    >
                                                        {data.threads?.length ||
                                                            0}
                                                    </span>
                                                </TabsTrigger>
                                                <TabsTrigger
                                                    value={`posts`}
                                                    className="ring-offset-background focus-visible:ring-ring
                                                        data-[state=active]:text-foreground inline-flex items-center justify-center
                                                        gap-2 whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium
                                                        transition-all data-[state=active]:bg-gray-100 data-[state=active]:shadow-sm
                                                        focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
                                                        disabled:pointer-events-none disabled:opacity-50"
                                                >
                                                    <span>Posts</span>
                                                    <span
                                                        className="inline-flex items-center rounded-full bg-blue-100 px-1.5 py-0.5 text-xs
                                                            font-medium text-gray-600"
                                                    >
                                                        {data.posts?.length ||
                                                            0}
                                                    </span>
                                                </TabsTrigger>
                                            </TabsList>
                                            <div
                                                className="max-h-[70vh] overflow-auto scrollbar scrollbar-thumb-gray-500
                                                    scrollbar-thumb-rounded-full scrollbar-w-2"
                                            >
                                                <div className="px-4">
                                                    <TabsContent value="categories">
                                                        {!data.categories
                                                            ?.length ? (
                                                            <EmptyState
                                                                message={
                                                                    "No matching categories found"
                                                                }
                                                            />
                                                        ) : (
                                                            <ul className="mt-6 flex flex-col gap-y-4 px-0">
                                                                {data?.categories?.map(
                                                                    (
                                                                        result,
                                                                    ) => (
                                                                        <CategorySearchResult
                                                                            key={
                                                                                result.id
                                                                            }
                                                                            categorySearchResult={
                                                                                result
                                                                            }
                                                                            searchString={
                                                                                searchTerm
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                            </ul>
                                                        )}
                                                    </TabsContent>{" "}
                                                    <TabsContent value="threads">
                                                        {!data.threads
                                                            ?.length ? (
                                                            <EmptyState
                                                                message={
                                                                    "No matching threads found"
                                                                }
                                                            />
                                                        ) : (
                                                            <ul className="mt-6 flex flex-col gap-y-4 px-0">
                                                                {data?.threads?.map(
                                                                    (
                                                                        result,
                                                                    ) => (
                                                                        <ThreadSearchResult
                                                                            key={
                                                                                result.id
                                                                            }
                                                                            threadSearchResult={
                                                                                result
                                                                            }
                                                                            searchString={
                                                                                searchTerm
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                            </ul>
                                                        )}
                                                    </TabsContent>
                                                    <TabsContent value="posts">
                                                        {!data.posts?.length ? (
                                                            <EmptyState
                                                                message={
                                                                    "No matching posts found"
                                                                }
                                                            />
                                                        ) : (
                                                            <ul className="mt-6 flex flex-col gap-y-4 px-0">
                                                                {data?.posts?.map(
                                                                    (
                                                                        result,
                                                                    ) => (
                                                                        <PostSearchResult
                                                                            key={
                                                                                result.id
                                                                            }
                                                                            postSearchResult={
                                                                                result
                                                                            }
                                                                        />
                                                                    ),
                                                                )}
                                                            </ul>
                                                        )}
                                                    </TabsContent>
                                                </div>
                                            </div>
                                        </Tabs>
                                    </ComboboxOptions>
                                </Combobox>
                            </div>
                            {/* {((!isPending &&
                        !data.categories?.length &&
                        !data.posts?.length &&
                        !data.threads?.length) ||
                        !searchText.length) && (
                        <div className="flex h-64 w-full items-center justify-center">
                            <p className="text-gray-400">No results</p>
                        </div>
                    )} */}
                        </div>
                    </>,
                    document.body,
                )}
            </div>
        </>
    );
}
