import React, { useCallback, useEffect, useState } from "react";
import {
    useArchetypeModelBlocks,
    useArchetypeModelVariableMap,
    useArchetypes,
    useArchetypeTimespanMap,
} from "../state";
import {
    ArchetypeComparisonCharts,
    ArchetypeComparisonSelectionMenu,
    ArchetypeComparisonTable,
    CollapseAllControlButton,
    ModelBlockCarouselMenu,
    useComparisonProperties,
} from "../shared";
import {
    useArchetypeValuesMap,
    useInterfaceStateGuides,
} from "../useInterfaceState";
import { useArchetypeDisplayMapChangeMutation } from "../useCalculateInteractiveModel";

const ArchetypeComparisonComponent = () => {
    const {
        selectedGuidesModelBlock,
        setSelectedGuidesModelBlock,
        modelVariableDisplayMapGuides,
        setModelVariableDisplayMapGuides,
        archetypeDisplayMapGuides,
        setArchetypeDisplayMapGuides,
        guidesChartVariablesMap,
        setGuidesChartVariablesMap,
        hasOpenGuidesVariables,
    } = useInterfaceStateGuides();
    const { mutateAsync, isPending } = useArchetypeDisplayMapChangeMutation();
    const archetypes = useArchetypes();
    const archetypeTimespanMap = useArchetypeTimespanMap();
    const archetypeValuesMap = useArchetypeValuesMap();
    const archetypeModelBlocks = useArchetypeModelBlocks();
    const archetypeModelVariableMap = useArchetypeModelVariableMap();

    const [isBusy, setIsBusy] = useState(false);

    const handleSetArchetypeDisplayMapGuides = useCallback(
        async (archetypeIds: string[]) => {
            const unselectedIds = Object.keys(archetypeDisplayMapGuides).filter(
                (id) => !archetypeIds.includes(id),
            );
            const newSelected = archetypeIds.filter(
                (id) => !archetypeDisplayMapGuides[id],
            );
            const newArchetypeDisplayMapGuides = {
                ...unselectedIds.reduce(
                    (map, id) => ({ ...map, [id]: false }),
                    {},
                ),
                ...archetypeIds.reduce(
                    (map, id) => ({ ...map, [id]: true }),
                    {},
                ),
            };

            if (
                newSelected?.length > 0 &&
                !!archetypeValuesMap &&
                (!archetypeValuesMap[newSelected[0]] ||
                    !Object.keys(archetypeValuesMap[newSelected[0]])?.length)
            ) {
                await mutateAsync(newSelected[0]);
            }

            setArchetypeDisplayMapGuides(newArchetypeDisplayMapGuides);
            setIsBusy(false);
        },
        [archetypeDisplayMapGuides, archetypeValuesMap],
    );

    useEffect(() => {
        if (archetypeModelBlocks?.length > 0 && !selectedGuidesModelBlock) {
            setSelectedGuidesModelBlock(archetypeModelBlocks[0]);
        }
    }, [archetypeModelBlocks]);

    useEffect(() => {
        if (!Object.keys(modelVariableDisplayMapGuides)?.length) {
            setModelVariableDisplayMapGuides(
                Object.values(archetypeModelVariableMap)?.reduce(
                    (map, variableArray) => ({
                        ...map,
                        ...variableArray.reduce(
                            (variableMap, variable) => ({
                                ...variableMap,
                                [variable.id]: true,
                            }),
                            {},
                        ),
                    }),
                    {},
                ) || {},
            );
        }
    }, [archetypeModelVariableMap]);

    useEffect(() => {
        if (!Object.keys(guidesChartVariablesMap)?.length) {
            setGuidesChartVariablesMap(
                Object.values(archetypeModelVariableMap)?.reduce(
                    (map, variableArray) => ({
                        ...map,
                        ...variableArray.reduce(
                            (variableMap, variable) => ({
                                ...variableMap,
                                [variable.id]: false,
                            }),
                            {},
                        ),
                    }),
                    {},
                ) || {},
            );
        }
    }, [archetypeModelVariableMap]);

    useEffect(() => {
        if (
            archetypes?.length > 0 &&
            Object.keys(archetypeDisplayMapGuides)?.length !== archetypes.length
        ) {
            const archetypesMissingKeys = archetypes?.filter(
                (archetype) =>
                    archetypeDisplayMapGuides[archetype.id] === undefined,
            );
            if (archetypesMissingKeys?.length > 0) {
                setArchetypeDisplayMapGuides({
                    ...archetypeDisplayMapGuides,
                    ...archetypesMissingKeys.reduce(
                        (map, archetype) => ({
                            ...map,
                            [archetype.id]:
                                Object.keys(archetypeDisplayMapGuides)
                                    ?.length == 0 && archetype.is_default
                                    ? true
                                    : false,
                        }),
                        {},
                    ),
                });
            }
        }
    }, [archetypes]);

    const { maxTimespan, visibleArchetypes } = useComparisonProperties({
        archetypes,
        archetypeTimespanMap,
        archetypeDisplayMap: archetypeDisplayMapGuides,
        archetypeValuesMap,
    });

    return (
        <>
            {archetypes?.length > 0 && !!archetypeDisplayMapGuides && (
                <ArchetypeComparisonSelectionMenu
                    {...{
                        archetypes,
                        archetypeTimespanMap,
                        isBusy,
                        setIsBusy,
                        handleSetArchetypeDisplayMap:
                            handleSetArchetypeDisplayMapGuides,
                        isPending,
                        archetypeDisplayMap: archetypeDisplayMapGuides,
                    }}
                />
            )}

            {!!guidesChartVariablesMap &&
                !!archetypeModelVariableMap &&
                !!archetypeModelBlocks && (
                    <ArchetypeComparisonCharts
                        {...{
                            maxTimespan,
                            visibleArchetypes,
                            chartVariablesMap: guidesChartVariablesMap,
                            setChartVariablesMap: setGuidesChartVariablesMap,
                            archetypeValuesMap,
                            archetypeModelBlocks,
                            archetypeModelVariableMap,
                        }}
                    />
                )}

            {!!selectedGuidesModelBlock && archetypeModelBlocks?.length > 0 && (
                <div
                    className="flex w-full items-center justify-between text-slate-700 transition-all
                        dark:text-slate-300"
                >
                    <span></span>
                    <div className="w-full">
                        <ModelBlockCarouselMenu
                            selectedModelBlock={selectedGuidesModelBlock}
                            setSelectedModelBlock={setSelectedGuidesModelBlock}
                            modelBlocks={archetypeModelBlocks}
                        />
                    </div>
                    <CollapseAllControlButton
                        allAreCollapsed={!hasOpenGuidesVariables}
                        setAllAreCollapsed={(hasOpen) => {
                            setModelVariableDisplayMapGuides(
                                Object.keys(
                                    modelVariableDisplayMapGuides,
                                ).reduce(
                                    (map, key) => ({
                                        ...map,
                                        [key]: !hasOpen,
                                    }),
                                    {},
                                ),
                            );
                        }}
                    />
                </div>
            )}

            <ArchetypeComparisonTable
                {...{
                    maxTimespan,
                    visibleArchetypes,
                    archetypeTimespanMap,
                    selectedModelBlock: selectedGuidesModelBlock,
                    modelVariableDisplayMap: modelVariableDisplayMapGuides,
                    setModelVariableDisplayMap:
                        setModelVariableDisplayMapGuides,
                    chartVariablesMap: guidesChartVariablesMap,
                    setChartVariablesMap: setGuidesChartVariablesMap,
                    archetypeValuesMap,
                    archetypeModelBlocks,
                    archetypeModelVariableMap,
                }}
            />
        </>
    );
};

export const ArchetypeComparison = React.memo(ArchetypeComparisonComponent);
