import React from "react";
import { Button } from "@/components";
import { Participant } from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";

// const StyledSelect = styled.select`
//     width: auto;
//     max-width: 250px;
//     padding: 0.5rem 2.25rem 0.5rem 0.75rem;
//     font-size: 0.875rem;
//     line-height: 1rem;
//     box-sizing: border-box;
//     border-radius: 0.25rem;
//     :focus {
//         outline: none;
//         box-shadow: none;
//     }
// `;

export const ParticipantImpersonateButton = (props: {
    participant: Participant;
    simulationSlug: string;
}) => {
    const { participant, simulationSlug } = props;

    return (
        <div>
            <Button
                onClick={(e) => {
                    e.preventDefault();
                    SapienInertia.post("participant.impersonate", {
                        participant_email: participant.email,
                        simulation_slug: simulationSlug,
                    });
                }}
            >
                Impersonate
            </Button>
        </div>
    );
};
