import React, { useEffect, useRef } from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ModelVariable, VariableValue } from "@/models";
import { InteractiveSectionSettings } from "./settings";
import { InteractiveSectionData } from "./data";
import { InteractiveSectionDashboard } from "./dashboard";
import { InteractiveSectionDesign } from "./design";
import { InteractiveSectionInsights } from "./insights";
import { InteractiveSectionGuides } from "./guides";
import { InteractiveSectionArchetypes } from "./archetypes";

import {
    FavoritesDrawerDisplay,
    InteractiveSectionFavorites,
} from "./favorites";
import { BottomDrawerWrapper, CenterPanelPageWrapper } from "./shared";
import {
    InputDataItem,
    InteractiveModelBlock,
    InteractivePageSection,
} from "./types";
import { useGetArchetypeValues } from "./useCalculateInteractiveModel";
import {
    useHandleArchetypeValuesMap,
    useHandleChartAndDataCardValueMaps,
    useInteractiveModule,
} from "./useInteractiveModule";
import { useArchetypes } from "./state";
import { XMarkIcon } from "@heroicons/react/24/solid";

type Props = {
    pageSection: InteractivePageSection;
    isDrawerOpen: boolean;
    setIsDrawerOpen: (isDrawerOpen: boolean) => void;
    modelBlock: InteractiveModelBlock;
    inputDataObject: Record<string, InputDataItem>;
    targetVariables: ModelVariable[];
    valuesMonthly: Record<string, VariableValue[]>;
    valuesYearly: Record<string, VariableValue[]>;
    archetypeValuesYearly: Record<string, VariableValue[]>;
    archetypeId: string;
    simulationSlug: string;
};

const InteractiveDetailDisplayComponent = ({
    pageSection,
    isDrawerOpen,
    setIsDrawerOpen,
    modelBlock,
    inputDataObject,
    targetVariables,
    valuesMonthly,
    valuesYearly,
    archetypeValuesYearly,
    archetypeId,
    simulationSlug,
}: Props) => {
    const containerRef = useRef(null);

    useInteractiveModule({
        modelBlock,
        inputDataObject,
        targetVariables,
        valuesMonthly,
        valuesYearly,
        archetypeValuesYearly,
        archetypeId,
        simulationSlug,
    });

    useGetArchetypeValues();

    useHandleArchetypeValuesMap();

    useHandleChartAndDataCardValueMaps();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
            });
        }
    }, [pageSection]);

    const archetypes = useArchetypes();

    return (
        <CenterPanelPageWrapper containerRef={containerRef}>
            {!!archetypeId && (
                <div className="flex h-11 w-full items-center justify-between bg-blue-700 px-6">
                    <div className="font-semibold tracking-wide text-white">
                        {`${archetypes?.find((archetype) => archetype.id == archetypeId)?.name}`}
                    </div>
                    <div className=" cursor-pointer text-slate-300 hover:text-white">
                        <InertiaLink
                            className="flex items-center"
                            href={sapienRoute("interactive.show", {
                                simulationSlug: simulationSlug,
                                moduleId: modelBlock?.id,
                            })}
                        >
                            Exit Archetype Editing Mode
                            <XMarkIcon className="ml-1 h-5 w-5" />
                        </InertiaLink>
                    </div>
                </div>
            )}
            <div className={`mb-8 flex flex-col p-6 ${!!archetypeId && ""}`}>
                {pageSection === "Dashboard" && <InteractiveSectionDashboard />}
                {pageSection === "Settings" && <InteractiveSectionSettings />}
                {pageSection === "Data" && <InteractiveSectionData />}
                {pageSection === "Insights" && <InteractiveSectionInsights />}
                {pageSection === "Watchlist" && <InteractiveSectionFavorites />}
                {pageSection === "Archetypes" && (
                    <InteractiveSectionArchetypes />
                )}
                {pageSection === "Design" && <InteractiveSectionDesign />}
                {pageSection === "Guides" && <InteractiveSectionGuides />}
            </div>
            <BottomDrawerWrapper
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
            >
                <FavoritesDrawerDisplay />
            </BottomDrawerWrapper>
        </CenterPanelPageWrapper>
    );
};

export const InteractiveDetailDisplay = React.memo(
    InteractiveDetailDisplayComponent,
);
