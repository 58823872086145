import React, { useEffect, useState } from "react";
import { Round, RoundShape } from "@/models";
import { H4ThinSpan } from "@/components";
import { useTimeHorizonStore, useRoundStore } from "@/hooks/store";
import { RoundForm } from "./RoundForm";
import { ModalContainer } from "@/modules/shared";
import { plus } from "react-icons-kit/feather/plus";
import { RoundDisplay } from "./RoundDisplay";
import { PrimaryButton } from "@/components/admin-components";
import { useSelectedSimulation } from "@/hooks";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { Icon } from "react-icons-kit";
import { move } from "react-icons-kit/feather/move";
import { SapienInertia } from "@/inertia-utils/hooks";

const SortableWrapper = SortableContainer<{ children?: React.ReactNode }>(
    ({ children }) => {
        return (
            <div className="sortable-list flex flex-row flex-wrap space-y-4">
                {children}
            </div>
        );
    },
);

const DragHandle = SortableHandle(() => (
    <div
        className="handle rouded-md -ml-2 -mr-2 flex h-16 cursor-grab items-center px-2
            hover:bg-slate-50"
    >
        <Icon
            icon={move}
            size={18}
            style={{
                display: "flex",
            }}
        />
    </div>
));

const SortableItem = SortableElement<{ children?: React.ReactNode }>(
    ({ children }) => {
        return (
            <div className="sortable-item w-full max-w-full">{children}</div>
        );
    },
);

export const RoundList = ({
    simulationId,
    displayOutline,
}: {
    simulationId: string;
    backgroundColor?: string;
    displayOutline?: boolean;
}) => {
    const { roundMap, editedRound, setEditedRound } = useRoundStore();
    const { timeHorizons } = useTimeHorizonStore();
    const { selectedSimulation } = useSelectedSimulation();

    const [localRounds, setLocalRounds] = useState<RoundShape[]>([]);

    useEffect(() => {
        if (!!roundMap) {
            setLocalRounds(Object.values(roundMap));
        }
    }, [roundMap]);

    return (
        <>
            <div className="space-y-4">
                <div className="flex flex-row items-center justify-between">
                    <span className="text-xl font-normal">Rounds</span>
                    <PrimaryButton
                        text={"Create Round"}
                        icon={plus}
                        handler={() => {
                            setEditedRound(
                                new Round({
                                    weight: !!Object.values(roundMap)?.length
                                        ? Math.max(
                                              ...Object.values(roundMap).map(
                                                  (round) => round.weight,
                                              ),
                                          ) + 1
                                        : 0,
                                    simulation_id: simulationId,
                                    time_horizon_id: !!timeHorizons?.length
                                        ? timeHorizons[0].id
                                        : "",
                                }),
                            );
                        }}
                    />
                </div>
                <SortableWrapper
                    useDragHandle
                    onSortEnd={({ oldIndex, newIndex }) => {
                        if (localRounds?.length > 0) {
                            SapienInertia.post(
                                "creator.reorder-rounds",
                                {
                                    round_id: localRounds[oldIndex]?.id,
                                    destination_index: newIndex,
                                },
                                {},
                                {
                                    preserveState: true,
                                    preserveScroll: true,
                                },
                            );
                        }
                    }}
                    transitionDuration={500}
                >
                    {localRounds !== undefined &&
                        localRounds.length > 0 &&
                        localRounds[0].simulation_id === simulationId &&
                        localRounds.map((round, index) => (
                            <SortableItem
                                key={`item-${round.id}`}
                                index={round.weight}
                            >
                                <RoundDisplay
                                    key={round.id}
                                    round={round}
                                    index={index}
                                    setEditedRound={() =>
                                        setEditedRound(round as Round)
                                    }
                                    simulation={selectedSimulation}
                                    displayOutline={displayOutline}
                                >
                                    <DragHandle />
                                </RoundDisplay>
                            </SortableItem>
                        ))}
                </SortableWrapper>
            </div>
            <ModalContainer
                isModalOpen={!!editedRound}
                setIsModalOpen={() => setEditedRound(undefined)}
                styles={{
                    boxShadow:
                        "0px 4px 6px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1",
                }}
                size="m"
                classNames={"z-100"}
            >
                <div className="px-4">
                    <div className="pb-8 pt-4">
                        <H4ThinSpan className="text-black">
                            {editedRound?.id ? `Edit Round` : `Create Round`}
                        </H4ThinSpan>
                    </div>
                    <div className="pb-2">
                        {!!editedRound && (
                            <RoundForm
                                cancel={() => setEditedRound(undefined)}
                                round={editedRound}
                                timeHorizons={timeHorizons}
                            />
                        )}
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};
