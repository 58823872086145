import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
    Accordion,
} from "@/components/Accordion";
import { SimulationShape } from "@/models";
import React from "react";

type Props = {
    sim: SimulationShape;
    setTeamIds: (teamIds: string[]) => void;
    teamIds: string[];
    setCohortIds: (cohortIds: string[]) => void;
    cohortIds: string[];
    setSimulationIds: (simulationIds: string[]) => void;
    simulationIds: string[];
};

export default function SimSelector({
    sim,
    setTeamIds,
    teamIds,
    setCohortIds,
    cohortIds,
    setSimulationIds,
    simulationIds,
}: Props) {
    return (
        <AccordionItem key={sim.id} title={sim.title} value={sim.id}>
            <AccordionTrigger className="flex w-full items-center justify-between p-4">
                <div className="flex w-full items-center justify-between pr-4">
                    <span>{sim.title}</span>
                    <label
                        className="flex items-center gap-2 text-sm text-slate-500"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <input
                            className="pointer-events-none"
                            type="checkbox"
                            checked={simulationIds.includes(sim.id)}
                            onChange={(e) => {
                                e.stopPropagation();
                                if (simulationIds.includes(sim.id)) {
                                    setSimulationIds(
                                        simulationIds.filter(
                                            (id) => id !== sim.id,
                                        ),
                                    );
                                } else {
                                    setSimulationIds([
                                        ...simulationIds,
                                        sim.id,
                                    ]);
                                }
                            }}
                        />
                        Create new cohort for this participant
                    </label>
                </div>
            </AccordionTrigger>
            <AccordionContent className={"flex flex-col gap-2 p-6"}>
                {sim.cohorts.map((cohort) => (
                    <div className="flex flex-col" key={cohort.id}>
                        <Accordion
                            type={"single"}
                            collapsible
                            className={"overflow-hidden rounded-md border"}
                        >
                            <AccordionItem
                                key={cohort.id}
                                title={cohort.title}
                                value={cohort.id}
                            >
                                <AccordionTrigger className="flex w-full justify-between p-4">
                                    <div className="flex w-full items-center justify-between pr-4">
                                        <span>{cohort.title}</span>
                                        <label
                                            onClick={(e) => e.stopPropagation()}
                                            className="flex items-center gap-2 text-sm text-slate-500"
                                        >
                                            <input
                                                type="checkbox"
                                                checked={cohortIds.includes(
                                                    cohort.id,
                                                )}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        cohortIds.includes(
                                                            cohort.id,
                                                        )
                                                    ) {
                                                        setCohortIds(
                                                            cohortIds.filter(
                                                                (id) =>
                                                                    id !==
                                                                    cohort.id,
                                                            ),
                                                        );
                                                    } else {
                                                        setCohortIds([
                                                            ...cohortIds,
                                                            cohort.id,
                                                        ]);
                                                    }
                                                }}
                                            />
                                            Create new team for this cohort
                                        </label>
                                    </div>
                                </AccordionTrigger>
                                <AccordionContent
                                    className={"flex flex-col gap-2 p-6"}
                                >
                                    {cohort.teams.map((team) => (
                                        <label
                                            className="flex items-center gap-2"
                                            key={team.id}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={teamIds.includes(
                                                    team.id,
                                                )}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    if (
                                                        teamIds.includes(
                                                            team.id,
                                                        )
                                                    ) {
                                                        setTeamIds(
                                                            teamIds.filter(
                                                                (id) =>
                                                                    id !==
                                                                    team.id,
                                                            ),
                                                        );
                                                    } else {
                                                        setTeamIds([
                                                            ...teamIds,
                                                            team.id,
                                                        ]);
                                                    }
                                                }}
                                            />
                                            {team.name}
                                        </label>
                                    ))}
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                ))}
            </AccordionContent>
        </AccordionItem>
    );
}
