import React from "react";
import { ModelVariable } from "@/models";
import { useModelVariables, useVariableRelationships } from "../state";

export const VariableLineageDisplay = (props: {
    modelVariable: ModelVariable;
}) => {
    const { modelVariable } = props;

    const { modelVariables } = useModelVariables();
    const { relationshipsBySourceId } = useVariableRelationships();

    return (
        <React.Fragment>
            <div
                className="m-0.5 flex flex-row items-center border-l-[1px] border-l-[rgba(28,78,216,0.2)]
                    p-0.5"
            >
                <div className="flex flex-col justify-center pl-1">
                    <div className="m-0.5 bg-[rgba(28,78,216,0.05)] p-2">
                        {modelVariable.label}
                    </div>
                </div>
                {!!relationshipsBySourceId &&
                    !!relationshipsBySourceId[modelVariable.id] && (
                        <div className="flex flex-col justify-center pl-1">
                            {relationshipsBySourceId[modelVariable.id].map(
                                (targetRelationship) => (
                                    <VariableLineageDisplay
                                        key={targetRelationship.id}
                                        modelVariable={
                                            modelVariables[
                                                targetRelationship
                                                    .target_variable_id
                                            ]
                                        }
                                    />
                                ),
                            )}
                        </div>
                    )}
            </div>
        </React.Fragment>
    );
};
