import React, { useCallback, useEffect, useState } from "react";
import {
    Cohort,
    ModelBlock,
    Round,
    Simulation,
    SimulationShape,
    Team,
    TeamWithProgressData,
} from "@/models";
import {
    ChevronDoubleDownIcon,
    ChevronDoubleUpIcon,
} from "@heroicons/react/24/outline";
import { ChartBarIcon, TableCellsIcon } from "@heroicons/react/24/solid";
import { DiscoveryLayerObject } from "../useDiscoveryLayer";
import {
    DiscoveryQuestionDisplay,
    DiscoverySelectionsByPeople,
    DiscoverySelectionsByType,
    DiscoveryWidgetWrapper,
} from "../shared";
import { DiscoveryBlockVariableDisplay } from "./DiscoveryBlockVariableDisplay";

const heightCalc = `calc(100vh - 42px - 42px - 2px)`;

export const DiscoveryCohortDetail = ({
    rounds,
    modelBlocks,
    simulation,
    cohort,
    teams,
    discoveryLayerObject,
}: {
    rounds: Round[];
    modelBlocks: ModelBlock[];
    simulation: Simulation | SimulationShape;
    cohort: Cohort;
    teams: (Team | TeamWithProgressData)[];
    discoveryLayerObject: DiscoveryLayerObject;
}) => {
    const {
        variableMap,
        valueMap,
        selectionMap,
        promptMap,
        lineageBlockIdsObject,
        blockVariableMap,
        selectedBlockIds,
        selectedPromptIds,
        toggleBlockId,
        toggleRoundId,
        teamMap,
        participantMap,
        selectionsGrouping,
        promptTypeAndScopeMap,
        // resultsGrouping,
    } = discoveryLayerObject;

    const [questionDisplay, setQuestionDisplay] = useState<
        "tables" | "charts" | ""
    >("");

    useEffect(() => {
        if (questionDisplay !== "") {
            setQuestionDisplay("");
        }
    }, [questionDisplay]);

    const [displayTypeScopeMap, setDisplayTypeScopeMap] = useState<{
        [promptType: string]: {
            [promptScope: string]: boolean;
        };
    }>(
        Object.keys(promptTypeAndScopeMap).reduce(
            (tMap, promptType) => ({
                ...tMap,
                [promptType]: Object.keys(
                    promptTypeAndScopeMap[promptType],
                ).reduce(
                    (sMap, promptScope) => ({ ...sMap, [promptScope]: true }),
                    {},
                ),
            }),
            {},
        ),
    );

    const handleSetDisplayTypeScopeMap = useCallback(
        (promptType: string, promptScope: string) => {
            setDisplayTypeScopeMap({
                ...displayTypeScopeMap,
                [promptType]: {
                    ...(displayTypeScopeMap[promptType] || {}),
                    [promptScope]:
                        !!displayTypeScopeMap[promptType] &&
                        displayTypeScopeMap[promptType][promptScope] !==
                            undefined
                            ? !displayTypeScopeMap[promptType][promptScope]
                            : false,
                },
            });
        },
        [displayTypeScopeMap],
    );

    return (
        !!cohort && (
            <div className="">
                <div className="overflow-auto">
                    <div
                        className="discovery-container grid w-full grid-cols-2"
                        style={{
                            height: heightCalc,
                            minHeight: "300px",
                            minWidth: "600px",
                        }}
                    >
                        <div className="overflow-y-auto px-6 pb-6">
                            <div className="my-3 flex items-end justify-between px-1">
                                <div className="text-xl">{"Selections"}</div>
                                <div className="flex items-center">
                                    {selectionsGrouping === "question" && (
                                        <>
                                            <button
                                                type="button"
                                                className="m-0.5 inline-flex items-center rounded-md bg-neutral-100 p-1 text-center text-sm
                                                    font-medium text-neutral-800 hover:bg-neutral-200 focus:outline-none
                                                    focus:ring-0"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setQuestionDisplay(
                                                        "charts",
                                                    );
                                                }}
                                            >
                                                <ChartBarIcon className="h-4 w-4" />
                                            </button>
                                            <button
                                                type="button"
                                                className="m-0.5 inline-flex items-center rounded-md bg-neutral-100 p-1 text-center text-sm
                                                    font-medium text-neutral-800 hover:bg-neutral-200 focus:outline-none
                                                    focus:ring-0"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setQuestionDisplay(
                                                        "tables",
                                                    );
                                                }}
                                            >
                                                <TableCellsIcon className="h-4 w-4" />
                                            </button>
                                        </>
                                    )}
                                    <SmallButton
                                        onClick={() => toggleRoundId("ALL")}
                                    >
                                        <ChevronDoubleDownIcon className="h-3 w-3" />
                                    </SmallButton>
                                    <SmallButton
                                        onClick={() => toggleRoundId()}
                                    >
                                        <ChevronDoubleUpIcon className="h-3 w-3" />
                                    </SmallButton>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-col space-y-6">
                                    {selectionsGrouping === "question" &&
                                        !!rounds?.length &&
                                        !!selectionMap &&
                                        rounds
                                            .filter(
                                                (round) =>
                                                    !!round.prompts &&
                                                    round.prompts_count > 0,
                                            )
                                            .map((round) => (
                                                <React.Fragment key={round.id}>
                                                    {round.prompts
                                                        .filter(
                                                            (prompt) =>
                                                                !!selectedPromptIds[
                                                                    prompt.id
                                                                ] &&
                                                                !!selectionMap[
                                                                    prompt.id
                                                                ]?.length,
                                                        )
                                                        .map((prompt) => (
                                                            <DiscoveryWidgetWrapper
                                                                key={prompt.id}
                                                                className="bg-indigo-50/80"
                                                            >
                                                                <div
                                                                    className="cursor-pointer pb-2 text-xs font-semibold"
                                                                    onClick={() =>
                                                                        toggleRoundId(
                                                                            round.id,
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        round.title
                                                                    }
                                                                </div>
                                                                <DiscoveryQuestionDisplay
                                                                    key={
                                                                        prompt.id
                                                                    }
                                                                    prompt={
                                                                        prompt
                                                                    }
                                                                    round={
                                                                        round
                                                                    }
                                                                    discoveryLayerObject={
                                                                        discoveryLayerObject
                                                                    }
                                                                    teams={
                                                                        teams as Team[]
                                                                    }
                                                                    questionDisplay={
                                                                        questionDisplay
                                                                    }
                                                                />
                                                            </DiscoveryWidgetWrapper>
                                                        ))}
                                                </React.Fragment>
                                            ))}
                                </div>
                                {selectionsGrouping === "type" &&
                                    !!rounds?.length &&
                                    !!selectedPromptIds &&
                                    !!Object.keys(selectedPromptIds).length && (
                                        <DiscoverySelectionsByType
                                            discoveryLayerObject={
                                                discoveryLayerObject
                                            }
                                            rounds={rounds}
                                            timeHorizons={
                                                simulation.timeHorizons
                                            }
                                            displayTypeScopeMap={
                                                displayTypeScopeMap
                                            }
                                            handleDisplayTypeScopeMap={
                                                handleSetDisplayTypeScopeMap
                                            }
                                        />
                                    )}
                                {selectionsGrouping === "people" &&
                                    !!rounds?.length &&
                                    !!selectionMap &&
                                    !!selectedPromptIds &&
                                    !!Object.keys(selectedPromptIds).length &&
                                    !!promptMap &&
                                    !!participantMap &&
                                    !!teamMap && (
                                        <DiscoverySelectionsByPeople
                                            discoveryLayerObject={
                                                discoveryLayerObject
                                            }
                                            rounds={rounds}
                                        />
                                    )}
                            </div>
                        </div>
                        <div className="overflow-y-auto px-6 pb-6">
                            <div className="my-3 flex items-end justify-between px-1">
                                <div className="text-xl">{"Results"}</div>
                                <div className="flex items-center">
                                    <SmallButton
                                        onClick={() => toggleBlockId("ALL")}
                                    >
                                        <ChevronDoubleDownIcon className="h-3 w-3" />
                                    </SmallButton>
                                    <SmallButton
                                        onClick={() => toggleBlockId()}
                                    >
                                        <ChevronDoubleUpIcon className="h-3 w-3" />
                                    </SmallButton>
                                </div>
                            </div>
                            <div className="">
                                <div className="flex flex-col space-y-6">
                                    {!!modelBlocks &&
                                        !!blockVariableMap &&
                                        !!selectedBlockIds &&
                                        !!lineageBlockIdsObject &&
                                        !!variableMap &&
                                        !!Object.keys(variableMap)?.length &&
                                        !!valueMap &&
                                        modelBlocks
                                            .filter(
                                                (modelBlock) =>
                                                    !!lineageBlockIdsObject[
                                                        modelBlock.id
                                                    ],
                                            )
                                            .map((modelBlock) => (
                                                <DiscoveryBlockVariableDisplay
                                                    key={modelBlock.id}
                                                    modelBlock={modelBlock}
                                                    selectedBlockIds={
                                                        selectedBlockIds
                                                    }
                                                    toggleBlockId={
                                                        toggleBlockId
                                                    }
                                                    blockVariableMap={
                                                        blockVariableMap
                                                    }
                                                    lineageIdMap={
                                                        lineageBlockIdsObject
                                                    }
                                                    variableMap={variableMap}
                                                    valueMap={valueMap}
                                                    selectedSimulation={
                                                        simulation
                                                    }
                                                    selectedCohort={cohort}
                                                    teams={teams as Team[]}
                                                />
                                            ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

const SmallButton: React.FC<{
    onClick?: () => void;
    children?: React.ReactNode;
}> = ({ onClick, children }) => {
    return (
        <button
            type="button"
            className="m-0.5 inline-flex items-center rounded-full bg-neutral-400 p-1 text-center
                text-sm font-medium text-neutral-100 hover:bg-neutral-500 focus:outline-none
                focus:ring-0"
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
        >
            {children}
        </button>
    );
};
