import React from "react";
import { sapienRoute, isCurrent } from "@/inertia-utils/hooks";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { Bolt } from "lucide-react";
import { ModelSuiteIconNavLink } from "./ModelSuiteNavLinks";
import { LaravelRoutes } from "@/ziggy-shims";

export function ModelSuiteLinkGroup({
    simulationSlug,
    currentRoute,
}: {
    simulationSlug: string;
    currentRoute: keyof LaravelRoutes;
}) {
    return (
        <div className="flex items-center rounded-full bg-slate-900 px-1">
            <ModelSuiteIconNavLink
                href={
                    !!simulationSlug &&
                    sapienRoute("model-builder.index", {
                        simulationSlug: simulationSlug,
                    })
                }
                title={"Builder"}
                active={isCurrent("model-builder.index", currentRoute, [
                    "model-builder.interactive",
                    "model-builder.interactive-detail",
                ])}
            >
                <Bolt className={"h-5 w-5"} />
            </ModelSuiteIconNavLink>

            <ModelSuiteIconNavLink
                href={
                    !!simulationSlug &&
                    sapienRoute("interactive.index", {
                        simulationSlug: simulationSlug,
                    })
                }
                title={"Interactive"}
                active={isCurrent("interactive.index", currentRoute, [
                    "interactive.show",
                    "interactive.edit-archetype",
                ])}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-chart-no-axes-combined h-5 w-5"
                >
                    <path d="M12 16v5" />
                    <path d="M16 14v7" />
                    <path d="M20 10v11" />
                    <path d="m22 3-8.646 8.646a.5.5 0 0 1-.708 0L9.354 8.354a.5.5 0 0 0-.707 0L2 15" />
                    <path d="M4 18v3" />
                    <path d="M8 14v7" />
                </svg>
            </ModelSuiteIconNavLink>

            <ModelSuiteIconNavLink
                href={
                    !!simulationSlug &&
                    sapienRoute("data-sandbox.index", {
                        simulationSlug: simulationSlug,
                    })
                }
                title={"Data Sandbox"}
                active={isCurrent("data-sandbox.index", currentRoute, [
                    "data-sandbox.show",
                ])}
            >
                <SparklesIcon className="h-5 w-5" />
            </ModelSuiteIconNavLink>
        </div>
    );
}
