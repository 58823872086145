import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";

type Props = {
    leftButtonHandler: () => void;
    rightButtonHandler: () => void | null;
    isLastStep: boolean;
    isFirstStep: boolean;
    hideLeftButton?: boolean;
    isBusy: boolean;
};

export default function QuestionModalFooter({
    leftButtonHandler,
    rightButtonHandler,
    isLastStep,
    isFirstStep,
    hideLeftButton = false,
    isBusy,
}: Props) {
    return (
        <div className="grid gap-6 border-t border-[#374151] px-6 pt-6 md:grid-cols-2">
            <div className="flex items-center justify-center">
                {!hideLeftButton && (
                    <button
                        disabled={isBusy}
                        data-testid="question-left-button"
                        onClick={() => {
                            leftButtonHandler();
                        }}
                        className="inline-flex w-full items-center justify-center rounded-full bg-slate-50/5 px-5
                            py-2.5 text-center text-sm font-medium text-white transition-all
                            hover:bg-slate-50/10 focus:outline-none focus:ring-2 focus:ring-blue-600
                            disabled:bg-slate-200/5"
                    >
                        {isFirstStep ? (
                            <>Cancel</>
                        ) : (
                            <>
                                <ArrowLeftIcon className="mr-2 h-3 w-3" />
                                Back
                            </>
                        )}
                    </button>
                )}
            </div>
            <div className="flex items-center justify-center">
                <button
                    disabled={!rightButtonHandler || isBusy}
                    onClick={() => {
                        rightButtonHandler();
                    }}
                    className="inline-flex w-full items-center justify-center rounded-full bg-blue-800 px-5
                        py-2.5 text-center text-sm font-medium text-white transition-all
                        hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600
                        disabled:bg-blue-800 disabled:opacity-75"
                >
                    {!isLastStep ? (
                        <>
                            Next <ArrowRightIcon className="ml-2 h-3 w-3" />
                        </>
                    ) : (
                        <>Save</>
                    )}
                </button>
            </div>
        </div>
    );
}
