export type RoleName =
    | "Participant"
    | "Creator"
    | "Facilitator"
    | "Admin"
    | "Super Admin"
    | "Sapien Super Admin"
    | "Unpublished Content Viewer";

export const orderedRoleNames = [
    "Sapien Super Admin",
    "Super Admin",
    "Admin",
    "Facilitator",
    "Creator",
    "Participant",
];

export const getHighestRole = (
    userRoles: {
        id: string | number;
        name: string;
    }[],
) => {
    const roleNames = userRoles.map((role) => role.name);
    const highestRoleName = orderedRoleNames.find((roleName) =>
        roleNames.includes(roleName),
    );
    return userRoles.find((role) => role.name == highestRoleName);
};
