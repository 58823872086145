import { AdminPageHeader } from "@/components/admin-components";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import { ProjectDashboardTopNav } from "@/Layouts/admin/ProjectDashboardTopNav";
import Authenticated from "@/Layouts/Authenticated";
import { Head } from "@inertiajs/react";
import React, { useEffect } from "react";
import RolePermissionSelector from "./RolePermissionSelector";
import { useNotificationStore } from "@/hooks/useNotificationStore";
import StyledToast from "@/components/toasts/StyledToast";
import { PermissionForm } from "./PermissionForm";

type Props = {
    roles: App.Data.RoleData[];
    permissions: App.Data.PermissionData[];
    toasts: any[];
} & SapienAdminPageProps;

export default function PermissionsIndex({
    roles,
    permissions,
    toasts,
}: Props) {
    const { setNotification, notification } = useNotificationStore();

    useEffect(() => {
        if (!!toasts && (toasts as any)?.length) {
            console.log(toasts);
            setNotification({
                autoClose: toasts[0].autoClose || 6000,
                text: toasts[0].message,
                isInline: true,
                textColor: "#4B5563",
                backgroundColor: "#F0FFF4",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
            });
        }
    }, [toasts, toasts?.length]);

    return (
        <Authenticated>
            <Head title="Permissions" />
            <AdminPageHeader>
                <ProjectDashboardTopNav />
            </AdminPageHeader>
            <div className="flex w-full flex-col items-center py-6">
                <div className="flex w-full max-w-4xl flex-col gap-6">
                    <PermissionForm />
                    {roles.map((role) => (
                        <RolePermissionSelector
                            key={role.id}
                            role={role}
                            permissions={permissions}
                        />
                    ))}
                </div>
            </div>
            {!!notification && (
                <>
                    <StyledToast props={notification} />
                </>
            )}
        </Authenticated>
    );
}
