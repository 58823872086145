import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { ParticipantShape, SimulationShape, UserShape } from "@/models";
import { AdminPageSection } from "@/components/admin-components";
import { Course } from "../ELearning/types";
import { UserManagementWrapper } from "./UserManagementWrapper";

type Props = {
    roles: { id: number; name: string }[];
    user: UserShape;
    participant?: ParticipantShape;
    simulations?: SimulationShape[];
    courses?: Course[];
} & SapienPageProps;

const SimOrCourseDisplay = ({
    simOrCourse,
    participant,
    isCourse,
}: {
    simOrCourse: SimulationShape | Course;
    participant: ParticipantShape;
    isCourse?: boolean;
}) => {
    return (
        <div
            key={simOrCourse.id}
            className="gap-2 divide-y rounded-md bg-white/75 px-4 py-2 hover:bg-white/100
                hover:shadow-sm"
        >
            <div className="flex justify-between gap-2 py-2">
                <span className="text-base font-semibold">
                    {simOrCourse.title}
                </span>
                <div className="flex flex-col items-end justify-end gap-px">
                    <span className="text-right text-xs tracking-wide text-slate-500">
                        {simOrCourse.slug}
                    </span>
                    <span className="pt-0.5 text-xs text-slate-700">
                        {`${isCourse ? "Lessons" : "Rounds"}: ${isCourse ? (simOrCourse as Course).lessons_count : simOrCourse.rounds_count}`}
                    </span>
                </div>
            </div>
            {/* <div>
                {participant?.rounds
                    ?.filter((round) => round.simulation_id === simOrCourse.id)
                    ?.map((round) => (
                        <div key={round.id}>
                            {participant?.teams
                                ?.filter(
                                    (team) => team.id === round.pivot.team_id,
                                )
                                ?.map((team) => (
                                    <React.Fragment key={team.id}>
                                        <div>{team.cohort?.title}</div>
                                        <div> {team.name}</div>
                                    </React.Fragment>
                                ))}
                            <div>{round.title}</div>
                        </div>
                    ))}
            </div> */}
            {participant?.teams
                ?.filter(
                    (team) => team.cohort?.simulation_id === simOrCourse.id,
                )
                ?.map((team) => (
                    <div key={team.id} className="p-2 text-sm">
                        <div>
                            {`Cohort: `}
                            {team.cohort?.title}
                        </div>
                        <div>
                            {`Team: `}
                            {team.name}
                        </div>
                        <div>
                            {`Round: `}
                            {
                                participant?.rounds?.find(
                                    (round) =>
                                        round.id === team.pivot?.round_id,
                                )?.title
                            }
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default function UserDetail({
    roles,
    user,
    participant,
    simulations,
    courses,
    ...pageProps
}: Props) {
    return (
        <UserManagementWrapper {...pageProps}>
            <>
                <AdminPageSection className={"bg-slate-200/50 py-4"}>
                    <div className="space-y-4 text-base">
                        <div className="text-lg font-medium">{user?.name}</div>
                        <div className="text-sm">{user?.email}</div>
                        <div className="text-sm">
                            <div className="font-semibold">Roles</div>
                            {user?.roles?.length > 0 ? (
                                user.roles.map((role) => (
                                    <div key={role.id}>{role.name}</div>
                                ))
                            ) : (
                                <div className="font-medium text-slate-600">
                                    No roles
                                </div>
                            )}
                        </div>
                    </div>
                </AdminPageSection>
                <AdminPageSection>
                    <div className="space-y-4 text-slate-900">
                        <div className="space-y-2 text-slate-900">
                            <div className="flex min-h-9 flex-row items-center justify-between">
                                <span className="text-lg font-normal">
                                    {"Simulations"}
                                </span>
                            </div>
                            <div className="grid w-full grid-cols-1 gap-4">
                                {simulations?.length > 0 ? (
                                    simulations.map((simulation) => (
                                        <SimOrCourseDisplay
                                            key={simulation.id}
                                            simOrCourse={simulation}
                                            participant={participant}
                                        />
                                    ))
                                ) : (
                                    <span className="text-sm">
                                        No simulations
                                    </span>
                                )}
                            </div>
                        </div>
                        {!!pageProps?.tenant?.has_e_learning_feature && (
                            <div className="space-y-2 text-slate-900">
                                <div className="flex min-h-9 flex-row items-center justify-between">
                                    <span className="text-lg font-normal">
                                        {"Courses"}
                                    </span>
                                </div>
                                <div className="grid w-full grid-cols-1 gap-4">
                                    {courses?.length > 0 ? (
                                        courses.map((course) => (
                                            <SimOrCourseDisplay
                                                key={course.id}
                                                simOrCourse={course}
                                                participant={participant}
                                                isCourse={true}
                                            />
                                        ))
                                    ) : (
                                        <span className="text-sm">
                                            No courses
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </AdminPageSection>
            </>
        </UserManagementWrapper>
    );
}
