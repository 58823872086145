import React from "react";
import { useFormatVariableValue } from "@/hooks";
import { CarouselCardWrapper } from "../shared";
import { SettingsDisplayProps } from "./props";
import { WizardVariableSettingsDisplay } from "./WizardVariableSettingsDisplay";
import { useAuthStore } from "@/hooks/store";
import { ArchetypeInputComparison } from "./ArchetypeInputComparison";
import { useArchetypes } from "../state";

const CarouselCardSettingsDisplayComponent = ({
    modelBlock,
    modelVariables,
    inputDataObject,
    setInputDataItem,
    showSettingsCharts,
}: SettingsDisplayProps) => {
    const { isSapienSuperAdmin } = useAuthStore();
    const archetypes = useArchetypes();
    const formatVariableValue = useFormatVariableValue();

    return (
        <CarouselCardWrapper modelBlockLabel={modelBlock.label}>
            {!!modelBlock.description?.length && (
                <div className="px-4 pt-4 text-slate-600 dark:text-slate-400">
                    {modelBlock.description}
                </div>
            )}
            <div className="p-4">
                <div className="flex flex-col space-y-4">
                    {modelVariables[modelBlock.id]
                        ?.filter(
                            (modelVariable) =>
                                !!inputDataObject[modelVariable.id],
                        )
                        .map((modelVariable, i) => (
                            <WizardVariableSettingsDisplay
                                key={modelVariable.id}
                                modelVariable={modelVariable}
                                inputDataItem={
                                    inputDataObject[modelVariable.id]
                                }
                                setInputDataItem={setInputDataItem}
                                showSettingsCharts={showSettingsCharts}
                                index={i}
                                formatVariableValue={formatVariableValue}
                                fullChartIconMenu={true}
                            >
                                {!!isSapienSuperAdmin && (
                                    <ArchetypeInputComparison
                                        modelVariable={modelVariable}
                                        archetypes={archetypes}
                                        formatVariableValue={
                                            formatVariableValue
                                        }
                                    />
                                )}
                            </WizardVariableSettingsDisplay>
                        ))}
                </div>
            </div>
        </CarouselCardWrapper>
    );
};

export const CarouselCardSettingsDisplay = React.memo(
    CarouselCardSettingsDisplayComponent,
);
