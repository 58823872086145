import React, { useState, useRef, useEffect } from "react";
import { Send, CircleUserRound, Hexagon, RefreshCw } from "lucide-react";
import { SubsectionWrapper } from "./SharedComponents";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ClaudeMessage, useClaudeChat } from "./useDataSandbox";

// const normalizeMarkdown = (message: string) =>
//     message.replace(/^\s*[-*]\s+/gm, "- ");

const FormattedMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                h1: ({ node, ...props }) => (
                    <h1 className="mb-2 text-2xl font-bold" {...props} />
                ),
                h2: ({ node, ...props }) => (
                    <h2 className="mb-1 text-xl font-semibold" {...props} />
                ),
                code: ({ node, ...props }) => (
                    <code
                        className="rounded bg-slate-100 p-1 font-mono text-sm dark:bg-slate-700"
                        {...props}
                    />
                ),
                pre: ({ node, ...props }) => (
                    <pre
                        className="overflow-x-auto rounded-lg border border-slate-200 bg-slate-100 p-4 text-sm
                            dark:border-slate-600 dark:bg-slate-800"
                        {...props}
                    />
                ),
                ul: ({ node, ...props }) => (
                    <ul
                        className="list-inside list-disc space-y-1 pl-4"
                        {...props}
                    />
                ),
                ol: ({ node, ...props }) => (
                    <ol
                        className="list-inside list-decimal space-y-1 pl-4"
                        {...props}
                    />
                ),
                a: ({ node, ...props }) => (
                    <a
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        {...props}
                    />
                ),
            }}
        >
            {message}
            {/* {normalizeMarkdown(message)} */}
        </ReactMarkdown>
    );
};

const MessageDisplay: React.FC<{ message: ClaudeMessage }> = ({ message }) => {
    return (
        <div
            className={`flex items-start space-x-3 ${
                message.type === "user" ? "justify-end" : "justify-start"
            }`}
        >
            {message.type === "claude" && (
                <Hexagon className="h-8 w-8 flex-shrink-0 text-blue-500 dark:text-blue-400" />
            )}
            <div
                className={`max-w-[80%] rounded-xl px-4 py-2 ${
                    message.type === "user"
                        ? "bg-blue-500 text-white"
                        : "bg-slate-200 text-slate-800 dark:bg-slate-700 dark:text-slate-200"
                } ${
                    message.content === "..." && message.type === "claude"
                        ? "animate-pulse tracking-widest"
                        : ""
                }`}
            >
                {/* {msg.content} */}
                <FormattedMessage message={message.content} />
            </div>
            {message.type === "user" && (
                <CircleUserRound className="h-8 w-8 flex-shrink-0 text-slate-500 dark:text-slate-400" />
            )}
        </div>
    );
};

export const ClaudeChat = () => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const chatEndRef = useRef<null | HTMLDivElement>(null);

    const {
        variablesForClaude,
        claudeMessages,
        sendMessage,
        allowedEntryLength,
    } = useClaudeChat({});

    const handleSendMessage = async () => {
        if (!message.trim()) return;

        setLoading(true);
        const messageToSend = message;
        setMessage("");
        await sendMessage(messageToSend);
        setLoading(false);
    };

    // Scroll to bottom when messages change
    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [claudeMessages]);

    // Handle Enter key for sending message
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && !loading) {
            handleSendMessage();
        }
    };

    return (
        <SubsectionWrapper title="Chat with Claude">
            <div
                className="flex h-[500px] flex-col rounded-lg border border-slate-200 bg-slate-50
                    dark:border-slate-700 dark:bg-slate-800"
            >
                {/* Chat Messages Area */}
                <div className="flex-grow space-y-4 overflow-y-auto p-4">
                    {claudeMessages.length === 0 ? (
                        <div className="text-center italic text-slate-500 dark:text-slate-400">
                            <div>{`Start a conversation with Claude ${variablesForClaude?.length > 0 && "about the following variables:"}`}</div>
                            <div>{`${variablesForClaude?.map((variable) => ` ${variable}`)}`}</div>
                        </div>
                    ) : (
                        claudeMessages.map((msg, index) => (
                            <MessageDisplay key={index} message={msg} />
                        ))
                    )}
                    {!!loading && (
                        <MessageDisplay
                            key={"loading"}
                            message={
                                {
                                    type: "claude",
                                    content: "...",
                                } as ClaudeMessage
                            }
                        />
                    )}
                    <div ref={chatEndRef} />
                </div>

                {/* Message Input Area */}
                <div className="flex items-center space-x-2 border-t border-slate-200 p-4 dark:border-slate-700">
                    <input
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Message Claude..."
                        className="flex-grow rounded-lg border border-slate-300 bg-white px-3 py-2 text-slate-800
                            focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-slate-600
                            dark:bg-slate-700 dark:text-slate-200"
                        disabled={loading}
                    />
                    <button
                        onClick={handleSendMessage}
                        disabled={
                            loading ||
                            !message.trim() ||
                            message?.length >= allowedEntryLength
                        }
                        className="flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white
                            transition-colors hover:bg-blue-600 disabled:opacity-50"
                    >
                        {loading ? (
                            <RefreshCw className="h-5 w-5 animate-spin" />
                        ) : (
                            <Send className="h-5 w-5" />
                        )}
                    </button>
                </div>
            </div>
        </SubsectionWrapper>
    );
};
