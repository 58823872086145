import React from "react";
import { CheckIcon } from "lucide-react";
import { UserShape } from "@/models";
import { MagicLinkButton } from "./MagicLinkButton";
import { RoleSelectionMenu } from "./RoleSelectionMenu";
import { Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

type Props = {
    role: string;
    users: UserShape[];
    roles: { id: number; name: string }[];
    userEmails: string[];
    handleSetUserEmails: (userEmail: string) => void;
};

export const RoleSection = ({
    role,
    users,
    roles,
    userEmails,
    handleSetUserEmails,
}: Props) => {
    return (
        <div
            key={role}
            className="flex flex-col space-y-2 divide-y rounded-md bg-slate-200/50 p-4"
        >
            <h3 className="text-base font-medium text-slate-500">{role}s</h3>
            <ul
                role="list"
                className="mt-4 divide-y divide-slate-300 border-b border-t border-slate-200"
            >
                {!users || users?.length == 0 ? (
                    <li
                        className="flex items-center justify-between space-x-3 py-4 text-sm font-medium
                            text-slate-600"
                    >
                        No users
                    </li>
                ) : (
                    users?.map((user) => (
                        <li
                            key={user.id}
                            className="flex items-center justify-between space-x-3 py-4 "
                        >
                            <div className="grid w-full grid-cols-2 gap-3 md:grid-cols-4">
                                <div className="flex">
                                    <Link
                                        href={sapienRoute("admin.users.show", {
                                            user: user.id,
                                        })}
                                        className="group flex items-center space-x-2"
                                    >
                                        <span className="truncate text-sm font-semibold text-slate-900 group-hover:underline">
                                            {user.name}
                                        </span>
                                        <ChevronRightIcon className="h-4 w-4 opacity-0 group-hover:opacity-100" />
                                    </Link>
                                </div>
                                <div className="flex items-center justify-end md:justify-start">
                                    <span className="overflow-hidden overflow-ellipsis text-sm text-slate-700">
                                        {user.email}
                                    </span>
                                </div>
                                <div className="flex flex-wrap items-center justify-start gap-2 md:justify-start">
                                    <MagicLinkButton
                                        user={user}
                                        handleOnSuccess={() =>
                                            handleSetUserEmails(user.email)
                                        }
                                    />
                                    
                                </div>
                                <div className="flex justify-end">
                                    <RoleSelectionMenu
                                        user={user}
                                        roles={roles}
                                    />
                                </div>
                            </div>
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
};
