import {
    useSelectedSimulation,
    useFindContentBlockMethods,
    useModalQueryParams,
    useGotContentBlock,
} from "@/hooks";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import {
    LayoutType,
    getShouldShowDeleteButton,
    getCanAddChildren,
    getContentBlockConfigByType,
    getLayoutConfigByType,
} from "@/model-configs";
import { ContentBlockShape, ContentBlockType, PromptType } from "@/models";
import {
    DocumentDuplicateIcon,
    EyeIcon,
    ShieldCheckIcon,
    VariableIcon,
    WindowIcon,
} from "@heroicons/react/24/solid";
import React, { useMemo } from "react";
import { Link } from "@inertiajs/react";
import { AddButton } from "./AddButton";
import { DeleteButton } from "./DeleteButton";
import { EditButton } from "./EditButton";
import MoveButton from "./MoveButton";
import { ReorderButtons } from "./ReorderButtons";
import ContentBlockButtonContainer from "./ContentBlockButtonContainer";
import { useMobileViewState } from "../../mobile-wrapper/useMobileViewState";
import { router } from "@inertiajs/react";
import { titleCase } from "@/util";
import {
    useDiscardActiveContentBlock,
    useSelectActiveContentBlock,
} from "@/hooks/activeContentBlock";
import { useMovedContentBlock } from "@/modules/moved-content-block/useMovedContentBlock";
import {
    CreateContentBlockBody,
    createContentBlock,
} from "../../content-block-buttons/create-content-block-button/createContentBlock";

const editableTypes = [
    ContentBlockType.Question,
    ContentBlockType.Image,
    ContentBlockType.Hero,
    ContentBlockType.Hero3,
    ContentBlockType.Card,
    ContentBlockType.Box,
    ContentBlockType.Navbar,
    ContentBlockType["Top Drawer"],
    ContentBlockType["Chart Container"],
];

const imageEditTypes = [
    ContentBlockType.Image,
    ContentBlockType.Section,
    LayoutType.Hero,
    ContentBlockType.Card,
    ContentBlockType.Box,
    ContentBlockType.Hero3,
    ContentBlockType.Navbar,
];

type EditorTooltipProps = {
    activeId: string;
    contentBlockNested: ContentBlockShape;
    parentType?: ContentBlockType;
    siblingIndex: number;
    siblingCount: number;
    isParentTopVisible: boolean;
    isParentBottomVisible: boolean;
    parentIsCloseToRightOfViewPort: boolean;
    editorSizes: {
        toolbar: string;
        leftMenu: string;
        rightMenu: string;
    };
};

//some blocks are part of nested structures, and the popover should never appear above the contents
const nestedTypes = [ContentBlockType.AccordionHeader];

export const ContentBlockEditPopover = ({
    activeId,
    contentBlockNested,
    parentType,
    siblingCount,
    siblingIndex,
    isParentTopVisible,
    isParentBottomVisible,
    editorSizes,
    parentIsCloseToRightOfViewPort,
}: EditorTooltipProps) => {
    const { setActiveContentBlock } = useSelectActiveContentBlock();
    const { selectedSimulation } = useSelectedSimulation();
    const { getContentBlockWithChildren } = useFindContentBlockMethods();
    const { discardActiveContentBlock } = useDiscardActiveContentBlock();
    const { openModal } = useModalQueryParams();

    const handleBackgroundImage = (contentBlockId) => {
        openModal(
            {
                modalComponent: "ImageModal",
                backgroundImageContentBlockId: contentBlockId,
            },
            true,
        );
    };

    const handleImage = (contentBlockId) => {
        openModal(
            {
                modalComponent: "ImageModal",
                imageContentBlockId: contentBlockId,
            },
            true,
        );
    };

    const handleVideo = (contentBlockId) => {
        openModal(
            {
                modalComponent: "VideoModal",
                videoContentBlockId: contentBlockId,
            },
            true,
        );
    };

    const editorFunctionMap: {
        [index: string]: (param?: any) => {} | void;
    } = {
        [ContentBlockType.Page]: (pageId: string) => {
            router.visit(
                sapienRoute("creator.design.move-page.show", {
                    pageId,
                }),
                {
                    preserveScroll: true,
                    preserveState: true,
                    only: ["locationInfo"],
                },
            );
        },
        [ContentBlockType.Question]: (contentBlockId: string) => {
            router.visit(
                sapienRoute("creator.design.questions.edit", {
                    contentBlockId,
                }),
                {
                    preserveScroll: true,
                    preserveState: true,
                },
            );
        },
        [ContentBlockType.Image]: handleImage,
        [ContentBlockType.Video]: handleVideo,
        [LayoutType.Hero]: handleImage,
        [ContentBlockType.Card]: handleBackgroundImage,
        [ContentBlockType.Section]: handleBackgroundImage,
        [ContentBlockType.Box]: handleBackgroundImage,
        [ContentBlockType.Hero3]: handleBackgroundImage,
        [ContentBlockType.Navbar]: handleBackgroundImage,
        [ContentBlockType["Inbox Notification"]]: (
            notificationModalContentBlockId,
        ) => {
            SapienInertia.post("modal.show", {
                modalComponent: "NotificationModal",
                modalPageProps: JSON.stringify({
                    NotificationModal: {
                        notificationModalContentBlockId,
                    },
                }),
            });
        },
        [ContentBlockType["Chart Container"]]: (id: string) => {
            const chart = getContentBlockWithChildren(id);
            openModal({
                modalComponent: "ChartWizard",
                parentContentBlockId: id,
                chartId: chart.id,
            });
        },
        [ContentBlockType.Leaderboard]: (parentContentBlockId: string) => {
            const table = getContentBlockWithChildren(parentContentBlockId);
            if (table.table_data) return;
            openModal({
                modalComponent: "TableWizard",
                parentContentBlockId: table.parent_content_block_id,
                tableId: table.id,
                type: "LEADERBOARD",
            });
        },
        [ContentBlockType.Table]: (parentContentBlockId: string) => {
            const table = getContentBlockWithChildren(parentContentBlockId);
            if (table.table_data) return;
            openModal({
                modalComponent: "TableWizard",
                parentContentBlockId: table.parent_content_block_id,
                tableId: table.id,
            });
        },
    };

    function getEditType(block: ContentBlockShape) {
        if (imageEditTypes.includes(block.content_block_type)) return "picture";
        if (block.content_block_type === ContentBlockType.Video) return "video";
        return "edit";
    }

    const { device } = useMobileViewState();

    const marginLeft = useMemo(() => {
        if (
            device ||
            contentBlockNested.content_block_type === ContentBlockType.Modal
        )
            return parseInt(editorSizes.leftMenu) + 60;

        if (parentIsCloseToRightOfViewPort) {
            return "right-0";
        }

        if (contentBlockNested.content_block_type === ContentBlockType.Page) {
            return "ml-[5px] @4xl:ml-[60px]";
        }

        if (
            contentBlockNested.content_block_type ===
            ContentBlockType["Rich Text"]
        ) {
            return "right-0";
        }

        //left menu is open
        if (!isParentTopVisible) {
            return parseFloat(editorSizes.leftMenu) > 0
                ? "ml-[360px] mt-12 left-0"
                : "left-0 ml-12 mt-12";
        }

        return "left-0 ml-[5px]";
    }, [
        isParentTopVisible,
        isParentBottomVisible,
        parentIsCloseToRightOfViewPort,
        editorSizes,
        device,
        contentBlockNested,
    ]);

    const positionClasses = useMemo(() => {
        if (
            device ||
            contentBlockNested.content_block_type === ContentBlockType.Modal
        )
            return "left-6 top-14 absolute z-100";

        if (contentBlockNested.content_block_type === ContentBlockType.Page) {
            return isParentTopVisible
                ? "mt-4 ml-4 absolute z-100"
                : "top-16 left-0 @4xl/with-side-nav:left-12 @4xl/with-side-nav:top-4 fixed z-100";
        }

        console.log("isParentTopVisible", isParentTopVisible,nestedTypes.includes(contentBlockNested.content_block_type));
        return isParentTopVisible ||
            nestedTypes.includes(contentBlockNested.content_block_type)
            ? "-top-14 absolute z-100"
            : isParentBottomVisible
              ? "-bottom-14 absolute z-100"
              : "top-16 @4xl/with-side-nav:left-12 @4xl/with-side-nav:top-8 left-0 fixed z-100";
    }, [
        isParentTopVisible,
        isParentBottomVisible,
        editorSizes,
        device,
        contentBlockNested,
    ]);

    const { gotContentBlock } = useGotContentBlock();
    async function saveContentBlocks(contentBlock: ContentBlockShape) {
        const config =
            getLayoutConfigByType(contentBlock.content_block_type) ||
            getContentBlockConfigByType(contentBlock.content_block_type);

        if ("function" === typeof config?.saveOverride) {
            const saved = await config?.saveOverride(
                contentBlock,
                selectedSimulation.slug,
            );
            if (saved) {
                Array.isArray(saved)
                    ? saved.forEach((contentBlock) =>
                          gotContentBlock(contentBlock),
                      )
                    : gotContentBlock(saved);
            }
        } else {
            const { contentBlock: block } = await createContentBlock({
                body: {
                    ...contentBlock,
                    position: "after",
                } as CreateContentBlockBody,
                selectedSimulation,
            });

            gotContentBlock(block);
        }
    }

    return (
        <div
            data-testid="content-block-edit-popover"
            id="content-block-edit-popover"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            className={`popover z-[9999] pointer-events-auto overflow-hidden rounded-lg bg-white text-sm
            text-gray-800 opacity-100 shadow-md shadow-blue-600 ring-1 ring-black
            ring-opacity-5 hover:bg-white ${positionClasses} ${marginLeft}`}
        >
            <ul className="relative flex list-none gap-1 p-2 transition-all">
                <li
                    //override styles from navbar theme
                    style={{ color: "#111827" }}
                    className="flex min-w-[1.5rem] items-center justify-center truncate rounded-md
                        text-gray-900"
                >
                    {contentBlockNested?.title ||
                        titleCase(contentBlockNested?.content_block_type)}
                </li>
                <ReorderButtons
                    showUpButton={!!parentType && siblingIndex !== 0}
                    showDownButton={
                        parentType && siblingIndex !== siblingCount - 1
                    }
                    contentBlockId={contentBlockNested?.id}
                    parentContentBlockId={
                        contentBlockNested?.parent_content_block_id
                    }
                    currentPosition={siblingIndex}
                    isDisabled={false}
                />

                {(getShouldShowDeleteButton(
                    contentBlockNested.content_block_type,
                    parentType,
                ) ||
                    getCanAddChildren(contentBlockNested.content_block_type) ||
                    editableTypes.includes(
                        contentBlockNested.content_block_type,
                    )) && (
                    <>
                        {contentBlockNested.content_block_type !==
                            ContentBlockType.Page && (
                            <ContentBlockButtonContainer hoverColor="bg-blue-600 text-white">
                                <button
                                    onClick={() => {
                                        openModal({
                                            modalComponent:
                                                "DynamicContentModal",
                                            contentBlockId: activeId,
                                        });
                                    }}
                                    className="rounded-md p-1 transition-all hover:text-white"
                                    data-testid="dynamic-content-button"
                                >
                                    {" "}
                                    <EyeIcon className="h-5 w-5" />
                                </button>
                            </ContentBlockButtonContainer>
                        )}
                        {contentBlockNested.content_block_type ===
                            ContentBlockType["Model Value Display"] && (
                            <ContentBlockButtonContainer
                                onClick={() => {
                                    openModal({
                                        modalComponent: "ModelDataSourceModal",
                                        contentBlockId: activeId,
                                    });
                                }}
                                hoverColor={"bg-blue-600 hover:text-white"}
                            >
                                <VariableIcon className="h-5 w-5" />
                            </ContentBlockButtonContainer>
                        )}

                        {/* {contentBlockNested.content_block_type !==
                            ContentBlockType.Page && (
                            <span data-testid="content-block-move-button">
                                <MoveButton
                                    isDisabled={false}
                                    onClick={() => {
                                        setMovedContentBlock(
                                            contentBlockNested,
                                        );
                                    }}
                                />
                            </span>
                        )} */}
                        {getShouldShowDeleteButton(
                            contentBlockNested.content_block_type,
                            parentType,
                        ) && (
                            <DeleteButton
                                showDeleteButton={getShouldShowDeleteButton(
                                    contentBlockNested.content_block_type,
                                    parentType,
                                )}
                                deleteContentBlock={() => {
                                    SapienInertia.delete(
                                        sapienRoute(
                                            "creator.design.content-blocks.destroy",
                                            {
                                                id: contentBlockNested.id,
                                            },
                                        ),
                                    );
                                    discardActiveContentBlock();
                                }}
                                topPosition={parentType && 0}
                                bottomPosition={!parentType && 0}
                                isDisabled={false}
                            />
                        )}
                        {getCanAddChildren(
                            contentBlockNested.content_block_type,
                        ) && (
                            <AddButton
                                showAddButton={getCanAddChildren(
                                    contentBlockNested.content_block_type,
                                )}
                                addContentBlock={() => {
                                    const config = getContentBlockConfigByType(
                                        contentBlockNested.content_block_type,
                                    );

                                    if (
                                        config &&
                                        "function" === typeof config.onClickAdd
                                    ) {
                                        const withChildren =
                                            getContentBlockWithChildren(
                                                contentBlockNested.id,
                                            );
                                        const blockOrConfigs =
                                            config.onClickAdd(withChildren);
                                        if (!Array.isArray(blockOrConfigs)) {
                                            saveContentBlocks(blockOrConfigs);
                                        }
                                    }
                                }}
                                isDisabled={false}
                            />
                        )}
                        {!!contentBlockNested.behaviors?.click
                            ?.ShowModalContentBlock?.modalContentBlockId && (
                            <ContentBlockButtonContainer
                                onClick={() => {
                                    const modalContentBlockId =
                                        contentBlockNested?.behaviors?.click
                                            ?.ShowModalContentBlock
                                            ?.modalContentBlockId ||
                                        contentBlockNested.id;

                                    const block =
                                        getContentBlockWithChildren(
                                            modalContentBlockId,
                                        );
                                    if (block) {
                                        setActiveContentBlock(
                                            modalContentBlockId,
                                        );
                                    }
                                }}
                                hoverColor={"bg-blue-600 hover:text-white"}
                            >
                                <WindowIcon className="h-5 w-5" />
                            </ContentBlockButtonContainer>
                        )}
                        {contentBlockNested.content_block_type ===
                            ContentBlockType.Form && (
                            <ContentBlockButtonContainer hoverColor="bg-blue-600 hover:text-white">
                                <button
                                    onClick={() =>
                                        openModal({
                                            modalComponent:
                                                "DynamicValidationRuleModal",
                                            contentBlockId: activeId,
                                        })
                                    }
                                    data-testid="open-dynamic-validation-rule-modal-button"
                                >
                                    <ShieldCheckIcon className="h-5 w-5" />
                                </button>
                            </ContentBlockButtonContainer>
                        )}
                        {Object.keys(editorFunctionMap).includes(
                            contentBlockNested.content_block_type,
                        ) && (
                            <EditButton
                                data-testid="edit-button"
                                //TODO: we need more robust logic to determine whether this is visible
                                showEditButton={
                                    Object.keys(editorFunctionMap).includes(
                                        contentBlockNested.content_block_type,
                                    ) && !contentBlockNested?.table_data?.length
                                }
                                editFunction={() => {
                                    if (
                                        Object.keys(editorFunctionMap).includes(
                                            contentBlockNested.content_block_type,
                                        )
                                    ) {
                                        if (
                                            contentBlockNested.content_block_type ===
                                                ContentBlockType.Question &&
                                            contentBlockNested?.prompt
                                                ?.prompt_type ===
                                                PromptType["Drag and Drop"] &&
                                            !contentBlockNested?.prompt?.options
                                                ?.length
                                        ) {
                                            SapienInertia.get(
                                                sapienRoute(
                                                    "creator.design.questions.drag-and-drop.edit",
                                                    {
                                                        drag_and_drop:
                                                            contentBlockNested
                                                                ?.prompt?.id,
                                                    },
                                                ),
                                                {},
                                                {
                                                    preserveScroll: true,
                                                    preserveState: true,
                                                },
                                            );
                                            return;
                                        }
                                        editorFunctionMap[
                                            contentBlockNested
                                                .content_block_type
                                        ](activeId);
                                    }
                                }}
                                editType={getEditType(contentBlockNested)}
                                isDisabled={false}
                            />
                        )}
                    </>
                )}
                <ContentBlockButtonContainer hoverColor="bg-blue-600 hover:text-white">
                    <button
                        onClick={() => {
                            openModal({
                                modalComponent: "ContentBlockTemplateModal",
                                contentBlockId: activeId,
                            });
                        }}
                        title="Create a template from this block"
                    >
                        <DocumentDuplicateIcon className="h-5 w-5" />
                    </button>
                </ContentBlockButtonContainer>
                <ContentBlockButtonContainer hoverColor="bg-blue-600 hover:text-white">
                    <Link
                        href={sapienRoute(
                            "creator.design.content-block.update.from-template",
                            {
                                parentContentBlockId:
                                    contentBlockNested.parent_content_block_id,
                                replacedContentBlockId: activeId,
                            },
                        )}
                        alt="Replace this block with a template"
                        title="Replace this block with a template"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1200pt"
                            height="1200pt"
                            version="1.1"
                            viewBox="0 0 1200 1200"
                            className="h-5 w-5"
                            fill="currentColor"
                        >
                            <g>
                                <path d="m670.8 284.4c15.602-15.602 15.602-42 0-57.602l-132-132c-15.602-15.602-42-15.602-57.602 0-15.602 15.602-15.602 42 0 57.602l63.602 63.602h-180c-100.8 0-182.4 81.602-182.4 182.4v223.2c0 22.801 18 40.801 40.801 40.801s40.801-18 40.801-40.801l-0.003906-224.4c0-55.199 45.602-100.8 100.8-100.8h180l-63.602 63.602c-15.602 15.602-15.602 42 0 57.602 8.3984 8.3984 18 12 28.801 12s20.398-3.6016 28.801-12z" />
                                <path d="m976.8 537.6c-22.801 0-40.801 18-40.801 40.801v223.2c0 55.199-45.602 100.8-100.8 100.8h-180l63.602-63.602c15.602-15.602 15.602-42 0-57.602-15.602-15.602-42-15.602-57.602 0l-132 133.2c-15.602 15.602-15.602 42 0 57.602l133.2 133.2c8.3984 8.3984 18 12 28.801 12s20.398-3.6016 28.801-12c15.602-15.602 15.602-42 0-57.602l-64.801-63.598h180c100.8 0 182.4-81.602 182.4-182.4l0.003906-223.2c0-22.797-18-40.797-40.801-40.797z" />
                                <path d="m976.8 62.398c-106.8 0-193.2 86.398-193.2 193.2 0 106.8 86.398 193.2 193.2 193.2s193.2-86.398 193.2-193.2c0-106.8-86.398-193.2-193.2-193.2z" />
                                <path d="m223.2 751.2c-106.8 0-193.2 86.398-193.2 193.2 0 106.8 86.398 193.2 193.2 193.2s193.2-86.398 193.2-193.2c0-106.8-86.398-193.2-193.2-193.2z" />
                            </g>
                        </svg>
                    </Link>
                </ContentBlockButtonContainer>
            </ul>
        </div>
    );
};
