import { SapienInertia } from "@/inertia-utils/hooks";
import { RoundShape, TeamShape, UserShape } from "@/models";
import { ModalContainer } from "@/modules/shared";
import {
    Combobox,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

type Props = {
    team: TeamShape;
    roundId: string;
    participants: UserShape[];
};

export function AddUserToTeam({ team, participants, roundId }: Props) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedUser, setSelectedUser] = useState<UserShape | null>(null);
    const [query, setQuery] = useState("");
    const filteredParticipants = participants.filter(
        (participant) =>
            participant.name.toLowerCase().includes(query.toLowerCase()) ||
            participant.email.toLowerCase().includes(query.toLowerCase()),
    );

    const addParticipant = async (participant: UserShape) => {
        return SapienInertia.post(
            "facilitation.participant.add",
            {
                ...participant,
                round_id: roundId,
                team_id: team.id,
            },
            {},
            {
                preserveScroll: true,
                onSuccess: () => {
                    setIsModalOpen(false);
                    setSelectedUser(null);
                    setQuery("");
                },
            },
        );
    };

    return (
        <>
            <button
                className="inline-flex items-center gap-2 rounded-md bg-indigo-600 px-4 py-2 text-sm
                    font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpen(true)}
            >
                <UserPlusIcon className="h-6 w-6" />
                <span>Add Existing User to {team.name}</span>
            </button>
            <ModalContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                size="l"
            >
                <div className="flex flex-col gap-4 rounded-md border border-slate-100/50 bg-white/75 p-3">
                    <div className="flex flex-col gap-4 ">
                        <div className="flex min-w-60 items-center gap-2">
                            <UserPlusIcon className="h-6 w-6" />{" "}
                            <span>Add Existing User to {team.name}</span>
                        </div>
                    </div>
                    <div className="relative flex flex-col gap-4">
                        <Combobox
                            value={selectedUser}
                            onChange={(user) => {
                                if (!user) return;
                                setSelectedUser(user);
                                setQuery(`${user.name} ${user.email}`);
                            }}
                        >
                            <ComboboxInput
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-900
                                    ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                    focus:ring-inset focus:ring-indigo-600"
                                placeholder={`Search for a user`}
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                }}
                                value={query}
                                autoComplete="off"
                            />
                            <ComboboxOptions
                                className="absolute left-0 top-10 z-10 max-h-60 w-full overflow-y-auto rounded-md border
                                    border-slate-100/50 bg-white p-4 shadow-md scrollbar-thin
                                    scrollbar-track-slate-100 scrollbar-thumb-slate-300"
                            >
                                {filteredParticipants.map((participant) => (
                                    <ComboboxOption
                                        key={participant.id}
                                        value={participant}
                                        className="flex items-center gap-2 rounded-md p-2 text-sapien-blue hover:bg-sapien-blue
                                            hover:text-white"
                                    >
                                        <UserPlusIcon className="h-6 w-6" />
                                        <span>{participant.name}</span>
                                        <span>{participant.email}</span>
                                    </ComboboxOption>
                                ))}
                            </ComboboxOptions>
                        </Combobox>
                        <div className="flex justify-end gap-2 border-t border-slate-100/50 pt-4">
                            <button
                                className="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                    hover:bg-red-700 focus-visible:outline focus-visible:outline-2
                                    focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedUser(null);
                                    setQuery("");
                                    setIsModalOpen(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                    data-[is-loading=true]:animate-pulse hover:bg-indigo-700 focus-visible:outline
                                    focus-visible:outline-2 focus-visible:outline-offset-2
                                    focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:opacity-50"
                                // data-is-loading={!selectedUser}
                                disabled={!selectedUser}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    await addParticipant(selectedUser);
                                    setIsModalOpen(false);
                                }}
                            >
                                <span>Add User</span>
                            </button>
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
}
