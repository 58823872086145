import React, { useMemo } from "react";
import {
    Cohort,
    ModelBlock,
    ModelVariable,
    Simulation,
    Team,
    VariableValue,
} from "@/models";
import { DiscoveryVariableTable } from "./DiscoveryVariableTable";
import { DiscoveryWidgetWrapper } from "../shared";

export const DiscoveryBlockVariableDisplay = ({
    selectedBlockIds,
    toggleBlockId,
    modelBlock,
    blockVariableMap,
    lineageIdMap,
    variableMap,
    valueMap,
    selectedSimulation,
    selectedCohort,
    teams,
}: {
    selectedBlockIds: { [index: string]: boolean };
    toggleBlockId: (blockId?: string) => void;
    modelBlock: ModelBlock;
    blockVariableMap: { [index: string]: ModelVariable[] };
    lineageIdMap: { [index: string]: boolean };
    variableMap: {
        [index: string]: ModelVariable;
    };
    valueMap: {
        [variableId: string]: {
            [scopeId: string]:
                | VariableValue
                | { [timeHorizonId: string]: VariableValue };
        };
    };
    selectedSimulation: Partial<Simulation>;
    selectedCohort: Cohort;
    teams: Team[];
}) => {
    const childBlocksToShow = useMemo(() => {
        return (
            !!modelBlock.modelBlocks?.length &&
            modelBlock.modelBlocks.filter((child) => !!lineageIdMap[child.id])
        );
    }, [modelBlock, blockVariableMap]);

    const isExpanded = useMemo(() => {
        return !!selectedBlockIds[modelBlock.id];
    }, [modelBlock, selectedBlockIds]);

    const filteredVariableMapKeys = useMemo(() => {
        return Object.keys(variableMap).filter(
            (variableId) =>
                variableMap[variableId].model_block_id === modelBlock.id &&
                !!valueMap[variableId] &&
                !!Object.keys(valueMap[variableId])?.length,
        );
    }, [modelBlock, variableMap, valueMap]);

    return (
        <>
            {isExpanded &&
                !!blockVariableMap[modelBlock.id]?.length &&
                !!filteredVariableMapKeys?.length && (
                    <DiscoveryWidgetWrapper
                        key={modelBlock.id}
                        className="bg-rose-50/80"
                    >
                        <div
                            className="mb-1 cursor-pointer border-b border-b-neutral-300 pb-1 text-xs font-semibold"
                            onClick={() => toggleBlockId(modelBlock.id)}
                        >
                            {modelBlock.label}
                        </div>
                        {filteredVariableMapKeys.map((variableId) => (
                            <div className="mt-5" key={variableId}>
                                <div className="flex flex-row items-end justify-between pb-2">
                                    <div className="text-sm">
                                        {variableMap[variableId].label}
                                    </div>
                                    <div className="hidden pl-1 text-xs font-light sm:inline-flex">
                                        {variableMap[variableId].scope}
                                    </div>
                                </div>
                                <div className="">
                                    <DiscoveryVariableTable
                                        selectedSimulation={selectedSimulation}
                                        selectedCohort={selectedCohort}
                                        modelVariable={variableMap[variableId]}
                                        teams={teams}
                                        variableValueMap={valueMap[variableId]}
                                    />
                                </div>
                            </div>
                        ))}
                    </DiscoveryWidgetWrapper>
                )}
            {!!childBlocksToShow?.length && (
                <>
                    {childBlocksToShow.map((child) => (
                        <DiscoveryBlockVariableDisplay
                            key={child.id}
                            modelBlock={child}
                            selectedBlockIds={selectedBlockIds}
                            toggleBlockId={toggleBlockId}
                            blockVariableMap={blockVariableMap}
                            lineageIdMap={lineageIdMap}
                            variableMap={variableMap}
                            valueMap={valueMap}
                            selectedSimulation={selectedSimulation}
                            selectedCohort={selectedCohort}
                            teams={teams}
                        />
                    ))}
                </>
            )}
        </>
    );
};
