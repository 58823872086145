import React from "react";
import Icon from "react-icons-kit";
import { sliders } from "react-icons-kit/feather/sliders";
import { ModelVariable } from "@/models";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ArrowLongRightIcon,
} from "@heroicons/react/24/solid";
import { DataCardValueMapItem } from "../types";
import { FavoriteToggleIcon } from "../shared";

const InteractiveDashboardCardComponent = ({
    modelVariable,
    dataCardValueMapItem,
}: {
    modelVariable: ModelVariable;
    dataCardValueMapItem: DataCardValueMapItem;
}) => {
    const {
        initialValue,
        initialFormatted,
        finalValue,
        finalFormatted,
        differenceNumerical,
        differenceFormatted,
    } = dataCardValueMapItem;
    return (
        <div className="relative flex h-full items-center justify-between p-4 px-5">
            <span className="absolute right-1 top-1 flex">
                <FavoriteToggleIcon modelVariableId={modelVariable.id} />
            </span>
            {!!modelVariable.expose_to_designer && (
                <span className="absolute left-1 top-1">
                    <span
                        title="Input Variable"
                        className="text-slate-400 dark:text-slate-600"
                    >
                        <Icon
                            icon={sliders}
                            size={12}
                            style={{ display: "flex" }}
                        />
                    </span>
                </span>
            )}
            <div className="flex h-full w-full flex-col space-y-2 pt-0.5">
                <div className="flex h-full w-full flex-col justify-between space-y-0.5">
                    <div
                        className={`flex w-full items-center justify-end text-sm font-bold ${
                            differenceNumerical == 0 || !differenceNumerical
                                ? "text-slate-500 dark:text-slate-500"
                                : differenceNumerical > 0
                                  ? "text-green-600 dark:text-green-500"
                                  : "text-red-600 dark:text-red-500"
                        } ${
                            differenceNumerical != undefined
                                ? ""
                                : "animate-pulse"
                        }`}
                        title={`Change Year 1 to Year ${finalValue?.time_index + 1}`}
                    >
                        {`${differenceFormatted}`}
                        {differenceNumerical == undefined ? (
                            ""
                        ) : differenceNumerical == 0 ? (
                            <ArrowLongRightIcon className="ml-1 h-3 w-3" />
                        ) : differenceNumerical > 0 ? (
                            <ArrowUpIcon className="ml-1 h-4 w-4" />
                        ) : (
                            <ArrowDownIcon className="ml-1 h-4 w-4" />
                        )}
                    </div>
                    <div className="flex items-center text-sm font-medium text-slate-700 dark:text-slate-400">
                        {modelVariable.label}
                    </div>
                </div>
                <div
                    className={
                        "flex h-full w-full flex-wrap items-center justify-between gap-1"
                    }
                >
                    <div className="flex flex-col">
                        <div
                            className={`text-left text-lg font-extrabold tracking-wide text-slate-700
                            dark:text-slate-300 ${!initialFormatted ? "animate-pulse" : ""}`}
                            title={
                                !!initialValue
                                    ? !!modelVariable.resample_function
                                        ? `Year ${initialValue?.time_index + 1} Value (${modelVariable.resample_function})`
                                        : `Year ${initialValue?.time_index + 1} Value`
                                    : ""
                            }
                        >
                            {initialFormatted || "..."}
                        </div>
                        <div className="text-xs text-slate-600 dark:text-slate-400">{`Year 1`}</div>
                    </div>
                    <div className="flex flex-col items-end">
                        <div
                            className={`text-lg font-extrabold tracking-wide text-slate-700 dark:text-slate-300
                            ${!finalFormatted ? "animate-pulse" : ""}`}
                            title={
                                !!finalValue
                                    ? !!modelVariable.resample_function
                                        ? `Year ${finalValue?.time_index + 1} Value (${modelVariable.resample_function})`
                                        : `Year ${finalValue?.time_index + 1} Value`
                                    : ""
                            }
                        >
                            {finalFormatted || "..."}
                        </div>
                        <div className="text-xs text-slate-600 dark:text-slate-400">{`Year ${finalValue?.time_index + 1}`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const InteractiveDashboardCard = React.memo(
    InteractiveDashboardCardComponent,
);
