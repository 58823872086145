import React, { useState } from "react";
import { FormInputToggle, FormToggleLabel } from "@/components";
import { RoundShape, TeamWithProgressData } from "@/models";
import Icon from "react-icons-kit";
import { mapPin } from "react-icons-kit/feather/mapPin";
import { minus } from "react-icons-kit/feather/minus";
import { circleO } from "react-icons-kit/fa/circleO";
import { circleONotch } from "react-icons-kit/fa/circleONotch";

const ProgressRing = ({
    radius,
    stroke,
    progress,
    color,
}: {
    radius: number;
    stroke: number;
    progress: number;
    color?: string;
}) => {
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <svg height={radius * 2} width={radius * 2}>
            <g>
                <circle
                    className="text-gray-300"
                    stroke="currentColor"
                    fill="transparent"
                    strokeWidth={stroke}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    stroke={color || "#0374fe"}
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={circumference + " " + circumference}
                    style={{
                        strokeDashoffset,
                        transition: "stroke-dashoffset 0.35s",
                        transform: "rotate(-90deg)",
                        transformOrigin: "50% 50%",
                    }}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontWeight={500}
                    fontSize={radius * 0.42}
                    fill="black"
                >
                    {`${progress}%`}
                </text>
            </g>
        </svg>
    );
};

const LegendItem = (props: { icon: any; color: string; text: string }) => {
    return (
        <span className="inline-flex flex-nowrap space-x-2">
            <Icon
                icon={props.icon}
                className="cursor-default"
                style={{
                    color: props.color,
                }}
            />
            <span>{props.text}</span>
        </span>
    );
};

const PlacementIndicator = ({
    participantCount,
}: {
    participantCount: number;
}) => {
    // if (!participantCount) return;
    return (
        <span
            style={{
                paddingLeft: "10px",
                display: "inline-flex",
                alignItems: "center",
            }}
        >
            <Icon
                icon={mapPin}
                style={{
                    cursor: "default",
                    color: !!participantCount ? "#545454" : "#54545400",
                }}
            />
            <span
                style={{
                    color: !!participantCount ? "#545454" : "#54545400",
                }}
            >
                {!!participantCount ? participantCount : 0}
            </span>
        </span>
    );
};

const TeamProgressCell = ({
    team,
    round,
    showPlacement,
}: {
    team: TeamWithProgressData;
    round: RoundShape;
    showPlacement: boolean;
}) => {
    return (
        <div className="flex justify-center">
            {!!round.prompts_count &&
                !!team.participants?.length &&
                !!team.progress_map &&
                !!team.progress_map[round.id] && (
                    <ProgressRing
                        radius={28}
                        stroke={4}
                        progress={team.progress_map[round.id].percent_complete}
                        color={
                            team.progress_map[round.id].percent_complete < 100
                                ? "#dc3545"
                                : "#28a745"
                        }
                    />
                )}
            {!round.prompts_count && (
                <span className="min-w-14">
                    <Icon
                        icon={minus}
                        className="cursor-default text-[#aaaaaa]"
                    />
                </span>
            )}
            {showPlacement && !!team.participants?.length && (
                <PlacementIndicator
                    participantCount={
                        (!!team.team_user_rounds &&
                            team.team_user_rounds?.filter(
                                (teamUserRound) =>
                                    teamUserRound.round_id == round.id,
                            )?.length) ||
                        0
                    }
                />
            )}
        </div>
    );
};

export const CohortProgressDisplay = ({
    teams,
    rounds,
    isCourse,
}: {
    teams: TeamWithProgressData[];
    rounds: RoundShape[];
    isCourse: boolean;
}) => {
    const [showPlacement, setShowPlacement] = useState<boolean>(true);
    const [horizontal, setHorizontal] = useState<boolean>(true);

    return (
        <div className="space-y-4">
            <div className="flex flex-wrap items-center gap-6">
                <div className="flex flex-row items-center space-x-2 text-sm">
                    <label>{"Horizontal"}</label>
                    <div className="relative">
                        <FormInputToggle
                            type="checkbox"
                            id="toggle-orientation"
                            checked={horizontal}
                            onChange={() => setHorizontal(!horizontal)}
                        />
                        <FormToggleLabel htmlFor="toggle-orientation" />
                    </div>
                </div>
                <div className="flex flex-row items-center space-x-2 text-sm">
                    <label>{"Show Placement"}</label>
                    <div className="relative">
                        <FormInputToggle
                            type="checkbox"
                            id="toggle"
                            checked={showPlacement}
                            onChange={() => setShowPlacement(!showPlacement)}
                        />
                        <FormToggleLabel htmlFor="toggle" />
                    </div>
                </div>
                <div className="space-x-4 text-xs">
                    <LegendItem
                        icon={circleO}
                        color={"#28a745"}
                        text={`${!isCourse ? "Round" : "Lesson"} Complete`}
                    />
                    <LegendItem
                        icon={circleONotch}
                        color={"#dc3545"}
                        text={`${!isCourse ? "Round" : "Lesson"} Incomplete`}
                    />
                    <LegendItem
                        icon={minus}
                        color={"#aaaaaa"}
                        text={"No Questions"}
                    />
                    <LegendItem
                        icon={mapPin}
                        color={"#545454"}
                        text={"Participant Location"}
                    />
                </div>
            </div>

            {teams !== undefined && rounds !== undefined ? (
                horizontal ? (
                    <div className="overflow-x-auto border">
                        <table className="w-full table-auto text-left text-sm text-gray-500">
                            <thead className="border-b bg-white">
                                <tr>
                                    <td
                                        rowSpan={2}
                                        className="table-col sticky left-0 max-w-64 bg-white px-2 py-2 font-semibold ring-1
                                            ring-inset ring-slate-200"
                                    >
                                        <div className="inline-flex w-full items-center justify-between text-nowrap">
                                            <span>
                                                {!isCourse ? "Round" : "Lesson"}
                                            </span>
                                        </div>
                                    </td>
                                    {rounds
                                        .filter(
                                            (round) =>
                                                showPlacement ||
                                                round.prompts_count > 0,
                                        )
                                        .map((round, roundIndex) => (
                                            <th
                                                key={`${roundIndex}-${round.id}`}
                                                className="border-l px-2 py-1"
                                            >
                                                <div className="flex flex-col justify-between">
                                                    <span
                                                        className="text-gray-700"
                                                        title={round.title}
                                                    >{`${roundIndex + 1}`}</span>
                                                </div>
                                            </th>
                                        ))}
                                </tr>
                                <tr>
                                    {rounds
                                        .filter(
                                            (round) =>
                                                showPlacement ||
                                                round.prompts_count > 0,
                                        )
                                        .map((round, roundIndex) => (
                                            <th
                                                key={round.id}
                                                className="border-l px-2 py-1"
                                            >
                                                <div className="flex flex-col justify-between">
                                                    <span className="max-w-28 text-xs font-normal">
                                                        {round.title}
                                                    </span>
                                                </div>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {teams
                                    .filter(
                                        (team) => !!team.participants?.length,
                                    )
                                    .map((team) => (
                                        <tr
                                            key={team.id}
                                            className={
                                                "table-row odd:bg-slate-50 even:bg-slate-100"
                                            }
                                        >
                                            <td
                                                key={team.id}
                                                className="table-col sticky left-0 min-w-32 max-w-64 bg-inherit px-2 py-1 font-semibold
                                                    ring-1 ring-inset ring-slate-200"
                                            >
                                                <div className="inline-flex w-full items-center justify-between font-normal">
                                                    <span>{team.name}</span>
                                                </div>
                                            </td>
                                            {rounds
                                                .filter(
                                                    (round) =>
                                                        showPlacement ||
                                                        round.prompts_count > 0,
                                                )
                                                .map((round) => (
                                                    <td
                                                        className="border-l px-2 py-1 text-center"
                                                        key={`${round.id}-${team.id}`}
                                                    >
                                                        <TeamProgressCell
                                                            team={team}
                                                            round={round}
                                                            showPlacement={
                                                                showPlacement
                                                            }
                                                        />
                                                    </td>
                                                ))}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="overflow-x-auto border">
                        <table className="w-full table-auto text-left text-sm text-gray-500">
                            <thead className="border-b bg-white">
                                <tr>
                                    <td
                                        colSpan={2}
                                        className="table-col sticky left-0 max-w-64 bg-white px-2 py-2 font-semibold ring-1
                                            ring-inset ring-slate-200"
                                    >
                                        <div className="inline-flex w-full items-center justify-between text-nowrap">
                                            <span>
                                                {!isCourse ? "Round" : "Lesson"}
                                            </span>
                                        </div>
                                    </td>
                                    {teams
                                        .filter(
                                            (team) =>
                                                !!team.participants?.length,
                                        )
                                        .map((team) => (
                                            <th
                                                key={team.id}
                                                className="border-l px-2 py-2 font-medium"
                                            >
                                                {team.name}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rounds
                                    .filter(
                                        (round) =>
                                            showPlacement ||
                                            round.prompts_count > 0,
                                    )
                                    .map((round, roundIndex) => (
                                        <tr
                                            key={round.id}
                                            className={
                                                "table-row odd:bg-slate-50 even:bg-slate-100"
                                            }
                                        >
                                            <th
                                                key={`${roundIndex}-${round.id}`}
                                                className="sticky left-0 min-w-9 border-l px-2 py-1"
                                            >
                                                <div className="flex flex-col justify-between">
                                                    <span
                                                        className="text-gray-700"
                                                        title={round.title}
                                                    >{`${roundIndex + 1}`}</span>
                                                </div>
                                            </th>
                                            <td
                                                key={round.id}
                                                className="table-col sticky left-9 min-w-32 max-w-64 bg-inherit px-2 py-2 text-xs
                                                    font-normal ring-1 ring-inset ring-slate-200"
                                            >
                                                <div className="inline-flex w-full items-center justify-between">
                                                    <span>{round.title}</span>
                                                </div>
                                            </td>
                                            {teams
                                                .filter(
                                                    (team) =>
                                                        !!team.participants
                                                            ?.length,
                                                )
                                                .map((team) => (
                                                    <td
                                                        className="border-l px-2 py-1 text-center"
                                                        key={`${round.id}-${team.id}`}
                                                    >
                                                        <TeamProgressCell
                                                            team={team}
                                                            round={round}
                                                            showPlacement={
                                                                showPlacement
                                                            }
                                                        />
                                                    </td>
                                                ))}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )
            ) : (
                <div></div>
            )}
        </div>
    );
};
