import React from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Head, Link } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import ApplicationLogo from "@/components/ApplicationLogo";
import DropdownAvatar from "@/Layouts/DropdownAvatar";

const CentralAdminWrapper = ({
    auth,
    children,
}: SapienPageProps & { children: React.ReactNode }) => {
    return (
        <>
            <Head title={"Tenants"} />
            <div>
                <nav className="bg-white shadow">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <Link href={sapienRoute("tenant.index")}>
                                        <ApplicationLogo className="block h-9 w-auto text-slate-500" />
                                    </Link>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex">
                                    <div className="flex space-x-4">
                                        <Link
                                            href={sapienRoute("tenant.index")}
                                            className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm
                                                font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                        >
                                            Tenants
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <DropdownAvatar
                                auth={auth}
                                logoutRoute={"central.logout"}
                                logoutRouteMethod={"get"}
                            />
                        </div>
                    </div>
                </nav>

                <main className="flex justify-center">{children}</main>
            </div>
        </>
    );
};

export { CentralAdminWrapper };
