import React, { FC, ReactNode } from "react";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import { SubNavLink } from "./SubNavLink";
import { RectangleGroupIcon } from "@heroicons/react/24/outline";
import { Icon } from "react-icons-kit";
import { box } from "react-icons-kit/feather/box";

export const ProjectDashboardTopNav: FC<{
    header?: string;
    children?: ReactNode;
}> = ({ header, children }) => {
    const { props } = usePage();
    return (
        <div className="flex flex-row items-center space-x-8">
            <div className="flex flex-wrap gap-2 text-sm font-semibold">
                <SubNavLink
                    href={sapienRoute("simulation.projects.index")}
                    active={isCurrent("simulation.projects.index", props.route)}
                >
                    <span className={"mr-2"}>
                        <Icon
                            icon={box}
                            size={14}
                            style={{
                                display: "flex",
                            }}
                        />
                    </span>
                    <span>Projects</span>
                </SubNavLink>
                <SubNavLink
                    href={sapienRoute("templates.index")}
                    active={isCurrent("templates.index", props.route, [
                        "templates.edit",
                    ])}
                >
                    <span className={"mr-2"}>
                        <RectangleGroupIcon className="h-5 w-5" />
                    </span>
                    <span>Templates</span>
                </SubNavLink>
            </div>
        </div>
    );
};
