import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { TopNavLinkDiv } from "@/components";

const ModelBuilderNavCenterComponent = ({
    simulationSlug,
    isInteractive,
}: {
    simulationSlug: string;
    isInteractive: boolean;
}) => {
    return (
        <>
            <TopNavLinkDiv
                style={{
                    padding: 0,
                }}
            >
                <InertiaLink
                    href={
                        !!simulationSlug &&
                        sapienRoute("model-builder.index", {
                            simulationSlug: simulationSlug,
                        })
                    }
                >
                    <div
                        className={`px-1 text-sm font-medium tracking-wide sm:px-3 ${
                            !isInteractive
                                ? "text-indigo-400"
                                : "text-slate-200"
                        }`}
                    >
                        Simulation
                    </div>
                </InertiaLink>
            </TopNavLinkDiv>
            <TopNavLinkDiv
                style={{
                    padding: 0,
                }}
            >
                <InertiaLink
                    href={
                        !!simulationSlug &&
                        sapienRoute("model-builder.interactive", {
                            simulationSlug: simulationSlug,
                            type: "interactive",
                        })
                    }
                >
                    <div
                        className={`px-1 text-sm font-medium tracking-wide sm:px-3 ${
                            !!isInteractive
                                ? "text-indigo-400"
                                : "text-slate-200"
                        }`}
                    >
                        Interactive
                    </div>
                </InertiaLink>
            </TopNavLinkDiv>
        </>
    );
};

export const ModelBuilderNavCenter = React.memo(ModelBuilderNavCenterComponent);
