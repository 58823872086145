import React, { useMemo } from "react";
import { Cohort, Participant, RoundShape, TeamShape } from "@/models";
import { SapienInertia } from "@/inertia-utils/hooks";

export const CohortRoundMenu = ({
    cohort,
    teams,
    rounds,
    label,
}: {
    cohort: Cohort;
    teams: TeamShape[];
    rounds: RoundShape[];
    label: string;
}) => {
    const cohortRoundId = useMemo(() => {
        let roundIdMap = {};
        if (!!teams?.length) {
            roundIdMap = teams.reduce((outerMap, team) => {
                if (!!team.participants) {
                    return {
                        ...outerMap,
                        ...team.participants.reduce((innerMap, participant) => {
                            if (participant.pivot?.round_id) {
                                return {
                                    ...innerMap,
                                    [participant.pivot.round_id]: true,
                                };
                            } else {
                                return innerMap;
                            }
                        }, {}),
                    };
                } else {
                    return outerMap;
                }
            }, {});
        }
        return Object.keys(roundIdMap).length === 1
            ? Object.keys(roundIdMap)[0]
            : "";
    }, [teams]);

    return (
        !!rounds && (
            <select
                id="location"
                name="location"
                className={`block w-full min-w-[200px] rounded-md border-0 border-slate-300 bg-white/75 px-3
                py-2 pr-8 text-sm font-normal leading-6 ring-1 ring-inset ring-slate-300
                focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-inset
                focus:ring-indigo-500 ${!!cohortRoundId ? "text-slate-700" : "text-slate-400"}`}
                value={cohortRoundId}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    e.stopPropagation();
                    SapienInertia.post(
                        "facilitation.set-cohort-round",
                        {
                            round_id: e.target.value,
                            cohort_id: cohort.id,
                        },
                        {
                            round_id: e.target.value,
                            cohort_id: cohort.id,
                        },
                        {
                            preserveScroll: true,
                        },
                    );
                }}
            >
                <option
                    key=""
                    value=""
                    disabled
                >{`-- Select ${label} for Cohort --`}</option>
                {rounds.map((round) => (
                    <option key={round.id} value={round.id}>
                        {round.title}
                    </option>
                ))}
            </select>
        )
    );
};

export const TeamRoundMenu = ({
    team,
    rounds,
    label,
}: {
    team: TeamShape;
    rounds: RoundShape[];
    label: string;
}) => {
    const teamRoundId = useMemo(() => {
        let roundIdMap = {};
        if (!!team?.participants) {
            roundIdMap = team.participants.reduce((map, participant) => {
                if (participant.pivot?.round_id) {
                    return {
                        ...map,
                        [participant.pivot.round_id]: true,
                    };
                } else {
                    return map;
                }
            }, {});
        }
        return Object.keys(roundIdMap).length === 1
            ? Object.keys(roundIdMap)[0]
            : "";
    }, [team]);

    return (
        !!rounds && (
            <select
                id="location"
                name="location"
                className={`block w-full min-w-[200px] rounded-md border-0 border-slate-300 bg-transparent
                px-3 py-2 pr-8 text-sm font-normal leading-6 ring-1 ring-inset ring-slate-300
                focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-inset
                focus:ring-indigo-500 ${!!teamRoundId ? "text-slate-700" : "text-slate-400"}`}
                value={teamRoundId}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    e.stopPropagation();
                    SapienInertia.post(
                        "facilitation.set-team-round",
                        {
                            round_id: e.target.value,
                            team_id: team.id,
                        },
                        {
                            round_id: e.target.value,
                            team_id: team.id,
                        },
                        {
                            preserveScroll: true,
                        },
                    );
                }}
            >
                <option
                    key=""
                    value=""
                    disabled
                >{`-- Select ${label} for Team --`}</option>
                {rounds.map((round) => (
                    <option key={round.id} value={round.id}>
                        {round.title}
                    </option>
                ))}
            </select>
        )
    );
};

export const ParticipantRoundMenu = ({
    participant,
    rounds,
}: {
    participant: Participant;
    rounds: RoundShape[];
}) => {
    return (
        !!rounds && (
            <select
                className="block w-full min-w-[200px] rounded-md border-0 border-slate-300 bg-transparent
                    px-3 py-2 pr-8 text-sm font-normal leading-6 text-slate-700 ring-1 ring-inset
                    ring-slate-300 placeholder:text-slate-400 focus:border-indigo-500
                    focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                value={participant.pivot?.round_id}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                    e.stopPropagation();
                    SapienInertia.post(
                        "facilitation.set-participant-round",
                        {
                            user_id: participant.pivot?.user_id,
                            team_id: participant.pivot.team_id,
                            round_id: e.target.value,
                        },
                        {
                            user_id: participant.pivot.user_id,
                            team_id: participant.pivot.team_id,
                            round_id: e.target.value,
                        },
                        {
                            preserveScroll: true,
                        },
                    );
                }}
            >
                {rounds.map((round) => (
                    <option key={round.id} value={round.id}>
                        {round.title}
                    </option>
                ))}
            </select>
        )
    );
};
